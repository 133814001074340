import { Navigate, Outlet } from "react-router-dom"
import { useAuthContext } from "../components/auth"
import { Paths } from "../constants"

export const RequireAuth: React.FC = () => {
  const auth = useAuthContext()

  if (!auth.isAuthenticated) {
    return <Navigate to={Paths.PUBLIC.LOGIN.PATH} />
  }

  return <Outlet />
}
