import { PathItem } from "@/types"

export const ROOM: PathItem = {
  LIST: {
    PATH: "rooms",
    LABEL: "Phòng học",
    BREADCRUMB: ["Phòng học"],
  },
  CREATE: { PATH: "rooms/create", LABEL: "Tạo mới phòng học", BREADCRUMB: ["Phòng học", "Tạo mới"] },
  EDIT: { PATH: "rooms/:id", LABEL: "Cập nhật phòng học", BREADCRUMB: ["Phòng học", "Cập nhật"] },
}

export const SUBJECT: PathItem = {
  LIST: { PATH: "subjects", LABEL: "Môn học", BREADCRUMB: ["Môn học"] },
  CREATE: { PATH: "subjects/create", LABEL: "Tạo mới môn học", BREADCRUMB: ["Môn học", "Tạo mới"] },
  EDIT: { PATH: "subjects/:id", LABEL: "Cập nhật môn học", BREADCRUMB: ["Môn học", "Cập nhật"] },
}

export const LOCATION: PathItem = {
  LIST: { PATH: "locations", LABEL: "Cơ sở", BREADCRUMB: ["Cơ sở"] },
  CREATE: { PATH: "locations/create", LABEL: "Tạo mới cơ sở", BREADCRUMB: ["Cơ sở"] },
  EDIT: { PATH: "locations/:id", LABEL: "Cập nhật cơ sở", BREADCRUMB: ["Cơ sở"] },
}

export const COURSE: PathItem = {
  LIST: { PATH: "courses", LABEL: "Khoá học", BREADCRUMB: [] },
  CREATE: { PATH: "courses/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "courses/:id", LABEL: "", BREADCRUMB: [] },
}

export const SCHOOL_HOUR: PathItem = {
  LIST: { PATH: "school-hours", LABEL: "Khung giờ học", BREADCRUMB: ["Khung giờ học"] },
  CREATE: { PATH: "school-hours/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "school-hours/:id", LABEL: "", BREADCRUMB: [] },
}

export const TEACHER_ROLE: PathItem = {
  LIST: { PATH: "teacher-roles", LABEL: "Phân môn giảng dạy", BREADCRUMB: ["Phân môn giảng dạy"] },
  CREATE: { PATH: "teacher-roles/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "teacher-roles/:id", LABEL: "", BREADCRUMB: [] },
}
export const CLASS_NUMBER: PathItem = {
  LIST: { PATH: "class-numbers", LABEL: "Sĩ số tối đa", BREADCRUMB: ["Sĩ số tối đa"] },
  CREATE: { PATH: "class-numbers/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "class-numbers/:id", LABEL: "", BREADCRUMB: [] },
}

export const TEACHER: PathItem = {
  LIST: { PATH: "teachers", LABEL: "Giáo viên", BREADCRUMB: ["Giáo viên"] },
  CREATE: { PATH: "teachers/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "teachers/:id", LABEL: "", BREADCRUMB: [] },
}

export const ABSENT_SCHEDULE: PathItem = {
  LIST: { PATH: "absent-schedule", LABEL: "Lịch nghỉ", BREADCRUMB: ["Lịch nghỉ"] },
  CREATE: { PATH: "absent-schedule/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "absent-schedule/:id", LABEL: "", BREADCRUMB: [] },
  VIEW: { PATH: "absent-schedule/view/:id", LABEL: "", BREADCRUMB: [] },
}

export const WORK_SCHEDULE: PathItem = {
  LIST: { PATH: "working-schedules", LABEL: "Đăng kí làm việc", BREADCRUMB: ["Đăng kí làm việc"] },
  CREATE: { PATH: "working-schedules/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "working-schedules/:id", LABEL: "", BREADCRUMB: [] },
}

export const CLASSES: PathItem = {
  LIST: { PATH: "classes", LABEL: "Lớp học", BREADCRUMB: ["Lớp học"] },
  CREATE: { PATH: "classes/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "classes/:id", LABEL: "", BREADCRUMB: [] },
}

export const WORK_TIME: PathItem = {
  LIST: { PATH: "work-times", LABEL: "Tổng Quan Lịch Học", BREADCRUMB: ["Tổng Quan Lịch Học"] },
  CREATE: { PATH: "work-times/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "work-times/:id", LABEL: "", BREADCRUMB: [] },
}

export const AVAILABILITY: PathItem = {
  LIST: { PATH: "availability", LABEL: "Kiểm tra khả dụng", BREADCRUMB: ["Kiểm tra khả dụng"] },
}

export const TEACH_SCHEDULE: PathItem = {
  LIST: { PATH: "teach-schedule", LABEL: "Lịch giảng dạy", BREADCRUMB: ["Lịch giảng dạy"] },
  CREATE: { PATH: "teach-schedule/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "teach-schedule/:id", LABEL: "", BREADCRUMB: [] },
}
export const EDU = "/edu/*"
