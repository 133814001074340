import { all, fork } from "redux-saga/effects";
import action from "./actions";
import auth from "./auth";
import department from "./department";
import course from "./course";
import employee from "./employee";
import permission from "./permission";
import profile from "./profile";
import role from "./role";
import room from "./room";
import schoolHour from "./school-hour";
import absentSchedule from "./absent-schedule";
import subject from "./subject";
import teacher from "./teacher";
import teacherRole from "./teacher-role";
import user from "./user";
import workSchedule from "./work-schedule";
import workTimes from "./work-time";

export default function* root() {
  yield all([
    fork(auth),
    fork(user),
    fork(room),
    fork(subject),
    fork(role),
    fork(permission),
    fork(action),
    fork(profile),
    fork(department),
    fork(course),
    fork(absentSchedule),
    fork(schoolHour),
    fork(employee),
    fork(teacherRole),
    fork(teacher),
    fork(workSchedule),
    fork(workTimes),
  ]);
}
