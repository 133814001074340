import FormItemData from "@/components/antd/form";
import {ACTION_METHOD_OBJ} from "@/constants";
import {layout} from "@/constants/layout";
import {useSubjects} from "@/modules/edu/pages/configuration/edu-subject/hooks";
import {useEmployee} from "@/modules/hr/pages/employee/hooks";
import {API} from "@/network/http";
import {BaseUpsertFormProps, SelectOptionProps} from "@/typing/common";
import {CreateTeacherRoleDto, EmployeeDto} from "@/__generated__/api-v1";
import {Button, Card, Col, Form, Modal, Row} from "antd";
import {first, take, takeRight} from "lodash";
import moment from "moment";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {DEFINE_MODULE} from "../constants";

interface FormInput extends Omit<CreateTeacherRoleDto, "startWorkingDate"> {
  startWorkingDate: ReturnType<typeof moment>;
  province: SelectOptionProps;
}

const ROLE_OPTIONS = [
  {value: "LECTURER", key: "LECTURER", name: "Giáo viên"},
  {value: "TUTOR", key: "TUTOR", name: "Trợ giảng"},
  {value: "INTERN", key: "INTERN", name: "Thực tập"},
];

const UpsertForm: React.FC<BaseUpsertFormProps> = forwardRef((props, ref) => {
  const {modalVisible, onCancel, onSubmit, actionType, values, loadingBtn} = props;

  const [form] = Form.useForm<FormInput>();

  // as the second argument
  useImperativeHandle(ref, () => ({
    onResetData() {
      // reset sale table
      form.resetFields();
    },
  }));

  const getListEmployee = async (searchValue?: string) => {
    const response = await API.employees.employeeControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const {subjects, fetchSubjects} = useSubjects();
  const getListSubject = async (searchValue?: string) => {
    const response = await fetchSubjects({name: searchValue});
    return (
      response?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  useEffect(() => {
    if (actionType === "UPDATE") {
      form.setFieldsValue({
        name: values.name,
        subjectId: values.subjectId,
        employeeId: values.employeeId,
        role: values.role,
        provinceId: values.provinceId,
        startWorkingDate: moment(values.startWorkingDate),
      });
    }
  }, []);

  const handleSubmit = async () => {
    const fieldsValidation = await form.validateFields();
    if (!fieldsValidation) {
      return;
    }

    const {province, ...formValues} = {...form.getFieldsValue()};
    if (actionType === "CREATE") {
      onSubmit(formValues, actionType);
    }
    if (actionType === "UPDATE") {
      onSubmit(formValues, actionType, values._id);
    }
  };

  const onResetForm = (): void => {
    // reset sale table
    form.resetFields();
  };

  const {employee, fetchEmployee} = useEmployee();
  const [provinces, setProvinces] = useState<SelectOptionProps[]>();
  const onEmployeeIdChange = async (id: string) => {
    const data = await fetchEmployee(id);
    const employee = data as EmployeeDto & {
      manager: {
        name: string;
        _id: string;
      };
      job: {
        name: string;
        _id: string;
      };
      locations: Array<{
        _id: string;
        name: string;
      }>;
    };

    setProvinces([
      {
        name: first(employee.locations)?.name!,
        value: first(employee.locations)?._id,
        key: first(employee.locations)?._id,
      },
    ]);

    form.setFieldsValue({
      province: {
        name: first(employee.locations)?.name,
        value: first(employee.locations)?._id,
        key: first(employee.locations)?._id,
      },
      startWorkingDate: moment(employee.startWorkingDate),
    });
  };

  const employeeId = Form.useWatch("employeeId", form);
  useEffect(() => {
    if (employeeId) {
      onEmployeeIdChange(employeeId);
    }
  }, [employeeId]);

  const role = Form.useWatch("role", form);
  const subjectId = Form.useWatch("subjectId", form);
  useEffect(() => {
    if (subjectId && role) {
      const subject = subjects.find(e => e._id === subjectId);
      form.setFieldsValue({name: `${ROLE_OPTIONS.find(e => e.value === role)?.name}: ${subject?.name}`});
    }
  }, [subjectId, role, subjects]);

  const renderContentList = [
    {
      inputType: "selectsearch",
      name: "employeeId",
      label: "Nhân viên",
      placeholder: "Chọn nhân viên",
      fetchOptions: getListEmployee,
      rules: [{required: true, message: "Chọn nhân viên"}],
    },
    {
      inputType: "select",
      name: "province",
      label: "Địa điểm làm việc",
      placeholder: "---",
      disabled: true,
      readOnly: true,
      options: provinces ?? [],
      rules: [{required: true}],
    },
    {
      inputType: "input",
      name: "name",
      label: "Tên vai trò",
      placeholder: "---",
      readOnly: true,
      disabled: true,
      rules: [{required: true}],
    },
    {
      inputType: "select",
      name: "role",
      label: "Vai trò",
      placeholder: "Chọn vai trò",
      options: ROLE_OPTIONS,
      rules: [{required: true, message: "Chọn vai trò"}],
    },
    {
      inputType: "selectsearch",
      name: "subjectId",
      label: "Môn học",
      placeholder: "Chọn môn học",
      fetchOptions: getListSubject,
      rules: [{required: true, message: "Chọn môn học"}],
    },
    {
      inputType: "datetime",
      name: "startWorkingDate",
      label: "Ngày bắt đầu",
      placeholder: "Nhập ngày bắt đầu",
      showTime: false,
      rules: [{required: true, message: "Nhập ngày bắt đầu"}],
    },
  ];

  const renderContent = () => {
    return (
      <Card style={{paddingRight: "20px"}}>
        <Row gutter={24}>
          <Col span={12}>
            {take(renderContentList, 2).map(item => item && <FormItemData key={item.name} {...item} />)}
          </Col>
          <Col span={12}>
            {takeRight(renderContentList, 4).map(item => item && <FormItemData key={item.name} {...item} />)}
          </Col>
        </Row>
      </Card>
    );
  };

  const renderFooter = () => {
    return (
      <>
        {props.actionType === "CREATE" && <Button onClick={() => onResetForm()}>Làm mới</Button>}
        <Button loading={loadingBtn} type="primary" onClick={handleSubmit}>
          Đồng ý
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal
        bodyStyle={{background: "#F0F2F5"}}
        width={1200}
        destroyOnClose
        maskClosable={false}
        title={`${ACTION_METHOD_OBJ[actionType]} ${DEFINE_MODULE.name}`}
        visible={modalVisible}
        onCancel={() => {
          onResetForm();
          onCancel();
        }}
        footer={renderFooter()}
      >
        <Form {...layout} labelWrap form={form}>
          {renderContent()}
        </Form>
      </Modal>
    </>
  );
});

export default UpsertForm;
