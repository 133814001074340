import { SelectOptionSearchProps } from "@/typing/common"
import { Select, Spin } from "antd"
import debounce from "lodash/debounce"
import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from "react"

const { Option } = Select

interface CustomComponent {
  fetchOptions: (val?: any) => any
  onFetchOtherOptions?: (query: any) => void
  selectTrigger?: (option?: any) => any
  debounceTimeout?: number
  placeholder?: React.ReactNode
  ref?: any
  selectedOptions?: SelectOptionSearchProps[]
  [key: string]: any
}

export const DebounceSelect: React.FC<CustomComponent> = forwardRef(
  ({ fetchOptions, selectTrigger, debounceTimeout = 1000, ...props }, ref) => {
    const [fetching, setFetching] = React.useState(false)
    const [options, setOptions] = React.useState<SelectOptionSearchProps[]>([])
    const [isFetchMore, setIsFetchMore] = React.useState<boolean>(false)
    const fetchRef = React.useRef(0)
    const fetch = useCallback(() => {
      return isFetchMore && props.onFetchOtherOptions ? props.onFetchOtherOptions : fetchOptions
    }, [fetchOptions, isFetchMore, props.onFetchOtherOptions])

    const setData = () => {
      fetch()().then((newOptions: any) => {
        setOptions(newOptions)
        setFetching(false)
      })
    }

    useImperativeHandle(ref, () => ({
      triggerFetch() {
        setData()
      },
    }))

    useEffect(() => {
      setData()
    }, [])

    const debounceFetcher = React.useMemo(() => {
      const loadOptions = (val: any) => {
        if (val.length > 10) {
          return val.substring(0, 10)
        }
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setOptions([])
        setFetching(true)
        fetch()(val).then((newOptions: any) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setOptions(newOptions)
          setFetching(false)
        })
      }

      return debounce(loadOptions, debounceTimeout)
    }, [fetch, debounceTimeout])
    return (
      <Select
        // labelInValue // to get object instead of value on select
        placeholder={props.placeholder}
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : "Không có dữ liệu"}
        showSearch
        allowClear={props.cantClear ? false : true}
        onSelect={(val: any) => {
          if (val === "SELECT_MORE_OPTIONS") {
            setIsFetchMore(true)
            setData()
            return
          }

          if (selectTrigger) {
            selectTrigger(options.find(i => i.value === val))
          }
        }}
        {...props}
      >
        {[...options, ...(props.selectedOptions ?? [])]
          .reduce((prev: SelectOptionSearchProps[], current) => {
            return prev?.find(e => e.value === current.value) ? prev : [...prev, current]
          }, [])
          .map((opt: SelectOptionSearchProps) => (
            <Option key={opt.value} value={opt.value}>
              {opt?.name ?? opt.label}
            </Option>
          ))}
      </Select>
    )
  },
)
