import { DownOutlined, UpOutlined } from "@ant-design/icons"
import { Col, Row } from "antd"
import React from "react"

interface CollapseRenderProps {
  collapsed: boolean
}

export const CollapseRender: React.FC<CollapseRenderProps> = ({ collapsed }) => {
  return (
    <Row gutter={3} justify="center" align="middle">
      <Col xxl={19} xl={19} lg={19} md={0} sm={0} xs={0}>
        {collapsed ? "Mở rộng" : "Thu gọn"}
      </Col>
      <Col xxl={5} xl={5} lg={5} md={4} sm={4} xs={4}>
        {collapsed ? <DownOutlined /> : <UpOutlined />}
      </Col>
    </Row>
  )
}
