export const NETWORK_ERROR = 1
export const REQUEST_ERROR = 2
export const BAD_REQUEST = 400
export const UNAUTHORIZED = 401
export const API_NOT_FOUND = 404

// Setting
export const SETTING_NOT_FOUND = 420
export const SETTING_INVALID_KEY_DATA = 421
export const SETTING_EXISTS = 422

// Action
export const ACTION_NOT_FOUND = 450

// Permission
export const PERMISSION_NOT_FOUND = 500
export const PERMISSION_EXISTS = 501

// Role
export const ROLE_NOT_FOUND = 550
export const ROLE_EXISTS = 551

// Auth
export const AUTH_REQUIRE_PHONE_NUMBER = 600
export const AUTH_WRONG_PASSWORD = 601
export const AUTH_REQUIRE_USERNAME = 602

// user
export const USER_CAN_NOT_EDIT_YOURSELF = 700
export const USER_INVALID = 701
export const USER_INVALID_TYPE = 702
export const USER_NOT_FOUND = 703
export const USER_EXITS = 704
export const USER_CAN_NOT_DELETE_YOURSELF = 705

// Location
export const LOCATION_EXISTS = 800
export const LOCATION_NOT_FOUND = 801

// school hour
export const SCHOOL_HOUR_TIME_EXISTS = 850
export const SCHOOL_HOUR_INVALID_STUDY_SCHEDULE = 851
