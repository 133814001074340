import { CARD_COLORS } from "@/modules/edu/pages/operation/work-time/constants"
import { TimetableEvent } from "@/__generated__/graphql-types"
import { Card, Popover, Space, Tag, Typography } from "antd"
import { Link } from "react-router-dom"

interface DayContentProps {
  data: TimetableEvent
  shift: string
  onClickUpdate: () => void
}

const { Text } = Typography

const DayContent: React.FC<DayContentProps> = ({ data, shift, onClickUpdate }) => {
  const now = new Date()
  const renderShift = {
    MORNING: "Sáng",
    AFTERNOON: "Chiều",
    EVENING: "Tối",
  }[shift]
  const isOpeningDate = data.lessonType == "STUDYING"
  const isGraduationDate = data.lessonType == "GRADUATED"
  const contentData = (
    <>
      <Space direction="vertical">
        <Link to={`/edu/classes?_id=${data.classId}&showPopup=true`}>{data.class?.name}</Link>
        <Text>Đã tuyển: {data.recruited ?? 0}</Text>
        <Text>Học bù: {data.makeUpLessons ?? 0}</Text>
        <Text>Vắng: {data.absent ?? 0}</Text>
        <Text>Thực học dự kiến: {data.expectedStudy ?? 0}</Text>
        <Text>Điểm danh: {data.attendance ?? 0}</Text>
      </Space>
    </>
  )

  // @ts-ignore
  const cardColor =
    data.status === "INVALID"
      ? CARD_COLORS.INVALID.color
      : data.scheduleType && data.scheduleType in CARD_COLORS
        ? // @ts-ignore
        CARD_COLORS[data.scheduleType].color
        : "inherit"
  const textColor = isOpeningDate ? "red" : (isGraduationDate ? "blue" : "black")
  return (
    <>
      <Popover content={contentData} placement="right">
        {data.lesson ? (
          <Card
            onClick={onClickUpdate}
            bodyStyle={{ padding: 0 }}
            style={{
              // minHeight: 50,
              // maxHeight: 85,
              // overflow: "auto",
              background: cardColor,
              textAlign: "left",
              padding: "4px 4px",
              borderWidth: "1px",
              borderRadius: "6px",
              borderColor: "#ababab",
              width: 140,
            }}
          >
            <p style={{ fontSize: 9, color: textColor, margin: 0 }}>{data.class?.name}</p>
            <p style={{ fontSize: 9, color: textColor, margin: 0 }}>
              Ca: {data.schoolHour?.timeFrame}
            </p>
            <p style={{ fontSize: 9, color: textColor, margin: 0 }}>GV: {data.lecturer?.name}</p>
            <p style={{ fontSize: 9, color: textColor, margin: 0 }}>
              Trợ Giảng: {data.tutors?.map(t => t.name).toString()}
            </p>
            {renderShift && (
              <p style={{ fontSize: 9, color: textColor, margin: 0 }}>{data.name}</p>
            )}
            {isOpeningDate && <Tag color="orange">Buổi khai giảng</Tag>}
            {isGraduationDate && <Tag color="blue">Buổi tốt nghiệp</Tag>}
          </Card>
        ) : null}
      </Popover>
    </>
  )
}

export default DayContent
