import FormItemData from "@/components/antd/form";
import {ACTION_METHOD_OBJ} from "@/constants";
import {API} from "@/network/http";
import {BaseUpsertFormProps} from "@/typing/common";
import {EmployeeDto} from "@/__generated__/api-v1";
import {Button, Card, Col, Form, Modal, Row} from "antd";
import {take, takeRight} from "lodash";
import moment from "moment";
import {FC, forwardRef, useEffect, useImperativeHandle} from "react";
import {DEFINE_MODULE} from "../constants";

type UpsertFormProps =
  | (Omit<BaseUpsertFormProps, "values" | "actionType"> & {
    actionType: "UPDATE";
    values: EmployeeDto;
  })
  | (BaseUpsertFormProps & {
    actionType: "CREATE";
    values?: undefined;
  });

interface FormInput {
  name: string;
  avatar?: string;
  mobilePhone: string;
  facebook: string;
  workEmail: string;
  workAddress: string;
  startWorkingDate: any;
  departmentId: string | null;
  userId?: string | null;
  jobId?: string | null;
  managerId?: string | null;
  type: string;
  locationIds: string[];
  no2LocationIds: string[];
  no3LocationIds: string[];
}

const UpsertForm: FC<UpsertFormProps> = forwardRef((props, ref) => {
  const {modalVisible, onCancel, onSubmit, actionType, values, loadingBtn} = props;
  const [form] = Form.useForm<FormInput>();

  // as the second argument
  useImperativeHandle(ref, () => ({
    onResetData() {
      // reset sale table
      form.resetFields();
    },
  }));

  const getDepartments = async (searchValue?: string) => {
    const response = await API.departments.departmentControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map(item => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const getUsers = async (searchValue?: string) => {
    const response = await API.users.userControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map(item => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const getJobs = async (searchValue?: string) => {
    const response = await API.jobs.jobControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map(item => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const getEmployees = async (searchValue?: string) => {
    const response = await API.employees.employeeControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map(item => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const getLocations = async (searchValue?: string) => {
    const response = await API.locations.locationControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map(item => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  useEffect(() => {
    if (actionType === "UPDATE" && values) {
      form.setFieldsValue({
        name: values.name || "",
        avatar: values.avatar || "",
        mobilePhone: values.mobilePhone || "",
        facebook: values.facebook || "",
        workEmail: values.workEmail || "",
        workAddress: values.workAddress || "",
        startWorkingDate: values?.startWorkingDate ? moment(values.startWorkingDate) : moment(),
        departmentId: values.departmentId || "",
        userId: values.userId || "",
        jobId: values.jobId || "",
        managerId: values.managerId || "",
        type: values.type || "STAFF",
        locationIds: values?.locationIds,
        no2LocationIds: values.no2LocationIds,
        no3LocationIds: values.no3LocationIds,
      });
    }
  }, [values]);

  const handleSubmit = async () => {
    const fieldsValidation = await form.validateFields();
    if (!fieldsValidation) {
      return;
    }
    const formValues = {...form.getFieldsValue()};
    console.log({formValues});
    if (actionType === "CREATE") {
      onSubmit(formValues, actionType);
    }
    if (actionType === "UPDATE") {
      onSubmit(formValues, actionType, values._id);
    }
  };

  const onResetForm = (): void => {
    // reset sale table
    form.resetFields();
  };

  const renderContentList = [
    {
      name: "name",
      label: "Tên nhân viên",
      inputType: "input",
      rules: [{required: true, message: "Không được để trống"}],
    },
    {
      name: "mobilePhone",
      label: "Di động",
      inputType: "input",
      rules: [{required: true, message: "Không được để trống"}],
    },
    {
      name: "facebook",
      label: "Link facebook cá nhân",
      inputType: "input",
    },
    {
      name: "workEmail",
      label: "Email công việc",
      inputType: "input",
      rules: [{required: true, message: "Không được để trống"}],
    },
    {
      name: "workAddress",
      label: "Địa chỉ làm việc",
      inputType: "input",
      rules: [{required: true, message: "Không được để trống"}],
    },
    {
      name: "startWorkingDate",
      label: "Thời gian bắt đầu làm việc",
      inputType: "datetime",
      showTime: false,
      rules: [{required: true, message: "Không được để trống"}],
    },

    {
      name: "departmentId",
      label: "Phòng ban",
      rules: [{required: true, message: "Không được để trống"}],
      inputType: "selectsearch",
      fetchOptions: getDepartments,
    },
    {
      name: "userId",
      label: "Liên kết với người dùng",
      inputType: "selectsearch",
      fetchOptions: getUsers,
    },
    {
      name: "jobId",
      label: "Chức vụ",
      rules: [{required: true, message: "Không được để trống"}],
      inputType: "selectsearch",
      fetchOptions: getJobs,
    },
    {
      name: "managerId",
      label: "Người quản lý",
      // rules: [{ required: true, message: "Không được để trống" }],
      inputType: "selectsearch",
      fetchOptions: getEmployees,
    },
    {
      name: "locationIds",
      label: "Địa điểm làm việc mong muốn",
      rules: [{required: true, message: "Không được để trống"}],
      inputType: "selectsearch",
      fetchOptions: getLocations,
    },
    {
      name: "no2LocationIds",
      label: "Địa điểm làm việc mong muốn: ưu tiên thứ 2",
      // rules: [{ required: true, message: "Không được để trống" }],
      inputType: "selectsearch",
      fetchOptions: getLocations,
    },
    {
      name: "no3LocationIds",
      label: "Địa điểm làm việc mong muốn: ưu tiên thứ 3",
      // rules: [{ required: true, message: "Không được để trống" }],
      inputType: "selectsearch",
      fetchOptions: getLocations,
    },
  ];

  const renderContent = () => {
    return (
      <Card style={{paddingRight: "20px"}}>
        <Row gutter={24}>
          <Col span={12}>
            {take(renderContentList, 8).map(item => item && <FormItemData key={item.name} {...item} />)}
          </Col>
          <Col span={12}>
            {takeRight(renderContentList, 5).map(item => item && <FormItemData key={item.name} {...item} />)}
          </Col>
        </Row>
      </Card>
    );
  };

  const renderFooter = () => {
    return (
      <>
        {props.actionType === "CREATE" && <Button onClick={() => onResetForm()}>Làm mới</Button>}
        <Button loading={loadingBtn} type="primary" onClick={handleSubmit}>
          Đồng ý
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal
        bodyStyle={{background: "#F0F2F5"}}
        width={1200}
        destroyOnClose={false}
        maskClosable={false}
        title={`${ACTION_METHOD_OBJ[actionType]} ${DEFINE_MODULE.name}`}
        visible={modalVisible}
        onCancel={() => {
          onResetForm();
          onCancel();
        }}
        footer={renderFooter()}
      >
        <Form form={form} labelCol={{span: 10}} wrapperCol={{span: 14}} labelWrap>
          {renderContent()}
        </Form>
      </Modal>
    </>
  );
});

export default UpsertForm;
