import {
  GET_DETAIL_SCHOOL_HOUR_SUCCESS,
  GET_LIST_SCHOOL_HOUR,
  GET_LIST_SCHOOL_HOUR_SUCCESS,
  PAGINATION_DEFAULT,
  SET_LOADING_SCHOOL_HOUR,
} from "../../constants"
import { ISchoolHourState } from "../types"

interface ActionProps {
  type: string
  payload: any
}

const initState: ISchoolHourState = {
  list: {
    data: [],
    total: 0,
    limit: PAGINATION_DEFAULT.limit,
    offset: PAGINATION_DEFAULT.offset,
  },
  detail: {
    data: {},
  },
  isLoadingRequest: false,
  isDeleteRequest: false,
  isUpdateRequest: false,
  isCreateRequest: false,
}

export const schoolHour = (state: ISchoolHourState = initState, action: ActionProps) => {
  const { type, payload } = action

  switch (type) {
    case GET_LIST_SCHOOL_HOUR: {
      return {
        ...state,
      }
    }
    case GET_LIST_SCHOOL_HOUR_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          data: payload.data,
          total: payload.total,
        },
      }
    }

    case GET_DETAIL_SCHOOL_HOUR_SUCCESS: {
      return {
        ...state,
        detail: {
          ...state.detail,
          data: payload.data,
        },
      }
    }

    case SET_LOADING_SCHOOL_HOUR: {
      return {
        ...state,
        ...payload,
      }
    }

    default: {
      return state
    }
  }
}
