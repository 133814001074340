import {
  GET_DETAIL_COURSE_SUCCESS,
  GET_LIST_COURSE,
  GET_LIST_COURSE_SUCCESS,
  PAGINATION_DEFAULT,
  SET_LOADING_COURSE,
} from "../../constants"
import { ICourseState } from "../types"

interface ActionProps {
  type: string
  payload: any
}

const initState: ICourseState = {
  list: {
    courses: [],
    total: 0,
    limit: PAGINATION_DEFAULT.limit,
    offset: PAGINATION_DEFAULT.offset,
  },
  detail: {
    courses: {},
  },
  isLoadingRequest: false,
  isDeleteRequest: false,
  isUpdateRequest: false,
  isCreateRequest: false,
}

export const course = (state: ICourseState = initState, action: ActionProps) => {
  const { type, payload } = action

  switch (type) {
    case GET_LIST_COURSE: {
      return {
        ...state,
      }
    }
    case GET_LIST_COURSE_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          courses: payload.data,
          total: payload.total,
        },
      }
    }

    case GET_DETAIL_COURSE_SUCCESS: {
      return {
        ...state,
        detail: {
          ...state.detail,
          courses: payload.data,
        },
      }
    }

    case SET_LOADING_COURSE: {
      return {
        ...state,
        ...payload,
      }
    }

    default: {
      return state
    }
  }
}
