import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../http"

interface ISchoolHours {
  name?: string
  email?: string
  roleId?: string
  limit?: number
  offset?: number
  timeFrame?: string
  ids?: string
}

interface ISchoolHourCreate {
  name: string
  email: string
  roleId: string
  photo?: string | null
  password: string
  type: string
  status: string
}

interface ISchoolHourUpdate {
  name?: string
  email?: string
  roleId?: string
  photo?: string | null
  password?: string
  type?: string
  status?: string
}

interface ISchoolHourArchive {
  ids: Array<string>
  isArchived: boolean
}

export const getSchoolHoursApi = async (data?: ISchoolHours) => {
  const url = "/school-hours"
  return await getRequest(url, { params: data })
}

export const getSchoolHourDetailApi = async (id: string) => {
  const url = `/school-hours/${id}`
  return await getRequest(url)
}

export const createSchoolHourApi = async (data: ISchoolHourCreate) => {
  const url = `/school-hours`
  return await postRequest(url, data)
}

export const updateSchoolHourApi = async (id: string, data: ISchoolHourUpdate) => {
  const url = `/school-hours/${id}`
  return await putRequest(url, data)
}

export const archiveManySchoolHourApi = async (data: ISchoolHourArchive) => {
  const url = `/school-hours`
  return await patchRequest(url, data)
}

export const deleteSchoolHourApi = async (id: string) => {
  const url = `/school-hours/${id}`
  return await deleteRequest(url)
}

export const deleteManySchoolHourApi = async (ids: string[]) => {
  const url = `/school-hours`
  return await deleteRequest(url, { data: { ids } })
}
