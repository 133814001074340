import List from "@/modules/core/pages/user/views/List";
import {Route, Routes} from "react-router-dom";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      {/* <Route path="create" element={<Create />} /> */}
      {/* <Route path=":id" element={<Edit />} /> */}
    </Routes>
  );
};
