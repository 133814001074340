import { DebounceSelect } from "@/components/antd/form/DebounceSelect";
import { Table, TableProps } from "@/components/antd/table/Table";
import { ACTION_METHOD_OBJ } from "@/constants";
import { AbsentScheduleStatus } from "@/modules/edu/pages/operation/absent-schedule/types";
import { API } from "@/network/http";
import { CRUD_ACTION } from "@/types";
import { formatToLocalTime } from "@/utils";
import { AbsentScheduleDto } from "@/__generated__/api-v1";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import type { ActionType, ProColumns } from "@ant-design/pro-table";
import { notification, Popconfirm, Space, Tag, TagProps, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { FC, useRef, useState } from "react";
import { DEFINE_MODULE, statuses } from "../constants";
import UpsertForm from "./UpsertForm";

const List: FC = () => {
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [isCreate, setCreate] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [values, setValues] = useState<any>();
  const actionRef = useRef<ActionType>();

  const [notifyApi, contextHolder] = notification.useNotification();

  const onClickEditButton = async (id: string) => {
    const detailData = await API.absentSchedules.absentScheduleControllerGetOne(id);
    setValues(detailData.data.data);
    setUpdate(true);
  };

  const onClickDeleteButton = async (id: string) => {
    try {
      await API.absentSchedules.absentScheduleControllerDeleteOne(id);
      notifyApi.success({ message: `${ACTION_METHOD_OBJ["DELETE"]} ${DEFINE_MODULE.name} thành công` });
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const onSubmit = async (val: any, type: CRUD_ACTION, _id?: string) => {
    try {
      setLoadingBtn(true);
      const reqData =
        type === "CREATE"
          ? await API.absentSchedules.absentScheduleControllerCreateOne(val)
          : await API.absentSchedules.absentScheduleControllerUpdateOne(_id || "", val);
      if (reqData && reqData.data) {
        if (type === "CREATE") setCreate(false);
        if (type === "UPDATE") setUpdate(false);
        notifyApi.success({
          message: "Thông báo",
          description: `${ACTION_METHOD_OBJ[type]} thành công`,
          placement: "topRight",
        });
        actionRef.current?.reload();
      }
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
    finally {
      setLoadingBtn(false);
    }
  };

  const fetchData = async (queries: Parameters<typeof API.absentSchedules.absentScheduleControllerGetMany>["0"]) => {
    return (await API.absentSchedules.absentScheduleControllerGetMany({ ...queries })).data;
  };

  const onDeleteMany = async (ids: string[]) => {
    try {
      await API.absentSchedules.absentScheduleControllerDeleteMany({ ids });
      notifyApi.success({ message: `${ACTION_METHOD_OBJ["DELETE"]} ${DEFINE_MODULE.name} thành công` });
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const onArchiveMany = async (ids: string[]) => {
    try {
      await API.absentSchedules.absentScheduleControllerUpdateMany({ ids, isArchived: true });
      notifyApi.success({ message: `${ACTION_METHOD_OBJ["ARCHIVE"]} ${ids.length} ${DEFINE_MODULE.name} thành công` });
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const onUnarchiveMany = async (ids: string[]) => {
    try {
      await API.absentSchedules.absentScheduleControllerUpdateMany({ ids, isArchived: false });
      notifyApi.success({ message: `${ACTION_METHOD_OBJ["UNARCHIVE"]} ${ids.length} ${DEFINE_MODULE.name} thành công` });
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const getListEmployee = async (searchValue?: string) => {
    const response = await API.employees.employeeControllerGetMany({ name: searchValue });
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };


  const getListDepartment = async (searchValue?: string) => {
    const response = await API.departments.departmentControllerGetMany({ name: searchValue });
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const getListLocation = async (searchValue?: string) => {
    const response = await API.locations.locationControllerGetMany({ name: searchValue });
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const columns: ProColumns<AbsentScheduleDto>[] = [
    {
      hideInTable: true,
      dataIndex: "employeeId",
      title: "Nhân viên",
      renderFormItem: () => {
        return <DebounceSelect placeholder="Chọn nhân viên" fetchOptions={getListEmployee} />;
      }
    },
    {
      hideInTable: true,
      dataIndex: "departmentId",
      title: "Phòng ban",
      renderFormItem: () => {
        return <DebounceSelect placeholder="Chọn phòng ban" fetchOptions={getListDepartment} />;
      }
    },
    {
      hideInTable: true,
      dataIndex: "locationId",
      title: "Địa điểm làm việc",
      renderFormItem: () => {
        return <DebounceSelect placeholder="Chọn địa điểm làm việc" fetchOptions={getListLocation} />;
      }
    },
    {
      hideInTable: true,
      dataIndex: "status",
      title: "Trạng thái",
      valueType: "select",
      valueEnum: {
        ACCEPTED: { text: "Đã duyệt", value: "ACCEPTED" },
        CANCELED: { text: "Đã huỷ", value: "CANCELED" },
        INITIAL: { text: "Dự thảo", value: "INITIAL" },
        REJECTED: { text: "Từ chối", value: "REJECTED" },
      }
    },
    {
      dataIndex: ["employee", "name"],
      title: "Nhân viên",
      search: false,
    },
    {
      dataIndex: ["employee", "department", "name"],
      title: "Phòng ban",
      search: false,
    },
    {
      dataIndex: "fromDate",
      title: "Từ ngày",
      search: false,
      renderText: (_, entity) => {
        return <p>{formatToLocalTime(entity.fromDate)}</p>;
      },
    },
    {
      dataIndex: "fromDate",
      title: "Từ ngày",
      valueType: "date",
      hideInTable: true
    },
    {
      dataIndex: "toDate",
      title: "Đến ngày",
      search: false,
      renderText: (_, entity) => {
        return <p>{formatToLocalTime(entity.toDate)}</p>;
      },
    },
    {
      dataIndex: "toDate",
      title: "Đến ngày",
      valueType: "date",
      hideInTable: true
    },
    {
      dataIndex: "---",
      title: "Địa điểm làm việc",
      search: false,
    },
    {
      dataIndex: "shift",
      title: "Loại ca",
      search: false,
      renderText: (_, entity) => {
        const name = [];
        let label = "";
        for (const id in entity.shift) {
          switch (entity.shift[id]) {
            case "MORNING": {
              label = "Sáng";
              break;
            }
            case "AFTERNOON": {
              label = "Chiều";
              break;
            }
            case "EVENING": {
              label = "Tối";
              break;
            }
          }
          if (!isEmpty(label)) name.push(label);
        }

        return (
          <span>
            {name.map((value, index) => {
              return <Tag>{value}</Tag>;
            })}
          </span>
        );
      },
    },
    {
      dataIndex: "schoolHourIds",
      title: "Ca nghỉ",
      search: false,
      renderText: (_, entity) => {
        const name = [];
        let label = "";
        for (const id in entity.schoolHourIds) {
          const schoolHour: any = entity.schoolHourIds.find((item: any) => item._id === entity.schoolHourIds[id]);
          if (schoolHour) label = schoolHour.timeFrame;
          if (!isEmpty(label)) name.push(label);
        }
        return (
          <span>
            {name.map((value, index) => {
              return `${value} `;
            })}
          </span>
        );
      },
    },
    {
      dataIndex: "reason",
      title: "Lý do",
      search: false,
    },
    {
      dataIndex: "status",
      title: "Trạng thái",
      renderText: (_, entity) => {
        const item = entity as AbsentScheduleDto & { status: AbsentScheduleStatus; };
        const status = item.status;
        const color: TagProps["color"] = status === "ACCEPTED" ? "green" : status === "REJECTED" ? "red" : "default";

        return <Tag color={color}>{statuses[status]}</Tag>;
      },
      search: false,
    },
    {
      title: "Thao tác",
      align: "center",
      search: false,
      fixed: "right",
      width: 80,
      render: (_, entity) => {
        return (
          <Space size={10}>
            <Tooltip title={`Cập nhật ${DEFINE_MODULE.name}`}>
              <EditTwoTone onClick={() => onClickEditButton(entity._id)} />
            </Tooltip>
            <Tooltip title={`Xoá ${DEFINE_MODULE.name}`}>
              <Popconfirm
                title={`Bạn có chắc rằng muốn xoá ${DEFINE_MODULE.name} này không?`}
                onConfirm={() => onClickDeleteButton(entity._id)}
                okText="Có"
                cancelText="Không"
              >
                <DeleteTwoTone twoToneColor={"red"} />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const protableProps: TableProps = {
    actionRef,
    DEFINE_MODULE,
    contextHolder,
    fetchData,
    columns,
    deleteMany: onDeleteMany,
    archiveMany: onArchiveMany,
    unArchiveMany: onUnarchiveMany,
    createForm: {
      isCreate,
      setCreate,
      component: (
        <UpsertForm
          key="CREATE"
          onCancel={() => setCreate(false)}
          modalVisible={isCreate}
          onSubmit={onSubmit}
          actionType="CREATE"
          loadingBtn={loadingBtn}
        />
      ),
    },
    updateForm: {
      isUpdate,
      component: (
        <UpsertForm
          key="UPDATE"
          onCancel={() => setUpdate(false)}
          modalVisible={isUpdate}
          onSubmit={onSubmit}
          actionType="UPDATE"
          loadingBtn={loadingBtn}
          values={values}
        />
      ),
    },
  };

  return <Table {...protableProps} />;
};

export default List;
