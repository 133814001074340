import {
  IGetAbsentSchedule,
  IUpsertAbsentSchedule,
  IPatchAbsentSchedule,
  IDeleteManyAbsentSchedule,
  IUpsertStatusAbsentSchedule,
} from "../../typing/absent_schedule"
import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../http"

const absentScheduleUrl = "/absent-schedules"

const getAbsentSchedulesApi = async (data?: IGetAbsentSchedule) => {
  return await getRequest(absentScheduleUrl, { params: data })
}

const getAbsentScheduleDetailApi = async (id: string) => {
  const url = `${absentScheduleUrl}/${id}`
  return await getRequest(url)
}

const createAbsentScheduleApi = async (data: IUpsertAbsentSchedule) => {
  return await postRequest(absentScheduleUrl, data)
}

const updateAbsentScheduleApi = async (id: string, data: IUpsertAbsentSchedule) => {
  const url = `${absentScheduleUrl}/${id}`
  return await putRequest(url, data)
}

const updateStatusAbsentScheduleApi = async (id: string, data: IUpsertStatusAbsentSchedule) => {
  const url = `${absentScheduleUrl}/${id}`
  return await putRequest(url, data)
}

const archiveManyAbsentScheduleApi = async (data: IPatchAbsentSchedule) => {
  return await patchRequest(absentScheduleUrl, data)
}

const deleteAbsentScheduleApi = async (id: string) => {
  const url = `${absentScheduleUrl}/${id}`
  return await deleteRequest(url)
}

const deleteManyAbsentScheduleApi = async (data: IDeleteManyAbsentSchedule) => {
  return await deleteRequest(absentScheduleUrl, { data })
}

export {
  getAbsentSchedulesApi,
  getAbsentScheduleDetailApi,
  createAbsentScheduleApi,
  updateAbsentScheduleApi,
  archiveManyAbsentScheduleApi,
  deleteAbsentScheduleApi,
  deleteManyAbsentScheduleApi,
  updateStatusAbsentScheduleApi,
}
