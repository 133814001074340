import {
  GET_DETAIL_WORK_SCHEDULE_SUCCESS,
  GET_LIST_WORK_SCHEDULE,
  GET_LIST_WORK_SCHEDULE_SUCCESS,
  PAGINATION_DEFAULT,
  SET_LOADING_WORK_SCHEDULE,
} from "../../constants"
import { IWorkScheduleState } from "../types"

interface ActionProps {
  type: string
  payload: any
}

const initState: IWorkScheduleState = {
  list: {
    workSchedules: [],
    total: 0,
    limit: PAGINATION_DEFAULT.limit,
    offset: PAGINATION_DEFAULT.offset,
  },
  detail: {
    workSchedules: {},
  },
  isLoadingRequest: false,
  isDeleteRequest: false,
  isUpdateRequest: false,
  isCreateRequest: false,
}

export const workSchedule = (state: IWorkScheduleState = initState, action: ActionProps) => {
  const { type, payload } = action

  switch (type) {
    case GET_LIST_WORK_SCHEDULE: {
      return {
        ...state,
      }
    }
    case GET_LIST_WORK_SCHEDULE_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          workSchedules: payload.data,
          total: payload.total,
        },
      }
    }

    case GET_DETAIL_WORK_SCHEDULE_SUCCESS: {
      return {
        ...state,
        detail: {
          ...state.detail,
          workSchedules: payload.data,
        },
      }
    }

    case SET_LOADING_WORK_SCHEDULE: {
      return {
        ...state,
        ...payload,
      }
    }

    default: {
      return state
    }
  }
}
