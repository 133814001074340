export type CurrentModal = "JobModal" | "DepartmentModal"
export const OPEN_MODAL = "OPEN_MODAL"
export const CLOSE_MODAL = "CLOSE_MODAL"

interface State {
  name?: string
}

interface Payload {
  name?: string
}

export interface Action {
  type: typeof CLOSE_MODAL | typeof OPEN_MODAL
  payload: Payload
}

const initState: State = {
  name: undefined,
}

export const currentModalReducer = (state = initState, action: Action): State => {
  const { type, payload } = action

  switch (type) {
    case "OPEN_MODAL": {
      return {
        ...state,
        name: payload.name,
      }
    }

    case "CLOSE_MODAL": {
      return {
        ...state,
        name: undefined,
      }
    }

    default: {
      return state
    }
  }
}
