import { EduPaths } from "@/modules/edu"
import i18n from "i18n-js"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import * as actionTypes from "../../constants/ActionTypes"
import history from "../../navigation"
import {
  archiveManySubjectApi,
  createSubjectApi,
  deleteManySubjectApi,
  deleteSubjectApi,
  getSubjectDetailApi,
  getSubjectsApi,
  updateSubjectApi,
} from "../../network"
import { convertObjectParamsToStringParams, getInitParams } from "../../utils"
import { getSubjectState } from "../selectors"
import { ISubjectState } from "../types"

interface IPayload {
  type: string
  payload: any
}

interface IResponse {
  data: any
  total: number
}

function* getListSubject(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const subject: ISubjectState = yield select<any>(getSubjectState)

    // validate and set requesting
    if (subject.isLoadingRequest) return
    yield put({
      type: actionTypes.SET_LOADING_SUBJECT,
      payload: { isLoadingRequest: true },
    })

    // get list
    const { data, total }: IResponse = yield call(getSubjectsApi, action.payload)
    yield history.push(`?${convertObjectParamsToStringParams(action.payload)}`)

    // update to state
    yield put({
      type: actionTypes.GET_LIST_SUBJECT_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({ type: actionTypes.GET_LIST_SUBJECT_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_SUBJECT,
      payload: { isLoadingRequest: false },
    })
  }
}

function* getDetailSubject(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const subject: ISubjectState = yield select<any>(getSubjectState)

    // validate and set requesting
    if (subject.isLoadingRequest) return
    yield put({
      type: actionTypes.SET_LOADING_SUBJECT,
      payload: { isLoadingRequest: true },
    })

    // call api
    const { data, total }: IResponse = yield call(getSubjectDetailApi, action.payload)

    // update to state
    yield put({
      type: actionTypes.GET_DETAIL_SUBJECT_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({ type: actionTypes.GET_DETAIL_SUBJECT_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_SUBJECT,
      payload: { isLoadingRequest: false },
    })
  }
}

function* updateDetailSubject(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const subject: ISubjectState = yield select<any>(getSubjectState)

    // validate and set requesting
    if (subject.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_SUBJECT,
      payload: { isUpdateRequest: true },
    })

    // call api update
    const { data, total }: IResponse = yield call(updateSubjectApi, action.payload.subjectId, action.payload.data)

    yield put({
      type: actionTypes.UPDATE_DETAIL_SUBJECT_SUCCESS,
    })

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thành công",
        message: data?.message || "",
        type: "success",
      },
    })
    history.push(EduPaths.SUBJECT.LIST.PATH)
  } catch (error) {
    console.log("e: ", error)
    yield put({ type: actionTypes.UPDATE_DETAIL_SUBJECT_FAILED, payload: error })
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thất bại",
        message: `${error}` || "",
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_SUBJECT,
      payload: { isUpdateRequest: false },
    })
  }
}

function* createSubject(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const subject: ISubjectState = yield select<any>(getSubjectState)

    // validate and set requesting
    if (subject.isCreateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_SUBJECT,
      payload: { isCreateRequest: true },
    })

    // call api create
    const { data, total }: IResponse = yield call(createSubjectApi, action.payload)

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Thêm mới thành công",
        message: data?.message || "",
        type: "success",
      },
    })

    // navigate
    history.push("/subjects")
  } catch (error) {
    yield put({ type: actionTypes.CREATE_SUBJECT_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_SUBJECT,
      payload: { isCreateRequest: false },
    })
  }
}

function* deleteOneSubject(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const subject: ISubjectState = yield select<any>(getSubjectState)

    // validate and set requesting
    if (subject.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_SUBJECT,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(deleteSubjectApi, action.payload.id)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteAction.deleteSuccess"),
          message: i18n.t("common.deleteAction.deleteSuccess"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_SUBJECT,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_SUBJECT,
      payload: { isDeleteRequest: false },
    })
  }
}

function* deleteManySubject(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const subject: ISubjectState = yield select<any>(getSubjectState)

    // validate and set requesting
    if (subject.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_SUBJECT,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(deleteManySubjectApi, action.payload.ids)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteManyAction.title"),
          message: i18n.t("common.deleteManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_SUBJECT,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_SUBJECT,
      payload: { isDeleteRequest: false },
    })
  }
}

function* archiveManySubject(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const subject: ISubjectState = yield select<any>(getSubjectState)

    // validate and set requesting
    if (subject.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_SUBJECT,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(archiveManySubjectApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.archiveManyAction.title"),
          message: i18n.t("common.archiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_SUBJECT,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_SUBJECT,
      payload: { isUpdateRequest: false },
    })
  }
}

function* unarchiveManySubject(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const subject: ISubjectState = yield select<any>(getSubjectState)

    // validate and set requesting
    if (subject.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_SUBJECT,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(archiveManySubjectApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.unarchiveManyAction.title"),
          message: i18n.t("common.unarchiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_SUBJECT,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_SUBJECT,
      payload: { isUpdateRequest: false },
    })
  }
}

export default function* root() {
  yield all([
    takeLatest(actionTypes.GET_LIST_SUBJECT, getListSubject),
    takeLatest(actionTypes.GET_DETAIL_SUBJECT, getDetailSubject),
    takeLatest(actionTypes.UPDATE_DETAIL_SUBJECT, updateDetailSubject),
    takeLatest(actionTypes.CREATE_SUBJECT, createSubject),
    takeLatest(actionTypes.DELETE_ONE_SUBJECT, deleteOneSubject),
    takeLatest(actionTypes.DELETE_MANY_SUBJECT, deleteManySubject),
    takeLatest(actionTypes.ARCHIVE_MANY_SUBJECT, archiveManySubject),
    takeLatest(actionTypes.UNARCHIVE_MANY_SUBJECT, unarchiveManySubject),
  ])
}
