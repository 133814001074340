/* eslint-disable */

import * as Apollo from "@apollo/client"
import { gql } from "@apollo/client"
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from "@apollo/client/cache"
import * as Types from "./graphql-types"
const defaultOptions = {}

export const GetClassDocument = gql`
  query getClass($id: String = "") {
    class(id: $id) {
      id
    }
  }
`
export function useGetClassQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetClassQuery, Types.GetClassQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetClassQuery, Types.GetClassQueryVariables>(GetClassDocument, options)
}
export function useGetClassLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassQuery, Types.GetClassQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetClassQuery, Types.GetClassQueryVariables>(GetClassDocument, options)
}
export type GetClassQueryHookResult = ReturnType<typeof useGetClassQuery>
export type GetClassLazyQueryHookResult = ReturnType<typeof useGetClassLazyQuery>
export type GetClassQueryResult = Apollo.QueryResult<Types.GetClassQuery, Types.GetClassQueryVariables>
export const GetTimeTableDocument = gql`
  query getTimeTable(
    $fromDate: String
    $toDate: String
    $offset: Int = 0
    $limit: Int = 10
    $areas: [String!]
    $locationIds: [String!]
    $teacherIds: [String!]
    $subjectIds: [String!]
    $roomIds: [String!]
    $shifts: [String!]
    $ts: String!
  ) {
    timetables(
      fromDate: $fromDate
      ts: $ts
      toDate: $toDate
      offset: $offset
      limit: $limit
      areas: $areas
      locationIds: $locationIds
      teacherIds: $teacherIds
      subjectIds: $subjectIds
      roomIds: $roomIds
      shifts: $shifts
    ) {
      total
      data {
        shift
        locationId
        week
        location {
          name
        }
        roomId
        room {
          code
        }
        monday {
          id
          scheduleType
          lessonType
          maxMemberLesson
          classId
          lesson
          name
          date
          dayOfWeek
          locationId
          location {
            name
            code
          }
          schoolHourId
          schoolHour {
            timeFrame
          }
          status
          class {
            name
            openingDate
            graduationDate
          }
          lecturer {
            name
          }
          tutors {
            name
          }
          interns {
            name
          }
        }
        tuesday {
          id
          scheduleType
          lessonType
          maxMemberLesson
          classId
          lesson
          name
          date
          dayOfWeek
          locationId
          location {
            name
            code
          }
          schoolHourId
          schoolHour {
            timeFrame
          }
          status
          class {
            name
            openingDate
            graduationDate
          }
          lecturer {
            name
          }
          tutors {
            name
          }
          interns {
            name
          }
        }
        wednesday {
          id
          scheduleType
          lessonType
          maxMemberLesson
          classId
          lesson
          name
          date
          dayOfWeek
          locationId
          location {
            name
            code
          }
          schoolHourId
          schoolHour {
            timeFrame
          }
          status
          class {
            name
            openingDate
            graduationDate
          }
          lecturer {
            name
          }
          tutors {
            name
          }
          interns {
            name
          }
        }
        thursday {
          id
          scheduleType
          lessonType
          maxMemberLesson
          classId
          lesson
          name
          date
          dayOfWeek
          locationId
          location {
            name
            code
          }
          schoolHourId
          schoolHour {
            timeFrame
          }
          status
          class {
            name
            openingDate
            graduationDate
          }
          lecturer {
            name
          }
          tutors {
            name
          }
          interns {
            name
          }
        }
        friday {
          id
          scheduleType
          lessonType
          maxMemberLesson
          classId
          lesson
          name
          date
          dayOfWeek
          locationId
          location {
            name
            code
          }
          schoolHourId
          schoolHour {
            timeFrame
          }
          status
          class {
            name
            openingDate
            graduationDate
          }
          lecturer {
            name
          }
          tutors {
            name
          }
          interns {
            name
          }
        }
        saturday {
          id
          scheduleType
          lessonType
          maxMemberLesson
          classId
          lesson
          name
          date
          dayOfWeek
          locationId
          location {
            name
            code
          }
          schoolHourId
          schoolHour {
            timeFrame
          }
          status
          class {
            name
            openingDate
            graduationDate
          }
          lecturer {
            name
          }
          tutors {
            name
          }
          interns {
            name
          }
        }
        sunday {
          id
          scheduleType
          lessonType
          maxMemberLesson
          classId
          lesson
          name
          date
          dayOfWeek
          locationId
          location {
            name
            code
          }
          schoolHourId
          schoolHour {
            timeFrame
          }
          status
          class {
            name
            openingDate
            graduationDate
          }
          lecturer {
            name
          }
          tutors {
            name
          }
          interns {
            name
          }
        }
      }
    }
  }
`
export function useGetTimeTableQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetTimeTableQuery, Types.GetTimeTableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetTimeTableQuery, Types.GetTimeTableQueryVariables>(GetTimeTableDocument, options)
}
export function useGetTimeTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTimeTableQuery, Types.GetTimeTableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetTimeTableQuery, Types.GetTimeTableQueryVariables>(GetTimeTableDocument, options)
}
export type GetTimeTableQueryHookResult = ReturnType<typeof useGetTimeTableQuery>
export type GetTimeTableLazyQueryHookResult = ReturnType<typeof useGetTimeTableLazyQuery>
export type GetTimeTableQueryResult = Apollo.QueryResult<Types.GetTimeTableQuery, Types.GetTimeTableQueryVariables>
export const GroupByClassDocument = gql`
  query groupByClass(
    $groupBy: [String!]!
    $offset: Int = 0
    $limit: Int = 10
    $ids: [String!]
    $sort: String = "updatedAt_desc"
    $status: String
    $subjectId: String
    $locationId: String
    $roomId: String
  ) {
    groupByClass(
      groupBy: $groupBy
      offset: $offset
      limit: $limit
      ids: $ids
      sort: $sort
      status: $status
      subjectId: $subjectId
      locationId: $locationId
      roomId: $roomId
    ) {
      data {
        _id
        name
        total
      }
      total
    }
  }
`
export function useGroupByClassQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GroupByClassQuery, Types.GroupByClassQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GroupByClassQuery, Types.GroupByClassQueryVariables>(GroupByClassDocument, options)
}
export function useGroupByClassLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GroupByClassQuery, Types.GroupByClassQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GroupByClassQuery, Types.GroupByClassQueryVariables>(GroupByClassDocument, options)
}
export type GroupByClassQueryHookResult = ReturnType<typeof useGroupByClassQuery>
export type GroupByClassLazyQueryHookResult = ReturnType<typeof useGroupByClassLazyQuery>
export type GroupByClassQueryResult = Apollo.QueryResult<Types.GroupByClassQuery, Types.GroupByClassQueryVariables>
export type ClassKeySpecifier = (
  | "id"
  | "isDeleted"
  | "isArchived"
  | "createdBy"
  | "updatedBy"
  | "deletedBy"
  | "deletedAt"
  | "createdAt"
  | "updatedAt"
  | "code"
  | "name"
  | "graduationDate"
  | "openingDate"
  | "subjectId"
  | "locationId"
  | "roomId"
  | "lecturerId"
  | "tutorIds"
  | "internIds"
  | "internLecturerIds"
  | "takeCareIds"
  | "groupLink"
  | "groupChat"
  | "isNo1Location"
  | "maxMemberRoom"
  | "numberOfCurrent"
  | "numberRecruited"
  | "numberRemain"
  | "lastShownUpCount"
  | "numberEndCourse"
  | "attendingRate"
  | "incomingRate"
  | "outgoingRate"
  | "numberRegister"
  | "revenueAfterDiscount"
  | "revenueReceived"
  | "revenueRemain"
  | "saleId"
  | "status"
  | "timeFrames"
  | ClassKeySpecifier
)[]
export type ClassFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isDeleted?: FieldPolicy<any> | FieldReadFunction<any>
  isArchived?: FieldPolicy<any> | FieldReadFunction<any>
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>
  deletedBy?: FieldPolicy<any> | FieldReadFunction<any>
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  code?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  graduationDate?: FieldPolicy<any> | FieldReadFunction<any>
  openingDate?: FieldPolicy<any> | FieldReadFunction<any>
  subjectId?: FieldPolicy<any> | FieldReadFunction<any>
  locationId?: FieldPolicy<any> | FieldReadFunction<any>
  roomId?: FieldPolicy<any> | FieldReadFunction<any>
  lecturerId?: FieldPolicy<any> | FieldReadFunction<any>
  tutorIds?: FieldPolicy<any> | FieldReadFunction<any>
  internIds?: FieldPolicy<any> | FieldReadFunction<any>
  internLecturerIds?: FieldPolicy<any> | FieldReadFunction<any>
  takeCareIds?: FieldPolicy<any> | FieldReadFunction<any>
  groupLink?: FieldPolicy<any> | FieldReadFunction<any>
  groupChat?: FieldPolicy<any> | FieldReadFunction<any>
  isNo1Location?: FieldPolicy<any> | FieldReadFunction<any>
  maxMemberRoom?: FieldPolicy<any> | FieldReadFunction<any>
  numberOfCurrent?: FieldPolicy<any> | FieldReadFunction<any>
  numberRecruited?: FieldPolicy<any> | FieldReadFunction<any>
  numberRemain?: FieldPolicy<any> | FieldReadFunction<any>
  lastShownUpCount?: FieldPolicy<any> | FieldReadFunction<any>
  numberEndCourse?: FieldPolicy<any> | FieldReadFunction<any>
  attendingRate?: FieldPolicy<any> | FieldReadFunction<any>
  incomingRate?: FieldPolicy<any> | FieldReadFunction<any>
  outgoingRate?: FieldPolicy<any> | FieldReadFunction<any>
  numberRegister?: FieldPolicy<any> | FieldReadFunction<any>
  revenueAfterDiscount?: FieldPolicy<any> | FieldReadFunction<any>
  revenueReceived?: FieldPolicy<any> | FieldReadFunction<any>
  revenueRemain?: FieldPolicy<any> | FieldReadFunction<any>
  saleId?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  timeFrames?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ClassesResponseKeySpecifier = ("total" | "data" | ClassesResponseKeySpecifier)[]
export type ClassesResponseFieldPolicy = {
  total?: FieldPolicy<any> | FieldReadFunction<any>
  data?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EmployeeKeySpecifier = (
  | "id"
  | "isDeleted"
  | "isArchived"
  | "createdBy"
  | "updatedBy"
  | "deletedBy"
  | "deletedAt"
  | "createdAt"
  | "updatedAt"
  | "name"
  | "userId"
  | "mobilePhone"
  | "workEmail"
  | "startWorkingDate"
  | "departmentId"
  | "locationIds"
  | EmployeeKeySpecifier
)[]
export type EmployeeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isDeleted?: FieldPolicy<any> | FieldReadFunction<any>
  isArchived?: FieldPolicy<any> | FieldReadFunction<any>
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>
  deletedBy?: FieldPolicy<any> | FieldReadFunction<any>
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
  mobilePhone?: FieldPolicy<any> | FieldReadFunction<any>
  workEmail?: FieldPolicy<any> | FieldReadFunction<any>
  startWorkingDate?: FieldPolicy<any> | FieldReadFunction<any>
  departmentId?: FieldPolicy<any> | FieldReadFunction<any>
  locationIds?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GroupByDataKeySpecifier = ("_id" | "id" | "name" | "total" | GroupByDataKeySpecifier)[]
export type GroupByDataFieldPolicy = {
  _id?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GroupByResponseKeySpecifier = ("data" | "total" | GroupByResponseKeySpecifier)[]
export type GroupByResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LocationKeySpecifier = (
  | "id"
  | "isDeleted"
  | "isArchived"
  | "createdBy"
  | "updatedBy"
  | "deletedBy"
  | "deletedAt"
  | "createdAt"
  | "updatedAt"
  | "name"
  | "nameSearch"
  | "code"
  | "provinceId"
  | "districtId"
  | "wardId"
  | "address"
  | "fullAddress"
  | "parkingAddress"
  | "hotline"
  | LocationKeySpecifier
)[]
export type LocationFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isDeleted?: FieldPolicy<any> | FieldReadFunction<any>
  isArchived?: FieldPolicy<any> | FieldReadFunction<any>
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>
  deletedBy?: FieldPolicy<any> | FieldReadFunction<any>
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  nameSearch?: FieldPolicy<any> | FieldReadFunction<any>
  code?: FieldPolicy<any> | FieldReadFunction<any>
  provinceId?: FieldPolicy<any> | FieldReadFunction<any>
  districtId?: FieldPolicy<any> | FieldReadFunction<any>
  wardId?: FieldPolicy<any> | FieldReadFunction<any>
  address?: FieldPolicy<any> | FieldReadFunction<any>
  fullAddress?: FieldPolicy<any> | FieldReadFunction<any>
  parkingAddress?: FieldPolicy<any> | FieldReadFunction<any>
  hotline?: FieldPolicy<any> | FieldReadFunction<any>
}
export type QueryKeySpecifier = (
  | "schoolHour"
  | "schoolHours"
  | "groupByClass"
  | "class"
  | "classes"
  | "groupByTimetable"
  | "timetables"
  | QueryKeySpecifier
)[]
export type QueryFieldPolicy = {
  schoolHour?: FieldPolicy<any> | FieldReadFunction<any>
  schoolHours?: FieldPolicy<any> | FieldReadFunction<any>
  groupByClass?: FieldPolicy<any> | FieldReadFunction<any>
  class?: FieldPolicy<any> | FieldReadFunction<any>
  classes?: FieldPolicy<any> | FieldReadFunction<any>
  groupByTimetable?: FieldPolicy<any> | FieldReadFunction<any>
  timetables?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RoomKeySpecifier = (
  | "id"
  | "isDeleted"
  | "isArchived"
  | "createdBy"
  | "updatedBy"
  | "deletedBy"
  | "deletedAt"
  | "createdAt"
  | "updatedAt"
  | "code"
  | "locationId"
  | "maxQuantity"
  | RoomKeySpecifier
)[]
export type RoomFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isDeleted?: FieldPolicy<any> | FieldReadFunction<any>
  isArchived?: FieldPolicy<any> | FieldReadFunction<any>
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>
  deletedBy?: FieldPolicy<any> | FieldReadFunction<any>
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  code?: FieldPolicy<any> | FieldReadFunction<any>
  locationId?: FieldPolicy<any> | FieldReadFunction<any>
  maxQuantity?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SchoolHourKeySpecifier = (
  | "id"
  | "isDeleted"
  | "isArchived"
  | "createdBy"
  | "updatedBy"
  | "deletedBy"
  | "deletedAt"
  | "createdAt"
  | "updatedAt"
  | "timeFrame"
  | "startHourName"
  | "startHour"
  | "endHour"
  | "endHourName"
  | "studySchedule"
  | SchoolHourKeySpecifier
)[]
export type SchoolHourFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isDeleted?: FieldPolicy<any> | FieldReadFunction<any>
  isArchived?: FieldPolicy<any> | FieldReadFunction<any>
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>
  deletedBy?: FieldPolicy<any> | FieldReadFunction<any>
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  timeFrame?: FieldPolicy<any> | FieldReadFunction<any>
  startHourName?: FieldPolicy<any> | FieldReadFunction<any>
  startHour?: FieldPolicy<any> | FieldReadFunction<any>
  endHour?: FieldPolicy<any> | FieldReadFunction<any>
  endHourName?: FieldPolicy<any> | FieldReadFunction<any>
  studySchedule?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SchoolHoursResponseKeySpecifier = ("total" | "data" | SchoolHoursResponseKeySpecifier)[]
export type SchoolHoursResponseFieldPolicy = {
  total?: FieldPolicy<any> | FieldReadFunction<any>
  data?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StudyScheduleKeySpecifier = (
  | "id"
  | "isDeleted"
  | "isArchived"
  | "createdBy"
  | "updatedBy"
  | "deletedBy"
  | "deletedAt"
  | "createdAt"
  | "updatedAt"
  | "timeFrame"
  | "startHourName"
  | "startHour"
  | "endHour"
  | "endHourName"
  | "studySchedule"
  | StudyScheduleKeySpecifier
)[]
export type StudyScheduleFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isDeleted?: FieldPolicy<any> | FieldReadFunction<any>
  isArchived?: FieldPolicy<any> | FieldReadFunction<any>
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>
  deletedBy?: FieldPolicy<any> | FieldReadFunction<any>
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  timeFrame?: FieldPolicy<any> | FieldReadFunction<any>
  startHourName?: FieldPolicy<any> | FieldReadFunction<any>
  startHour?: FieldPolicy<any> | FieldReadFunction<any>
  endHour?: FieldPolicy<any> | FieldReadFunction<any>
  endHourName?: FieldPolicy<any> | FieldReadFunction<any>
  studySchedule?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TimeFrameKeySpecifier = ("dayOfWeek" | "schoolHourId" | TimeFrameKeySpecifier)[]
export type TimeFrameFieldPolicy = {
  dayOfWeek?: FieldPolicy<any> | FieldReadFunction<any>
  schoolHourId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TimetableKeySpecifier = (
  | "week"
  | "shift"
  | "locationId"
  | "location"
  | "roomId"
  | "room"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday"
  | TimetableKeySpecifier
)[]
export type TimetableFieldPolicy = {
  week?: FieldPolicy<any> | FieldReadFunction<any>
  shift?: FieldPolicy<any> | FieldReadFunction<any>
  locationId?: FieldPolicy<any> | FieldReadFunction<any>
  location?: FieldPolicy<any> | FieldReadFunction<any>
  roomId?: FieldPolicy<any> | FieldReadFunction<any>
  room?: FieldPolicy<any> | FieldReadFunction<any>
  monday?: FieldPolicy<any> | FieldReadFunction<any>
  tuesday?: FieldPolicy<any> | FieldReadFunction<any>
  wednesday?: FieldPolicy<any> | FieldReadFunction<any>
  thursday?: FieldPolicy<any> | FieldReadFunction<any>
  friday?: FieldPolicy<any> | FieldReadFunction<any>
  saturday?: FieldPolicy<any> | FieldReadFunction<any>
  sunday?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TimetableEventKeySpecifier = (
  | "id"
  | "classId"
  | "class"
  | "lesson"
  | "name"
  | "date"
  | "dayOfWeek"
  | "schoolHourId"
  | "schoolHour"
  | "locationId"
  | "location"
  | "area"
  | "lecturerId"
  | "lecturer"
  | "tutorIds"
  | "tutors"
  | "internIds"
  | "interns"
  | "maxMemberLesson"
  | "formTextColor"
  | "status"
  | "scheduleType"
  | "lessonType"
  | "recruited"
  | "makeUpLessons"
  | "absent"
  | "expectedStudy"
  | "attendance"
  | TimetableEventKeySpecifier
)[]
export type TimetableEventFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  classId?: FieldPolicy<any> | FieldReadFunction<any>
  class?: FieldPolicy<any> | FieldReadFunction<any>
  lesson?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  date?: FieldPolicy<any> | FieldReadFunction<any>
  dayOfWeek?: FieldPolicy<any> | FieldReadFunction<any>
  schoolHourId?: FieldPolicy<any> | FieldReadFunction<any>
  schoolHour?: FieldPolicy<any> | FieldReadFunction<any>
  locationId?: FieldPolicy<any> | FieldReadFunction<any>
  location?: FieldPolicy<any> | FieldReadFunction<any>
  area?: FieldPolicy<any> | FieldReadFunction<any>
  lecturerId?: FieldPolicy<any> | FieldReadFunction<any>
  lecturer?: FieldPolicy<any> | FieldReadFunction<any>
  tutorIds?: FieldPolicy<any> | FieldReadFunction<any>
  tutors?: FieldPolicy<any> | FieldReadFunction<any>
  internIds?: FieldPolicy<any> | FieldReadFunction<any>
  interns?: FieldPolicy<any> | FieldReadFunction<any>
  maxMemberLesson?: FieldPolicy<any> | FieldReadFunction<any>
  formTextColor?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  scheduleType?: FieldPolicy<any> | FieldReadFunction<any>
  lessonType?: FieldPolicy<any> | FieldReadFunction<any>
  recruited?: FieldPolicy<any> | FieldReadFunction<any>
  makeUpLessons?: FieldPolicy<any> | FieldReadFunction<any>
  absent?: FieldPolicy<any> | FieldReadFunction<any>
  expectedStudy?: FieldPolicy<any> | FieldReadFunction<any>
  attendance?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TimetablesResponseKeySpecifier = ("total" | "data" | TimetablesResponseKeySpecifier)[]
export type TimetablesResponseFieldPolicy = {
  total?: FieldPolicy<any> | FieldReadFunction<any>
  data?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TypedTypePolicies = TypePolicies & {
  Class?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | ClassKeySpecifier | (() => undefined | ClassKeySpecifier)
    fields?: ClassFieldPolicy
  }
  ClassesResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | ClassesResponseKeySpecifier | (() => undefined | ClassesResponseKeySpecifier)
    fields?: ClassesResponseFieldPolicy
  }
  Employee?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | EmployeeKeySpecifier | (() => undefined | EmployeeKeySpecifier)
    fields?: EmployeeFieldPolicy
  }
  GroupByData?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | GroupByDataKeySpecifier | (() => undefined | GroupByDataKeySpecifier)
    fields?: GroupByDataFieldPolicy
  }
  GroupByResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | GroupByResponseKeySpecifier | (() => undefined | GroupByResponseKeySpecifier)
    fields?: GroupByResponseFieldPolicy
  }
  Location?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | LocationKeySpecifier | (() => undefined | LocationKeySpecifier)
    fields?: LocationFieldPolicy
  }
  Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier)
    fields?: QueryFieldPolicy
  }
  Room?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | RoomKeySpecifier | (() => undefined | RoomKeySpecifier)
    fields?: RoomFieldPolicy
  }
  SchoolHour?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | SchoolHourKeySpecifier | (() => undefined | SchoolHourKeySpecifier)
    fields?: SchoolHourFieldPolicy
  }
  SchoolHoursResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | SchoolHoursResponseKeySpecifier | (() => undefined | SchoolHoursResponseKeySpecifier)
    fields?: SchoolHoursResponseFieldPolicy
  }
  StudySchedule?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | StudyScheduleKeySpecifier | (() => undefined | StudyScheduleKeySpecifier)
    fields?: StudyScheduleFieldPolicy
  }
  TimeFrame?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | TimeFrameKeySpecifier | (() => undefined | TimeFrameKeySpecifier)
    fields?: TimeFrameFieldPolicy
  }
  Timetable?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | TimetableKeySpecifier | (() => undefined | TimetableKeySpecifier)
    fields?: TimetableFieldPolicy
  }
  TimetableEvent?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | TimetableEventKeySpecifier | (() => undefined | TimetableEventKeySpecifier)
    fields?: TimetableEventFieldPolicy
  }
  TimetablesResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | TimetablesResponseKeySpecifier | (() => undefined | TimetablesResponseKeySpecifier)
    fields?: TimetablesResponseFieldPolicy
  }
}
