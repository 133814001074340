import { PathItem } from "@/types"

export const DASHBOARD = "/"

export const PROFILE: PathItem = {
  DETAIL: { PATH: "/profile", LABEL: "Thông tin cá nhân", BREADCRUMB: ["Thông tin cá nhân"] },
  EDIT: { PATH: "/profile/edit", LABEL: "", BREADCRUMB: [] },
}

export const USER: PathItem = {
  LIST: { PATH: "/users", LABEL: "Người dùng", BREADCRUMB: ["Người dùng"] },
  CREATE: { PATH: "/users/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/users/:id", LABEL: "", BREADCRUMB: [] },
}

export const ROLE: PathItem = {
  LIST: { PATH: "/roles", LABEL: "Vai trò", BREADCRUMB: ["Vai trò"] },
  CREATE: { PATH: "/roles/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/roles/:id", LABEL: "", BREADCRUMB: [] },
}

export const PERMISSION: PathItem = {
  LIST: { PATH: "/permissions", LABEL: "Quyền hạn", BREADCRUMB: ["Quyền hạn"] },
  CREATE: { PATH: "/permissions/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "/permissions/:id", LABEL: "", BREADCRUMB: [] },
}

export const PUBLIC: PathItem = {
  LOGIN: { PATH: "/auth/login", LABEL: "Đăng nhập", BREADCRUMB: ["Đăng nhập"] },
  FORGOT_PASSWORD: { PATH: "/auth/forgot-password", LABEL: "", BREADCRUMB: [] },
  RESET_PASSWORD: { PATH: "/auth/reset-password", LABEL: "", BREADCRUMB: [] },
}
