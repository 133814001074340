export const setLocalStorage = (key: string, value: any) => {
  return localStorage.setItem(key, value)
}

export const removeLocalStorage = (key: string) => {
  return localStorage.removeItem(key)
}

export const getLocalStorage = (key: string) => {
  return localStorage.getItem(key)
}

export const formatToLocalDate = (dateTime: string) => {
  return new Date(dateTime).toLocaleString()
}

export const formatToLocalTime = (dateTime: string) => {
  return new Date(dateTime).toLocaleString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
}
