import { DebounceSelect } from "@/components/antd/form/DebounceSelect"
import { SearchBarType } from "@/modules/edu/pages/operation/work-time/type"
import { API } from "@/network/http"
import { SearchOutlined } from "@ant-design/icons"
import { Form } from "antd"
import { memo } from "react"

const LocationSearch: React.FC<SearchBarType> = ({ style, name }) => {
  const getListLocation = async (searchValue?: string) => {
    const response = await API.locations.locationControllerGetMany({ name: searchValue })
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    )
  }

  return (
    <Form.Item name={name}>
      <DebounceSelect
        mode="multiple"
        placeholder={
          <>
            <SearchOutlined /> Cơ sở{" "}
          </>
        }
        fetchOptions={getListLocation}
      />
    </Form.Item>
  )
}

export default memo(LocationSearch)
