import { API } from "@/network/http"
import { CreateTeacherDto, TeacherDto } from "@/__generated__/api-v1"

import { useCallback, useEffect, useState } from "react"

export const useCreateTeacher = () => {
  const createTeacher = async (data: CreateTeacherDto) => {
    try {
      await API.teachers.teacherControllerCreateOne(data)
    } catch (e) {
      console.log(e)
    }
  }

  return createTeacher
}

type TeacherQuery = Parameters<typeof API.teachers.teacherControllerGetMany>["0"]
export const useTeachers = (init = true) => {
  const [teachers, setTeachers] = useState<TeacherDto[]>([])

  const fetchTeachers = useCallback(async (query: TeacherQuery = { limit: "100" }) => {
    const response = await API.teachers.teacherControllerGetMany(query)

    setTeachers(response.data.data)
    return response.data.data
  }, [])

  useEffect(() => {
    if (init) {
      fetchTeachers(undefined)
    }
  }, [init])

  return { fetchTeachers, teachers }
}
