/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useIsWideLayout } from "@/components/layout/menuItems"
import { ACTION_METHOD_OBJ, PAGINATION_DEFAULT } from "@/constants"
import ClassUpsertForm from "@/modules/edu/pages/configuration/classes/views/UpsertForm"
import { TimeTableItemProps } from "@/modules/edu/pages/operation/work-time/type"
import DayContent from "@/modules/edu/pages/operation/work-time/views/components/DayContent"
import SearchBar from "@/modules/edu/pages/operation/work-time/views/components/SearchBar"
import UpsertForm from "@/modules/edu/pages/operation/work-time/views/UpsertForm"
import { API } from "@/network/http"
import { CRUD_ACTION } from "@/types"
import { getDayInWeek } from "@/utils"
import { useGetTimeTableLazyQuery } from "@/__generated__/apollo"
import { Timetable } from "@/__generated__/graphql-types"
import { PlusCircleOutlined } from "@ant-design/icons"
import { PageContainer } from "@ant-design/pro-layout"
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-table"
import { Empty, notification, Pagination, Space, Spin } from "antd"
import { FilterValue } from "antd/lib/table/interface"
import moment, { Moment } from "moment"
import { CSSProperties, FC, useRef, useState } from "react"
import { DAY_IN_WEEK, DEFINE_MODULE, SESSION_IN_DAY } from "../constants"

const styleCalendarNumber: CSSProperties = {
  position: "absolute",
  top: 5,
  left: -5,
  width: "100%",
  textAlign: "right",
  color: "#555",
  fontSize: "12px",
}

const List: FC = () => {
  const [isCreate, setCreate] = useState<boolean>(false)
  const [isUpdate, setUpdate] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<Timetable[]>([])
  const [timeTableItem, setTimeTableItem] = useState<TimeTableItemProps>()
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [filterTable, setFilterTable] = useState<any>();
  const [notifyApi, contextHolder] = notification.useNotification()
  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 100,
    total: 0
  })

  const actionRef = useRef<ActionType>()

  const [getTimeTable] = useGetTimeTableLazyQuery({
    variables: {
      fromDate: "",
      toDate: "",
    },
  })

  const fetchData = async (queries: any) => {
    setLoading(true)
    const timeTable = await getTimeTable({
      variables: {
        fromDate: queries.fromDate ?? moment().startOf("day"),
        toDate: queries.toDate ?? moment().endOf("day"),
        ...queries,
        ts: (new Date()).getTime().toString()
      },
    })
    setLoading(false)
    setDataSource((timeTable.data?.timetables?.data as Timetable[]) || [])
    setPagination({
      ...pagination,
      total: timeTable.data?.timetables?.total || 0
    })
    // setWeekendList(getDayInWeek(queries.week));
    return {
      data: timeTable.data?.timetables.data,
      total: timeTable.data?.timetables.total,
    }
  }

  const handleChange = (pagination: any, filters: Record<string, FilterValue | null>) => {
    console.log(pagination)
    const offSetField = ((pagination?.page == 0 ? 1 : pagination?.page) - 1) * (pagination?.pageSize ?? PAGINATION_DEFAULT.limit)
    fetchData({
      ...filters,
      fromDate: filters?.fromDate ?? moment().startOf("day"),
      toDate: filters?.toDate ?? moment().endOf("day"),
      offset: offSetField,
      limit: pagination?.pageSize
    })
  }

  const onChangePagination = (page: number, pageSize: number) => {
    console.log("onChangePagination", page, pageSize)
    setPagination({
      ...pagination,
      page,
      pageSize
    })

    localStorage.pagination = JSON.stringify({
      page,
      pageSize
    })

    handleChange({
      page,
      pageSize
    }, filterTable)
  }

  const onClickCreateNew = () => {
    setCreate(true)
  }

  const onClickCreate = (item: Timetable, dayInWeek: number, dateTime: Moment) => {
    setTimeTableItem({
      roomId: item.roomId || "",
      locationId: item.locationId || "",
      startAt: dateTime,
      dayInWeek: DAY_IN_WEEK[dayInWeek],
      classId: "",
    })
    setCreate(true)
  }

  const onClickUpdate = (
    item: Timetable,
    dayInWeek: number,
    id: string | number,
    dateTime: Moment,
    classId: string,
  ) => {
    setTimeTableItem({
      id,
      roomId: item.roomId || "",
      locationId: item.locationId || "",
      startAt: dateTime,
      dayInWeek: DAY_IN_WEEK[dayInWeek],
      classId,
    })
    setUpdate(true)
  }

  const onEmptyDayOfWeek = (item: Timetable, dayInWeek: number) => {
    const dateTime = moment(getDayInWeek(Number(item.week))[dayInWeek])
    return (
      <Space direction="vertical" style={{ minWidth: 140 }}>
        <div style={styleCalendarNumber}>{dateTime.format("DD-MM-YYYY")}</div>
        <button
          onClick={() => onClickCreate(item, dayInWeek, dateTime)}
          style={{
            width: "140px",
            height: "94px",
            background: "none",
            border: "1px solid #f0f0f0",
            cursor: "pointer",
            borderRadius: "6px",
          }}
        >
          <PlusCircleOutlined />
        </button>
      </Space>
    )
  }

  const onDataDayOfWeek = (
    entity: Timetable,
    dayInWeek: number,
    day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday",
  ) => {
    const dateTime = moment(getDayInWeek(Number(entity.week))[dayInWeek])

    return (
      <Space direction="vertical" style={{ minWidth: 120 }}>
        <div style={styleCalendarNumber}>{dateTime.format("DD-MM-YYYY")}</div>
        {entity[day]?.map((item, idx) => (
          <DayContent
            key={`${JSON.stringify(entity)}_${idx}`}
            onClickUpdate={() => onClickUpdate(item, dayInWeek, item.id, dateTime, item.class ? item.classId : "")}
            data={item}
            shift={`${entity.shift}`}
          />
        ))}
      </Space>
    )
  }

  interface ShiftProps {
    shiftName: "MORNING" | "AFTERNOON" | "EVENING"
  }

  const columns: ProColumns<Timetable>[] = [
    {
      title: "Loại",
      search: false,
      width: 60,
      align: "center",
      fixed: "left",
      render: (_, entity) => {
        if (entity.shift) {
          const shift = entity.shift as any as ShiftProps["shiftName"]
          return <div style={{ fontSize: 12 }}>{SESSION_IN_DAY[shift]?.name}</div>
        }
        return null
      },
    },
    {
      title: "Cơ sở",
      search: false,
      dataIndex: ["location", "name"],
      width: 70,
      align: "center",
      fixed: "left",
    },
    {
      title: "Phòng",
      search: false,
      dataIndex: ["room", "code"],
      width: 60,
      align: "center",
      fixed: "left",
    },
    {
      title: "Thứ 2",
      search: false,
      align: "center",
      render: (_, entity) => {
        if (entity?.monday?.length === 0) {
          return onEmptyDayOfWeek(entity, 0)
        }
        return onDataDayOfWeek(entity, 0, "monday")
      },
    },
    {
      title: "Thứ 3",
      search: false,
      align: "center",
      // width: 165,
      render: (_, entity) => {
        if (entity?.tuesday?.length === 0) {
          return onEmptyDayOfWeek(entity, 1)
        }
        return onDataDayOfWeek(entity, 1, "tuesday")
      },
    },
    {
      title: "Thứ 4",

      search: false,
      align: "center",
      // width: 165,
      render: (_, entity) => {
        if (entity?.wednesday?.length === 0) {
          return onEmptyDayOfWeek(entity, 2)
        }
        return onDataDayOfWeek(entity, 2, "wednesday")
      },
    },
    {
      title: "Thứ 5",

      search: false,
      align: "center",
      // width: 165,
      render: (_, entity) => {
        if (entity?.thursday?.length === 0) {
          return onEmptyDayOfWeek(entity, 3)
        }
        return onDataDayOfWeek(entity, 3, "thursday")
      },
    },
    {
      title: "Thứ 6",

      search: false,
      align: "center",
      // width: 165,
      render: (_, entity) => {
        if (entity?.friday?.length === 0) {
          return onEmptyDayOfWeek(entity, 4)
        }
        return onDataDayOfWeek(entity, 4, "friday")
      },
    },
    {
      title: "Thứ 7",

      search: false,
      align: "center",
      // width: 165,
      render: (_, entity) => {
        if (entity?.saturday?.length === 0) {
          return onEmptyDayOfWeek(entity, 5)
        }
        return onDataDayOfWeek(entity, 5, "saturday")
      },
    },
    {
      title: "Chủ nhật",

      align: "center",
      search: false,
      // width: 165,
      render: (_, entity) => {
        if (entity?.sunday?.length === 0) {
          return onEmptyDayOfWeek(entity, 6)
        }
        return onDataDayOfWeek(entity, 6, "sunday")
      },
    },
  ]

  const onSubmit = async (val: any, type: CRUD_ACTION, _id?: string) => {
    try {
      setLoadingBtn(true)
      const reqData =
        type === "CREATE"
          ? await API.classes.classControllerCreateOne(val)
          : await API.classes.classControllerUpdateOne(_id || "", val)
      if (reqData && reqData.data) {
        if (type === "CREATE") setCreate(false)
        if (type === "UPDATE") setUpdate(false)
        notifyApi.success({
          message: "Thông báo",
          description: `${ACTION_METHOD_OBJ[type]} thành công`,
          placement: "topRight",
        })
        actionRef.current?.reload()
      }
    } catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      })
    } finally {
      setLoadingBtn(false)
    }
  }

  const [prefilledValues, setPrefilledValues] = useState({ staringDate: new Date().toISOString() })

  const isWideLayout = useIsWideLayout()

  return (
    <PageContainer title="Tổng Quan Lịch Học" className={isWideLayout ? "wide-layout-page-content" : ""}>
      {contextHolder}
      <SearchBar
        triggerSearch={(p, f) => handleChange(pagination, f)}
        handleCreate={onClickCreateNew}
        setFilterTable={setFilterTable}
        onReload={() => {
          actionRef.current?.reload()
        }}
      />
      <div>
        {loading && <div style={{ position: 'absolute', zIndex: 100, width: '100%', height: '100%', textAlign: 'center', paddingTop: 103 }}>
          <Spin />
          <span>Đang tải dữ liệu</span>
        </div>}

        <ProTable<Timetable>
          headerTitle={`Danh sách ${DEFINE_MODULE.name}`}
          actionRef={actionRef}
          bordered
          rowKey={record => JSON.stringify(record)}
          search={false}
          dataSource={dataSource}
          onChange={(p, f) => handleChange(pagination, filterTable)}
          options={{ density: false, fullScreen: false, reload: false }}
          pagination={false}
          toolBarRender={false}
          columns={columns}
          // scroll={{ x: 1300, y: 430 }}
          locale={{
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Không có dữ liệu"} />,
          }}
          // @ts-ignore
          request={async (params, sort, filter) => {
            try {
              const p = JSON.parse(localStorage.pagination);
              return handleChange(p, filterTable)
            } catch (e) {
              return handleChange(pagination, filterTable)
            }
          }}
        />
        <Pagination
          onChange={onChangePagination}
          defaultPageSize={pagination.pageSize}
          defaultCurrent={pagination.page}
          showSizeChanger={true}
          total={pagination.total}
          showTotal={(total, range) => `${range[0]}-${range[1]} trên ${total} ${DEFINE_MODULE.name}`}
          style={{ float: "right" }}
        />
      </div>
      {isCreate && (
        <ClassUpsertForm
          notify={notifyApi}
          key="CREATE"
          onCancel={() => setCreate(false)}
          modalVisible={isCreate}
          onSubmit={onSubmit}
          actionType="CREATE"
          loadingBtn={loadingBtn}
        // prefilledValues={prefilledValues}
        />
      )}

      {isUpdate && (
        <UpsertForm
          notify={notifyApi}
          key="UPDATE"
          onCancel={() => setUpdate(false)}
          modalVisible={isUpdate}
          notifyApi={notifyApi}
          actionRef={actionRef}
          actionType="UPDATE"
          onSubmit={() => null}
          values={timeTableItem}
        />
      )}
    </PageContainer>
  )
}

export default List
