export const CLASS_STATUS = {
  DRAFT: "Nháp",
  DRAUGHT: "Dự thảo",
  NOT_OPEN_YET: "Chưa khai giảng",
  STUDYING: "Đang học",
  GRADUATED: "Đã tốt nghiệp",
  CANCELED: "Đã huỷ",
}

export type ClassStatusType = keyof typeof CLASS_STATUS
