import { Action } from "history"
import { useCallback } from "react"
import { useNavigate, useNavigationType } from "react-router-dom"

export const useGoBack = (parentRoute: string) => {
  const type = useNavigationType()
  const navigate = useNavigate()
  const goBack = useCallback(() => {
    if (type === Action.Push) {
      return navigate(-1)
    }

    return navigate(parentRoute ?? "/")
  }, [])

  return { goBack }
}
