import { WorkingScheduleStatus } from "@/modules/edu/pages/operation/working-schedule/types"

export const DEFINE_MODULE = {
  name: "lịch làm việc",
  fields: "working-schedules",
}

export const statuses: Record<WorkingScheduleStatus, string> = {
  ACCEPTED: "Đã duyệt",
  CANCELED: "Đã huỷ",
  INITIAL: "Dự thảo",
  REJECTED: "Từ chối",
}
