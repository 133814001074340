import { message } from "antd"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"

export const Notifications = () => {
  const { title, message: content, isNoti, type } = useSelector((state: RootState) => state.common.notification)

  useEffect(() => {
    if (content || title) {
      message.open({ content, duration: 3, type })
    }
  }, [isNoti])

  return null
}
