import { PathItem } from "@/types"

export const EMPLOYEE: PathItem = {
  LIST: { PATH: "employees", LABEL: "Nhân viên", BREADCRUMB: ["Nhân viên"] },
  CREATE: { PATH: "employees/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "employees/:id", LABEL: "", BREADCRUMB: [] },
}

export const DEPARTMENT: PathItem = {
  LIST: { PATH: "departments", LABEL: "Phòng ban", BREADCRUMB: ["Phòng ban"] },
  CREATE: { PATH: "departments/create", LABEL: "", BREADCRUMB: [] },
  EDIT: { PATH: "departments/:id", LABEL: "", BREADCRUMB: [] },
}

export const HR = "/hr/*"
