import { AxiosResponse } from "axios"
import i18n from "i18n-js"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import { ACCESS_TOKEN, AUTH_FORGOT_PASSWORD_UPDATE, IS_AUTHENTICATED, PUBLIC, USER_INFO } from "../../constants"
import * as actionTypes from "../../constants/ActionTypes"
import history from "../../navigation"
import { forgotPasswordApi, loginApi, resetPasswordApi } from "../../network"
import { API } from "../../network/http"
import { setLocalStorage } from "../../utils"
import { getAuthState } from "../selectors"
import { IAuthState } from "../types"

interface IPayload {
  type: string
  payload: any
}

interface IResponse {
  data: any
  total: number
}

function* handleCheckAuthentication() {
  try {
    yield put({ type: actionTypes.GET_USER_PROFILE })
    // //Todo: check time token to expired

    // const auth = localStorage.getItem(IS_AUTHENTICATED);
    // const accessToken = localStorage.getItem(ACCESS_TOKEN) as string;
    // const parseResult = parseJwt(accessToken);
    // if (parseResult) {
    //   if (parseResult.exp < new Date().getTime() / 1000) {
    //     //refesh token
    //   }
    // }
  } catch (error) {
    yield put({ type: actionTypes.GET_USER_PROFILE_FAILED, payload: error })
  }
}

function* usage(action: IPayload) {
  const res: AxiosResponse = yield call(API.auth.authControllerLogin, action.payload)

  API.auth.authControllerLogin({ password: "xxx", username: "xxx" }).then().catch()
  console.log(res)
}

function* login(action: IPayload) {
  try {
    const { data }: AxiosResponse = yield call(loginApi, action.payload)

    yield setLocalStorage(ACCESS_TOKEN, data.accessToken)
    yield setLocalStorage(IS_AUTHENTICATED, true)
    yield put({ type: actionTypes.LOGIN_SUCCESS, payload: data })
    yield put({ type: actionTypes.GET_USER_PROFILE })
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Đăng nhập thành công",
        message: `Bạn đang truy cập vào hệ thống ERP của Mỹ Thuật Bụi`,
        type: "success",
      },
    })
  } catch (error) {
    yield put({ type: actionTypes.LOGIN_FAILED, payload: error })
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Đăng nhập không thành công",
        message: "Vui lòng kiểm tra lại thông tin đăng nhập",
        type: "error",
      },
    })
  }
}

function* logout(action: IPayload) {
  try {
    // clear all local storage
    localStorage.removeItem(ACCESS_TOKEN)
    localStorage.removeItem(IS_AUTHENTICATED)
    localStorage.removeItem(USER_INFO)
    localStorage.clear()

    history.push(PUBLIC.LOGIN.PATH)
  } catch (error) {
    console.log(error)
  }
}

function* sessionExpired(action: IPayload) {
  try {
    localStorage.removeItem(ACCESS_TOKEN)
    localStorage.removeItem(IS_AUTHENTICATED)
    localStorage.removeItem(USER_INFO)
    yield put({ type: actionTypes.SESSION_EXPIRED_SUCCESS })
  } catch (error) {
    console.log(error)
  }
}

function* forgotPassword(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const auth: IAuthState = yield select<any>(getAuthState)
    if (auth.isLoadingRequest) return

    // set loading
    yield put({
      type: actionTypes.AUTH_SET_LOADING,
      payload: { isLoadingRequest: true },
    })

    // clear state
    yield put({
      type: AUTH_FORGOT_PASSWORD_UPDATE,
      payload: { data: null },
    })

    // send forgot password
    const { data }: IResponse = yield call(forgotPasswordApi, action.payload)

    // update to state
    yield put({
      type: AUTH_FORGOT_PASSWORD_UPDATE,
      payload: { data },
    })

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("auth.forgotPassword.titleSuccess"),
        message: i18n.t("auth.forgotPassword.messageSuccess"),
        type: "success",
      },
    })
  } catch (error: any) {
    console.log(error)

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("auth.forgotPassword.titleFail"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.AUTH_SET_LOADING,
      payload: { isLoadingRequest: false },
    })
  }
}

function* resetPassword(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const auth: IAuthState = yield select<any>(getAuthState)
    if (auth.isLoadingRequest) return

    // set loading
    yield put({
      type: actionTypes.AUTH_SET_LOADING,
      payload: { isLoadingRequest: true },
    })

    // clear state
    // yield put({
    //   type: AUTH_FORGOT_PASSWORD_UPDATE,
    //   payload: { data: null },
    // });

    // send forgot password
    const { data }: IResponse = yield call(resetPasswordApi, action.payload)

    // update to state
    // yield put({
    //   type: AUTH_FORGOT_PASSWORD_UPDATE,
    //   payload: { data },
    // });

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("auth.resetPassword.titleSuccess"),
        message: i18n.t("auth.resetPassword.messageSuccess"),
        type: "success",
      },
    })
  } catch (error: any) {
    console.log(error)

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("auth.resetPassword.titleFail"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.AUTH_SET_LOADING,
      payload: { isLoadingRequest: false },
    })
  }
}

export default function* root() {
  yield all([
    takeLatest(actionTypes.LOGIN, login),
    takeLatest(actionTypes.LOGOUT, logout),
    takeLatest(actionTypes.CHECK_AUTHENTICATION, handleCheckAuthentication),
    takeLatest(actionTypes.AUTH_FORGOT_PASSWORD, forgotPassword),
    takeLatest(actionTypes.AUTH_RESET_PASSWORD, resetPassword),
    takeLatest(actionTypes.SESSION_EXPIRED, sessionExpired),
  ])
}
