/* eslint-disable jsx-a11y/anchor-is-valid */
import { DAY_OF_WEEK_OBJ, STUDY_SCHEDULE_STATUS } from "@/modules/edu/pages/configuration/classes/constants"
import { API } from "@/network/http"
import { CRUD_ACTION } from "@/types"
import { formatDate } from "@/utils"
// import { ResponseManyStudySchedule } from "@/__generated__/api-v1"
import { StudyScheduleDto } from "@/__generated__/api-v1"
import { ActionType, EditableProTable, ProColumns } from "@ant-design/pro-table"
import { Card, Form, FormInstance, Spin, Tooltip } from "antd"
import { NotificationInstance } from "antd/lib/notification"
import { findIndex, isEmpty } from "lodash"
import { forwardRef, useEffect, useRef, useState } from "react"

interface StudyScheduleProps {
  ref?: any
  actionType: CRUD_ACTION
  classId: string
  defaultShow?: true | undefined
  isShow: boolean
  defaultValue?: any[]
  hasAlreadyGenerated: boolean
  notify?: NotificationInstance
  form: FormInstance
  getStudySchedule?: any
  reschedule?: number
  updateScheduledItem?: any
  rescheduling?: boolean
  setRescheduling?: any
  data?: any
  setData?: any
  onScheduleGenerated: () => void
}

const StudyScheduleTable: React.FC<StudyScheduleProps> = forwardRef(
  (
    {
      form,
      isShow,
      classId,
      hasAlreadyGenerated,
      notify,
      actionType,
      getStudySchedule: getStudyScheduleProps,
      reschedule,
      rescheduling,
      setRescheduling,
      data,
      setData,
      onScheduleGenerated,
    },
    ref,
  ) => {
    const actionRef = useRef<ActionType>()
    const [listSchoolHour, setListSchoolHour] = useState<any>({})
    const [listRoom, setListRoom] = useState<any>({})
    const [listLecturer, setListLecturer] = useState<any>([])
    const [listTutor, setListTutor] = useState<any>([])
    const [listIntern, setListIntern] = useState<any>([])

    const fetchData = async (queries: Parameters<typeof API.studySchedules.studyScheduleControllerGenerate>["0"]) => {
      const response = (await API.studySchedules.studyScheduleControllerGenerate({ ...queries })).data
      return response
    }

    // const generateStudySchedule = async () => {
    //   return await fetchData({
    //     classId,
    //     force: true,
    //   })
    // }

    const getStudySchedule = async () => {
      if (classId) {
        const response = (await API.studySchedules.studyScheduleControllerGetMany({ classId, limit: "200" })).data
        return response
      }
    }

    const getListSchoolHour = async () => {
      const response = await API.schoolHours.schoolHourControllerGetMany({ limit: "100" })
      const data = response?.data?.data?.reduce((prev, curr) => {
        return {
          ...prev,
          [`${curr._id}`]: {
            text: curr.timeFrame,
            value: curr._id,
          },
        }
      }, {})
      setListSchoolHour({
        dropdown: data,
        raw: response?.data?.data,
      })
    }

    const getListRoom = async (searchValue?: string) => {
      const response = await API.rooms.roomControllerGetMany({
        code: searchValue,
        limit: "1000",
      })
      const data = response?.data?.data?.reduce((prev, curr) => {
        return {
          ...prev,
          [`${curr._id}`]: {
            text: curr.code,
            value: curr._id,
          },
        }
      }, {})
      setListRoom({
        dropdown: data,
        raw: response?.data?.data,
      })
    }

    const getListAvailable = async (
      fieldKey: "lecturer" | "tutor" | "intern",
      roles?: ("LECTURER" | "TUTOR" | "INTERN" | "INTERN_LECTURER")[],
    ) => {
      if (
        form.getFieldValue("subjectId") &&
        form.getFieldValue("locationId") &&
        form.getFieldValue("roomId") &&
        form
          .getFieldValue("timeFrames")
          ?.every((time: { schoolHourId: string; dayOfWeek: string }) => time.dayOfWeek && time.schoolHourId)
      ) {
        const response = await API.availability.availabilityControllerCheck({
          subjectIds: [form.getFieldValue("subjectId")],
          locationIds: [form.getFieldValue("locationId")],
          daysOfWeek: form.getFieldValue("daysOfWeek"),
          areas: [],
          filterFields: [],
          sortFields: [],
          offset: "0",
          limit: "100",
          roles,
          ids: [],
        })
        const raw =
          response?.data?.data?.map((item: any) => ({
            _id: item?.employeeId,
            name: item?.employee[0].name,
          })) || []

        const data =
          response?.data?.data?.map((item: any) => ({
            value: item?.employeeId,
            label: item?.employee[0].name,
          })) || []
        console.log(raw)
        switch (fieldKey) {
          case "lecturer":
            setListLecturer({
              dropdown: data,
              raw,
            })
            break
          case "tutor":
            setListTutor({
              dropdown: data,
              raw,
            })
            break
          case "intern":
            setListIntern({
              dropdown: data,
              raw,
            })
            break
          default:
            break
        }
      }
    }

    // const regenerateStudySchedule = async () => {
    //   const studySchedulePreview = await getStudyScheduleProps();
    //   console.log(studySchedulePreview)
    //   setData(studySchedulePreview)
    // }

    useEffect(() => {
      async function fetchData() {
        await Promise.all([
          getListSchoolHour(),
          getListRoom(),
          getListAvailable("lecturer"),
          getListAvailable("tutor"),
          getListAvailable("intern"),
        ])
      }

      fetchData()
    }, [])

    const generateNewListScheduled = async () => {
      const response = await getStudyScheduleProps()
      setRescheduling(false)
      setData(response)
      onScheduleGenerated()
    }

    const fetchListScheduled = async () => {
      const response = await getStudySchedule()
      setData(response)
      setRescheduling(false)
    }

    const bindingDataToTable = async () => {
      return data
    }

    const updateScheduledItem = async (item: any) => {
      const { data: newData } = data
      // @ts-ignore
      console.log("dda: ", data)
      // const statusObj = (await API.studySchedules.checkStaffStatus({ ...item })).data

      const index = findIndex(newData, { refId: item.refId })
      newData.splice(index, 1, {
        ...item,
        // fieldsStatus: statusObj.fieldsStatus,
        // status: statusObj.status,
        // statusObj,
        // lecturer: data.data[index].
      })
      console.log("data: ", data)
      setData({
        ...data,
        data: newData,
      })
    }

    useEffect(() => {
      setRescheduling(true)
      if (reschedule && reschedule > 0) {
        generateNewListScheduled()
      } else {
        fetchListScheduled()
      }
    }, [reschedule])

    useEffect(() => {
      actionRef.current?.reload()
    }, [data])

    // const columns: ProColumns<ResponseManyStudySchedule>[] = [
    const columns: ProColumns<StudyScheduleDto>[] = [
      {
        title: "Buổi học",
        dataIndex: "name",
        formItemProps: {
          rules: [
            {
              required: true,
              message: "Không để trống",
            },
          ],
        },
        width: 80,
        render: (value, entity: any) => {
          const status = entity?.status
          return {
            // props: {
            //   style: {
            //     background: status === STUDY_SCHEDULE_STATUS.INVALID ? "red" : "white",
            //     color: status === STUDY_SCHEDULE_STATUS.INVALID ? "white" : "black",
            //   },
            // },
            children: <div>{value}</div>,
          }
        },
      },
      {
        title: "Ngày học",
        dataIndex: "date",
        width: 80,
        valueType: "date",
        formItemProps: {
          rules: [
            {
              required: true,
              message: "Không để trống",
            },
          ],
        },
        render: (_, entity) => {
          return formatDate(entity.date)
        },
      },
      {
        title: "Thứ",
        dataIndex: "dayOfWeek",
        width: 120,
        // valueType: "select",
        valueEnum: DAY_OF_WEEK_OBJ,
        editable: false,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "Không để trống",
            },
          ],
        },
      },
      {
        title: "Khung giờ",
        dataIndex: ["schoolHour", "timeFrame"],
        valueType: "select",
        valueEnum: listSchoolHour.dropdown,
        width: 120,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "Không để trống",
            },
          ],
        },
      },
      {
        title: "Phòng học",
        dataIndex: ["room", "_id"],
        width: 120,
        valueType: "select",
        valueEnum: listRoom.dropdown,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "Không để trống",
            },
          ],
        },
        render: (_, entity) => {
          const code = entity?.room?.code
          // check status is valid
          // const statusObj = entity?.statusObj?.fieldsStatus?.find((o: any) => o.value?.indexOf(entity?.room?._id) > -1)
          const statusObj = null as any

          return {
            props: {
              style: {
                background: statusObj?.isValid === false ? "red" : "white",
                color: statusObj?.isValid === false ? "white" : "black",
              },
            },
            children: (
              <Tooltip title={statusObj?.description} color="cyan">
                <div>{code}</div>
              </Tooltip>
            ),
          }
        },
        // fieldProps: {
        //   options: listRoom,
        //   showSearch: true,
        //   onSearch: val => getListRoom(val),
        // },
      },
      {
        title: "Giáo viên",
        dataIndex: ["lecturer", "_id"],
        width: 180,
        valueType: "select",
        formItemProps: {
          rules: [
            {
              required: true,
              message: "Không để trống",
            },
          ],
        },
        fieldProps: (e, _) => {
          const selectedItem = _.entity.lecturer
          return {
            options: [
              { value: selectedItem?._id, label: selectedItem?.name },
              ...(listLecturer.dropdown?.filter((d: any) => d.value !== selectedItem?._id) ?? []),
            ],
          }
        },
        render: (_, entity) => {
          const employeeName = entity?.lecturer?.name
          // @ts-ignore
          const statusObj = entity?.statusObj?.fieldsStatus?.find(
            (o: any) => o.value?.indexOf(entity?.lecturerId) > -1 && o.errorName === "TEACHER_NOT_AVAILABLE",
          )
          const status = statusObj?.isValid !== false

          // check status is valid
          if (status) return <div>{employeeName}</div>

          // // status is invalid
          // let title = ""
          // let isLecturerError = false
          // const fieldsStatus = entity.fieldsStatus || []
          // for (let i = 0; i < fieldsStatus.length; i++) {
          //   if (fieldsStatus[i].name === STUDY_SCHEDULE_FIELD_NAME.LECTURER_ID) {
          //     isLecturerError = true
          //     title += fieldsStatus[i].description + `\n`
          //   }
          // }
          // if (!isLecturerError) return <div>{employeeName}</div>

          return {
            props: {
              style: {
                background: !status ? "red" : "white",
                color: !status ? "white" : "black",
              },
            },
            children: (
              <Tooltip title={statusObj.description} color="cyan">
                <div>{employeeName}</div>
              </Tooltip>
            ),
          }
        },
      },
      {
        title: "Trợ giảng",
        dataIndex: "tutorIds",
        width: 180,
        valueType: "select",
        fieldProps: (e, _) => {
          const selectedItems = _.entity.tutors ?? []
          return {
            options: [...selectedItems.map(e => ({ value: e._id, label: e.name })), ...(listTutor.dropdown ?? [])],
            mode: "multiple",
          }
        },
        render: (v, entity) => {
          const tutorsComponent = entity.tutors.map(t => {
            if (entity.tutorIds.indexOf(t._id) === -1) {
              return null
            }
            const employeeName = t?.name
            // @ts-ignore
            const statusObject = entity?.statusObj?.fieldsStatus?.find(
              (f: any) => f.value?.indexOf(t._id) > -1 && f.errorName === "TUTOR_NOT_AVAILABLE",
            )
            const status = statusObject?.isValid === false ? STUDY_SCHEDULE_STATUS.INVALID : STUDY_SCHEDULE_STATUS.VALID
            // check status is valid
            if (status === STUDY_SCHEDULE_STATUS.VALID) return <div>{employeeName}</div>
            const style = {
              // background: status === STUDY_SCHEDULE_STATUS.INVALID ? "red" : "white",
              color: status === STUDY_SCHEDULE_STATUS.INVALID ? "red" : "black",
            }
            return (
              <Tooltip title={statusObject?.description} color="cyan">
                <div style={style}>{employeeName}</div>
              </Tooltip>
            )
            // props: {
            //   style: {
            //     background: status === STUDY_SCHEDULE_STATUS.INVALID ? "red" : "white",
            //     color: status === STUDY_SCHEDULE_STATUS.INVALID ? "white" : "black",
            //   },
            // },
            // children: (
            //   <Tooltip title={statusObject?.description} color="cyan">
            //     <div>{employeeName}</div>
            //   </Tooltip>
            // ),
          })
          return {
            children: <div>{tutorsComponent}</div>,
          }
        },
      },
      {
        title: "Thực tập",
        dataIndex: "internIds",
        width: 180,
        valueType: "select",
        fieldProps: (e, _) => {
          const selectedItems = _.entity.interns ?? []
          return {
            options: [...selectedItems.map(e => ({ value: e._id, label: e.name })), ...(listIntern.dropdown ?? [])],
            mode: "multiple",
          }
        },
        // renderText: (_, entity) => {
        //   return entity.tutors?.map(item => item.name)?.toString()
        // },
      },
      // {
      //   title: "Tối đa",
      //   dataIndex: ["room", "maxQuantity"],
      //   width: 120,
      //   editable: false,
      // },
      // {
      //   title: "Trạng thái",
      //   dataIndex: "status",
      //   width: 120,
      //   render: (value, entity: any) => {
      //     const color = value === STUDY_SCHEDULE_STATUS.INVALID ? 'red' : 'blue';
      //     const text = value === STUDY_SCHEDULE_STATUS.INVALID ? 'Không hợp lệ' : 'Hợp lệ';
      //     return <div><Tag color={color}>{text}</Tag></div>;
      //   }
      // },
      // {
      //   title: "Đã tuyển",
      //   dataIndex: "datuyen",
      //   width: 120,
      // },
      // {
      //   title: "Học bù",
      //   dataIndex: "hocbu",
      //   width: 120,
      // },
      // {
      //   title: "Vắng",
      //   dataIndex: "vang",
      //   width: 120,
      // },
      // {
      //   title: "Dự kiến",
      //   dataIndex: "dukien",
      //   width: 120,
      // },
      // {
      //   title: "Điểm danh",
      //   dataIndex: "diemdanh",
      //   width: 120,
      // },
      actionType !== "READ"
        ? {
            title: "Thao tác",
            fixed: "right",
            width: 120,
            valueType: "option",
            render: (text, record, _, action) => [
              <a
                key="editable"
                onClick={() => {
                  // @ts-ignore
                  action?.startEditable?.(record.refId)
                }}
              >
                Chỉnh sửa
              </a>,
            ],
          }
        : {},
    ]

    const [editableForm] = Form.useForm()

    return (
      <>
        {isShow && (
          <Card>
            {rescheduling && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 100,
                  width: "100%",
                  height: "100%",
                  textAlign: "center",
                  paddingTop: 103,
                }}
              >
                <Spin />
                <span>Đang tải dữ liệu</span>
              </div>
            )}
            <EditableProTable<StudyScheduleDto>
              rowKey="refId"
              key={`table_timeframe`}
              bordered
              actionRef={actionRef}
              // dataSource={data}
              request={async () => {
                return bindingDataToTable()
                // if (hasAlreadyGenerated && reschedule === 0) {
                //   return await getStudySchedule()
                // }
                // return await getStudyScheduleProps()
              }}
              recordCreatorProps={false}
              editable={{
                saveText: "Lưu",
                cancelText: "Hủy",
                // type: 'multiple',
                form: editableForm,
                onSave: async (key, record, originRow, newLineConfig) => {
                  // console.log("ahi", listLecturer, "ttw", record, "new: ", newLineConfig)
                  try {
                    const dataSend = {
                      // classId,
                      ...originRow,
                      lesson: record.lesson,
                      roomId: record.room._id,
                      room: listRoom.raw.find((r: any) => r._id === record.room._id),
                      schoolHourId: record.schoolHourId,
                      schoolHour: listSchoolHour.raw.find((r: any) => r._id === record.schoolHourId),
                      lecturerId: record.lecturer._id,
                      lecturer: listLecturer.raw.find((r: any) => r._id === record.lecturer._id) ?? record.lecturer,
                      tutorIds: isEmpty(record.tutorIds) ? undefined : record.tutorIds,
                      internIds: isEmpty(record.internIds) ? undefined : record.internIds,
                      date: record.date,
                      dayOfWeek: record.dayOfWeek,
                      maxMemberLesson: record.maxMemberLesson,
                    }
                    updateScheduledItem(dataSend)
                    // actionRef.current?.reload()
                    // const reqData = await API.studySchedules.studyScheduleControllerUpdateOne(
                    //   record._id,
                    //   dataSend as any,
                    // )
                    // if (reqData.data) {
                    //   notify?.success({
                    //     message: "Cập nhật lịch học thành công",
                    //   })
                    //   actionRef.current?.reload()
                    // }
                  } catch (ex: any) {
                    let message = ex.error?.message || ex.message || ex
                    const errorName = ex.error?.name || ex.name

                    switch (errorName) {
                      case "StudyScheduleNotFoundWorkSchedule":
                        message = "Nhân viên chưa đăng kí lịch làm việc vào ngày này"
                        break

                      case "StudyScheduleInvalidNumberOfShift":
                        message = "Vượt quá số ca trong tháng"
                        break

                      default:
                        break
                    }

                    notify?.error({ message })
                    // actionRef.current?.reload()
                  }
                },
                actionRender: (row, config, dom) => [dom.save, dom.cancel],
                // onlyAddOneLineAlertMessage: "Add one line per edit",
                onlyOneLineEditorAlertMessage: "Vui lòng lưu hoặc huỷ chỉnh sửa hiện tại trước khi chỉnh sửa dòng khác",
              }}
              columns={columns}
              toolBarRender={false}
              search={false}
              style={{ paddingBottom: 16 }}
              scroll={{ x: 1300, y: 430 }}
              pagination={{ showTotal: (total, range) => `${range[0]}-${range[1]} trên ${total} lịch học` }}
            />
          </Card>
        )}
      </>
    )
  },
)

export default StudyScheduleTable
