import FormItemData from "@/components/antd/form";
import {ACTION_METHOD_OBJ} from "@/constants";
import {layout} from "@/constants/layout";
import {API} from "@/network/http";
import {BaseUpsertFormProps} from "@/typing/common";
import {EmployeeDto} from "@/__generated__/api-v1";
import {Button, Card, Form, Modal, Row} from "antd";
import moment from "moment";
import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import {DEFINE_MODULE} from "../constants";

const UpsertForm: React.FC<BaseUpsertFormProps> = forwardRef((props, ref) => {
  const {modalVisible, onCancel, onSubmit, actionType, values, loadingBtn} = props;

  const [form] = Form.useForm();
  const employeeId = Form.useWatch("employeeId", form);

  // as the second argument
  useImperativeHandle(ref, () => ({
    onResetData() {
      // reset sale table
      form.resetFields();
    },
  }));

  const getListEmployee = async (searchValue?: string) => {
    const response = await API.employees.employeeControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const onEmployeeIdChange = async (id: string) => {
    const res = await API.employees.employeeControllerGetOne(id);
    const employee = res?.data?.data as EmployeeDto & {
      manager: {
        name: string;
        _id: string;
      };
      job: {
        name: string;
        _id: string;
      };
    };

    form.setFieldsValue({
      employeeId: id,
      startWorkingDate: moment(employee.startWorkingDate),
      workAddress: employee.workAddress,
      manager: employee.manager,
      job: employee.job,
    });
  };

  useEffect(() => {
    if (actionType === "UPDATE") {
      onEmployeeIdChange(values.employeeId);
    }
  }, []);

  useEffect(() => {
    if (employeeId) {
      onEmployeeIdChange(employeeId);
    }
  }, [employeeId]);

  const handleSubmit = async () => {
    const fieldsValidation = await form.validateFields();
    if (!fieldsValidation) {
      return;
    }
    const formValues = {...form.getFieldsValue()};
    if (actionType === "CREATE") {
      onSubmit(formValues, actionType);
    }
    if (actionType === "UPDATE") {
      onSubmit(formValues, actionType, values._id);
    }
  };

  const onResetForm = (): void => {
    // reset sale table
    form.resetFields();
  };

  const renderContentList = [
    {
      inputType: "selectsearch",
      name: "employeeId",
      label: "Nhân viên",
      placeholder: "Chọn nhân viên",
      disabled: actionType === "UPDATE",
      fetchOptions: getListEmployee,
      rules: [{required: true, message: "Chọn nhân viên"}],
    },
    {
      inputType: "input",
      name: "workAddress",
      label: "Địa điểm làm việc",
      placeholder: "---",
      disabled: true,
    },
    {
      inputType: "datetime",
      name: "startWorkingDate",
      label: "Ngày bắt đầu làm",
      placeholder: "---",
      disabled: true,
    },
    {
      inputType: "input",
      name: ["manager", "name"],
      label: "Người quản lí",
      placeholder: "---",
      disabled: true,
    },
    {
      inputType: "input",
      name: ["job", "name"],
      label: "Chức vụ",
      placeholder: "---",
      disabled: true,
    },
  ];

  const renderContent = () => {
    return (
      <Card style={{paddingRight: "20px"}}>
        <Row gutter={24}>{renderContentList.map(item => item && <FormItemData key={item.name} {...item} />)}</Row>
      </Card>
    );
  };

  const renderFooter = () => {
    return (
      <>
        {props.actionType === "CREATE" && <Button onClick={() => onResetForm()}>Làm mới</Button>}
        <Button loading={loadingBtn} type="primary" onClick={handleSubmit}>
          Đồng ý
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal
        bodyStyle={{background: "#F0F2F5"}}
        width={700}
        destroyOnClose
        maskClosable={false}
        title={`${ACTION_METHOD_OBJ[actionType]} ${DEFINE_MODULE.name}`}
        visible={modalVisible}
        onCancel={() => {
          onResetForm();
          onCancel();
        }}
        footer={renderFooter()}
      >
        <Form {...layout} form={form}>
          {renderContent()}
        </Form>
      </Modal>
    </>
  );
});

export default UpsertForm;
