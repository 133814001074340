import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../http"

interface ITeacherRoles {
  name?: string
  limit?: number
  offset?: number
}

interface ITeacherRoleCreate {
  name: string
  subjectId: string
  employeeId: string
  provinceId: string
  startWorkingDate: string
}

interface ITeacherRoleUpdate {
  name: string
  subjectId: string
  employeeId: string
  provinceId: string
  startWorkingDate: string
}

interface ITeacherRoleArchive {
  ids: Array<string>
  isArchived: boolean
}

export const getTeacherRolesApi = async (data?: ITeacherRoles) => {
  const url = "/teacher-roles"
  return await getRequest(url, { params: data })
}

export const getTeacherRoleDetailApi = async (id: string) => {
  const url = `/teacher-roles/${id}`
  return await getRequest(url)
}

export const createTeacherRoleApi = async (data: ITeacherRoleCreate) => {
  const url = `/teacher-roles`
  return await postRequest(url, data)
}

export const updateTeacherRoleApi = async (id: string, data: ITeacherRoleUpdate) => {
  const url = `/teacher-roles/${id}`
  return await putRequest(url, data)
}

export const archiveManyTeacherRoleApi = async (data: ITeacherRoleArchive) => {
  const url = `/teacher-roles`
  return await patchRequest(url, data)
}

export const deleteTeacherRoleApi = async (id: string) => {
  const url = `/teacher-roles/${id}`
  return await deleteRequest(url)
}

export const deleteManyTeacherRoleApi = async (ids: string[]) => {
  const url = `/teacher-roles`
  return await deleteRequest(url, { data: { ids } })
}
