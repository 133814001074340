import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../http"

interface IDepartments {
  name?: string
  email?: string
  roleId?: string
  limit?: number
  offset?: number
  ids?: string
}

interface IDepartmentCreate {
  name: string
  email: string
  roleId: string
  photo?: string | null
  password: string
  type: string
  status: string
}

interface IDepartmentUpdate {
  name?: string
  email?: string
  roleId?: string
  photo?: string | null
  password?: string
  type?: string
  status?: string
}

interface IDepartmentArchive {
  ids: Array<string>
  isArchived: boolean
}

export const getDepartmentsApi = async (data?: IDepartments) => {
  const url = "/departments"
  return await getRequest(url, { params: data })
}

export const getDepartmentDetailApi = async (id: string) => {
  const url = `/departments/${id}`
  return await getRequest(url)
}

export const createDepartmentApi = async (data: IDepartmentCreate) => {
  const url = `/departments`
  return await postRequest(url, data)
}

export const updateDepartmentApi = async (id: string, data: IDepartmentUpdate) => {
  const url = `/departments/${id}`
  return await putRequest(url, data)
}

export const archiveManyDepartmentApi = async (data: IDepartmentArchive) => {
  const url = `/departments`
  return await patchRequest(url, data)
}

export const deleteDepartmentApi = async (id: string) => {
  const url = `/departments/${id}`
  return await deleteRequest(url)
}

export const deleteManyDepartmentApi = async (ids: string[]) => {
  const url = `/departments`
  return await deleteRequest(url, { data: { ids } })
}
