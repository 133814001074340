import { HrPaths } from "@/modules/hr"
import i18n from "i18n-js"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import { ActionTypes } from "../../constants"
import * as actionTypes from "../../constants/ActionTypes"
import history from "../../navigation"
import {
  archiveManyEmployeeApi,
  createEmployeeApi,
  deleteEmployeeApi,
  deleteManyEmployeeApi,
  getEmployeeDetailApi,
  getEmployeesApi,
  updateEmployeeApi,
} from "../../network"
import { convertObjectParamsToStringParams, getInitParams } from "../../utils"
import { getEmployeeState } from "../selectors"
import { IEmployeeState, IRoleState } from "../types"

interface IPayload {
  type: string
  payload: any
}

interface IResponse {
  data: any
  total: number
}

function* getListEmployee(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const employeeState: IRoleState = yield select<any>(getEmployeeState)

    // validate and set requesting
    if (employeeState.isLoadingRequest) return
    yield put({
      type: ActionTypes.SET_LOADING_ROLE,
      payload: { isLoadingRequest: true },
    })

    const { data, total }: IResponse = yield call(getEmployeesApi, action.payload)
    yield history.push(`?${convertObjectParamsToStringParams(action.payload)}`)
    yield put({
      type: ActionTypes.GET_LIST_EMPLOYEE_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({ type: ActionTypes.GET_LIST_EMPLOYEE_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_EMPLOYEE,
      payload: { isLoadingRequest: false },
    })
  }
}

function* createEmployee(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const employeeState: IEmployeeState = yield select<any>(getEmployeeState)

    // validate and set requesting
    if (employeeState.isCreateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_EMPLOYEE,
      payload: { isCreateRequest: true },
    })

    // call api create
    const { data }: IResponse = yield call(createEmployeeApi, action.payload)

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Thêm mới thành công",
        message: data?.message || "",
        type: "success",
      },
    })

    // navigate
    history.push(HrPaths.EMPLOYEE.LIST.PATH)
  } catch (error) {
    yield put({ type: actionTypes.CREATE_EMPLOYEE_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_EMPLOYEE,
      payload: { isCreateRequest: false },
    })
  }
}

function* deleteOneEmployee(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const employeeState: IEmployeeState = yield select<any>(getEmployeeState)

    // validate and set requesting
    if (employeeState.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_EMPLOYEE,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data }: IResponse = yield call(deleteEmployeeApi, action.payload.id)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteAction.deleteSuccess"),
          message: i18n.t("common.deleteAction.deleteSuccess"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_EMPLOYEE,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_EMPLOYEE,
      payload: { isDeleteRequest: false },
    })
  }
}

function* updateDetailEmployee(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const employeeState: IEmployeeState = yield select<any>(getEmployeeState)

    // validate and set requesting
    if (employeeState.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_EMPLOYEE,
      payload: { isUpdateRequest: true },
    })

    // call api update
    const { data }: IResponse = yield call(updateEmployeeApi, action.payload.id, action.payload.data)

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thành công",
        message: data?.message || "",
        type: "success",
      },
    })
    history.push(HrPaths.EMPLOYEE.LIST.PATH)

    // clear detail data
    yield put({
      type: actionTypes.UPDATE_DETAIL_EMPLOYEE,
      payload: { data: null, total: 0 },
    })
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_DETAIL_EMPLOYEE_FAILED,
      payload: error,
    })
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thất bại",
        message: `${error}` || "",
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_EMPLOYEE,
      payload: { isUpdateRequest: false },
    })
  }
}

function* getDetailEmployee(action: IPayload) {
  try {
    const { data, total }: IResponse = yield call(getEmployeeDetailApi, action.payload)

    yield put({
      type: actionTypes.GET_DETAIL_EMPLOYEE_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({
      type: actionTypes.GET_DETAIL_EMPLOYEE_FAILED,
      payload: error,
    })
  }
}

function* deleteManyEmployee(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const employeeState: IEmployeeState = yield select<any>(getEmployeeState)

    // validate and set requesting
    if (employeeState.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_EMPLOYEE,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data }: IResponse = yield call(deleteManyEmployeeApi, action.payload.ids)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteManyAction.title"),
          message: i18n.t("common.deleteManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_EMPLOYEE,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_EMPLOYEE,
      payload: { isDeleteRequest: false },
    })
  }
}

function* archiveManyEmployee(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const employeeState: IEmployeeState = yield select<any>(getEmployeeState)

    // validate and set requesting
    if (employeeState.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_EMPLOYEE,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data }: IResponse = yield call(archiveManyEmployeeApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.archiveManyAction.title"),
          message: i18n.t("common.archiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_EMPLOYEE,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_EMPLOYEE,
      payload: { isUpdateRequest: false },
    })
  }
}

function* unarchiveManyEmployee(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const employeeState: IEmployeeState = yield select<any>(getEmployeeState)

    // validate and set requesting
    if (employeeState.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_EMPLOYEE,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data }: IResponse = yield call(archiveManyEmployeeApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.unarchiveManyAction.title"),
          message: i18n.t("common.unarchiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_EMPLOYEE,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_EMPLOYEE,
      payload: { isUpdateRequest: false },
    })
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_LIST_EMPLOYEE, getListEmployee),
    takeLatest(actionTypes.GET_DETAIL_EMPLOYEE, getDetailEmployee),
    takeLatest(ActionTypes.CREATE_EMPLOYEE, createEmployee),
    takeLatest(actionTypes.DELETE_ONE_EMPLOYEE, deleteOneEmployee),
    takeLatest(actionTypes.UPDATE_DETAIL_EMPLOYEE, updateDetailEmployee),
    takeLatest(actionTypes.DELETE_MANY_EMPLOYEE, deleteManyEmployee),
    takeLatest(actionTypes.ARCHIVE_MANY_EMPLOYEE, archiveManyEmployee),
    takeLatest(actionTypes.UNARCHIVE_MANY_EMPLOYEE, unarchiveManyEmployee),
  ])
}
