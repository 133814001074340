import { createApolloClient } from "@/apollo-client"
import { ApolloProvider } from "@apollo/client"
import { ConfigProvider } from "antd"
import viVN from "antd/lib/locale-provider/vi_VN"
import { Provider as ReduxProvider } from "react-redux"

import { AuthProvider } from "./components/auth"
import { Notifications } from "./components/Notifications"
import { Navigator } from "./Navigator"
import store from "./redux/store"

const App = () => {
  const apolloClient = createApolloClient()
  return (
    <ConfigProvider locale={viVN}>
      <ApolloProvider client={apolloClient}>
        <ReduxProvider store={store}>
          <AuthProvider>
            <Navigator />
            <Notifications />
          </AuthProvider>
        </ReduxProvider>
      </ApolloProvider>
    </ConfigProvider>
  )
}

export default App
