import { EduPaths } from "@/modules/edu"
import { ClassNumberRouter } from "@/modules/edu/pages/configuration/class-number"
import { ClassesRouter } from "@/modules/edu/pages/configuration/classes"
import { RoomRouter } from "@/modules/edu/pages/configuration/edu-room"
import { SubjectRouter } from "@/modules/edu/pages/configuration/edu-subject"
import { SchoolHourRouter } from "@/modules/edu/pages/configuration/edu-working-hour"
import { LocationRouter } from "@/modules/edu/pages/configuration/location"
import { TeacherRouter } from "@/modules/edu/pages/configuration/teacher"
import { TeacherRoleRouter } from "@/modules/edu/pages/configuration/teacher-role"
import { AbsentScheduleRouter } from "@/modules/edu/pages/operation/absent-schedule"
import { AvailabilityRouter } from "@/modules/edu/pages/operation/availability"
import { WorkTimeRouter } from "@/modules/edu/pages/operation/work-time"
import { WorkingScheduleRouter } from "@/modules/edu/pages/operation/working-schedule"
import { TeachScheduleRouter } from "@/modules/edu/pages/operation/teach-schedule"
import { Route, Routes } from "react-router-dom"

export const Router = () => {
  return (
    <Routes>
      <Route path={`${EduPaths.LOCATION.LIST.PATH}/*`} element={<LocationRouter />} />
      <Route path={`${EduPaths.SUBJECT.LIST.PATH}/*`} element={<SubjectRouter />} />
      <Route path={`${EduPaths.ROOM.LIST.PATH}/*`} element={<RoomRouter />} />
      <Route path={`${EduPaths.SCHOOL_HOUR.LIST.PATH}/*`} element={<SchoolHourRouter />} />
      <Route path={`${EduPaths.TEACHER_ROLE.LIST.PATH}/*`} element={<TeacherRoleRouter />} />
      <Route path={`${EduPaths.CLASS_NUMBER.LIST.PATH}/*`} element={<ClassNumberRouter />} />
      <Route path={`${EduPaths.TEACHER.LIST.PATH}/*`} element={<TeacherRouter />} />
      <Route path={`${EduPaths.CLASSES.LIST.PATH}/*`} element={<ClassesRouter />} />
      <Route path={`${EduPaths.WORK_SCHEDULE.LIST.PATH}/*`} element={<WorkingScheduleRouter />} />
      <Route path={`${EduPaths.WORK_TIME.LIST.PATH}/*`} element={<WorkTimeRouter />} />
      <Route path={`${EduPaths.ABSENT_SCHEDULE.LIST.PATH}/*`} element={<AbsentScheduleRouter />} />
      <Route path={`${EduPaths.AVAILABILITY.LIST.PATH}/*`} element={<AvailabilityRouter />} />
      <Route path={`${EduPaths.TEACH_SCHEDULE.LIST.PATH}/*`} element={<TeachScheduleRouter />} />
    </Routes>
  )
}
