import { EduPaths } from "@/modules/edu"
import i18n from "i18n-js"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import * as actionTypes from "../../constants/ActionTypes"
import history from "../../navigation"
import {
  archiveManyRoomApi,
  createRoomApi,
  deleteManyRoomApi,
  deleteRoomApi,
  getRoomDetailApi,
  getRoomsApi,
  updateRoomApi,
} from "../../network"
import { convertObjectParamsToStringParams, getInitParams } from "../../utils"
import { getRoomState } from "../selectors"
import { IRoomState } from "../types"

interface IPayload {
  type: string
  payload: any
}

interface IResponse {
  data: any
  total: number
}

function* getListRoom(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const room: IRoomState = yield select<any>(getRoomState)

    // validate and set requesting
    if (room.isLoadingRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ROOM,
      payload: { isLoadingRequest: true },
    })

    // get list
    const { data, total }: IResponse = yield call(getRoomsApi, action.payload)
    yield history.push(`?${convertObjectParamsToStringParams(action.payload)}`)

    // update to state
    yield put({
      type: actionTypes.GET_LIST_ROOM_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({ type: actionTypes.GET_LIST_ROOM_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ROOM,
      payload: { isLoadingRequest: false },
    })
  }
}

function* getDetailRoom(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const room: IRoomState = yield select<any>(getRoomState)

    // validate and set requesting
    if (room.isLoadingRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ROOM,
      payload: { isLoadingRequest: true },
    })

    // call api
    const { data, total }: IResponse = yield call(getRoomDetailApi, action.payload)

    // update to state
    yield put({
      type: actionTypes.GET_DETAIL_ROOM_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({ type: actionTypes.GET_DETAIL_ROOM_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ROOM,
      payload: { isLoadingRequest: false },
    })
  }
}

function* updateDetailRoom(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const room: IRoomState = yield select<any>(getRoomState)

    // validate and set requesting
    if (room.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ROOM,
      payload: { isUpdateRequest: true },
    })

    // call api update
    const { data }: IResponse = yield call(updateRoomApi, action.payload.roomId, action.payload.data)

    yield put({
      type: actionTypes.UPDATE_DETAIL_ROOM_SUCCESS,
    })

    // update to state
    yield put({
      type: actionTypes.GET_DETAIL_ROOM_SUCCESS,
      payload: { data: null, total: 0 },
    })

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thành công",
        message: data?.message || "",
        type: "success",
      },
    })
    history.push(EduPaths.ROOM.LIST.PATH)
  } catch (error) {
    console.log("e: ", error)
    yield put({ type: actionTypes.UPDATE_DETAIL_ROOM_FAILED, payload: error })
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thất bại",
        message: `${error}` || "",
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ROOM,
      payload: { isUpdateRequest: false },
    })
  }
}

function* createRoom(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const room: IRoomState = yield select<any>(getRoomState)

    // validate and set requesting
    if (room.isCreateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ROOM,
      payload: { isCreateRequest: true },
    })

    // call api create
    const { data, total }: IResponse = yield call(createRoomApi, action.payload)

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Thêm mới thành công",
        message: data?.message || "",
        type: "success",
      },
    })

    // navigate
    history.push("/rooms")
  } catch (error) {
    yield put({ type: actionTypes.CREATE_ROOM_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ROOM,
      payload: { isCreateRequest: false },
    })
  }
}

function* deleteOneRoom(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const room: IRoomState = yield select<any>(getRoomState)

    // validate and set requesting
    if (room.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ROOM,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(deleteRoomApi, action.payload.id)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteAction.deleteSuccess"),
          message: i18n.t("common.deleteAction.deleteSuccess"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_ROOM,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ROOM,
      payload: { isDeleteRequest: false },
    })
  }
}

function* deleteManyRoom(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const room: IRoomState = yield select<any>(getRoomState)

    // validate and set requesting
    if (room.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ROOM,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(deleteManyRoomApi, action.payload.ids)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteManyAction.title"),
          message: i18n.t("common.deleteManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_ROOM,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ROOM,
      payload: { isDeleteRequest: false },
    })
  }
}

function* archiveManyRoom(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const room: IRoomState = yield select<any>(getRoomState)

    // validate and set requesting
    if (room.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ROOM,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(archiveManyRoomApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.archiveManyAction.title"),
          message: i18n.t("common.archiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_ROOM,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ROOM,
      payload: { isUpdateRequest: false },
    })
  }
}

function* unarchiveManyRoom(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const room: IRoomState = yield select<any>(getRoomState)

    // validate and set requesting
    if (room.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ROOM,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(archiveManyRoomApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.unarchiveManyAction.title"),
          message: i18n.t("common.unarchiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_ROOM,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ROOM,
      payload: { isUpdateRequest: false },
    })
  }
}

export default function* root() {
  yield all([
    takeLatest(actionTypes.GET_LIST_ROOM, getListRoom),
    takeLatest(actionTypes.GET_DETAIL_ROOM, getDetailRoom),
    takeLatest(actionTypes.UPDATE_DETAIL_ROOM, updateDetailRoom),
    takeLatest(actionTypes.CREATE_ROOM, createRoom),
    takeLatest(actionTypes.DELETE_ONE_ROOM, deleteOneRoom),
    takeLatest(actionTypes.DELETE_MANY_ROOM, deleteManyRoom),
    takeLatest(actionTypes.ARCHIVE_MANY_ROOM, archiveManyRoom),
    takeLatest(actionTypes.UNARCHIVE_MANY_ROOM, unarchiveManyRoom),
  ])
}
