import { EduPaths } from "@/modules/edu"
import i18n from "i18n-js"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import * as actionTypes from "../../constants/ActionTypes"
import history from "../../navigation"
import {
  archiveManyCourseApi,
  createCourseApi,
  deleteCourseApi,
  deleteManyCourseApi,
  getCourseDetailApi,
  getCoursesApi,
  updateCourseApi,
} from "../../network"
import { convertObjectParamsToStringParams, getInitParams } from "../../utils"
import { getCourseState } from "../selectors"
import { ICourseState } from "../types"

interface IPayload {
  type: string
  payload: any
}

interface IResponse {
  data: any
  total: number
}

function* getListCourse(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const course: ICourseState = yield select<any>(getCourseState)

    // validate and set requesting
    if (course.isLoadingRequest) return
    yield put({
      type: actionTypes.SET_LOADING_COURSE,
      payload: { isLoadingRequest: true },
    })

    // get list
    const { data, total }: IResponse = yield call(getCoursesApi, action.payload)
    yield history.push(`?${convertObjectParamsToStringParams(action.payload)}`)

    // update to state
    yield put({
      type: actionTypes.GET_LIST_COURSE_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({ type: actionTypes.GET_LIST_COURSE_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_COURSE,
      payload: { isLoadingRequest: false },
    })
  }
}

function* getDetailCourse(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const course: ICourseState = yield select<any>(getCourseState)

    // validate and set requesting
    if (course.isLoadingRequest) return
    yield put({
      type: actionTypes.SET_LOADING_COURSE,
      payload: { isLoadingRequest: true },
    })

    // call api
    const { data, total }: IResponse = yield call(getCourseDetailApi, action.payload)

    // update to state
    yield put({
      type: actionTypes.GET_DETAIL_COURSE_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({ type: actionTypes.GET_DETAIL_COURSE_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_COURSE,
      payload: { isLoadingRequest: false },
    })
  }
}

function* updateDetailCourse(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const course: ICourseState = yield select<any>(getCourseState)

    // validate and set requesting
    if (course.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_COURSE,
      payload: { isUpdateRequest: true },
    })

    // call api update
    const { data }: IResponse = yield call(updateCourseApi, action.payload.courseId, action.payload.data)

    yield put({
      type: actionTypes.UPDATE_DETAIL_COURSE_SUCCESS,
    })

    // update to state
    yield put({
      type: actionTypes.GET_DETAIL_COURSE_SUCCESS,
      payload: { data: null, total: 0 },
    })

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thành công",
        message: data?.message || "",
        type: "success",
      },
    })
    history.push(EduPaths.COURSE.LIST.PATH)
  } catch (error) {
    console.log("e: ", error)
    yield put({ type: actionTypes.UPDATE_DETAIL_COURSE_FAILED, payload: error })
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thất bại",
        message: `${error}` || "",
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_COURSE,
      payload: { isUpdateRequest: false },
    })
  }
}

function* createCourse(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const course: ICourseState = yield select<any>(getCourseState)

    // validate and set requesting
    if (course.isCreateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_COURSE,
      payload: { isCreateRequest: true },
    })

    // call api create
    const { data }: IResponse = yield call(createCourseApi, action.payload)

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Thêm mới thành công",
        message: data?.message || "",
        type: "success",
      },
    })

    // navigate
    history.push("/courses")
  } catch (error) {
    yield put({ type: actionTypes.CREATE_COURSE_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_COURSE,
      payload: { isCreateRequest: false },
    })
  }
}

function* deleteOneCourse(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const course: ICourseState = yield select<any>(getCourseState)

    // validate and set requesting
    if (course.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_COURSE,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data }: IResponse = yield call(deleteCourseApi, action.payload.id)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteAction.deleteSuccess"),
          message: i18n.t("common.deleteAction.deleteSuccess"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_COURSE,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_COURSE,
      payload: { isDeleteRequest: false },
    })
  }
}

function* deleteManyCourse(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const course: ICourseState = yield select<any>(getCourseState)

    // validate and set requesting
    if (course.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_COURSE,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data }: IResponse = yield call(deleteManyCourseApi, action.payload.ids)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteManyAction.title"),
          message: i18n.t("common.deleteManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_COURSE,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_COURSE,
      payload: { isDeleteRequest: false },
    })
  }
}

function* archiveManyCourse(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const course: ICourseState = yield select<any>(getCourseState)

    // validate and set requesting
    if (course.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_COURSE,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data }: IResponse = yield call(archiveManyCourseApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.archiveManyAction.title"),
          message: i18n.t("common.archiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_COURSE,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_COURSE,
      payload: { isUpdateRequest: false },
    })
  }
}

function* unarchiveManyCourse(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const course: ICourseState = yield select<any>(getCourseState)

    // validate and set requesting
    if (course.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_COURSE,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data }: IResponse = yield call(archiveManyCourseApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.unarchiveManyAction.title"),
          message: i18n.t("common.unarchiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_COURSE,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_COURSE,
      payload: { isUpdateRequest: false },
    })
  }
}

export default function* root() {
  yield all([
    takeLatest(actionTypes.GET_LIST_COURSE, getListCourse),
    takeLatest(actionTypes.GET_DETAIL_COURSE, getDetailCourse),
    takeLatest(actionTypes.UPDATE_DETAIL_COURSE, updateDetailCourse),
    takeLatest(actionTypes.CREATE_COURSE, createCourse),
    takeLatest(actionTypes.DELETE_ONE_COURSE, deleteOneCourse),
    takeLatest(actionTypes.DELETE_MANY_COURSE, deleteManyCourse),
    takeLatest(actionTypes.ARCHIVE_MANY_COURSE, archiveManyCourse),
    takeLatest(actionTypes.UNARCHIVE_MANY_COURSE, unarchiveManyCourse),
  ])
}
