import FormItemData from "@/components/antd/form";
import {ACTION_METHOD_OBJ} from "@/constants";
import {API} from "@/network/http";
import {BaseUpsertFormProps} from "@/typing/common";
import {Button, Card, Form, Modal, Row} from "antd";
import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import {USER_STATUS_ARR} from "../constants";

const UpsertForm: React.FC<BaseUpsertFormProps> = forwardRef((props, ref) => {
  const {modalVisible, onCancel, onSubmit, actionType, values, loadingBtn} = props;
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    onResetData() {
      form.resetFields();
    },
  }));

  const getRoleId = async (name?: string) => {
    const listRoles = await API.roles.roleControllerGetMany({limit: "10", name});
    return (
      listRoles?.data?.data.map(d => ({
        value: d._id,
        name: d.name,
        key: d._id,
      })) || []
    );
  };

  useEffect(() => {
    if (actionType === "UPDATE") {
      form.setFieldsValue({
        name: values.name,
        username: values.username,
        email: values.email,
        phone: values.phone,
        password: values.password,
        type: values.type,
        roleId: values.roleId,
        status: values.status,
      });
    }
  }, []);

  const handleSubmit = async () => {
    const fieldsValidation = await form.validateFields();
    if (!fieldsValidation) {
      return;
    }
    const formValues = {...form.getFieldsValue()};
    formValues.type = "USER";
    if (!formValues.password || formValues.password.length === 0) delete formValues.password;
    if (actionType === "CREATE") {
      onSubmit(formValues, actionType);
    }
    if (actionType === "UPDATE") {
      onSubmit(formValues, actionType, values._id);
    }
  };

  const onResetForm = (): void => {
    // reset sale table
    form.resetFields();
  };

  const renderContentList = [
    {
      inputType: "input",
      name: "name",
      label: "Tên người dùng",
      placeholder: "Nhập tên người dùng",
      rules: [{required: true, message: "Nhập tên người dùng"}],
    },
    {
      inputType: "input",
      name: "username",
      label: "Tên đăng nhập",
      placeholder: "Nhập tên đăng nhập",
      rules: [{required: true, message: "Nhập tên đăng nhập"}],
    },
    {
      inputType: "input",
      name: "email",
      label: "Email",
      placeholder: "Nhập email",
      rules: [
        {
          type: "email",
          message: "Email không hợp lệ",
        },
        {
          required: true,
          message: "Nhập email",
        },
      ],
    },
    {
      inputType: "input",
      name: "phone",
      label: "Số điện thoại",
      placeholder: "Nhập số điện thoại",
      maxLength: 10,
      rules: [{required: true}, {max: 10}],
    },
    {
      inputType: "selectsearch",
      name: "roleId",
      label: "Vai trò",
      placeholder: "Chọn vai trò",
      // options: roles,
      fetchOptions: getRoleId,
      rules: [{required: true, message: "Vui lòng chọn vai trò!"}],
    },
    actionType === "CREATE" && {
      inputType: "password",
      name: "password",
      label: "Mật khẩu",
      placeholder: "Nhập mật khẩu",
      maxLength: 10,
      rules: [{required: true}, {max: 10}],
    },
    {
      inputType: "select",
      name: "status",
      label: "Trạng thái",
      placeholder: "Vui lòng chọn trạng thái",
      options: USER_STATUS_ARR,
      rules: [{required: true, message: "Vui lòng chọn trạng thái!"}],
    },
  ];

  const renderContent = () => {
    return (
      <Card style={{paddingRight: "20px"}}>
        <Row gutter={24}>
          {/* <Col span={24}>
            <FormItem name="name" label="Tên người dùng" rules={[{ required: true, message: "Nhập tên người dùng" }]}>
              <Input placeholder="Nhập tên người dùng" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem name="username" label="Tên đăng nhập" rules={[{ required: true, message: "Nhập tên đăng nhập" }]}>
              <Input placeholder="Nhập tên người dùng" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              name="email"
              label="Email"
              rules={[
                {
                  type: "email",
                  message: "Email không hợp lệ",
                },
                {
                  required: true,
                  message: "Nhập email",
                },
              ]}
            >
              <Input placeholder="Nhập email" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem name="phone" label="Số điện thoại" rules={[{ required: true }, { max: 10 }]}>
              <Input placeholder="Nhập số điện thoại" maxLength={10} />
            </FormItem>
          </Col>

          <Col span={24}>
            <FormItem name="roleId" label="Vai trò" rules={[{ required: true, message: "Vui lòng chọn vai trò!" }]}>
              <Select placeholder="Chọn vai trò">
                {roles.map(item => (
                  <Option key={item.key} value={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>

          {actionType === 'CREATE' && <Col span={24}>
            <FormItem
              name="password"
              label="Mật khẩu"
              rules={[{ required: true }, { max: 10 }]}
            >
              <Input.Password placeholder="Nhập mật khẩu" maxLength={10} />
            </FormItem>
          </Col>}

          <Col span={24}>
            <FormItem
              name="status"
              label="Trạng thái"
              rules={[{ required: true, message: "Vui lòng chọn trạng thái!" }]}
            >
              <Select placeholder="Chọn trạng thái">
                {USER_STATUS_ARR.map(item => (
                  <Option key={item.key} value={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col> */}

          {renderContentList.map(item => item && <FormItemData key={item.name} {...item} />)}
        </Row>
      </Card>
    );
  };

  const renderFooter = () => {
    return (
      <>
        {props.actionType === "CREATE" && <Button onClick={() => onResetForm()}>Làm mới</Button>}
        <Button loading={loadingBtn} type="primary" onClick={handleSubmit}>
          Đồng ý
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal
        bodyStyle={{background: "#F0F2F5"}}
        width={700}
        destroyOnClose
        maskClosable={false}
        title={`${ACTION_METHOD_OBJ[actionType]} nhân viên`}
        visible={modalVisible}
        onCancel={() => {
          onResetForm();
          onCancel();
        }}
        footer={renderFooter()}
      >
        <Form form={form}>{renderContent()}</Form>
      </Modal>
    </>
  );
});

export default UpsertForm;
