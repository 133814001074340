import { HrPaths } from "@/modules/hr"
import { DepartmentRouter } from "@/modules/hr/pages/department"
import { EmployeeRouter } from "@/modules/hr/pages/employee"
import { Route, Routes } from "react-router-dom"

export const Router = () => {
  return (
    <Routes>
      <Route path={`${HrPaths.EMPLOYEE.LIST.PATH}/*`} element={<EmployeeRouter />} />
      <Route path={`${HrPaths.DEPARTMENT.LIST.PATH}/*`} element={<DepartmentRouter />} />
    </Routes>
  )
}
