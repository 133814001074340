import { CollapseRender } from "@/components/antd/CollapseRender"
import { DebounceSelect } from "@/components/antd/form/DebounceSelect"
import { ACTION_METHOD_OBJ } from "@/constants"
import { CLASS_STATUS } from "@/modules/edu/pages/configuration/classes/utils"
import { WORKING_HOUR_TRANSLATIONS } from "@/modules/edu/pages/configuration/edu-working-hour/constants"
import { useSchoolHours } from "@/modules/edu/pages/configuration/edu-working-hour/hooks"
import { API } from "@/network/http"
import { CRUD_ACTION } from "@/types"
import { formatToLocalTime } from "@/utils"
import { ClassDto, CreateWorkTimeDto } from "@/__generated__/api-v1"
import { useGroupByClassLazyQuery } from "@/__generated__/apollo"
import { DeleteTwoTone, EditTwoTone, EyeTwoTone, PlusOutlined } from "@ant-design/icons"
import { PageContainer } from "@ant-design/pro-layout"
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-table"
import { Button, Col, Empty, Form, notification, Popconfirm, Row, Select, Space, Tooltip, Typography } from "antd"
import { useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { DEFINE_MODULE } from "../constants"
import UpsertForm from "./UpsertForm"

const List = () => {
  const [searchParams] = useSearchParams()

  const [loadingBtn, setLoadingBtn] = useState<boolean>(false)
  const [isCreate, setCreate] = useState<boolean>(false)
  const [isUpdate, setUpdate] = useState<boolean>(false)
  const [isRead, setRead] = useState<boolean>(false)

  const [values, setValues] = useState<any>()
  const [source, setSource] = useState<any>()
  const [groupFields, setGroupFields] = useState<any[]>([])
  const [totalData, setTotalData] = useState<any>(0)

  const actionRef = useRef<ActionType>()

  const { schoolHours } = useSchoolHours()
  const [notifyApi, contextHolder] = notification.useNotification()

  const onClickEditButton = async (id: string) => {
    const detailData = await API.classes.classControllerGetOne(id)
    setValues(detailData.data.data)
    setUpdate(true)
  }

  const onClickReadButton = async (id: string) => {
    const detailData = await API.classes.classControllerGetOne(id)
    setValues(detailData.data.data)
    setRead(true)
  }

  const onClickDeleteButton = async (id: string) => {
    try {
      await API.classes.classControllerDeleteOne(id)
      notifyApi.success({ message: `${ACTION_METHOD_OBJ["DELETE"]} ${DEFINE_MODULE.name} thành công` })
      actionRef.current?.reload()
    } catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      })
    }
  }

  const onSubmit = async (val: any, type: CRUD_ACTION, _id?: string) => {
    try {
      setLoadingBtn(true)
      const reqData =
        type === "CREATE"
          ? await API.classes.classControllerCreateOne(val)
          : await API.classes.classControllerUpdateOne(_id || "", val)
      if (reqData && reqData.data) {
        if (type === "CREATE") setCreate(false)
        // if (type === "UPDATE") setUpdate(false)
        notifyApi.success({
          message: "Thông báo",
          description: `${ACTION_METHOD_OBJ[type]} thành công`,
          placement: "topRight",
        })
        actionRef.current?.reload()
      }
    } catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      })
    } finally {
      setLoadingBtn(false)
    }
  }

  const [groupByClass] = useGroupByClassLazyQuery({ variables: { groupBy: [] } })

  const onDeleteMany = async (ids: string[]) => {
    try {
      await API.classes.classControllerDeleteMany({ ids })
      notifyApi.success({ message: `${ACTION_METHOD_OBJ["DELETE"]} ${DEFINE_MODULE.name} thành công` })
      actionRef.current?.reload()
    } catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      })
    }
  }

  const onArchiveMany = async (ids: string[]) => {
    try {
      // @ts-ignore
      await API.classes.classControllerUpdateMany({ ids, isArchived: true })
      notifyApi.success({ message: `${ACTION_METHOD_OBJ["ARCHIVE"]} ${ids.length} ${DEFINE_MODULE.name} thành công` })
      actionRef.current?.reload()
    } catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      })
    }
  }

  const onUnarchiveMany = async (ids: string[]) => {
    try {
      // @ts-ignore
      await API.classes.classControllerUpdateMany({ ids, isArchived: false })
      notifyApi.success({ message: `${ACTION_METHOD_OBJ["UNARCHIVE"]} ${ids.length} ${DEFINE_MODULE.name} thành công` })
      actionRef.current?.reload()
    } catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      })
    }
  }

  const onDraughtMany = async (ids: string[]) => {
    try {
      // @ts-ignore
      await API.classes.classControllerUpdateMany({ ids, isArchived: false, status: "DRAUGHT" })
      notifyApi.success({ message: `${ACTION_METHOD_OBJ["UNARCHIVE"]} ${ids.length} ${DEFINE_MODULE.name} thành công` })
      actionRef.current?.reload()
    } catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      })
    }
  }

  const groupByData = async (groupFields: ("status" | "subjectId" | "locationId" | "roomId")[]) => {
    const groupClass = await groupByClass({
      variables: {
        groupBy: groupFields,
      },
    })
    // setDataSource(timeTable.data?.timetables?.data as Timetable[] || []);
    // setWeekendList(getDayInWeek(queries.week));
    return {
      data: groupClass.data?.groupByClass.data,
      total: groupClass.data?.groupByClass.total,
    }
  }

  const fetchData = async (queries?: Parameters<typeof API.classes.classControllerGetMany>["0"]) => {
    const sort = { sortFields: queries?.sortFields ?? ["updatedAt", "desc"] }
    const data = (await API.classes.classControllerGetMany({ ...queries, ...sort })).data
    setSource(data.data)
    setTotalData(data.total)
  }

  const handleChange = (pagination: any, filters: any) => {
    return fetchData({
      ...pagination,
      ...filters,
    })
  }

  const onSelectFilter = async (value: any, origin: any) => {
    const newGroupFields = [...groupFields]
    const existInGroup = newGroupFields.findIndex(item => item._id === origin.key)
    if (existInGroup === -1) newGroupFields.push({ _id: origin.key, name: origin.children })
    const graphqlData = await groupByData([...newGroupFields.map(item => item._id)])
    const newList: any[] = []
    graphqlData.data?.forEach(item => {
      newList.push({ ...item })
    })
    setTotalData(graphqlData.total)
    setGroupFields(newGroupFields)
    setSource(newList)
  }

  const onDeSelectFilter = async (e: any) => {
    const newGroupFields = [...groupFields].filter(item => item._id !== e)
    if (newGroupFields.length === 0) {
      fetchData()
    } else {
      const graphqlData = await groupByData([...newGroupFields.map(item => item._id)])
      const newList: any[] = []
      graphqlData.data?.forEach(item => {
        newList.push({ ...item })
      })
      setTotalData(graphqlData.total)
      setSource(newList)
    }
    setGroupFields(newGroupFields)
  }

  useEffect(() => {
    const params: any = {}
    searchParams.forEach((v, k) => {
      params[k] = v
    })
    if (params.showModal) {
      onClickEditButton(params._id)
    } else {
      fetchData()
    }
    if (params.showPopup) {
      onClickReadButton(params._id)
    }
  }, [])

  const onExpandRow = async (record: any, params?: any) => {
    const groupQuery = { [groupFields[groupFields.length - 1]._id]: record._id, ...params }
    const data = (await API.classes.classControllerGetMany(groupQuery)).data
    return data
  }

  const getListTeacher = async (searchValue?: string) => {
    const response = await API.teachers.teacherControllerGetMany({ name: searchValue })
    return (
      response?.data?.data?.map((item: any) => ({
        key: item?._id,
        value: item?._id,
        name: item?.employee?.name,
      })) || []
    )
  }

  const getListSubject = async (searchValue?: string) => {
    const response = await API.subjects.subjectControllerGetMany({ name: searchValue })
    return (
      response?.data?.data?.map((item: any) => ({
        key: item?._id,
        value: item?._id,
        name: item?.name,
      })) || []
    )
  }

  const columns: ProColumns<ClassDto>[] = [
    {
      hideInTable: true,
      dataIndex: "subjectId",
      title: "Môn học",
      renderFormItem: () => {
        return <DebounceSelect placeholder="Chọn môn học" fetchOptions={getListSubject} />
      },
    },
    {
      dataIndex: "name",
      title: "Tên lớp",
      search: false,
      width: 100,
      fixed: "left",
      renderText: (_, entity) => {
        return (
          <Typography.Text type="success" onClick={() => onClickReadButton(entity._id)}>
            {entity.name}
          </Typography.Text>
        )
      },
    },
    {
      dataIndex: "code",
      title: "Mã lớp",
      search: false,
      width: 100,
    },
    {
      dataIndex: "openingDate",
      title: "Khai giảng",
      search: false,
      width: 120,
      renderText: (_, entity) => {
        if (!entity.openingDate) return "---"
        return <>{formatToLocalTime(entity.openingDate || "")}</>
      },
    },
    {
      dataIndex: "graduationDate",
      title: "Tốt nghiệp",
      width: 120,
      search: false,
      renderText: (_, entity) => {
        if (!entity.graduationDate) return "---"
        return <>{formatToLocalTime(entity.graduationDate || "")}</>
      },
    },
    {
      dataIndex: "---",
      title: "Ca học",
      search: false,
      renderText: (_, record) => {
        const items = record.timeFrames
        return !items ? (
          "---"
        ) : (
          <div style={{ minWidth: "100px" }}>
            {items.map((e, idx) => (
              <div key={`${record._id}_${idx}_${e.schoolHourId}`}>
                <Typography.Text style={{ whiteSpace: "nowrap" }}>
                  {schoolHours.find(s => s._id === e.schoolHourId)?.timeFrame} {` `}
                  {WORKING_HOUR_TRANSLATIONS[e.dayOfWeek as CreateWorkTimeDto["dateOfWeek"]]}
                </Typography.Text>
              </div>
            ))}
          </div>
        )
      },
      width: 160,
    },
    {
      dataIndex: ["lecturer", "name"],
      title: "Giáo viên",
      search: false,
      width: 150,
    },
    {
      dataIndex: ["tutor", "name"],
      title: "Trợ giảng",
      search: false,
      width: 150,
    },
    {
      dataIndex: "-",
      title: "Thực tập",
      search: false,
      width: 150,
    },
    {
      dataIndex: "numberEndCourse",
      title: "Số ngày còn",
      search: false,
      width: 80,
    },
    {
      dataIndex: "groupLink",
      title: "Group lớp",
      search: false,
      width: 80,
      renderText: (_, record) => {
        return record.groupLink ? (
          <a href={record.groupLink} target="_blank" rel="noreferrer">
            Link Group lớp
          </a>
        ) : (
          "---"
        )
      },
    },
    {
      dataIndex: "groupChat",
      title: "Group chat",
      search: false,
      width: 80,
      renderText: (_, record) => {
        return record.groupChat ? (
          <a href={record.groupChat} target="_blank" rel="noreferrer">
            Link Group chat
          </a>
        ) : (
          "---"
        )
      },
    },
    {
      dataIndex: "-",
      title: "Tối đa",
      search: false,
      width: 80,
    },
    {
      dataIndex: "-",
      title: "Ghi danh",
      search: false,
      width: 80,
    },
    {
      dataIndex: "-",
      title: "Đã tuyển",
      search: false,
      width: 80,
    },
    {
      dataIndex: "-",
      title: "Còn trống",
      search: false,
      width: 80,
    },
    {
      dataIndex: "-",
      title: "Chuyển vào",
      search: false,
      width: 80,
    },
    {
      dataIndex: "-",
      title: "Chuyển đi",
      search: false,
      width: 80,
    },
    {
      dataIndex: "-",
      title: "Chăm sóc lớp",
      search: false,
      width: 120,
    },
    {
      dataIndex: "status",
      title: "Trạng thái",
      search: false,
      width: 120,
      renderText: (_, record) => {
        return record.status ? CLASS_STATUS[record.status as keyof typeof CLASS_STATUS] : "---"
      },
    },
    {
      title: "Thao tác",
      align: "center",
      search: false,
      fixed: "right",
      width: 80,
      render: (_, entity) => {
        return (
          <Space size={10}>
            <Tooltip title={`Xem ${DEFINE_MODULE.name}`}>
              <EyeTwoTone onClick={() => onClickReadButton(entity._id)} />
            </Tooltip>
            <Tooltip title={`Cập nhật ${DEFINE_MODULE.name}`}>
              <EditTwoTone onClick={() => onClickEditButton(entity._id)} />
            </Tooltip>
            <Tooltip title={`Xoá ${DEFINE_MODULE.name}`}>
              <Popconfirm
                title={`Bạn có chắc rằng muốn xoá ${DEFINE_MODULE.name} này không?`}
                onConfirm={() => onClickDeleteButton(entity._id)}
                okText="Có"
                cancelText="Không"
              >
                <DeleteTwoTone twoToneColor={"red"} />
              </Popconfirm>
            </Tooltip>
          </Space>
        )
      },
    },
  ]

  const columnsChild: ProColumns<ClassDto>[] = [
    {
      hideInTable: true,
      dataIndex: "subjectId",
      title: "Môn học",
      renderFormItem: () => {
        return <DebounceSelect placeholder="Chọn môn học" fetchOptions={getListSubject} />
      },
    },
    {
      dataIndex: "name",
      title: "Tên lớp",
      width: 100,
      fixed: "left",
    },
    {
      dataIndex: "code",
      title: "Mã lớp",
      width: 100,
    },
    {
      dataIndex: "openingDate",
      title: "Khai giảng",
      search: false,
      width: 120,
      renderText: (_, entity) => {
        if (!entity.openingDate) return "---"
        return <>{formatToLocalTime(entity.openingDate || "")}</>
      },
    },
    {
      dataIndex: "graduationDate",
      title: "Tốt nghiệp",
      width: 120,
      search: false,
      renderText: (_, entity) => {
        if (!entity.graduationDate) return "---"
        return <>{formatToLocalTime(entity.graduationDate || "")}</>
      },
    },
    {
      dataIndex: "---",
      title: "Ca học",
      search: false,
      renderText: (_, record) => {
        const items = record.timeFrames
        return !items ? (
          "---"
        ) : (
          <div style={{ minWidth: "100px" }}>
            {items.map((e, idx) => (
              <div key={`${record._id}_${idx}_${e.schoolHourId}`}>
                <Typography.Text style={{ whiteSpace: "nowrap" }}>
                  {schoolHours.find(s => s._id === e.schoolHourId)?.timeFrame} {` `}
                  {WORKING_HOUR_TRANSLATIONS[e.dayOfWeek as CreateWorkTimeDto["dateOfWeek"]]}
                </Typography.Text>
              </div>
            ))}
          </div>
        )
      },
      width: 160,
    },
    {
      dataIndex: ["lecturer", "name"],
      title: "Giáo viên",
      search: false,
      width: 150,
    },
    {
      dataIndex: ["tutor", "name"],
      title: "Trợ giảng",
      search: false,
      width: 150,
    },
    {
      dataIndex: "-",
      title: "Thực tập",
      search: false,
      width: 150,
    },
    {
      dataIndex: "numberEndCourse",
      title: "Số ngày còn",
      search: false,
      width: 80,
    },
    {
      dataIndex: "groupLink",
      title: "Group lớp",
      search: false,
      width: 80,
      renderText: (_, record) => {
        return record.groupLink ? (
          <a href={record.groupLink} target="_blank" rel="noreferrer">
            Link Group lớp
          </a>
        ) : (
          "---"
        )
      },
    },
    {
      dataIndex: "groupChat",
      title: "Group chat",
      search: false,
      width: 80,
      renderText: (_, record) => {
        return record.groupChat ? (
          <a href={record.groupChat} target="_blank" rel="noreferrer">
            Link Group chat
          </a>
        ) : (
          "---"
        )
      },
    },
    {
      dataIndex: "-",
      title: "Tối đa",
      search: false,
      width: 80,
    },
    {
      dataIndex: "-",
      title: "Ghi danh",
      search: false,
      width: 80,
    },
    {
      dataIndex: "-",
      title: "Đã tuyển",
      search: false,
      width: 80,
    },
    {
      dataIndex: "-",
      title: "Còn trống",
      search: false,
      width: 80,
    },
    {
      dataIndex: "-",
      title: "Chuyển vào",
      search: false,
      width: 80,
    },
    {
      dataIndex: "-",
      title: "Chuyển đi",
      search: false,
      width: 80,
    },
    {
      dataIndex: "-",
      title: "Chăm sóc lớp",
      search: false,
      width: 120,
    },
    {
      dataIndex: "status",
      title: "Trạng thái",
      search: false,
      width: 120,
      renderText: (_, record) => {
        return "---"
      },
    },
    {
      title: "Thao tác",
      align: "center",
      search: false,
      fixed: "right",
      width: 120,
      render: (_, entity) => {
        return (
          <Space size={10}>
            <Tooltip title={`Xem ${DEFINE_MODULE.name}`}>
              <EyeTwoTone onClick={() => onClickReadButton(entity._id)} />
            </Tooltip>
            <Tooltip title={`Cập nhật ${DEFINE_MODULE.name}`}>
              <EditTwoTone onClick={() => onClickEditButton(entity._id)} />
            </Tooltip>
            <Tooltip title={`Xoá ${DEFINE_MODULE.name}`}>
              <Popconfirm
                title={`Bạn có chắc rằng muốn xoá ${DEFINE_MODULE.name} này không?`}
                onConfirm={() => onClickDeleteButton(entity._id)}
                okText="Có"
                cancelText="Không"
              >
                <DeleteTwoTone twoToneColor={"red"} />
              </Popconfirm>
            </Tooltip>
          </Space>
        )
      },
    },
  ]

  const expandedRowRender = (record: any) => {
    return (
      <ProTable
        rowKey={`_id`}
        request={params => onExpandRow(record, params)}
        toolBarRender={false}
        columns={columnsChild}
        scroll={{ x: 1300, y: 430 }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Không có dữ liệu"} />,
        }}
        rowSelection={{
          onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
          },

          getCheckboxProps: (record: any) => ({
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
          }),
        }}
        tableAlertRender={({ selectedRowKeys }) => (
          <Space size={24}>
            <span>
              Đang chọn {selectedRowKeys.length} {DEFINE_MODULE.name}
            </span>
          </Space>
        )}
        tableAlertOptionRender={({ selectedRowKeys, onCleanSelected }) => {
          return (
            <Space size={16}>
              <Button
                type="primary"
                onClick={() => {
                  const listId = selectedRowKeys as string[]
                  onDeleteMany(listId)
                  onCleanSelected()
                }}
                danger
              >
                Xóa {selectedRowKeys.length} {DEFINE_MODULE.name}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  const listId = selectedRowKeys as string[]
                  onArchiveMany(listId)
                  onCleanSelected()
                }}
                danger
              >
                Lưu trữ {selectedRowKeys.length} {DEFINE_MODULE.name}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  const listId = selectedRowKeys as string[]
                  onUnarchiveMany(listId)
                  onCleanSelected()
                }}
                danger
              >
                Hủy lưu trữ {selectedRowKeys.length} {DEFINE_MODULE.name}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  const listId = selectedRowKeys as string[]
                  onDraughtMany(listId)
                  onCleanSelected()
                }}
                danger
              >
                Chuyển thành Dự Thảo
              </Button>
            </Space>
          )
        }}
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} trên ${total} ${DEFINE_MODULE.name}`,
        }}
      />
    )
  }

  return (
    <PageContainer>
      {contextHolder}
      <ProTable<any>
        headerTitle={`Danh sách ${DEFINE_MODULE.name}`}
        actionRef={actionRef}
        bordered
        rowKey="_id"
        options={{ density: false, fullScreen: false }}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} trên ${totalData} 
              ${groupFields[groupFields.length - 1] ? groupFields[groupFields.length - 1].name : DEFINE_MODULE.name}`,
        }}
        toolBarRender={() => [
          <Row style={{ minWidth: 650 }}>
            <Col span={20}>
              <Form.Item name="groupBy" label="Nhóm theo" style={{ maxWidth: 500, textOverflow: "ellipsis" }}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Nhóm theo"
                  onSelect={(e: any, d: any) => onSelectFilter(e, d)}
                  onDeselect={(e: any) => onDeSelectFilter(e)}
                >
                  {/* value is key, _id or code ( must be unique) */}
                  <Select.Option key="status" value="status">
                    Trạng thái
                  </Select.Option>
                  <Select.Option key="subjectId" value="subjectId">
                    Môn học
                  </Select.Option>
                  <Select.Option key="locationId" value="locationId">
                    Địa điểm
                  </Select.Option>
                  <Select.Option key="roomId" value="roomId">
                    Phòng học
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Button type="primary" onClick={() => setCreate(true)}>
                <PlusOutlined /> Tạo mới
              </Button>
            </Col>
          </Row>,
        ]}
        columns={columns}
        sticky={{ offsetScroll: 10 }}
        scroll={{ x: 1300, y: 430 }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Không có dữ liệu"} />,
        }}
        rowSelection={
          groupFields.length === 0 && {
            onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
            },

            getCheckboxProps: (record: any) => ({
              disabled: record.name === "Disabled User", // Column configuration not to be checked
              name: record.name,
            }),
          }
        }
        tableAlertRender={({ selectedRowKeys }) => (
          <Space size={24}>
            <span>
              Đang chọn {selectedRowKeys.length} {DEFINE_MODULE.name}
            </span>
          </Space>
        )}
        tableAlertOptionRender={({ selectedRowKeys, onCleanSelected }) => {
          return (
            <Space size={16}>
              <Button
                type="primary"
                onClick={() => {
                  const listId = selectedRowKeys as string[]
                  onDeleteMany(listId)
                  onCleanSelected()
                }}
                danger
              >
                Xóa {selectedRowKeys.length} {DEFINE_MODULE.name}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  const listId = selectedRowKeys as string[]
                  onArchiveMany(listId)
                  onCleanSelected()
                }}
                danger
              >
                Lưu trữ {selectedRowKeys.length} {DEFINE_MODULE.name}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  const listId = selectedRowKeys as string[]
                  onUnarchiveMany(listId)
                  onCleanSelected()
                }}
                danger
              >
                Hủy lưu trữ {selectedRowKeys.length} {DEFINE_MODULE.name}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  const listId = selectedRowKeys as string[]
                  onDraughtMany(listId)
                  onCleanSelected()
                }}
                danger
              >
                Chuyển thành Dự Thảo
              </Button>
            </Space>
          )
        }}
        // search={groupFields.length > 0 ? false : undefined}
        search={{
          labelWidth: "auto",

          resetText: "Làm mới",
          collapseRender: collapsed => {
            return <CollapseRender collapsed={collapsed} />
          },
        }}
        cardBordered
        onChange={handleChange}
        // @ts-ignore
        request={async (params, sort, filter) => {
          // @ts-ignore
          const requestData = {
            ...params,
            // offset: ((params?.current || 1) - 1) * (params?.pageSize || 20),
            // limit: params.pageSize,
          }
          delete requestData.current
          delete requestData.pageSize

          // @ts-ignore
          const { current, pageSize, ...data } = requestData

          // @ts-ignore
          return fetchData(data)
        }}
        dataSource={source}
        columnsState={{
          persistenceKey: "pro-table-singe-demos",
          persistenceType: "localStorage",
          onChange(value) {
            console.log("value: ", value)
          },
        }}
        form={{
          syncToUrl: (values, type) => {
            return values
          },
        }}
        expandable={
          groupFields.length === 0
            ? undefined
            : {
                expandedRowRender,
                rowExpandable: record => record.__typename === "GroupByData",
              }
        }
        dateFormatter="string"
      />
      {isCreate && (
        <UpsertForm
          notify={notifyApi}
          key="CREATE"
          onCancel={() => setCreate(false)}
          modalVisible={isCreate}
          onSubmit={onSubmit}
          actionType="CREATE"
          loadingBtn={loadingBtn}
          onReload={() => {
            actionRef.current?.reload()
          }}
        />
      )}
      {isUpdate && (
        <UpsertForm
          notify={notifyApi}
          key="UPDATE"
          onCancel={() => setUpdate(false)}
          modalVisible={isUpdate}
          onSubmit={onSubmit}
          actionType="UPDATE"
          loadingBtn={loadingBtn}
          values={values}
          onReload={() => {
            actionRef.current?.reload()
            onClickEditButton(values._id)
          }}
        />
      )}

      {isRead && (
        <UpsertForm
          notify={notifyApi}
          key="READ"
          onCancel={() => setRead(false)}
          modalVisible={isRead}
          onSubmit={() => null}
          actionType="READ"
          loadingBtn={loadingBtn}
          values={values}
        />
      )}
    </PageContainer>
  )
}

export default List
