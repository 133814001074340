import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../http"

interface IPermissions {
  name?: string
  description?: string
  key?: string
  limit?: number
  offset?: number
}

interface IPermissionCreate {
  name: string
  key: string
  description?: string
  actionIds: Array<string>
}

interface IPermissionUpdate {
  name: string
  key: string
  description?: string
  actionIds: Array<string>
}

interface IPermissionArchive {
  ids: Array<string>
  isArchived: boolean
}

export const getPermissionsApi = async (data?: IPermissions) => {
  const url = "/permissions"
  return await getRequest(url, { params: data })
}

export const getPermissionDetailApi = async (id: string) => {
  const url = `/permissions/${id}`
  return await getRequest(url)
}

export const createPermissionApi = async (data: IPermissionCreate) => {
  const url = `/permissions`
  return await postRequest(url, data)
}

export const updatePermissionApi = async (id: string, data: IPermissionUpdate) => {
  const url = `/permissions/${id}`
  return await putRequest(url, data)
}

export const archiveManyPermissionApi = async (data: IPermissionArchive) => {
  const url = `/permissions`
  return await patchRequest(url, data)
}

export const deletePermissionApi = async (id: string) => {
  const url = `/permissions/${id}`
  return await deleteRequest(url)
}

export const deleteManyPermissionApi = async (ids: string[]) => {
  const url = `/permissions`
  return await deleteRequest(url, { data: { ids } })
}
