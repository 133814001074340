import { EduPaths } from "@/modules/edu"
import i18n from "i18n-js"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import { ActionTypes } from "../../constants"
import * as actionTypes from "../../constants/ActionTypes"
import history from "../../navigation"
import {
  archiveManySchoolHourApi,
  createSchoolHourApi,
  deleteManySchoolHourApi,
  deleteSchoolHourApi,
  getSchoolHourDetailApi,
  getSchoolHoursApi,
  updateSchoolHourApi,
} from "../../network"
import { convertObjectParamsToStringParams, getInitParams } from "../../utils"
import { getSchoolHourState } from "../selectors"
import { IRoleState, ISchoolHourState } from "../types"

interface IPayload {
  type: string
  payload: any
}

interface IResponse {
  data: any
  total: number
}

function* getListSchoolHour(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const SchoolHour: IRoleState = yield select<any>(getSchoolHourState)

    // validate and set requesting
    if (SchoolHour.isLoadingRequest) return
    yield put({
      type: ActionTypes.SET_LOADING_ROLE,
      payload: { isLoadingRequest: true },
    })

    const { data, total }: IResponse = yield call(getSchoolHoursApi, action.payload)
    yield history.push(`?${convertObjectParamsToStringParams(action.payload)}`)
    yield put({
      type: ActionTypes.GET_LIST_SCHOOL_HOUR_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({ type: ActionTypes.GET_LIST_SCHOOL_HOUR_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_SCHOOL_HOUR,
      payload: { isLoadingRequest: false },
    })
  }
}

function* createSchoolHour(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const SchoolHour: ISchoolHourState = yield select<any>(getSchoolHourState)

    // validate and set requesting
    if (SchoolHour.isCreateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_SCHOOL_HOUR,
      payload: { isCreateRequest: true },
    })

    // call api create
    const { data }: IResponse = yield call(createSchoolHourApi, action.payload)

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Thêm mới thành công",
        message: data?.message || "",
        type: "success",
      },
    })

    // navigate
    history.push(EduPaths.SCHOOL_HOUR.LIST.PATH)
  } catch (error: any) {
    yield put({ type: actionTypes.CREATE_SCHOOL_HOUR_FAILED, payload: error })
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_SCHOOL_HOUR,
      payload: { isCreateRequest: false },
    })
  }
}

function* deleteOneSchoolHour(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const SchoolHour: ISchoolHourState = yield select<any>(getSchoolHourState)

    // validate and set requesting
    if (SchoolHour.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_SCHOOL_HOUR,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data }: IResponse = yield call(deleteSchoolHourApi, action.payload.id)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteAction.deleteSuccess"),
          message: i18n.t("common.deleteAction.deleteSuccess"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_SCHOOL_HOUR,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_SCHOOL_HOUR,
      payload: { isDeleteRequest: false },
    })
  }
}

function* updateDetailSchoolHour(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const SchoolHour: ISchoolHourState = yield select<any>(getSchoolHourState)

    // validate and set requesting
    if (SchoolHour.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_SCHOOL_HOUR,
      payload: { isUpdateRequest: true },
    })

    // call api update
    const { data }: IResponse = yield call(updateSchoolHourApi, action.payload.id, action.payload.data)

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thành công",
        message: data?.message || "",
        type: "success",
      },
    })
    history.push(EduPaths.SCHOOL_HOUR.LIST.PATH)
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_DETAIL_SCHOOL_HOUR_FAILED,
      payload: error,
    })
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thất bại",
        message: `${error}` || "",
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_SCHOOL_HOUR,
      payload: { isUpdateRequest: false },
    })
  }
}

function* getDetailSchoolHour(action: IPayload) {
  try {
    const { data, total }: IResponse = yield call(getSchoolHourDetailApi, action.payload)

    yield put({
      type: actionTypes.GET_DETAIL_SCHOOL_HOUR_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({
      type: actionTypes.GET_DETAIL_SCHOOL_HOUR_FAILED,
      payload: error,
    })
  }
}

function* deleteManySchoolHour(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const SchoolHour: ISchoolHourState = yield select<any>(getSchoolHourState)

    // validate and set requesting
    if (SchoolHour.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_SCHOOL_HOUR,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data }: IResponse = yield call(deleteManySchoolHourApi, action.payload.ids)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteManyAction.title"),
          message: i18n.t("common.deleteManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_SCHOOL_HOUR,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_SCHOOL_HOUR,
      payload: { isDeleteRequest: false },
    })
  }
}

function* archiveManySchoolHour(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const SchoolHour: ISchoolHourState = yield select<any>(getSchoolHourState)

    // validate and set requesting
    if (SchoolHour.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_SCHOOL_HOUR,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data }: IResponse = yield call(archiveManySchoolHourApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.archiveManyAction.title"),
          message: i18n.t("common.archiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_SCHOOL_HOUR,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_SCHOOL_HOUR,
      payload: { isUpdateRequest: false },
    })
  }
}

function* unarchiveManySchoolHour(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const SchoolHour: ISchoolHourState = yield select<any>(getSchoolHourState)

    // validate and set requesting
    if (SchoolHour.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_SCHOOL_HOUR,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data }: IResponse = yield call(archiveManySchoolHourApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.unarchiveManyAction.title"),
          message: i18n.t("common.unarchiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_SCHOOL_HOUR,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_SCHOOL_HOUR,
      payload: { isUpdateRequest: false },
    })
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_LIST_SCHOOL_HOUR, getListSchoolHour),
    takeLatest(actionTypes.GET_DETAIL_SCHOOL_HOUR, getDetailSchoolHour),
    takeLatest(ActionTypes.CREATE_SCHOOL_HOUR, createSchoolHour),
    takeLatest(actionTypes.DELETE_ONE_SCHOOL_HOUR, deleteOneSchoolHour),
    takeLatest(actionTypes.UPDATE_DETAIL_SCHOOL_HOUR, updateDetailSchoolHour),
    takeLatest(actionTypes.DELETE_MANY_SCHOOL_HOUR, deleteManySchoolHour),
    takeLatest(actionTypes.ARCHIVE_MANY_SCHOOL_HOUR, archiveManySchoolHour),
    takeLatest(actionTypes.UNARCHIVE_MANY_SCHOOL_HOUR, unarchiveManySchoolHour),
  ])
}
