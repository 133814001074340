import ModalWarning from "@/modules/edu/pages/operation/work-time/views/components/check/ModalWarning"
import AreaSearch from "@/modules/edu/pages/operation/work-time/views/components/search/AreaSearchComp"
import LocationSearch from "@/modules/edu/pages/operation/work-time/views/components/search/LocationSearchComp"
import { DEFINE_MODULE } from "@/modules/hr/pages/department/constants"
import { API } from "@/network/http"
import { StudyScheduleDto } from "@/__generated__/api-v1"
import { Button, Col, DatePicker, Form, Modal, notification, Row } from "antd"
import { useState } from "react"

interface ModalCheckProps {
  modalVisible: boolean
  onResetForm?: () => void
  onCancel: () => void
}

const stylesSearchDate = {
  width: "100%",
  minWidth: "130px",
}

const ModalCheck: React.FC<ModalCheckProps> = props => {
  const [form] = Form.useForm()
  const { modalVisible, onResetForm, onCancel } = props
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

  const [warningData, setWarningData] = useState<StudyScheduleDto[]>([])

  const handleSubmit = async () => {
    await form.validateFields()
    setLoadingBtn(true)
    const formValues = { ...form.getFieldsValue() }
    try {
      const res = await API.studySchedules.checkStudyScheduleControllerCheckInvalid({
        fromDate: formValues.fromDate.toISOString(),
        toDate: formValues.toDate.toISOString(),
        ...(formValues.areas && formValues.areas.length > 0 ? { areas: [...formValues.areas].join(",") } : {}),
        ...(formValues.locationIds && formValues.locationIds.length > 0
          ? { locationIds: [...formValues.locationIds].join(",") }
          : {}),
      })
      if (res.data.total === 0) {
        notification.success({
          message: "Tất cả các buổi học đều hợp lệ!",
        })
        return
      }
      const studyData = res.data.data as StudyScheduleDto[]
      setWarningData(studyData)
    } catch (err: any) {
      notification.error({
        message: "Không đáp ứng điều kiện để thêm khoá học",
      })
    } finally {
      setLoadingBtn(false)
    }
  }

  const renderFooter = () => {
    return (
      <>
        <Button loading={loadingBtn} type="primary" onClick={handleSubmit}>
          Đồng ý
        </Button>
        <Button danger onClick={onCancel}>
          Huỷ
        </Button>
      </>
    )
  }

  return (
    <>
      <Modal
        bodyStyle={{ background: "#F0F2F5" }}
        width={700}
        destroyOnClose
        maskClosable={false}
        title={`Kiểm tra ${DEFINE_MODULE.name}`}
        visible={modalVisible}
        onCancel={() => {
          if (onResetForm) onResetForm()
          onCancel()
        }}
        footer={renderFooter()}
      >
        <Form labelWrap form={form}>
          <Row gutter={12}>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item name="fromDate" label="Từ ngày" rules={[{ required: true, message: "Vui lòng chọn ngày" }]}>
                {/* @ts-ignore */}
                <DatePicker placeholder="Từ ngày" style={stylesSearchDate} />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item name="toDate" label="Đến ngày" rules={[{ required: true, message: "Vui lòng đến ngày" }]}>
                {/* @ts-ignore */}
                <DatePicker placeholder="Đến ngày" style={stylesSearchDate} />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <AreaSearch name="areas" label="Khu vực" />
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <LocationSearch name="locationIds" label="Cơ sở" />
            </Col>
          </Row>
        </Form>
      </Modal>
      {warningData.length > 0 && (
        <ModalWarning data={warningData} visible={warningData.length > 0} onCancel={() => setWarningData([])} />
      )}
    </>
  )
}

export default ModalCheck
