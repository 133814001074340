import { EduPaths } from "@/modules/edu"
import i18n from "i18n-js"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import { ActionTypes } from "../../constants"
import history from "../../navigation"
import { API } from "../../network/http"
import { convertObjectParamsToStringParams, getInitParams } from "../../utils"
import { IPayload, IResponse } from "../../__generated__"

import { getWorkTimeState } from "../selectors"
import { IWorkTimeState } from "../types"

function* getListWorkTime(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const workTime: IWorkTimeState = yield select<any>(getWorkTimeState)

    // validate and set requesting
    if (workTime.isLoadingRequest) return
    yield put({
      type: ActionTypes.SET_LOADING_WORK_TIME,
      payload: { isLoadingRequest: true },
    })

    const { data }: IResponse = yield call(API.workTimes.workTimeControllerGetMany, action.payload)
    yield history.push(`?${convertObjectParamsToStringParams(action.payload)}`)
    yield put({
      type: ActionTypes.GET_LIST_WORK_TIME_SUCCESS,
      payload: { ...data },
    })
  } catch (error) {
    yield put({ type: ActionTypes.GET_LIST_WORK_TIME_FAILED, payload: error })
  } finally {
    yield put({
      type: ActionTypes.SET_LOADING_WORK_TIME,
      payload: { isLoadingRequest: false },
    })
  }
}

function* createWorkTime(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const workTime: IWorkTimeState = yield select<any>(getWorkTimeState)

    // validate and set requesting
    if (workTime.isCreateRequest) return
    yield put({
      type: ActionTypes.SET_LOADING_WORK_TIME,
      payload: { isCreateRequest: true },
    })

    // call api create
    const { data }: IResponse = yield call(API.workTimes.workTimeControllerCreateOne, action.payload)

    // show notification
    yield put({
      type: ActionTypes.NOTIFICATION,
      payload: {
        title: "Thêm mới thành công",
        message: data?.message || "",
        type: "success",
      },
    })

    // navigate
    history.push(EduPaths.WORK_TIME.LIST.PATH)
  } catch (error) {
    yield put({ type: ActionTypes.CREATE_WORK_TIME_FAILED, payload: error })
  } finally {
    yield put({
      type: ActionTypes.SET_LOADING_WORK_TIME,
      payload: { isCreateRequest: false },
    })
  }
}

function* deleteOneWorkTime(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const workTime: IWorkTimeState = yield select<any>(getWorkTimeState)

    // validate and set requesting
    if (workTime.isDeleteRequest) return
    yield put({
      type: ActionTypes.SET_LOADING_WORK_TIME,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data }: IResponse = yield call(API.workTimes.workTimeControllerDeleteOne, action.payload.id)

    // if success
    if (data) {
      // show notification
      yield put({
        type: ActionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteAction.deleteSuccess"),
          message: i18n.t("common.deleteAction.deleteSuccess"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: ActionTypes.GET_LIST_WORK_TIME,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    yield put({
      type: ActionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: ActionTypes.SET_LOADING_WORK_TIME,
      payload: { isDeleteRequest: false },
    })
  }
}

function* updateDetailWorkTime(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const workTime: IWorkTimeState = yield select<any>(getWorkTimeState)

    // validate and set requesting
    if (workTime.isUpdateRequest) return
    yield put({
      type: ActionTypes.SET_LOADING_WORK_TIME,
      payload: { isUpdateRequest: true },
    })

    // call api update
    const { data }: IResponse = yield call(
      API.workTimes.workTimeControllerUpdateOne,
      action.payload.id,
      action.payload.data,
    )

    // show notification
    yield put({
      type: ActionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thành công",
        message: data?.message || "",
        type: "success",
      },
    })
    history.push(EduPaths.WORK_TIME.LIST.PATH)
  } catch (error) {
    yield put({
      type: ActionTypes.UPDATE_DETAIL_WORK_TIME_FAILED,
      payload: error,
    })
    yield put({
      type: ActionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thất bại",
        message: `${error}` || "",
        type: "error",
      },
    })
  } finally {
    yield put({
      type: ActionTypes.SET_LOADING_WORK_TIME,
      payload: { isUpdateRequest: false },
    })
  }
}

function* getDetailWorkTime(action: IPayload) {
  try {
    const { data, total }: IResponse = yield call(API.workTimes.workTimeControllerGetOne, action.payload)

    yield put({
      type: ActionTypes.GET_DETAIL_WORK_TIME_SUCCESS,
      payload: { ...data },
    })
  } catch (error) {
    yield put({
      type: ActionTypes.GET_DETAIL_WORK_TIME_FAILED,
      payload: error,
    })
  }
}

function* clearDetailWorkTime() {
  yield put({
    type: ActionTypes.GET_DETAIL_WORK_TIME_SUCCESS,
    payload: { data: null },
  })
}

function* deleteManyWorkTime(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const workTime: IWorkTimeState = yield select<any>(getWorkTimeState)

    // validate and set requesting
    if (workTime.isDeleteRequest) return
    yield put({
      type: ActionTypes.SET_LOADING_WORK_TIME,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data }: IResponse = yield call(API.workTimes.workTimeControllerDeleteMany, action.payload.ids)

    // if success
    if (data) {
      // show notification
      yield put({
        type: ActionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteManyAction.title"),
          message: i18n.t("common.deleteManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: ActionTypes.GET_LIST_WORK_TIME,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: ActionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: ActionTypes.SET_LOADING_WORK_TIME,
      payload: { isDeleteRequest: false },
    })
  }
}

function* archiveManyWorkTime(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const workTime: IWorkTimeState = yield select<any>(getWorkTimeState)

    // validate and set requesting
    if (workTime.isUpdateRequest) return
    yield put({
      type: ActionTypes.SET_LOADING_WORK_TIME,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data }: IResponse = yield call(API.workTimes.workTimeControllerUpdateMany, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: ActionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.archiveManyAction.title"),
          message: i18n.t("common.archiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: ActionTypes.GET_LIST_WORK_TIME,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: ActionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: ActionTypes.SET_LOADING_WORK_TIME,
      payload: { isUpdateRequest: false },
    })
  }
}

// Test

function* unarchiveManyWorkTime(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const workTime: IWorkTimeState = yield select<any>(getWorkTimeState)

    // validate and set requesting
    if (workTime.isUpdateRequest) return
    yield put({
      type: ActionTypes.SET_LOADING_WORK_TIME,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data }: IResponse = yield call(API.workTimes.workTimeControllerUpdateMany, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: ActionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.unarchiveManyAction.title"),
          message: i18n.t("common.unarchiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: ActionTypes.GET_LIST_WORK_TIME,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: ActionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: ActionTypes.SET_LOADING_WORK_TIME,
      payload: { isUpdateRequest: false },
    })
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_LIST_WORK_TIME, getListWorkTime),
    takeLatest(ActionTypes.GET_DETAIL_WORK_TIME, getDetailWorkTime),
    takeLatest(ActionTypes.CREATE_WORK_TIME, createWorkTime),
    takeLatest(ActionTypes.DELETE_ONE_WORK_TIME, deleteOneWorkTime),
    takeLatest(ActionTypes.UPDATE_DETAIL_WORK_TIME, updateDetailWorkTime),
    takeLatest(ActionTypes.CLEAR_DETAIL_WORK_TIME, clearDetailWorkTime),
    takeLatest(ActionTypes.DELETE_MANY_WORK_TIME, deleteManyWorkTime),
    takeLatest(ActionTypes.ARCHIVE_MANY_WORK_TIME, archiveManyWorkTime),
    takeLatest(ActionTypes.UNARCHIVE_MANY_WORK_TIME, unarchiveManyWorkTime),
  ])
}
