import AddressInput from "@/components/address";
import FormItemData from "@/components/antd/form";
import {ACTION_METHOD_OBJ} from "@/constants";
import {API} from "@/network/http";
import {BaseUpsertFormProps, SelectOptionProps} from "@/typing/common";
import {Button, Card, Form, Modal, Row} from "antd";
import {cloneDeep} from "lodash";
import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {DEFINE_MODULE} from "../constants";

const UpsertForm: React.FC<BaseUpsertFormProps> = forwardRef((props, ref) => {
  const {modalVisible, onCancel, onSubmit, actionType, values, loadingBtn} = props;
  const [location, setLocation] = useState<{
    ward: SelectOptionProps[];
    district: SelectOptionProps[];
    province: SelectOptionProps[];
  }>({
    ward: [],
    district: [],
    province: [],
  });
  const [form] = Form.useForm();

  // as the second argument
  useImperativeHandle(ref, () => ({
    onResetData() {
      // reset sale table
      form.resetFields();
    },
  }));

  const fetchProvince = useCallback(async () => {
    if (actionType === "CREATE") {
      const getP = await API.provinces.masterDataControllerGetManyProvinces({limit: "100"});
      const provinceMapping =
        getP.data.data.map(
          p =>
          ({
            key: p.id,
            value: p.id,
            name: p.name,
          } as any),
        ) ?? [];
      const newLocation = cloneDeep({...location, province: provinceMapping});
      setLocation(newLocation);
    }
    if (actionType === "UPDATE") {
      const [getP, getD, getW] = await Promise.all([
        API.provinces.masterDataControllerGetManyProvinces({limit: "100"}),
        API.districts.masterDataControllerGetManyDistricts({provinceId: values.provinceId, limit: "100"}),
        API.wards.masterDataControllerGetManyWards({districtId: values.districtId, limit: "100"}),
      ]);

      const provinceMapping =
        getP.data.data.map(
          p =>
          ({
            key: p.id,
            value: p.id,
            name: p.name,
          } as any),
        ) ?? [];

      const districtMapping =
        getD.data.data.map(
          p =>
          ({
            key: p.id,
            value: p.id,
            name: p.name,
          } as any),
        ) ?? [];

      const wardMapping =
        getW.data.data.map(
          p =>
          ({
            key: p.id,
            value: p.id,
            name: p.name,
          } as any),
        ) ?? [];

      const newLocation = cloneDeep({province: provinceMapping, district: districtMapping, ward: wardMapping});
      form.setFieldsValue({
        name: values.name,
        code: values.code,
        parkingAddress: values.parkingAddress,
        hotline: values.hotline,
        location: {
          address: values.address,
          province: ~~values.provinceId,
          district: ~~values.districtId,
          ward: ~~values.wardId,
        },
        fullAddress: values.fullAddress,
      });
      setLocation(newLocation);
    }
  }, []);

  useEffect(() => {
    fetchProvince();
  }, []);

  const handleSubmit = async () => {
    const fieldsValidation = await form.validateFields();
    if (!fieldsValidation) {
      return;
    }
    const formValues = {...form.getFieldsValue()};

    const provinceId = formValues.location.province;
    const districtId = formValues.location.district;
    const wardId = formValues.location.ward;
    const provinceName = location.province.find(p => p.value === provinceId)?.name;
    const districtName = location.district.find(d => d.value === districtId)?.name;
    const wardName = location.ward.find(w => w.value === wardId)?.name;
    formValues.fullAddress = `${formValues.location.address}, ${wardName}, ${districtName}, ${provinceName}.`;
    formValues.provinceId = formValues.location.province;
    formValues.districtId = formValues.location.district;
    formValues.wardId = formValues.location.ward;
    formValues.address = formValues.location.address;
    delete formValues.location;
    if (actionType === "CREATE") {
      onSubmit(formValues, actionType);
    }
    if (actionType === "UPDATE") {
      onSubmit(formValues, actionType, values._id);
    }
  };

  const onResetForm = (): void => {
    // reset sale table
    form.resetFields();
  };

  const renderContentList = [
    {
      inputType: "input",
      name: "name",
      label: "Tên cơ sở",
      placeholder: "Tên cơ sở",
      rules: [{required: true, message: "Nhập tên cơ sở"}],
    },
    {
      inputType: "input",
      name: "code",
      label: "Mã cơ sở",
      placeholder: "Mã cơ sở",
      rules: [{required: true, message: "Nhập mã cơ sở"}],
    },
    {
      inputType: "input",
      name: "parkingAddress",
      label: "Địa điểm đỗ xe",
      placeholder: "Địa điểm đỗ xe",
      rules: [{required: true, message: "Nhập Địa điểm đỗ xe"}],
    },
    {
      inputType: "input",
      name: "hotline",
      label: "Đường dây nóng",
      placeholder: "Đường dây nóng",
      rules: [{required: true, message: "Nhập Đường dây nóng"}],
    },
  ];

  const renderContent = () => {
    return (
      <Card style={{paddingRight: "20px"}}>
        <Row gutter={24}>
          {renderContentList.map(item => item && <FormItemData key={item.name} {...item} />)}
          <AddressInput location={location} setLocation={setLocation} form={form} />
        </Row>
      </Card>
    );
  };

  const renderFooter = () => {
    return (
      <>
        {props.actionType === "CREATE" && <Button onClick={() => onResetForm()}>Làm mới</Button>}
        <Button loading={loadingBtn} type="primary" onClick={handleSubmit}>
          Đồng ý
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal
        bodyStyle={{background: "#F0F2F5"}}
        width={1300}
        destroyOnClose
        maskClosable={false}
        title={`${ACTION_METHOD_OBJ[actionType]} ${DEFINE_MODULE.name}`}
        visible={modalVisible}
        onCancel={() => {
          onResetForm();
          onCancel();
        }}
        footer={renderFooter()}
      >
        <Form form={form}>{renderContent()}</Form>
      </Modal>
    </>
  );
});

export default UpsertForm;
