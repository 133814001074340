import { Button, Card, Col, DatePicker, Form, Row, Typography } from "antd"
import AreaSearch from "./AreaSeachComp"
import LocationSearch from "./LocationSearchComp"
import SubjectSearch from "./SubjectSearchComp"
import TimeShiftSearch from "./TimeShiftSearchComp"

const { Title } = Typography

const stylesSearch = {
  width: "100%",
  minWidth: "100px",
}

const stylesSearchDate = {
  width: "100%",
  minWidth: "130px",
}

interface SearchBarProps {
  triggerSearch: (pagination: any, filters: any) => void
}

export const SearchBar: React.FC<SearchBarProps> = ({ triggerSearch }) => {
  const [form] = Form.useForm()

  function handleSearch() {
    const querySearch = form?.getFieldsValue()
    if (querySearch.fromDate || querySearch.toDate) {
      if (!querySearch.fromDate) {
        form.setFields([
          { name: "toDate", errors: [] },
          { name: "fromDate", errors: ["Chọn ngày bắt đầu"] },
        ])
        return
      }
      if (!querySearch.toDate) {
        form.setFields([
          { name: "fromDate", errors: [] },
          { name: "toDate", errors: ["Chọn ngày kết thúc"] },
        ])
        return
      }
      const startTime = querySearch.fromDate.valueOf()
      const endTime = querySearch.toDate.valueOf()
      if (startTime > endTime) {
        form.setFields([
          { name: "fromDate", errors: ["Ngày bắt đầu phải nhỏ hơn"] },
          { name: "toDate", errors: ["Ngày kết thúc phải lớn hơn"] },
        ])
        return
      }
    } else {
      form.setFields([
        { name: "fromDate", errors: [] },
        { name: "toDate", errors: [] },
      ])
    }
    const formatQuery = Object.fromEntries(
      Object.entries(querySearch).filter(([k, v]: any) => {
        if (Array.isArray(v) && v.length === 0) {
          return false
        }
        return true
      }),
    )
    triggerSearch(null, formatQuery)
  }

  const listSearchBar = [
    { label: "Khu vực", name: "areas", component: <AreaSearch style={stylesSearch} name="areas" /> },
    { label: "Cơ sở", name: "locationIds", component: <LocationSearch style={stylesSearch} name="locationIds" /> },
    { label: "Môn học", name: "subjectIds", component: <SubjectSearch style={stylesSearch} name="subjectIds" /> },
    { label: "Loại ca", name: "shifts", component: <TimeShiftSearch style={stylesSearch} name="shifts" /> },
  ]

  return (
    <Card style={{ border: "none" }} bodyStyle={{ padding: "12px 0" }}>
      <Form form={form}>
        <Row gutter={24}>
          <Col xl={4} lg={4} md={4} sm={4} xs={4}>
            <Form.Item name="fromDate" label="Từ">
              {/* @ts-ignore */}
              <DatePicker
                style={stylesSearchDate}
                onSelect={() => {
                  form.setFields([{ name: "fromDate", errors: [] }])
                }}
              />
            </Form.Item>
          </Col>
          <Col xl={4} lg={4} md={4} sm={4} xs={4}>
            <Form.Item name="toDate" label="Đến">
              {/* @ts-ignore */}
              <DatePicker
                style={stylesSearchDate}
                onSelect={() => {
                  form.setFields([{ name: "toDate", errors: [] }])
                }}
              />
            </Form.Item>
          </Col>
          {listSearchBar.map((item, idx) => (
            <Col key={`search_bar_${idx}`} xl={4} lg={8} md={24} sm={24} xs={24}>
              {item.component}
            </Col>
          ))}
          <Col span={20}></Col>
          <Col span={4}>
            <Button onClick={handleSearch}>Tìm kiếm</Button>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}
