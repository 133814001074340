/* eslint-disable react-hooks/exhaustive-deps */
import FormItemData from "@/components/antd/form"
import { DebounceSelect } from "@/components/antd/form/DebounceSelect"
import { EntityStates } from "@/components/EntityStates"
import { ACTION_METHOD_OBJ } from "@/constants"
import { layout } from "@/constants/layout"
import { MoreStaffModal } from "@/modules/edu/pages/configuration/classes/components/MoreStaffModal"
import { API } from "@/network/http"
import { BaseUpsertFormProps, SelectOptionProps, SelectOptionSearchProps } from "@/typing/common"
import { ClassDto } from "@/__generated__/api-v1"
import { PlusOutlined } from "@ant-design/icons"
import { useBoolean } from "ahooks"
import { Button, Card, Col, Divider, Form, Modal, Row, Select, Space, Tabs, Typography } from "antd"
import { sortBy, take, takeRight } from "lodash"
import moment, { Moment } from "moment"
import { forwardRef, memo, ReactElement, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react"
import { convertDate } from "../../work-time/handler"
import { DEFINE_MODULE } from "../constants"
import { TEACH_SCHEDULE_STATUS, getDayInWeek, TeachScheduleStatusType } from "../utils"

const FormItem = Form.Item

interface Props extends BaseUpsertFormProps {
  onReload?: () => void
  prefilledValues?: {
    startingDate: string
  }
}

interface ClassInfoProps {
  locations: SelectOptionProps[]
  rooms: SelectOptionProps[]
  // lecturer: SelectOptionProps[]
  takeCares: SelectOptionProps[]
  tutors: SelectOptionProps[]
  interns: SelectOptionProps[]
  subjects: SelectOptionProps[]
  schoolHours: SelectOptionProps[]
}

const UpsertForm = forwardRef((props: Props, ref) => {
  const { modalVisible, onCancel, onSubmit, actionType, values, loadingBtn, notify, prefilledValues, onReload } = props

  const [classInfo, setClassInfo] = useState<ClassInfoProps>({
    locations: [],
    rooms: [],
    takeCares: [],
    tutors: [],
    interns: [],
    subjects: [],
    schoolHours: [],
  })
  const [disabledField, setDisableField] = useState<boolean>(true)

  const [isLoaded, setLoaded] = useState<boolean>(false)

  const [moreStaffModal, { setFalse: closeMoreStaff, setTrue: openMoreStaff }] = useBoolean()
  const [moreStaffName, setMoreStaffName] = useState<
    "lecturerId" | "tutorIds" | "internIds" | "employeeTakeCareIds" | null
  >(null)

  const [selectedOptions, setSelectedOptions] = useState<{
    lecturerId?: SelectOptionSearchProps[]
    tutorIds?: SelectOptionSearchProps[]
    internIds?: SelectOptionSearchProps[]
    employeeTakeCareIds?: SelectOptionSearchProps[]
  }>({})

  const roomRef = useRef<any>()
  const lecturerRef = useRef<any>()
  const internLectureRef = useRef<any>()
  const tutorRef = useRef<any>()
  const internRef = useRef<any>()

  const [form] = Form.useForm()

  const watchClassId = Form.useWatch("classId", form)
  const watchSubjectId = Form.useWatch("subjectId", form)
  const watchLocationId = Form.useWatch("locationId", form)
  const watchRoomId = Form.useWatch("roomId", form)
  const watchTimeFrames = Form.useWatch("timeFrames", form)
  const watchDate = Form.useWatch("date", form)

  // as the second argument
  useImperativeHandle(ref, () => ({
    onResetData() {
      // reset sale table
      form.resetFields()
    },
  }))

  useEffect(() => {
    if (prefilledValues) {
      form.setFieldsValue({ startingDate: new Date(prefilledValues.startingDate) })
    }
    console.log("useEffect: ", prefilledValues)
  }, [prefilledValues])

  const onTriggerRoomFetch = () => {
    roomRef.current.triggerFetch()
  }

  const onSelectSchoolHour = (option: SelectOptionProps) => {
    form.setFieldsValue({
      shift: convertDate(option.option?.studySchedule)?.name,
    })
  }

  const onSelectDate = (option: Moment) => {
    form.setFieldsValue({
      dayInWeek: getDayInWeek(option),
    })
  }

  const getListLocation = async (searchValue?: string) => {
    const response = await API.locations.locationControllerGetMany({ name: searchValue })
    return (
      response?.data?.data?.map(item => ({
        key: item?._id,
        value: item?._id,
        name: item?.name,
      })) || []
    )
  }

  const getListRoom = async (searchValue?: string) => {
    if (isLoaded && watchLocationId) {
      const response = await API.rooms.roomControllerGetMany({
        // code: searchValue,
        // locationId: form.getFieldValue("locationId"),
      })
      return (
        response?.data?.data?.map((item: any) => ({
          key: item?._id,
          value: item?._id,
          name: item?.code,
        })) || []
      )
    }
    return []
  }

  const getListAvailable = useCallback(
    async (roles?: ("LECTURER" | "TUTOR" | "INTERN" | "INTERN_LECTURER")[]) => {
      if (
        isLoaded &&
        watchSubjectId &&
        watchLocationId &&
        watchRoomId &&
        watchTimeFrames?.every(
          (time: { schoolHourId: string; dayOfWeek: string }) => time?.dayOfWeek && time?.schoolHourId,
        )
      ) {
        const response = await API.availability.availabilityControllerCheck({
          subjectIds: [form.getFieldValue("subjectId")],
          locationIds: [form.getFieldValue("locationId")],
          daysOfWeek: form.getFieldValue("timeFrames")?.map((o: any) => o.dayOfWeek),
          fromDate: form.getFieldValue("openingDate"),
          toDate: form.getFieldValue("graduationDate"),
          areas: [],
          filterFields: [],
          sortFields: [],
          offset: "0",
          limit: "10",
          roles,
          ids: [],
          // force: true,
        })
        return (
          response?.data?.data?.map((item: any) => ({
            key: item?.employeeId,
            value: item?.employeeId,
            name: item?.employee[0]?.name,
          })) ||
          // .concat([{ key: "SELECT_MORE_OPTIONS", name: "Xem thêm ngoài khả dụng", value: "SELECT_MORE_OPTIONS" }])
          []
        )
      }
      return []
    },
    [watchSubjectId, watchLocationId, watchRoomId, watchTimeFrames],
  )

  const onSearchSchoolHour = async (e: string) => {
    const searchString = !e || e == undefined ? "" : e
    const response = await API.schoolHours.schoolHourControllerGetMany({ limit: "100" })
    const sortedData = sortBy(response?.data?.data, ["startHour", "endHour"])
    return (
      sortedData
        .filter((o: any) => o.timeFrame.includes(searchString))
        .map((item: any) => ({
          key: item?._id,
          value: item?._id,
          name: item?.timeFrame,
          option: item,
        })) || []
    )
  }

  useEffect(() => {
    if (actionType !== "CREATE") {
      const getDetailSubject = async () => {
        const sub = await API.subjects.subjectControllerGetOne(values.classes.subjectId)
        let listSubject: { value: number; name: string }[] = []
        for (let i = 1; i <= sub.data.data.numberOfLessons; i++) {
          listSubject.push({
            value: i,
            name: `Buổi ${i}`,
          })
        }
        setClassInfo(prop => {
          return { ...prop, subjects: listSubject }
        })
      }

      const getDetailSchoolHour = async () => {
        setClassInfo(prop => {
          return { ...prop, schoolHours: [] }
        })
        const list = await getListClass()
        const schoolHours = list.filter(i => i.key === values.classes._id)[0].option.schoolHours
        setClassInfo(prop => {
          return {
            ...prop,
            schoolHours: schoolHours.map((i: { _id: any; timeFrame: any }) => ({
              value: i._id,
              name: i.timeFrame,
              option: i,
            })),
          }
        })
      }
      getDetailSubject()
      getDetailSchoolHour()
    }
  }, [actionType])

  useEffect(() => {
    if (actionType === "UPDATE" || actionType === "READ") {
      console.log("vaa; ", values)
      form.setFieldsValue({
        name: values.name,
        code: values.code,
        classId: values.classId,
        locationId: values.locationId,
        roomId: values.roomId,
        lecturerId: values.lecturerId,
        tutorIds: values.tutorIds ?? [],
        internIds: values.internIds ?? [],
        employeeTakeCareIds: values.takeCareIds ?? [],
        status: values.status,
        date: moment(values.date),
        dayInWeek: getDayInWeek(values.date),
        schoolHourId: values.schoolHourId,
        shift: convertDate(values.schoolHour[0].studySchedule)?.name,
        studySchedule: values.studySchedule,
        maxDayOfLesson: values.maxDayOfLesson,
      })

      setSelectedOptions({
        lecturerId: [{ id: values?.lecturer._id, value: values?.lecturer._id, label: values?.lecturer?.name }],
        internIds: values.interns.map((e: any) => ({ id: e._id, value: e._id, label: e.name })),
        tutorIds: values.tutors.map((e: any) => ({ id: e._id, value: e._id, label: e.name })),
        employeeTakeCareIds: values.takeCares.map((e: any) => ({ id: e._id, value: e._id, label: e.name })),
      })
    }
    setLoaded(true)
  }, [])

  useEffect(() => {
    if (
      watchSubjectId &&
      watchLocationId &&
      watchRoomId &&
      watchTimeFrames?.every(
        (time: { schoolHourId: string; dayOfWeek: string }) => time?.dayOfWeek && time?.schoolHourId,
      )
    ) {
      lecturerRef.current.triggerFetch()
      internLectureRef.current.triggerFetch()
      tutorRef.current.triggerFetch()
      internRef.current.triggerFetch()
    }
  }, [watchSubjectId, watchLocationId, watchRoomId, watchTimeFrames, form])

  useEffect(() => {
    if (watchLocationId) {
      onTriggerRoomFetch()
    }
  }, [watchLocationId])

  const handleSubmit = async (status?: TeachScheduleStatusType) => {
    const fieldsValidation = await form.validateFields()
    if (!fieldsValidation) {
      return
    }
    const formValues = { ...form.getFieldsValue(), ...(status && { status }) }
    if (actionType === "CREATE") {
      onSubmit(formValues, actionType)
    }
    if (actionType === "UPDATE") {
      onSubmit(formValues, actionType, values._id)
    }
  }

  const handleDelete = async () => {
    try {
      await API.classes.classControllerDeleteOne(values?._id)
      notify?.success({ message: `${ACTION_METHOD_OBJ["DELETE"]} ${DEFINE_MODULE.name} thành công` })
      props.onReload && props.onReload()
      props.onCancel()
    } catch (err: any) {
      notify?.error({
        message: err.error?.message || err.message || err,
      })
    }
  }

  const onResetForm = (): void => {
    // reset sale table
    form.resetFields()
  }

  const getListClass = useCallback(
    async (searchValue?: string) => {
      const response = await API.classes.classControllerGetMany({
        name: searchValue,
        // roomId: values?.roomId,
        // locationId: values?.locationId,
      })

      return (
        response?.data?.data?.map((item: any) => ({
          value: item._id,
          key: item._id,
          name: item.name,
          option: item,
        })) || []
      )
    },

    [watchClassId, actionType],
  )

  const onSelectClass = (option: SelectOptionSearchProps) => {
    const classOption = option.option as ClassDto
    const listSubject = []
    // @ts-ignore
    for (let i = 1; i <= classOption.subject.numberOfLessons; i++) {
      listSubject.push({
        value: i,
        name: `Buổi ${i}`,
      })
    }
    const classInfoInput: ClassInfoProps = {
      locations: [
        {
          value: classOption.location._id,
          name: classOption.location.name,
        },
      ],
      rooms: [],
      // lecturer: [
      //   {
      //     value: classOption.lecturer._id,
      //     name: classOption.lecturer.name,
      //   },
      // ],
      takeCares:
        classOption.takeCares?.map(item => ({
          value: item._id,
          name: item.name,
        })) || [],
      tutors:
        classOption.tutors.map(tutor => ({
          value: tutor._id,
          name: tutor.name,
        })) || [],
      interns:
        classOption.interns.map(intern => ({
          value: intern._id,
          name: intern.name,
        })) || [],
      schoolHours:
        classOption.schoolHours.map(schoolHour => ({
          value: schoolHour._id,
          name: schoolHour.timeFrame,
          option: schoolHour,
        })) || [],
      subjects: listSubject,
    }
    form.setFieldsValue({
      // roomId: classOption.roomId,
      locationId: classOption.locationId,
      // lecturerId: classOption.lecturerId,
      tutorId: classOption.tutorIds,
      takeCareId: classOption.takeCareIds,
      internId: classOption.internIds,
    })

    setClassInfo(classInfoInput)
    setDisableField(false)
  }

  const inputs = [
    {
      inputType: "selectsearch",
      name: "classId",
      label: "Lớp",
      fetchOptions: getListClass,
      selectTrigger: onSelectClass,
      rules: [{ required: true, message: "Chọn lớp" }],
    },
    {
      inputType: "select",
      name: "studySchedule",
      label: "Buổi",
      placeholder: "---",
      options: classInfo.subjects,
      disabled: actionType === "CREATE" && disabledField,
      rules: [{ required: true }],
    },
    {
      inputType: "datetime",
      name: "date",
      label: "Ngày",
      selectTrigger: onSelectDate,
      disabled: actionType === "CREATE" && values,
    },
    {
      inputType: "text",
      name: "dayInWeek",
      label: "Thứ",
      style: { marginLeft: 105, width: 100 },
      readOnlyValue: "",
    },
    {
      inputType: "select",
      name: "schoolHourId",
      label: "Ca",
      placeholder: "---",
      options: classInfo.schoolHours,
      selectTrigger: onSelectSchoolHour,
      rules: [{ required: true, message: "Chọn ca" }],
    },
    {
      inputType: "text",
      name: "shift",
      label: "Loại ca",
      style: { marginLeft: 105, width: 100 },
      readOnlyValue: "",
    },
  ]

  const studentReportInputs = [
    {
      inputType: "input",
      name: "__studentReport2",
      label: "Sĩ số tối đa",
      placeholder: "0",
      disabled: true,
    },
    {
      inputType: "input",
      name: "__studentReport3",
      label: "Ghi danh",
      placeholder: "0",
      disabled: true,
    },
    {
      inputType: "input",
      name: "__studentReport4",
      label: "Đã tuyển",
      placeholder: "0",
      disabled: true,
    },
    {
      inputType: "input",
      name: "__studentReport5",
      label: "Chuyển vào",
      placeholder: "0",
      disabled: true,
    },
    {
      inputType: "input",
      name: "__studentReport6",
      label: "Chuyển đi",
      placeholder: "0",
      disabled: true,
    },
  ]

  const revenueReportInputs = [
    {
      inputType: "number",
      name: "maxDayOfLesson",
      label: "Sĩ số tối đa buổi",
      placeholder: "0",
      // disabled: true,
    },
    {
      inputType: "input",
      name: "__studentReport4",
      label: "Đã tuyển",
      placeholder: "0",
      disabled: true,
    },
    {
      inputType: "input",
      name: "__studentReport4",
      label: "Học bù",
      placeholder: "0",
      disabled: true,
    },
    {
      inputType: "input",
      name: "__studentReport4",
      label: "Vắng",
      placeholder: "0",
      disabled: true,
    },
    {
      inputType: "input",
      name: "__studentReport4",
      label: "Thực học dự kiến",
      placeholder: "11",
      disabled: true,
    },
  ]

  const states = Object.keys(TEACH_SCHEDULE_STATUS).map(e => ({
    label: TEACH_SCHEDULE_STATUS[e as keyof typeof TEACH_SCHEDULE_STATUS],
    active: e === values?.status,
  }))

  // console.log("formValues", { ...form.getFieldsValue() || values })

  const renderContent = () => {
    return (
      <>
        <Row gutter={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <EntityStates states={states} />
        </Row>

        <Typography.Title level={5} style={{ marginBottom: "20px", marginTop: "20px" }}>
          Thông tin buổi học
        </Typography.Title>
        <Row gutter={12}>
          <Col span={11}>
            <Row>
              {take(inputs, inputs.length - 3).map(item => item && <FormItemData key={item.name} {...item} />)}

              {takeRight(inputs, 3).map(item => item && <FormItemData key={item.name} {...item} />)}
            </Row>
          </Col>

          <Col span={11} offset={2}>
            <Row>
              <Col span={24}>
                <FormItem name="locationId" label="Cơ sở" rules={[{ required: true, message: "Chọn cơ sở giảng dạy" }]}>
                  <DebounceSelect
                    selectTrigger={onTriggerRoomFetch}
                    placeholder="Chọn cơ sở giảng dạy"
                    fetchOptions={getListLocation}
                    disabled={actionType === "READ"}
                  />
                </FormItem>
              </Col>

              <Col span={24}>
                <FormItem name="roomId" label="Phòng học" rules={[{ required: true, message: "Chọn phòng học" }]}>
                  <DebounceSelect
                    ref={roomRef}
                    placeholder="Chọn phòng học"
                    fetchOptions={getListRoom}
                    disabled={actionType === "READ"}
                  />
                </FormItem>
              </Col>

              <Col span={24}>
                <FormItem name="lecturerId" label="Giáo viên" rules={[{ required: true, message: "Chọn giáo viên" }]}>
                  <DebounceSelect
                    ref={lecturerRef}
                    placeholder="Chọn giáo viên"
                    fetchOptions={() => getListAvailable(["LECTURER"])}
                    disabled={actionType === "READ"}
                    selectedOptions={selectedOptions?.lecturerId}
                    dropdownRender={(menu: ReactElement) => (
                      <>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={() => {
                              setMoreStaffName("lecturerId")
                              openMoreStaff()
                            }}
                          >
                            Xem thêm ngoài khả dụng
                          </Button>
                        </Space>
                      </>
                    )}
                  />
                </FormItem>
              </Col>

              <Col span={24}>
                <FormItem
                  name="tutorIds"
                  label="Trợ giảng"
                  // rules={[{ required: true, message: "Chọn Trợ giảng" }]}
                >
                  <DebounceSelect
                    ref={tutorRef}
                    mode="multiple"
                    placeholder="Chọn Trợ giảng"
                    selectedOptions={selectedOptions?.tutorIds}
                    fetchOptions={() => getListAvailable(["LECTURER", "TUTOR"])}
                    disabled={actionType === "READ"}
                    dropdownRender={(menu: ReactElement) => (
                      <>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={() => {
                              setMoreStaffName("tutorIds")
                              openMoreStaff()
                            }}
                          >
                            Xem thêm ngoài khả dụng
                          </Button>
                        </Space>
                      </>
                    )}
                  />
                </FormItem>
              </Col>

              <Col span={24}>
                <FormItem
                  name="internIds"
                  label="Thực tập"
                  // rules={[{ required: true, message: "Chọn Thực tập" }]}
                >
                  <DebounceSelect
                    ref={internRef}
                    selectedOptions={selectedOptions?.internIds}
                    mode="multiple"
                    placeholder="Chọn Thực tập"
                    fetchOptions={() => getListAvailable(["LECTURER", "INTERN_LECTURER"])}
                    disabled={actionType === "READ"}
                    dropdownRender={(menu: ReactElement) => (
                      <>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={() => {
                              setMoreStaffName("internIds")
                              openMoreStaff()
                            }}
                          >
                            Xem thêm ngoài khả dụng
                          </Button>
                        </Space>
                      </>
                    )}
                  />
                </FormItem>
              </Col>

              <Col span={24}>
                <FormItem
                  name="employeeTakeCareIds"
                  label="Chăm sóc lớp"
                  // rules={[{ required: true, message: "Chọn Chăm sóc lớp" }]}
                >
                  <DebounceSelect
                    ref={internLectureRef}
                    mode="multiple"
                    selectedOptions={selectedOptions?.employeeTakeCareIds}
                    placeholder="Chọn Chăm sóc lớp"
                    fetchOptions={() => getListAvailable(["LECTURER", "TUTOR", "INTERN"])}
                    disabled={actionType === "READ"}
                    dropdownRender={(menu: ReactElement) => (
                      <>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={() => {
                              setMoreStaffName("employeeTakeCareIds")
                              openMoreStaff()
                            }}
                          >
                            Xem thêm ngoài khả dụng
                          </Button>
                        </Space>
                      </>
                    )}
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={11}>
            <Typography.Title level={5} style={{ marginBottom: "20px", marginTop: "20px" }}>
              Theo dõi học viên theo lớp
            </Typography.Title>
            <Row>{studentReportInputs.map(item => item && <FormItemData key={item.name} {...item} />)}</Row>
          </Col>
          <Col span={11} offset={2}>
            <Typography.Title level={5} style={{ marginBottom: "20px", marginTop: "20px" }}>
              Theo dõi học viên theo buổi
            </Typography.Title>
            <Row>{revenueReportInputs.map(item => item && <FormItemData key={item.name} {...item} />)}</Row>
          </Col>
        </Row>
      </>
    )
  }

  const renderFooter = () => {
    return (
      <>
        <>
          {["INIT", "ACTIVE"].includes(values?.status as TeachScheduleStatusType) && (
            <Button loading={loadingBtn} type="primary" danger onClick={() => handleSubmit("CANCELED")}>
              Từ chối
            </Button>
          )}

          {["INIT", "CANCELED"].includes(values?.status as TeachScheduleStatusType) && (
            <Button loading={loadingBtn} type="primary" onClick={() => handleSubmit("ACTIVE")}>
              Duyệt
            </Button>
          )}
        </>
        {/* )} */}
        {props.actionType === "CREATE" && <Button onClick={() => onResetForm()}>Làm mới</Button>}
        <Button loading={loadingBtn} type="primary" onClick={() => handleSubmit()}>
          {props.actionType === "CREATE" ? "Tạo mới" : "Cập nhật"}
        </Button>
      </>
    )
  }

  return (
    <>
      <Modal
        bodyStyle={{ background: "#F0F2F5" }}
        width={1350}
        destroyOnClose
        maskClosable={false}
        title={`${ACTION_METHOD_OBJ[actionType]} ${DEFINE_MODULE.name}`}
        visible={modalVisible}
        onCancel={() => {
          onResetForm()
          onCancel()
        }}
        footer={renderFooter()}
      >
        <Card style={{ paddingRight: "20px" }}>
          <Form labelAlign="left" labelWrap {...layout} form={form}>
            {renderContent()}
          </Form>
        </Card>
        <MoreStaffModal
          visible={moreStaffModal}
          name={moreStaffName}
          onClose={closeMoreStaff}
          onSubmit={(name, item) => {
            if (!name) return

            if (name === "lecturerId") {
              form.setFieldsValue({ lecturerId: item.value })
            } else {
              const currentValue = form.getFieldValue(name) ?? []
              form.setFieldsValue({ [name]: [...currentValue, item.value] })
            }

            setSelectedOptions(prev => ({ ...prev, [name]: [...(prev[name] ?? []), item] }))
          }}
        />
      </Modal>
    </>
  )
})

export default memo(UpsertForm)
