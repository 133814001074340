import { API } from "@/network/http"
import { SchoolHourDto } from "@/__generated__/api-v1"
import { sortBy } from "lodash"
import { useEffect, useState } from "react"

type SchoolHourQuery = Parameters<typeof API.schoolHours.schoolHourControllerGetMany>["0"]
export const useSchoolHours = (init = true) => {
  const [schoolHours, setSchoolHours] = useState<SchoolHourDto[]>([])

  const fetchSchoolHours = async (query: SchoolHourQuery = { limit: "500" }) => {
    const response = await API.schoolHours.schoolHourControllerGetMany(query)
    const sortedData = sortBy(response.data.data, ["startHour", "endHour"])
    setSchoolHours(sortedData)
    return sortedData
  }

  useEffect(() => {
    if (init) {
      fetchSchoolHours(undefined)
    }
  }, [init])

  return { fetchSchoolHours, schoolHours }
}
