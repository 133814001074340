// import package
import { PAGINATION_DEFAULT } from "@/constants"
// import app component
import { API } from "@/network/http"
import { AvailabilityDto } from "@/__generated__/api-v1"
import { PageContainer } from "@ant-design/pro-layout"
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-table"
import { Empty, Form, notification, TablePaginationConfig, Tag } from "antd"
import { FilterValue } from "antd/lib/table/interface"
import moment from "moment"
import { FC, useEffect, useRef, useState } from "react"
import { DEFINE_MODULE } from "../constants"
import { SearchBar } from "./components"

const List: FC = () => {
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<AvailabilityDto[]>([])
  const [values, setValues] = useState<any>()
  const actionRef = useRef<ActionType>()
  const [form] = Form.useForm()

  const [notifyApi, contextHolder] = notification.useNotification()

  // get list data
  const fetchData = async (queries: any) => {
    const response = await API.availability.availabilityControllerCheck({ ...queries })
    setDataSource(response?.data?.data ?? [])
  }

  const handleChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
    if (!pagination) {
      fetchData({
        ...filters,
        fromDate: filters.fromDate ?? moment().startOf("day"),
        toDate: filters.toDate ?? moment().endOf("day"),
        offset: 0,
      })
    }
    const offSetField = ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? PAGINATION_DEFAULT.limit)
    fetchData({
      ...filters,
      fromDate: filters.fromDate ?? moment().startOf("day"),
      toDate: filters.toDate ?? moment().endOf("day"),
      offset: offSetField,
    })
  }

  useEffect(() => {
    fetchData({
      fromDate: moment().startOf("day"),
      toDate: moment().endOf("day"),
      offset: 0,
    })
  }, [])

  // columns list
  const columns: ProColumns<AvailabilityDto>[] = [
    {
      title: "Giáo viên",
      dataIndex: ["employee"],
      search: false,
      render: (_, entity) => {
        let employeeName = ""
        // @ts-ignore
        if (entity?.employee && entity?.employee[0]) {
          // @ts-ignore
          employeeName = entity?.employee[0].name
        }
        return employeeName
      },
    },
    {
      dataIndex: ["date"],
      title: "Tháng",
      search: false,
      render: (_, entity) => {
        return (
          <>
            {new Date(entity.fromDate).getMonth() + 1}/{new Date(entity.fromDate).getFullYear()}
          </>
        )
      },
    },
    {
      dataIndex: ["role"],
      title: "Chức vụ",
      search: false,
      width: 200,
      render: (_, entity) => {
        // @ts-ignore
        const data = entity?.teacher_roles?.map((item: any) => <Tag>{item.name}</Tag>)
        return <>{data}</>
      },
    },
    {
      dataIndex: "maxNumberOfShiftsInMonth",
      title: "Đăng ký",
      search: false,
    },
    {
      dataIndex: "numberOfShiftsInMonth",
      title: "Đã nhận",
      search: false,
    },
    {
      dataIndex: "emptyNumberOfShiftsInMonth",
      title: "Trống",
      search: false,
    },
    {
      dataIndex: "priorityLocations",
      title: "Cơ sở ưu tiên",
      search: false,
      render: (_, entity) => {
        // @ts-ignore
        const data = entity.priorityLocations?.map((item: any) => <Tag>{item.name}</Tag>)
        return <>{data}</>
      },
    },
    {
      dataIndex: "availabilityLocation",
      title: "Cơ sở có thể nhận lịch",
      search: false,
      render: (_, entity) => {
        // @ts-ignore
        const data = entity.availableLocations?.map((item: any) => <Tag>{item.name}</Tag>)
        return <>{data}</>
      },
    },
    {
      dataIndex: "note",
      title: "Lưu ý khác",
      search: false,
    },
  ]

  return (
    <PageContainer>
      <SearchBar triggerSearch={handleChange} />
      <ProTable<AvailabilityDto>
        headerTitle={`Danh sách ${DEFINE_MODULE.name}`}
        actionRef={actionRef}
        bordered
        rowKey={record => JSON.stringify(record)}
        // search={false}
        dataSource={dataSource}
        onChange={handleChange}
        options={{ density: false, fullScreen: false }}
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} trên ${total} ${DEFINE_MODULE.name}`,
        }}
        toolBarRender={false}
        columns={columns}
        scroll={{ x: 1300, y: 430 }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Không có dữ liệu"} />,
        }}
      />
    </PageContainer>
  )
}

export default List
