import {Table, TableProps} from "@/components/antd/table/Table";
import {ACTION_METHOD_OBJ} from "@/constants";
import {API} from "@/network/http";
import {CRUD_ACTION} from "@/types";
import {formatToLocalDate} from "@/utils";
import {UserDto} from "@/__generated__/api-v1";
import {DeleteTwoTone, EditTwoTone} from "@ant-design/icons";
import type {ActionType, ProColumns} from "@ant-design/pro-table";
import {notification, Popconfirm, Space, Tag, Tooltip} from "antd";
import {FC, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {DEFINE_MODULE, UserStatus, USER_STATUS_ENUM} from "../constants";
import UpsertForm from "./UpsertForm";

const List: FC = () => {
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [isCreate, setCreate] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [values, setValues] = useState<any>();
  const actionRef = useRef<ActionType>();

  const [notifyApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();

  const onClickEditButton = async (id: string) => {
    const detailData = await API.users.userControllerGetOne(id);
    setValues(detailData.data.data);
    setUpdate(true);
  };

  const onClickDeleteButton = async (id: string) => {
    try {
      await API.users.userControllerDeleteOne(id);
      notifyApi.success({message: `${ACTION_METHOD_OBJ['DELETE']} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err?.error?.message || err?.message || err,
      });
    }
  };

  const onSubmit = async (val: any, type: CRUD_ACTION, _id?: string) => {
    try {
      setLoadingBtn(true);
      const reqData =
        type === "CREATE"
          ? await API.users.userControllerCreateOne(val)
          : await API.users.userControllerUpdateOne(_id || "", val);
      if (reqData && reqData.data) {
        if (type === "CREATE") setCreate(false);
        if (type === "UPDATE") setUpdate(false);
        notifyApi.success({
          message: "Thông báo",
          description: `${ACTION_METHOD_OBJ[type]} thành công`,
          placement: "topRight",
        });
        actionRef.current?.reload();
      }
    } finally {
      setLoadingBtn(false);
    }
  };

  const fetchData = async (queries: Parameters<typeof API.users.userControllerGetMany>["0"]) => {
    return (await API.users.userControllerGetMany({...queries})).data;
  };

  const onDeleteMany = async (ids: string[]) => {
    try {
      await API.users.userControllerDeleteMany(ids);
      notifyApi.success({message: `${ACTION_METHOD_OBJ['DELETE']} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const onArchiveMany = async (ids: string[]) => {
    try {
      await API.users.userControllerUpdateMany({ids, isArchived: true});
      notifyApi.success({message: `${ACTION_METHOD_OBJ['ARCHIVE']} ${ids.length} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
    // dispatch(archiveManyUser({ ids, isArchived: true }));
  };

  const onUnarchiveMany = async (ids: string[]) => {
    try {
      await API.users.userControllerUpdateMany({ids, isArchived: false});
      notifyApi.success({message: `${ACTION_METHOD_OBJ['UNARCHIVE']} ${ids.length} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
    // dispatch(unarchiveManyUser({ ids, isArchived: false }));
  };

  const columns: ProColumns<UserDto>[] = [
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      fieldProps: {
        placeholder: "Tên",
      },
    },
    {
      title: "Tên đăng nhập",
      dataIndex: "username",
      key: "username",
      fieldProps: {
        placeholder: "Tên đăng nhập",
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "SĐT",
      dataIndex: "phone",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, entity) => {
        const data = USER_STATUS_ENUM[`${entity?.status as UserStatus}`];
        return (
          <Tag color={data?.color} key={data?.key}>
            {data?.name}
          </Tag>
        );
      },
      search: false,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      hideInTable: true,
      valueType: 'select',
      valueEnum: USER_STATUS_ENUM
    },
    {
      title: "Khởi tạo",
      key: "createdAt",
      dataIndex: "createdAt",
      search: false,
      render: (_, entity) => <p>{formatToLocalDate(entity.createdAt || "")}</p>,
    },
    {
      title: "Cập nhật",
      key: "updatedAt",
      dataIndex: "updatedAt",
      search: false,
      render: (_, entity) => <p>{formatToLocalDate(entity.updatedAt || "")}</p>,
    },
    {
      title: "Thao tác",
      align: "center",
      search: false,
      fixed: "right",
      width: 80,
      render: (_, entity) => {
        return (
          <Space size={10}>
            <Tooltip title="Cập nhật người dùng">
              <EditTwoTone onClick={() => onClickEditButton(entity._id)} />
            </Tooltip>
            <Tooltip title="Xoá người dùng">
              <Popconfirm
                title="Bạn có chắc rằng muốn xoá người dùng này không?"
                onConfirm={() => onClickDeleteButton(entity._id)}
                okText="Có"
                cancelText="Không"
              >
                <DeleteTwoTone twoToneColor={"red"} />
              </Popconfirm>
            </Tooltip>

            {/* <EditAction link={Paths.USER.EDIT.PATH.replace(":id", entity._id)} />
            <DeleteAction
              onOk={() => {
                dispatch(deleteOneUser({ id: entity._id }))
              }}
            /> */}
          </Space>
        );
      },
    },
  ];

  const protableProps: TableProps = {
    actionRef,
    DEFINE_MODULE,
    contextHolder,
    fetchData,
    columns,
    deleteMany: onDeleteMany,
    archiveMany: onArchiveMany,
    unArchiveMany: onUnarchiveMany,
    createForm: {
      isCreate,
      setCreate,
      component: (
        <UpsertForm
          notify={notifyApi}
          key="CREATE"
          onCancel={() => setCreate(false)}
          modalVisible={isCreate}
          onSubmit={onSubmit}
          actionType="CREATE"
          loadingBtn={loadingBtn}
        />
      ),
    },
    updateForm: {
      isUpdate,
      component: (
        <UpsertForm
          notify={notifyApi}
          key="UPDATE"
          onCancel={() => setUpdate(false)}
          modalVisible={isUpdate}
          onSubmit={onSubmit}
          actionType="UPDATE"
          loadingBtn={loadingBtn}
          values={values}
        />
      ),
    },
  };

  return <Table {...protableProps} />;
};

export default List;
