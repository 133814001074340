import { Checkbox, Col, DatePicker, Form, Input, InputNumber, Select, TimePicker, Typography } from "antd"
import { SelectOptionProps } from "../../../typing/common"
import { DebounceSelect } from "./DebounceSelect"

const FormItem = Form.Item
const { Option } = Select

export default function FormItemData(props: any) {
  const {
    name,
    label,
    placeholder,
    rules,
    minLength,
    maxLength,
    min,
    max,
    style,
    format,
    disabled,
    readOnly,
    mode,
    colSpan,
    readOnlyValue,
    selectTrigger,
  } = props
  switch (props.inputType) {
    case "text":
      return (
        <FormItem label={label} shouldUpdate={(prevValues, curValues) => prevValues[name] !== curValues[name]}>
          {({ getFieldValue }) => {
            const textField = getFieldValue(name)
            return (
              <Typography.Text style={style} className="ant-form-text" type="secondary">
                {textField ? textField : readOnlyValue}
              </Typography.Text>
            )
          }}
        </FormItem>
      )
    case "input":
      return (
        <Col span={colSpan || 24}>
          <FormItem name={name} label={label} rules={rules}>
            <Input
              placeholder={placeholder}
              min={min}
              max={max}
              maxLength={maxLength}
              minLength={minLength}
              readOnly={readOnly}
              disabled={disabled}
            />
          </FormItem>
        </Col>
      )
    case "number":
      return (
        <Col span={colSpan || 24}>
          <FormItem name={name} label={label} rules={rules}>
            <InputNumber
              style={{ width: "100%" }}
              placeholder={placeholder}
              min={min}
              max={max}
              maxLength={maxLength}
              minLength={minLength}
              readOnly={readOnly}
              disabled={disabled}
            />
          </FormItem>
        </Col>
      )
    case "textarea":
      return (
        <Col span={colSpan || 24}>
          <FormItem name={name} label={label} rules={rules}>
            <Input.TextArea placeholder={placeholder} maxLength={maxLength} />
          </FormItem>
        </Col>
      )
    case "select":
      // eslint-disable-next-line no-case-declarations
      const { options, inputType, ...rest } = props
      if (rest.selectTrigger) delete rest.selectTrigger
      return (
        <Col span={colSpan || 24}>
          <FormItem name={name} label={label} rules={rules}>
            <Select
              mode={mode}
              placeholder={placeholder}
              onSelect={(val: any) => {
                if (selectTrigger) {
                  selectTrigger(options.find((i: any) => i.value === val))
                }
              }}
              {...rest}
            >
              {options.map((opt: SelectOptionProps) => (
                <Option key={opt.key} value={opt.value}>
                  {opt.name}
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>
      )
    case "selectsearch":
      // eslint-disable-next-line no-case-declarations
      const { fetchOptions, ref } = props
      return (
        <Col span={colSpan || 24}>
          <FormItem name={name} label={label} rules={rules}>
            <DebounceSelect
              disabled={disabled}
              selectTrigger={selectTrigger}
              ref={ref}
              mode={mode}
              placeholder={placeholder}
              fetchOptions={fetchOptions}
            />
          </FormItem>
        </Col>
      )
    case "password":
      return (
        <Col span={colSpan || 24}>
          <FormItem name="password" label="Mật khẩu" rules={rules}>
            <Input.Password placeholder="Nhập mật khẩu" maxLength={maxLength} />
          </FormItem>
        </Col>
      )
    case "checkbox":
      return (
        <Col span={colSpan || 24}>
          <FormItem name={name} label={label} valuePropName="checked">
            <Checkbox />
          </FormItem>
        </Col>
      )
    case "time":
      return (
        <Col span={colSpan || 24}>
          <FormItem name={name} label={label} rules={rules}>
            <TimePicker
              disabled={disabled}
              placeholder={placeholder}
              style={style || { width: "100%" }}
              format={format ?? "HH:mm:ss"}
            />
          </FormItem>
        </Col>
      )
    case "datetime":
      return (
        <Col span={colSpan || 24}>
          <FormItem name={name} label={label} rules={rules}>
            {/* @ts-ignore  */}
            <DatePicker
              disabled={disabled}
              placeholder={placeholder}
              style={style || { width: "100%" }} // { width: '100%' }
              format={format ? format : props.showTime ? "DD-MM-YYYY HH:mm" : "DD-MM-YYYY"} // "DD-MM-YYYY HH:mm"
              showTime={props.showTime ?? { format: "HH:mm" }} // { format: 'HH:mm' }
              onChange={selectTrigger}
            />
          </FormItem>
        </Col>
      )

    default:
      return <></>
  }
}
