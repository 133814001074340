import { UserRouter } from "@/modules/core/pages/user"
import { Route, Routes } from "react-router-dom"
import { MainLayout } from "./components/layout"
import { Paths } from "./constants"
import { Login } from "./modules/core/pages/authentication/Login"
import { Router as RoleRouter } from "./modules/core/pages/user-role/Router"
import { EduPaths, EduRouter } from "./modules/edu"
import { DashboardRouter } from "./modules/edu/pages/dashboard"
import { HrPaths, HrRouter } from "./modules/hr"
import history from "./navigation"
import { HistoryRouter } from "./navigation/HistoryRouter"
import { RequireAuth } from "./navigation/RequireAuth"

export const Navigator = () => {
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path={Paths.PUBLIC.LOGIN.PATH} element={<Login />} />
        {/* <Route path={Paths.PUBLIC.FORGOT_PASSWORD.PATH} element={<ForgotPassword />} /> */}
        {/* <Route path={Paths.PUBLIC.RESET_PASSWORD.PATH} element={<ResetPassword />} /> */}

        <Route element={<RequireAuth />}>
          <Route element={<MainLayout />}>
            <Route path={Paths.DASHBOARD} element={<DashboardRouter />} />

            {/* Core Routes */}
            {/* <Route path={`${Paths.PROFILE.DETAIL.PATH}/*`} element={<ProfileRouter />} /> */}
            <Route path={`${Paths.USER.LIST.PATH}/*`} element={<UserRouter />} />
            <Route path={`${Paths.ROLE.LIST.PATH}/*`} element={<RoleRouter />} />
            {/* End Core Routes */}

            {/* Edu Routes */}
            <Route path={`${EduPaths.EDU}`} element={<EduRouter />} />
            {/* End Core Routes */}

            <Route path={`${HrPaths.HR}`} element={<HrRouter />} />

            {/* {profile?.isRootAdmin && <Route path={`${Paths.PERMISSION.LIST.PATH}/*`} element={<PermissionRouter />} />} */}
          </Route>
        </Route>
        <Route path="*" element={<b>Not found</b>}></Route>
      </Routes>
    </HistoryRouter>
  )
}
