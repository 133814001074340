import { postRequest } from "../http"

interface ILoginData {
  username: string
  password: string
}

interface IForgotPassword {
  email: string
}

interface IResetPassword {
  verifyCode: string
  password: string
}

interface IProfileUpdate {
  name: string
  photo: string
  email: string
  phone: string
  password?: string
  settings: object
  filters: Array<any>
}

// interface IUpdateProfile {}

export const loginApi = async (data: ILoginData) => {
  const url = "/auth/login"
  return await postRequest(url, data)
}

export const forgotPasswordApi = async (data: IForgotPassword) => {
  const url = "/auth/forgot-password"
  return await postRequest(url, data)
}

export const resetPasswordApi = async (data: IResetPassword) => {
  const url = "/auth/reset-password"
  return await postRequest(url, data)
}
