import i18n from "i18n-js"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import * as actionTypes from "../../constants/ActionTypes"
import history from "../../navigation"
import {
  archiveManyPermissionApi,
  createActionApi,
  createPermissionApi,
  deleteManyPermissionApi,
  deletePermissionApi,
  getPermissionDetailApi,
  getPermissionsApi,
  updatePermissionApi,
} from "../../network"
import { convertObjectParamsToStringParams, getInitParams } from "../../utils"
import { getPermissionState } from "../selectors"
import { IPermissionState } from "../types"
interface IPayload {
  type: string
  payload: any
}

interface IResponse {
  data: any
  total: number
}

function* getListPermission(action: IPayload) {
  try {
    const { data, total }: IResponse = yield call(getPermissionsApi, action.payload)
    yield history.push(`?${convertObjectParamsToStringParams(action.payload)}`)
    yield put({
      type: actionTypes.GET_LIST_PERMISSION_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({ type: actionTypes.GET_LIST_PERMISSION_FAILED, payload: error })
  }
}

function* getDetailPermission(action: IPayload) {
  try {
    const { data, total }: IResponse = yield call(getPermissionDetailApi, action.payload)

    yield put({
      type: actionTypes.GET_DETAIL_PERMISSION_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({
      type: actionTypes.GET_DETAIL_PERMISSION_FAILED,
      payload: error,
    })
  }
}

function* createPermission(action: IPayload) {
  try {
    yield Promise.all(action.payload.actions.map((item: any) => createActionApi(item))).then(result =>
      createPermissionApi({
        key: action.payload.key,
        name: action.payload.name,
        actionIds: result.map(item => `${item.data._id}`),
      }),
    )

    yield put({
      type: actionTypes.GET_LIST_PERMISSION,
    })

    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "",
        message: "Tạo mới thành công",
        type: "success",
      },
    })

    history.push("/permissions")
  } catch (error) {
    yield put({ type: actionTypes.CREATE_PERMISSION_FAILED, payload: error })
  }
}

function* updatePermission(action: IPayload) {
  const { permissionId, actionListing, key, name }: any = action.payload
  try {
    yield Promise.all(actionListing.map((item: any) => createActionApi(item))).then(result =>
      updatePermissionApi(permissionId, {
        key,
        name,
        actionIds: result.map(item => `${item.data._id}`),
      }),
    )

    yield put({
      type: actionTypes.GET_LIST_PERMISSION,
    })
    yield put({ type: actionTypes.UPDATE_PERMISSION_SUCCESS })
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "",
        message: "Cập nhật thành công",
        type: "success",
      },
    })
    history.push("/permissions")
  } catch (error) {
    yield put({ type: actionTypes.CREATE_PERMISSION_FAILED, payload: error })
  }
}

function* deleteOnePermisison(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const permisison: IPermissionState = yield select<any>(getPermissionState)

    // validate and set requesting
    if (permisison.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(deletePermissionApi, action.payload.id)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteAction.deleteSuccess"),
          message: i18n.t("common.deleteAction.deleteSuccess"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_PERMISSION,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_PERMISSION,
      payload: { isDeleteRequest: false },
    })
  }
}

function* deleteManyPermission(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const permission: IPermissionState = yield select<any>(getPermissionState)

    // validate and set requesting
    if (permission.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_PERMISSION,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(deleteManyPermissionApi, action.payload.ids)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteManyAction.title"),
          message: i18n.t("common.deleteManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_PERMISSION,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_PERMISSION,
      payload: { isDeleteRequest: false },
    })
  }
}

function* archiveManyPermisison(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const permisison: IPermissionState = yield select<any>(getPermissionState)

    // validate and set requesting
    if (permisison.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_PERMISSION,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(archiveManyPermissionApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.archiveManyAction.title"),
          message: i18n.t("common.archiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_PERMISSION,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_PERMISSION,
      payload: { isUpdateRequest: false },
    })
  }
}

function* unarchiveManyPermisison(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const permisison: IPermissionState = yield select<any>(getPermissionState)

    // validate and set requesting
    if (permisison.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_PERMISSION,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(archiveManyPermissionApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.unarchiveManyAction.title"),
          message: i18n.t("common.unarchiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_PERMISSION,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_PERMISSION,
      payload: { isUpdateRequest: false },
    })
  }
}

export default function* root() {
  yield all([
    takeLatest(actionTypes.GET_LIST_PERMISSION, getListPermission),
    takeLatest(actionTypes.GET_DETAIL_PERMISSION, getDetailPermission),
    takeLatest(actionTypes.CREATE_PERMISSION, createPermission),
    takeLatest(actionTypes.UPDATE_PERMISSION, updatePermission),
    takeLatest(actionTypes.DELETE_ONE_PERMISSION, deleteOnePermisison),
    takeLatest(actionTypes.DELETE_MANY_PERMISSION, deleteManyPermission),
    takeLatest(actionTypes.ARCHIVE_MANY_PERMISSION, archiveManyPermisison),
    takeLatest(actionTypes.UNARCHIVE_MANY_PERMISSION, unarchiveManyPermisison),
  ])
}
