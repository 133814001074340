import FormItemData from "@/components/antd/form";
import {ACTION_METHOD_OBJ} from "@/constants";
import {layout} from "@/constants/layout";
import {WorkingScheduleStatus} from "@/modules/edu/pages/operation/working-schedule/types";
import {useEmployees} from "@/modules/hr/pages/employee/hooks";
import {API} from "@/network/http";
import {BaseUpsertFormProps} from "@/typing/common";
import {EmployeeDto} from "@/__generated__/api-v1";
import {Button, Card, Checkbox, Col, Divider, Form, Modal, Row} from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import {first, take, takeRightWhile} from "lodash";
import moment from "moment";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {DEFINE_MODULE} from "../constants";

const UpsertForm: React.FC<BaseUpsertFormProps> = forwardRef((props, ref) => {
  const {modalVisible, onCancel, onSubmit, actionType, values, loadingBtn} = props;
  const [form] = Form.useForm();
  const [formList, setFormList] = useState<any>([]);

  // as the second argument
  useImperativeHandle(ref, () => ({
    onResetData() {
      // reset sale table
      form.resetFields();
    },
  }));

  const {employees, fetchEmployees} = useEmployees();
  const getEmployees = async (searchValue?: string) => {
    const data = await fetchEmployees({name: searchValue});
    return (
      data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const getLocations = async (searchValue?: string) => {
    const response = await API.locations.locationControllerGetMany({name: searchValue, limit: "100"});
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  useEffect(() => {
    if (actionType === "UPDATE") {
      console.log({values});
      form.setFieldsValue({
        employeeId: values.employeeId,
        fromDate: moment(values.fromDate),
        toDate: moment(values.toDate),
        maxNumberOfShiftsInMonth: values.maxNumberOfShiftsInMonth,
        numberOfShiftsInMonth: values.numberOfShiftsInMonth,
        note: values.note,
        availableLocationId: first(values.availableLocationIds),
        priorityLocationId: first(values.priorityLocationIds),
        list: values.list || [],
      });

      setFormList(values.list);
    }
  }, []);

  const handleSubmit = async (status?: WorkingScheduleStatus) => {
    const fieldsValidation = await form.validateFields();
    if (!fieldsValidation) {
      return;
    }

    const formValues = {
      ...form.getFieldsValue(),
      list: formList,
      ...(status && {status}),
    };

    if (actionType === "CREATE") {
      onSubmit(formValues, actionType);
    }
    if (actionType === "UPDATE") {
      onSubmit(formValues, actionType, values._id);
    }
  };

  const onResetForm = (): void => {
    // reset sale table
    form.resetFields();
  };

  const watchedEmployeeId = Form.useWatch("employeeId", form);
  const watchedToDate = Form.useWatch("toDate", form);
  useEffect(() => {
    if (watchedEmployeeId) {
      const employee = employees.find(e => e._id === watchedEmployeeId) as EmployeeDto & {
        department: {name: string; _id: string;};
      };
      form.setFieldsValue({deparment: employee?.department.name});
    }
  }, [watchedEmployeeId, employees]);

  useEffect(() => {
    if (watchedToDate) {
      form.setFieldsValue({month: moment(watchedToDate).format("MM/YYYY")});
    }
  }, [watchedToDate]);

  const renderContentList = [
    {
      inputType: "selectsearch",
      name: "employeeId",
      label: "Nhân viên",
      placeholder: "Chọn nhân viên",
      fetchOptions: getEmployees,
      rules: [{required: true, message: "Chọn nhân viên"}],
    },
    {
      inputType: "input",
      name: "deparment",
      label: "Phòng ban",
      placeholder: "---",
      disabled: true,
    },
    {
      inputType: "datetime",
      name: "fromDate",
      label: "Từ ngày",
      placeholder: "Nhập ngày bắt đầu",
      showTime: false,
      rules: [{required: true, message: "Nhập ngày bắt đầu"}],
    },
    {
      inputType: "datetime",
      name: "toDate",
      label: "Tới ngày",
      showTime: false,
      placeholder: "Nhập ngày kết thúc",
      rules: [{required: true, message: "Nhập ngày kết thúc"}],
    },
    {
      inputType: "input",
      name: "month",
      label: "Tháng",
      placeholder: "---",
      disabled: true,
    },
    {
      inputType: "selectsearch",
      name: "priorityLocationId",
      label: "Cơ sở ưu tiên",
      placeholder: "Chọn cơ sở ưu tiên",
      fetchOptions: getLocations,
      rules: [{required: true, message: "Chọn cơ sở ưu tiên"}],
      rightCol: true,
    },
    {
      inputType: "selectsearch",
      name: "availableLocationId",
      label: "Cơ sở có thể nhận lịch",
      placeholder: "Chọn Cơ sở có thể nhận lịch",
      fetchOptions: getLocations,
      rules: [{required: true, message: "Chọn Cơ sở có thể nhận lịch"}],
      rightCol: true,
    },
    {
      inputType: "number",
      name: "maxNumberOfShiftsInMonth",
      label: "Số ca tối đa trong tháng",
      placeholder: "Nhập số ca tối đa",
      rules: [{required: true, message: "Không được để trống"}],
      rightCol: true,
    },
    {
      inputType: "textarea",
      name: "note",
      label: "Lưu ý khác",
      placeholder: "Lưu ý...",
      rightCol: true,
    },
    {
      inputType: "number",
      name: "numberOfShiftsInMonth",
      label: "Số ca đã nhận trong tháng",
      rightCol: true,
      disabled: true,
      // @ts-ignore
      readOnly: true,
    },
  ];

  const handleListChange = (dayOfWeek: string, shift: string) => {
    const list: any = [...formList] || [];
    const index = list.findIndex((value: any) => value.type === shift);
    if (index >= 0) {
      list[index].monday = dayOfWeek === "monday" ? !list[index].monday : list[index].monday;
      list[index].tuesday = dayOfWeek === "tuesday" ? !list[index].tuesday : list[index].tuesday;
      list[index].wednesday = dayOfWeek === "wednesday" ? !list[index].wednesday : list[index].wednesday;
      list[index].thursday = dayOfWeek === "thursday" ? !list[index].thursday : list[index].thursday;
      list[index].friday = dayOfWeek === "friday" ? !list[index].friday : list[index].friday;
      list[index].saturday = dayOfWeek === "saturday" ? !list[index].saturday : list[index].saturday;
      list[index].sunday = dayOfWeek === "sunday" ? !list[index].sunday : list[index].sunday;
    } else {
      list.push({
        type: shift,
        monday: dayOfWeek === "monday",
        tuesday: dayOfWeek === "tuesday",
        wednesday: dayOfWeek === "wednesday",
        thursday: dayOfWeek === "thursday",
        friday: dayOfWeek === "friday",
        saturday: dayOfWeek === "saturday",
        sunday: dayOfWeek === "sunday",
      });
    }

    form.setFieldsValue({
      ...form.getFieldsValue(),
      list,
    });
    setFormList(list);
  };

  type DayOfWeek = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";
  type WorkingShirf = "morning" | "afternoon" | "evening";

  const isChecked = (formList: any, dayOfWeek: DayOfWeek, shift: WorkingShirf): boolean => {
    const item = formList.find((e: any) => e.type === shift) ?? {};
    return typeof item[dayOfWeek] !== "undefined" ? item[dayOfWeek] : false;
  };

  const renderList = () => {
    return (
      <tbody className="custom-reg-table">
        <tr style={{borderBottom: "1px solid #f0f0f0"}}>
          <td style={{textAlign: "left", padding: 5, paddingLeft: 10}}>
            <b>Sáng</b>
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("monday", "morning")}
              checked={isChecked(formList, "monday", "morning")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("tuesday", "morning")}
              checked={isChecked(formList, "tuesday", "morning")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("wednesday", "morning")}
              checked={isChecked(formList, "wednesday", "morning")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("thursday", "morning")}
              checked={isChecked(formList, "thursday", "morning")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("friday", "morning")}
              checked={isChecked(formList, "friday", "morning")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("saturday", "morning")}
              checked={isChecked(formList, "saturday", "morning")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("sunday", "morning")}
              checked={isChecked(formList, "sunday", "morning")}
            />
          </td>
        </tr>
        <tr style={{borderBottom: "1px solid #f0f0f0"}}>
          <td style={{textAlign: "left", padding: 5, paddingLeft: 10}}>
            <b>Chiều</b>
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("monday", "afternoon")}
              checked={isChecked(formList, "monday", "afternoon")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("tuesday", "afternoon")}
              checked={isChecked(formList, "tuesday", "afternoon")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("wednesday", "afternoon")}
              checked={isChecked(formList, "wednesday", "afternoon")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("thursday", "afternoon")}
              checked={isChecked(formList, "thursday", "afternoon")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("friday", "afternoon")}
              checked={isChecked(formList, "friday", "afternoon")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("saturday", "afternoon")}
              checked={isChecked(formList, "saturday", "afternoon")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("sunday", "afternoon")}
              checked={isChecked(formList, "sunday", "afternoon")}
            />
          </td>
        </tr>
        <tr style={{borderBottom: "1px solid #f0f0f0"}}>
          <td style={{textAlign: "left", padding: 5, paddingLeft: 10}}>
            <b>Tối</b>
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("monday", "evening")}
              checked={isChecked(formList, "monday", "evening")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("tuesday", "evening")}
              checked={isChecked(formList, "tuesday", "evening")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("wednesday", "evening")}
              checked={isChecked(formList, "wednesday", "evening")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("thursday", "evening")}
              checked={isChecked(formList, "thursday", "evening")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("friday", "evening")}
              checked={isChecked(formList, "friday", "evening")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("saturday", "evening")}
              checked={isChecked(formList, "saturday", "evening")}
            />
          </td>
          <td style={{textAlign: "center", padding: 5}}>
            <Checkbox
              onChange={() => handleListChange("sunday", "evening")}
              checked={isChecked(formList, "sunday", "evening")}
            />
          </td>
        </tr>
      </tbody>
    );
  };

  const renderContent = () => {
    return (
      <Card>
        <Row gutter={[24, 24]}>
          <Col span={12} push={12} style={{display: "flex", justifyContent: "right"}}>
            <ButtonGroup>
              <Button
                type="primary"
                disabled={(values?.status as WorkingScheduleStatus) !== "INITIAL" && props.actionType === "UPDATE"}
              >
                Dự thảo
              </Button>
              <Button type="primary" disabled={(values?.status as WorkingScheduleStatus) !== "ACCEPTED"}>
                Đã duyệt
              </Button>
              <Button type="primary" disabled={!["CANCELED", "REJECTED"].includes(values?.status)}>
                Huỷ
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[24, 24]}>
          <Col span={12}>
            {take(renderContentList, 5).map(item => item && <FormItemData key={item.name} {...item} />)}
          </Col>
          <Col span={12}>
            {takeRightWhile(renderContentList, "rightCol").map(
              item => item && <FormItemData key={item.name} {...item} />,
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24} offset={4}>
            <table style={{border: "1px solid #f0f0f0"}}>
              <tr style={{background: "#1890ff", color: "#fff"}}>
                <th style={{width: 80, padding: "5px"}}></th>
                <th style={{width: 80, padding: "5px"}}>Thứ 2</th>
                <th style={{width: 80, padding: "5px"}}>Thứ 3</th>
                <th style={{width: 80, padding: "5px"}}>Thứ 4</th>
                <th style={{width: 80, padding: "5px"}}>Thứ 5</th>
                <th style={{width: 80, padding: "5px"}}>Thứ 6</th>
                <th style={{width: 80, padding: "5px"}}>Thứ 7</th>
                <th style={{width: 100, padding: "5px"}}>Chủ nhật</th>
              </tr>
              {renderList()}
            </table>
          </Col>
        </Row>
      </Card>
    );
  };

  const renderFooter = () => {
    return (
      <>
        <Button loading={loadingBtn} type="ghost" danger onClick={() => handleSubmit("ACCEPTED")}>
          Huỷ
        </Button>
        {(values?.status as WorkingScheduleStatus) === "INITIAL" && (
          <>
            <Button loading={loadingBtn} type="primary" danger onClick={() => handleSubmit("CANCELED")}>
              Từ chối
            </Button>
            <Button loading={loadingBtn} type="primary" onClick={() => handleSubmit("ACCEPTED")}>
              Duyệt
            </Button>
          </>
        )}
        {props.actionType === "CREATE" && <Button onClick={() => onResetForm()}>Làm mới</Button>}
        <Button loading={loadingBtn} type="primary" onClick={() => handleSubmit()}>
          {props.actionType === "CREATE" ? "Tạo mới" : "Cập nhật"}
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal
        bodyStyle={{background: "#F0F2F5"}}
        width={1200}
        destroyOnClose
        maskClosable={false}
        title={`${ACTION_METHOD_OBJ[actionType]} ${DEFINE_MODULE.name}`}
        visible={modalVisible}
        onCancel={() => {
          onResetForm();
          onCancel();
        }}
        footer={renderFooter()}
      >
        <Form {...layout} labelWrap form={form}>
          {renderContent()}
        </Form>
      </Modal>
    </>
  );
});

export default UpsertForm;
