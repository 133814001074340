import { Breadcrumbs } from "@/components/Breadcrumbs"
import { useIsWideLayout } from "@/components/layout/menuItems"
import { Layout } from "antd"
import { FC } from "react"
import { Outlet } from "react-router-dom"
import { Header } from "./Header"

const { Content, Footer } = Layout

export const MainLayout: FC = () => {
  const isWideLayout = useIsWideLayout()

  return (
    <Layout className="layout" id="mtb-erp-app">
      <Header />
      <Content style={{ padding: isWideLayout ? "0 10px" : "0 32px", minHeight: "calc(100vh - 134px)" }}>
        <Breadcrumbs />
        <Layout className="site-layout-background" style={{ padding: "12px 0" }}>
          <Content style={{ padding: isWideLayout ? "0 8px" : "0 12px" }}>
            <Outlet />
          </Content>
        </Layout>
      </Content>
      <Footer style={{ textAlign: "center" }}>Copyright ©2022 - My Thuat Bui</Footer>
    </Layout>
  )
}
