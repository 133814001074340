import { DebounceSelect } from "@/components/antd/form/DebounceSelect"
import { SearchBarType } from "@/modules/edu/pages/operation/work-time/type"
import { API } from "@/network/http"
import { SearchOutlined } from "@ant-design/icons"
import { Form } from "antd"
import { memo } from "react"

const RoomSearch: React.FC<SearchBarType> = ({ style, name, label }) => {
  const getListRoom = async (searchValue?: string) => {
    const response = await API.rooms.roomControllerGetMany({
      code: searchValue,
    })
    return (
      response?.data?.data?.map((item: any) => ({
        key: item?._id,
        value: item?._id,
        name: item?.code,
      })) || []
    )
  }

  return (
    <Form.Item name={name} label={label} style={{ marginBottom: "12px" }}>
      <DebounceSelect
        mode="multiple"
        placeholder={
          <>
            <SearchOutlined /> Phòng{" "}
          </>
        }
        fetchOptions={getListRoom}
      />
    </Form.Item>
  )
}

export default memo(RoomSearch)
