import {
  GET_DETAIL_ABSENT_SCHEDULE_SUCCESS,
  GET_LIST_ABSENT_SCHEDULE,
  GET_LIST_ABSENT_SCHEDULE_SUCCESS,
  PAGINATION_DEFAULT,
  SET_LOADING_ABSENT_SCHEDULE,
} from "../../constants"
import { IAbsentScheduleState } from "../types"

interface ActionProps {
  type: string
  payload: any
}

const initState: IAbsentScheduleState = {
  list: {
    absentSchedules: [],
    total: 0,
    limit: PAGINATION_DEFAULT.limit,
    offset: PAGINATION_DEFAULT.offset,
  },
  detail: {
    absentSchedules: {},
  },
  isLoadingRequest: false,
  isDeleteRequest: false,
  isUpdateRequest: false,
  isCreateRequest: false,
}

export const absentSchedule = (state: IAbsentScheduleState = initState, action: ActionProps) => {
  const { type, payload } = action

  switch (type) {
    case GET_LIST_ABSENT_SCHEDULE: {
      return {
        ...state,
      }
    }
    case GET_LIST_ABSENT_SCHEDULE_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          absentSchedules: payload.data,
          total: payload.total,
        },
      }
    }

    case GET_DETAIL_ABSENT_SCHEDULE_SUCCESS: {
      return {
        ...state,
        detail: {
          ...state.detail,
          absentSchedules: payload.data,
        },
      }
    }

    case SET_LOADING_ABSENT_SCHEDULE: {
      return {
        ...state,
        ...payload,
      }
    }

    default: {
      return state
    }
  }
}
