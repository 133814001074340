import { AbsentScheduleStatus } from "@/modules/edu/pages/operation/absent-schedule/types"

export const DEFINE_MODULE = {
  name: "đăng ký lịch nghỉ",
  fields: "absentSchedules",
}

export const statuses: Record<AbsentScheduleStatus, string> = {
  ACCEPTED: "Đã duyệt",
  CANCELED: "Đã huỷ",
  INITIAL: "Dự thảo",
  REJECTED: "Từ chối",
}
