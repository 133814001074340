import { GET_USER_PROFILE_SUCCESS, LOGIN_SUCCESS } from "../../constants"

interface IAuthState {
  accessToken: string | null
  data: object | null
}

interface ActionProps {
  type: string
  payload: PayloadProps
}

interface PayloadProps {
  accessToken?: string
  data?: object
}

const initState: IAuthState = {
  accessToken: null,
  data: null,
}

export const profile = (state = initState, action: ActionProps) => {
  const { type, payload } = action

  switch (type) {
    case LOGIN_SUCCESS: {
      const { accessToken } = payload
      return {
        ...state,
        accessToken,
      }
    }

    case GET_USER_PROFILE_SUCCESS: {
      return { ...state, data: payload }
    }
    default: {
      return state
    }
  }
}
