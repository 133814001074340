/* eslint-disable react-hooks/exhaustive-deps */
import FormItemData from "@/components/antd/form"
import { DebounceSelect } from "@/components/antd/form/DebounceSelect"
import { EntityStates } from "@/components/EntityStates"
import { ACTION_METHOD_OBJ } from "@/constants"
import { layout } from "@/constants/layout"
import { MoreStaffModal } from "@/modules/edu/pages/configuration/classes/components/MoreStaffModal"
import StudyScheduleTable from "@/modules/edu/pages/configuration/classes/components/studyScheduleTable"
import { CLASS_STATUS } from "@/modules/edu/pages/configuration/classes/utils"
import { useSubjects } from "@/modules/edu/pages/configuration/edu-subject/hooks"
import { useSchoolHours } from "@/modules/edu/pages/configuration/edu-working-hour/hooks"
import { API } from "@/network/http"
import { BaseUpsertFormProps, SelectOptionSearchProps } from "@/typing/common"
import { calculateGraduationDate } from "@/utils"
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import { useBoolean } from "ahooks"
import { Button, Card, Col, Divider, Form, Input, Modal, Popconfirm, Row, Select, Space, Tabs, Typography } from "antd"
import { take, takeRight } from "lodash"
import moment from "moment"
import { forwardRef, memo, ReactElement, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react"
import { DAY_OF_WEEK, DEFINE_MODULE, STATUS } from "../constants"

const FormItem = Form.Item
const { Option } = Select
const { TabPane } = Tabs

interface Props extends BaseUpsertFormProps {
  onReload?: () => void
  prefilledValues?: {
    startingDate: string
  }
}

const UpsertForm = forwardRef((props: Props, ref) => {
  const { modalVisible, onCancel, onSubmit, actionType, values, loadingBtn, notify, prefilledValues, onReload } = props

  const [isLoaded, setLoaded] = useState<boolean>(false)
  const [showStudyScheduleTable, setShowStudyScheduleTable] = useState<boolean>(false)
  const [triggerReschedule, setTriggerReschedule] = useState<number>(0)

  const [moreStaffModal, { setFalse: closeMoreStaff, setTrue: openMoreStaff }] = useBoolean()
  const [moreStaffName, setMoreStaffName] = useState<
    "lecturerId" | "tutorIds" | "internIds" | "employeeTakeCareIds" | null
  >(null)
  const [studySchedule, setStudySchedule] = useState<any>([])
  const [rescheduling, setRescheduling] = useState<boolean>(false)
  const [submitRequested, { setTrue: requestSubmit, setFalse: cancelRequestSubmit }] = useBoolean()

  const [selectedOptions, setSelectedOptions] = useState<{
    lecturerId?: SelectOptionSearchProps[]
    tutorIds?: SelectOptionSearchProps[]
    internIds?: SelectOptionSearchProps[]
    employeeTakeCareIds?: SelectOptionSearchProps[]
  }>({})

  const roomRef = useRef<any>()
  const lecturerRef = useRef<any>()
  const internLectureRef = useRef<any>()
  const tutorRef = useRef<any>()
  const internRef = useRef<any>()

  const [form] = Form.useForm()

  const watchSubjectId = Form.useWatch("subjectId", form)
  const watchLocationId = Form.useWatch("locationId", form)
  const watchRoomId = Form.useWatch("roomId", form)
  const watchTimeFrames = Form.useWatch("timeFrames", form)
  const watchOpeningDate = Form.useWatch("openingDate", form)

  // as the second argument
  useImperativeHandle(ref, () => ({
    onResetData() {
      // reset sale table
      form.resetFields()
    },
  }))

  useEffect(() => {
    if (prefilledValues) {
      form.setFieldsValue({ startingDate: new Date(prefilledValues.startingDate) })
    }
  }, [prefilledValues])

  const { subjects, fetchSubjects } = useSubjects(false)
  const getListSubject = async (searchValue?: string) => {
    const response = await fetchSubjects({ name: searchValue })
    return (
      response?.map((item: any) => ({
        key: item?._id,
        value: item?._id,
        name: item?.name,
      })) || []
    )
  }

  const onTriggerRoomFetch = () => {
    roomRef.current.triggerFetch()
  }

  const getListLocation = async (searchValue?: string) => {
    const response = await API.locations.locationControllerGetMany({ name: searchValue })
    return (
      response?.data?.data?.map(item => ({
        key: item?._id,
        value: item?._id,
        name: item?.name,
      })) || []
    )
  }

  const getListRoom = async (searchValue?: string) => {
    if (isLoaded && watchLocationId) {
      const response = await API.rooms.roomControllerGetMany({
        code: searchValue,
        locationId: form.getFieldValue("locationId"),
      })

      return (
        response?.data?.data?.map((item: any) => ({
          key: item?._id,
          value: item?._id,
          name: item?.code,
        })) || []
      )
    }
    return []
  }

  const getListAvailable = useCallback(
    async (roles?: ("LECTURER" | "TUTOR" | "INTERN" | "INTERN_LECTURER")[]) => {
      if (
        isLoaded &&
        watchSubjectId &&
        watchLocationId &&
        watchRoomId &&
        watchTimeFrames?.every(
          (time: { schoolHourId: string; dayOfWeek: string }) => time?.dayOfWeek && time?.schoolHourId,
        )
      ) {
        const response = await API.availability.availabilityControllerCheck({
          subjectIds: [form.getFieldValue("subjectId")],
          locationIds: [form.getFieldValue("locationId")],
          daysOfWeek: form.getFieldValue("timeFrames")?.map((o: any) => o.dayOfWeek),
          fromDate: form.getFieldValue("openingDate"),
          toDate: form.getFieldValue("graduationDate"),
          areas: [],
          filterFields: [],
          sortFields: [],
          offset: "0",
          limit: "100",
          roles,
          ids: [],
          // @ts-ignore
          force: true,
        })
        return (
          response?.data?.data?.map((item: any) => ({
            key: item?.employeeId,
            value: item?.employeeId,
            name: item?.employee[0]?.name,
          })) || []
        )
      }
      return []
    },
    [watchSubjectId, watchLocationId, watchRoomId, watchTimeFrames],
  )

  const handlePreviewStudySchedule = async (values: any) => {
    const formValues = { ...(form.getFieldsValue() || values) }
    return (await API.studySchedules.studyScheduleControllerPreview({ ...formValues }))?.data
  }

  useEffect(() => {
    if (watchTimeFrames && watchSubjectId && watchOpeningDate) {
      const subject = subjects.find(e => e._id === watchSubjectId)
      const graduationDate = calculateGraduationDate(watchTimeFrames, watchOpeningDate, subject?.numberOfLessons ?? 0)

      form.setFieldsValue({ graduationDate })
    }
  }, [watchTimeFrames, watchSubjectId, watchOpeningDate, subjects])

  const { fetchSchoolHours, schoolHours } = useSchoolHours(true)
  const onSearchSchoolHour = async (e: string) => {
    const searchString = !e || e == undefined ? "" : e
    const sortedData = schoolHours.length > 0 ? schoolHours : await fetchSchoolHours({ limit: "500" })

    return (
      sortedData
        .filter((o: any) => o.timeFrame.includes(searchString))
        .map((item: any) => ({
          key: item?._id,
          value: item?._id,
          name: item?.timeFrame,
          option: item,
        })) || []
    )
  }

  const onConfirmClass = async () => {
    try {
      await API.classes.classControllerAcceptClass(values._id)
      notify?.success({ message: "Lớp học đã được chuyền thành lớp chính thức" })
      props.onReload && props.onReload()
      // props.onCancel()
    } catch (ex: any) {
      notify?.warning({ message: ex?.message })
    }
  }

  const onDraught = async () => {
    try {
      // @ts-ignore
      await API.classes.classControllerUpdateMany({ ids: [values._id], isArchived: false, status: "DRAUGHT" })
      notify?.success({ message: "Lớp học đã được chuyền thành dự thảo" })
      props.onReload && props.onReload()
      // props.onCancel()
    } catch (err: any) {
      notify?.warning({ message: err?.message })
    }
  }

  const onScheduleGenerated = async () => {
    try {
      // @ts-ignore
      await onDraught()
      props.onReload && props.onReload()
      // props.onCancel()
    } catch (err: any) {
      notify?.warning({ message: err?.message })
    }
  }

  const updateScheduledItem = (item: any) => {
    console.log(item)
  }

  useEffect(() => {
    if (actionType === "UPDATE" || actionType === "READ") {
      form.setFieldsValue({
        name: values?.name,
        code: values?.code,
        graduationDate: moment(values?.graduationDate),
        openingDate: moment(values?.openingDate),
        subjectId: values?.subjectId,
        locationId: values?.locationId,
        roomId: values?.roomId,
        lecturerId: values?.lecturerId,
        tutorIds: values?.tutorIds ?? [],
        internIds: values?.internIds ?? [],
        employeeTakeCareIds: values?.takeCareIds ?? [],
        groupLink: values?.groupLink,
        groupChat: values?.groupChat,
        isNo1Location: values?.isNo1Location,
        maxMemberRoom: values?.maxMemberRoom,
        timeFrames: values?.timeFrames,
        status: values?.status,
      })

      setSelectedOptions({
        lecturerId: [{ id: values?.lecturer._id, value: values?.lecturer._id, label: values?.lecturer?.name }],
        internIds: values?.interns.map((e: any) => ({ id: e._id, value: e._id, label: e?.name })),
        tutorIds: values?.tutors.map((e: any) => ({ id: e._id, value: e._id, label: e?.name })),
        employeeTakeCareIds: values?.takeCares.map((e: any) => ({ id: e._id, value: e._id, label: e?.name })),
      })
    }
    setLoaded(true)
  }, [])

  useEffect(() => {
    if (
      watchSubjectId &&
      watchLocationId &&
      watchRoomId &&
      watchTimeFrames?.every(
        (time: { schoolHourId: string; dayOfWeek: string }) => time?.dayOfWeek && time?.schoolHourId,
      )
    ) {
      lecturerRef.current.triggerFetch()
      internLectureRef.current.triggerFetch()
      tutorRef.current.triggerFetch()
      internRef.current.triggerFetch()
    }
  }, [watchSubjectId, watchLocationId, watchRoomId, watchTimeFrames, form])

  useEffect(() => {
    if (watchLocationId) {
      onTriggerRoomFetch()
    }
  }, [watchLocationId])

  const handleSubmit = async () => {
    await form.validateFields()
    if (
      !watchTimeFrames ||
      (watchTimeFrames &&
        !watchTimeFrames.every(
          (time: { schoolHourId: string; dayOfWeek: string }) => time?.dayOfWeek && time?.schoolHourId,
        ))
    ) {
      notify?.warning({ message: "Vui lòng thêm thời gian học" })
      return
    }
    const formValues = {
      ...form.getFieldsValue(),
      schedules: studySchedule?.data,
    }
    if (actionType === "CREATE") {
      onSubmit(formValues, actionType)
    }
    if (actionType === "UPDATE") {
      console.log("Updating....")
      onSubmit(formValues, actionType, values._id)
    }
  }

  const handleDelete = async () => {
    try {
      await API.classes.classControllerDeleteOne(values?._id)
      notify?.success({ message: `${ACTION_METHOD_OBJ["DELETE"]} ${DEFINE_MODULE?.name} thành công` })
      props.onReload && props.onReload()
      // props.onCancel()
    } catch (err: any) {
      notify?.error({
        message: err.error?.message || err.message || err,
      })
    }
  }

  const onAbort = async () => {
    try {
      await API.classes.classControllerCancelClass(values?._id)
      notify?.success({ message: `Huỷ lớp thành công.` })
      props.onReload && props.onReload()
      // props.onCancel()
    } catch (err: any) {
      notify?.error({
        message: err.error?.message || err.message || err,
      })
    }
  }
  const onResetForm = (): void => {
    // reset sale table
    form.resetFields()
  }

  useEffect(() => {
    if (submitRequested && studySchedule) {
      handleSubmit()
      onScheduleGenerated()
      cancelRequestSubmit()
    }
  }, [submitRequested, studySchedule, form])

  const inputs = [
    {
      inputType: "input",
      name: "name",
      label: "Tên lớp học",
      placeholder: "VD: Màu nước 199 Thái Hà",
      rules: [{ required: true, message: "Không được để trống" }],
      disabled: actionType === "READ",
    },
    {
      inputType: "input",
      name: "code",
      label: "Mã lớp học",
      placeholder: "Nhập mã lớp học",
      rules: [{ required: true, message: "Nhập mã" }],
      disabled: actionType === "READ",
    },
    {
      inputType: "selectsearch",
      name: "subjectId",
      label: "Môn học",
      placeholder: "Chọn môn học",
      fetchOptions: getListSubject,
      rules: [{ required: true, message: "Chọn môn học" }],
      disabled: actionType === "READ",
    },
    {
      inputType: "datetime",
      name: "openingDate",
      label: "Khai giảng",
      showTime: false,
      placeholder: "Chọn ngày khai giảng",
      rules: [{ required: true, message: "Chọn ngày khai giảng" }],
      disabled: actionType === "READ" || values?.status === STATUS.NOT_OPEN_YET,
    },
    {
      inputType: "datetime",
      name: "graduationDate",
      label: "Tốt nghiệp",
      showTime: false,
      placeholder: "Nhập ngày tốt nghiệp",
      rules: [{ required: true, message: "Nhập ngày tốt nghiệp" }],
      disabled: actionType === "READ",
    },
    {
      inputType: "input",
      name: "groupLink",
      label: "Link nhóm",
      placeholder: "Nhập link nhóm",
      disabled: actionType === "READ",
    },
    {
      inputType: "input",
      name: "groupChat",
      label: "Link group chat",
      placeholder: "Nhập link group chat",
      disabled: actionType === "READ",
    },
    {
      inputType: "input",
      name: "absentList",
      label: "Lịch nghỉ",
      placeholder: "---",
      disabled: true,
    },
  ]

  const studentReportInputs = [
    {
      inputType: "input",
      name: "__studentReport1",
      label: "Sức chứa",
      placeholder: "0",
      disabled: true,
    },
    {
      inputType: "input",
      name: "__studentReport2",
      label: "Sĩ số tối đa",
      placeholder: "0",
      disabled: true,
    },
    {
      inputType: "input",
      name: "__studentReport3",
      label: "Ghi danh",
      placeholder: "0",
      disabled: true,
    },
    {
      inputType: "input",
      name: "__studentReport4",
      label: "Đã tuyển",
      placeholder: "0",
      disabled: true,
    },
    {
      inputType: "input",
      name: "__studentReport5",
      label: "Chuyển vào",
      placeholder: "0",
      disabled: true,
    },
    {
      inputType: "input",
      name: "__studentReport6",
      label: "Chuyển đi",
      placeholder: "0",
      disabled: true,
    },
  ]

  const revenueReportInputs = [
    {
      inputType: "input",
      name: "__revenueReport1",
      label: "Nhân viên kinh doanh",
      placeholder: "---",
      disabled: true,
    },
    {
      inputType: "input",
      name: "__revenueReport2",
      label: "Tổng doanh thu sau chiết khấu",
      placeholder: "0",
      disabled: true,
    },
    {
      inputType: "input",
      name: "__revenueReport3",
      label: "Tổng đã thu",
      placeholder: "0",
      disabled: true,
    },
    {
      inputType: "input",
      name: "__revenueReport4",
      label: "Tổng cần thu",
      placeholder: "0",
      disabled: true,
    },
  ]

  const states = Object.keys(CLASS_STATUS).map(e => ({
    label: CLASS_STATUS[e as keyof typeof CLASS_STATUS],
    active: e === values?.status,
  }))

  const renderContent = () => {
    return (
      <>
        <Row gutter={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <EntityStates states={states} />
        </Row>

        <Typography.Title level={5} style={{ marginBottom: "20px", marginTop: "20px" }}>
          Thông tin lớp học
        </Typography.Title>
        <Row gutter={12}>
          <Col span={11}>
            <Row>
              {take(inputs, inputs.length - 3).map(item => item && <FormItemData key={item?.name} {...item} />)}

              <Col span={24} style={{ backgroundColor: "#f9f9f9", marginBottom: "24px", padding: "5px 10px" }}>
                <FormItem label="Khung Giờ">
                  <Form.List name="timeFrames">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, idx) => {
                          // console.log(idx, field)
                          const defaultVal = form.getFieldValue("timeFrames")
                          return (
                            <Col key={`list-${idx}`} span={24}>
                              <FormItem style={{ marginBottom: "0" }}>
                                <Input.Group style={{ width: "100%" }} key={`dayOfWeek-${idx}`} compact>
                                  <FormItem
                                    label={idx === 0 ? "Thứ" : undefined}
                                    name={[field.key, "dayOfWeek"]}
                                    fieldKey={[field.key, `dayOfWeek`]}
                                    style={{ display: "inline-block", width: "46%", marginBottom: "4px" }}
                                    initialValue={defaultVal ? defaultVal[field.key]?.dayOfWeek : ""}
                                  >
                                    <Select style={{ width: "100%" }} disabled={actionType === "READ"}>
                                      {DAY_OF_WEEK.map(dow => (
                                        <Option key={dow.key} value={dow.value}>
                                          {dow?.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </FormItem>

                                  <FormItem
                                    label={idx === 0 ? "Ca học" : undefined}
                                    name={[field.key, "schoolHourId"]}
                                    fieldKey={[field.key, `schoolHourId`]}
                                    style={{ display: "inline-block", width: "46%", marginBottom: "4px" }}
                                    initialValue={defaultVal ? defaultVal[field.key]?.schoolHourId : ""}
                                  >
                                    <DebounceSelect
                                      fetchOptions={onSearchSchoolHour}
                                      disabled={actionType === "READ"}
                                    />
                                  </FormItem>

                                  <FormItem
                                    label={idx === 0 ? "-" : undefined}
                                    style={{ textAlign: "center", width: "8%", marginBottom: "4px" }}
                                  >
                                    {actionType !== "READ" ? (
                                      <DeleteOutlined style={{}} onClick={() => remove(idx)} />
                                    ) : null}
                                  </FormItem>
                                </Input.Group>
                              </FormItem>
                            </Col>
                          )
                        })}
                        <Col span={24}>
                          {actionType !== "READ" ? (
                            <Form.Item>
                              <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                Thêm khung giờ
                              </Button>
                            </Form.Item>
                          ) : null}
                        </Col>
                      </>
                    )}
                  </Form.List>
                </FormItem>
              </Col>

              {takeRight(inputs, 3).map(item => item && <FormItemData key={item?.name} {...item} />)}
            </Row>
          </Col>

          <Col span={11} offset={2}>
            <Row>
              <Col span={24}>
                <FormItem name="locationId" label="Cơ sở" rules={[{ required: true, message: "Chọn cơ sở giảng dạy" }]}>
                  <DebounceSelect
                    selectTrigger={onTriggerRoomFetch}
                    placeholder="Chọn cơ sở giảng dạy"
                    fetchOptions={getListLocation}
                    disabled={actionType === "READ"}
                  />
                </FormItem>
              </Col>

              <Col span={24}>
                <FormItem name="roomId" label="Phòng học" rules={[{ required: true, message: "Chọn phòng học" }]}>
                  <DebounceSelect
                    ref={roomRef}
                    placeholder="Chọn phòng học"
                    fetchOptions={getListRoom}
                    disabled={actionType === "READ"}
                  />
                </FormItem>
              </Col>

              <Col span={24}>
                <FormItem name="lecturerId" label="Giáo viên" rules={[{ required: true, message: "Chọn giáo viên" }]}>
                  <DebounceSelect
                    ref={lecturerRef}
                    placeholder="Chọn giáo viên"
                    fetchOptions={() => getListAvailable(["LECTURER"])}
                    disabled={actionType === "READ"}
                    selectedOptions={selectedOptions?.lecturerId}
                    dropdownRender={(menu: ReactElement) => (
                      <>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={() => {
                              setMoreStaffName("lecturerId")
                              openMoreStaff()
                            }}
                          >
                            Xem thêm ngoài khả dụng
                          </Button>
                        </Space>
                      </>
                    )}
                  />
                </FormItem>
              </Col>

              <Col span={24}>
                <FormItem
                  name="tutorIds"
                  label="Trợ giảng"
                  // rules={[{ required: true, message: "Chọn Trợ giảng" }]}
                >
                  <DebounceSelect
                    ref={tutorRef}
                    mode="multiple"
                    placeholder="Chọn Trợ giảng"
                    selectedOptions={selectedOptions?.tutorIds}
                    fetchOptions={() => getListAvailable(["LECTURER", "TUTOR"])}
                    disabled={actionType === "READ"}
                    dropdownRender={(menu: ReactElement) => (
                      <>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={() => {
                              setMoreStaffName("tutorIds")
                              openMoreStaff()
                            }}
                          >
                            Xem thêm ngoài khả dụng
                          </Button>
                        </Space>
                      </>
                    )}
                  />
                </FormItem>
              </Col>

              <Col span={24}>
                <FormItem
                  name="internIds"
                  label="Thực tập"
                  // rules={[{ required: true, message: "Chọn Thực tập" }]}
                >
                  <DebounceSelect
                    ref={internRef}
                    selectedOptions={selectedOptions?.internIds}
                    mode="multiple"
                    placeholder="Chọn Thực tập"
                    fetchOptions={() => getListAvailable(["LECTURER", "INTERN_LECTURER"])}
                    disabled={actionType === "READ"}
                    dropdownRender={(menu: ReactElement) => (
                      <>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={() => {
                              setMoreStaffName("internIds")
                              openMoreStaff()
                            }}
                          >
                            Xem thêm ngoài khả dụng
                          </Button>
                        </Space>
                      </>
                    )}
                  />
                </FormItem>
              </Col>

              <Col span={24}>
                <FormItem
                  name="employeeTakeCareIds"
                  label="Chăm sóc lớp"
                  // rules={[{ required: true, message: "Chọn Chăm sóc lớp" }]}
                >
                  <DebounceSelect
                    ref={internLectureRef}
                    mode="multiple"
                    selectedOptions={selectedOptions?.employeeTakeCareIds}
                    placeholder="Chọn Chăm sóc lớp"
                    fetchOptions={() => getListAvailable(["LECTURER", "TUTOR", "INTERN"])}
                    disabled={actionType === "READ"}
                    dropdownRender={(menu: ReactElement) => (
                      <>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={() => {
                              setMoreStaffName("employeeTakeCareIds")
                              openMoreStaff()
                            }}
                          >
                            Xem thêm ngoài khả dụng
                          </Button>
                        </Space>
                      </>
                    )}
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={11}>
            <Typography.Title level={5} style={{ marginBottom: "20px", marginTop: "20px" }}>
              Báo cáo học viên
            </Typography.Title>
            <Row>{studentReportInputs.map(item => item && <FormItemData key={item?.name} {...item} />)}</Row>
          </Col>
          <Col span={11} offset={2}>
            <Typography.Title level={5} style={{ marginBottom: "20px", marginTop: "20px" }}>
              Báo cáo doanh thu
            </Typography.Title>
            <Row>{revenueReportInputs.map(item => item && <FormItemData key={item?.name} {...item} />)}</Row>
          </Col>
        </Row>
      </>
    )
  }

  const renderFooter = () => {
    return (
      <>
        {(actionType === "CREATE" || actionType === "READ") && (
          <Button
            onClick={() => {
              onResetForm()
              onCancel()
            }}
          >
            Đóng
          </Button>
        )}
        {actionType !== "READ" && (
          <>
            {actionType !== "CREATE" && (
              <Popconfirm title={`Bạn có chắc rằng muốn huỷ không?`} onConfirm={onAbort} okText="Có" cancelText="Không">
                <Button danger={true} style={{ float: "left" }}>
                  Huỷ lớp
                </Button>
              </Popconfirm>
            )}
            <Button loading={loadingBtn} type="primary" onClick={handleSubmit}>
              Lưu
            </Button>
          </>
        )}
        {/* {actionType === "UPDATE" && values.isGeneratedStudySchedule !== true && ( */}
        {["CREATE", "UPDATE"].includes(actionType) && values?.status === "DRAUGHT" && (
          <Button type="primary" onClick={onConfirmClass}>
            Xác nhận
          </Button>
        )}
        {["CREATE", "UPDATE"].includes(actionType) && values?.status === "DRAFT" && (
          <Button disabled={studySchedule.total === 0} type="primary" onClick={onDraught}>
            Chuyển dự thảo
          </Button>
        )}
      </>
    )
  }

  return (
    <>
      <Modal
        bodyStyle={{ background: "#F0F2F5" }}
        width={1350}
        destroyOnClose
        maskClosable={false}
        title={`${ACTION_METHOD_OBJ[actionType]} ${DEFINE_MODULE?.name}`}
        visible={modalVisible}
        onCancel={() => {
          onResetForm()
          onCancel()
        }}
        footer={renderFooter()}
      >
        <Card style={{ paddingRight: "20px" }}>
          <Form labelAlign="left" labelWrap {...layout} form={form}>
            {renderContent()}
          </Form>
          <Tabs
            defaultActiveKey="3"
            tabBarExtraContent={
              ["UPDATE"].includes(actionType) &&
              ["DRAUGHT", "DRAFT"].includes(values?.status) &&
              (!studySchedule || studySchedule?.total === 0) ? (
                <Button
                  loading={rescheduling}
                  type="primary"
                  onClick={() => setTriggerReschedule(triggerReschedule + 1)}
                >
                  Tạo lịch học
                </Button>
              ) : (
                <></>
              )
            }
          >
            <TabPane tab="Báo cáo điểm danh" key="1" disabled>
              Báo cáo điểm danh
            </TabPane>
            <TabPane tab="Danh sách học viên" key="2" disabled>
              Danh sách học viên
            </TabPane>
            <TabPane tab="Lịch học" key="3">
              {/* {(actionType === "UPDATE" || actionType === "READ") && */}
              {/* (values.isGeneratedStudySchedule || showStudyScheduleTable) && ( */}
              <StudyScheduleTable
                getStudySchedule={handlePreviewStudySchedule}
                updateScheduledItem={updateScheduledItem}
                reschedule={triggerReschedule}
                actionType={actionType}
                form={form}
                notify={notify}
                classId={values?._id}
                hasAlreadyGenerated={values?.isGeneratedStudySchedule}
                isShow={true || values?.isGeneratedStudySchedule || showStudyScheduleTable}
                rescheduling={rescheduling}
                setRescheduling={setRescheduling}
                data={studySchedule}
                setData={setStudySchedule}
                onScheduleGenerated={requestSubmit}
              />
              {/* )} */}
              {/* {actionType === "CREATE" && <Empty />} */}
            </TabPane>
            <TabPane tab="Đăng kí chưa xác nhận" key="4" disabled>
              Đăng kí chưa xác nhận
            </TabPane>
          </Tabs>
        </Card>
        <MoreStaffModal
          visible={moreStaffModal}
          name={moreStaffName}
          onClose={closeMoreStaff}
          onSubmit={(name, item) => {
            if (!name) return

            if (name === "lecturerId") {
              form.setFieldsValue({ lecturerId: item.value })
            } else {
              const currentValue = form.getFieldValue(name) ?? []
              form.setFieldsValue({ [name]: [...currentValue, item.value] })
            }

            setSelectedOptions(prev => ({ ...prev, [name]: [...(prev[name] ?? []), item] }))
          }}
        />
      </Modal>
    </>
  )
})

export default memo(UpsertForm)
