import { all, call, put, takeLatest } from "redux-saga/effects"
import * as actionTypes from "../../constants/ActionTypes"
import { getActionsApi } from "../../network"
interface IPayload {
  type: string
  payload: any
}

interface IResponse {
  data: any
  total: number
}

function* getListAction(action: IPayload) {
  try {
    const { data, total }: IResponse = yield call(getActionsApi, action.payload)
    yield put({
      type: actionTypes.GET_LIST_ACTION_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({ type: actionTypes.GET_LIST_ACTION_FAILED, payload: error })
  }
}

export default function* root() {
  yield all([takeLatest(actionTypes.GET_LIST_ACTION, getListAction)])
}
