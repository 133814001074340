import {DebounceSelect} from "@/components/antd/form/DebounceSelect";
import {Table, TableProps} from "@/components/antd/table/Table";
import {ACTION_METHOD_OBJ} from "@/constants";
import {WorkingScheduleStatus} from "@/modules/edu/pages/operation/working-schedule/types";
import {API} from "@/network/http";
import {CRUD_ACTION} from "@/types";
import {formatOnlyDate} from "@/utils";
import {CreateWorkScheduleDto, UpdateWorkScheduleDto, WorkScheduleDto} from "@/__generated__/api-v1";
import {DeleteTwoTone, EditTwoTone} from "@ant-design/icons";
import type {ActionType, ProColumns} from "@ant-design/pro-table";
import {notification, Popconfirm, Space, Tag, TagProps, Tooltip} from "antd";
import {FC, useRef, useState} from "react";
import {DEFINE_MODULE, statuses} from "../constants";
import UpsertForm from "./UpsertForm";

const List: FC = () => {
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [isCreate, setCreate] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [values, setValues] = useState<any>();
  const actionRef = useRef<ActionType>();

  const [notifyApi, contextHolder] = notification.useNotification();

  const onClickEditButton = async (id: string) => {
    const detailData = await API.workSchedules.workScheduleControllerGetOne(id);
    setValues(detailData.data.data);
    setUpdate(true);
  };

  const onClickDeleteButton = async (id: string) => {
    try {
      await API.workSchedules.workScheduleControllerDeleteOne(id);
      notifyApi.success({message: `${ACTION_METHOD_OBJ['DELETE']} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const onSubmit = async (formData: any, type: CRUD_ACTION, _id?: string) => {
    try {
      if (loadingBtn) return false;
      setLoadingBtn(true);

      // format data
      formData.employeeId = formData.employeeId?.value || formData.employeeId;
      formData.priorityLocationId = formData.priorityLocationId?.value || formData.priorityLocationId;
      formData.availableLocationId = formData.availableLocationId?.value || formData.availableLocationId;

      const payload: CreateWorkScheduleDto = {
        ...formData,
        availableLocationIds: [formData.priorityLocationId],
        priorityLocationIds: [formData.availableLocationId],
      };
      // call api create or update
      const reqData =
        type === "CREATE"
          ? await API.workSchedules.workScheduleControllerCreateOne(payload)
          : //@ts-ignore
          await API.workSchedules.workScheduleControllerUpdateOne(_id || "", payload);

      // process response
      if (reqData && reqData.data) {
        if (type === "CREATE") setCreate(false);
        if (type === "UPDATE") setUpdate(false);

        // show notification
        notifyApi.success({
          message: "Thông báo",
          description: `${ACTION_METHOD_OBJ[type]} thành công`,
          placement: "topRight",
        });
        actionRef.current?.reload();
      }
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    } finally {
      setLoadingBtn(false);
    }
  };

  // get list data
  const fetchData = async (queries: Parameters<typeof API.workSchedules.workScheduleControllerGetMany>["0"]) => {
    return (await API.workSchedules.workScheduleControllerGetMany({...queries})).data;
  };

  const onDeleteMany = async (ids: string[]) => {
    try {
      await API.workSchedules.workScheduleControllerDeleteMany({ids});
      notifyApi.success({message: `${ACTION_METHOD_OBJ['DELETE']} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const onArchiveMany = async (ids: string[]) => {
    try {
      await API.workSchedules.workScheduleControllerUpdateMany({ids, isArchived: true});
      notifyApi.success({message: `${ACTION_METHOD_OBJ['ARCHIVE']} ${ids.length} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const onUnarchiveMany = async (ids: string[]) => {
    try {
      await API.workSchedules.workScheduleControllerUpdateMany({ids, isArchived: false});
      notifyApi.success({message: `${ACTION_METHOD_OBJ['UNARCHIVE']} ${ids.length} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const getListEmployee = async (searchValue?: string) => {
    const response = await API.employees.employeeControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };


  const getListDepartment = async (searchValue?: string) => {
    const response = await API.departments.departmentControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const getListLocation = async (searchValue?: string) => {
    const response = await API.locations.locationControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  // columns list
  const columns: ProColumns<WorkScheduleDto>[] = [
    {
      hideInTable: true,
      dataIndex: "employeeId",
      title: "Nhân viên",
      renderFormItem: () => {
        return <DebounceSelect placeholder="Chọn nhân viên" fetchOptions={getListEmployee} />;
      }
    },
    {
      hideInTable: true,
      dataIndex: "departmentId",
      title: "Phòng ban",
      renderFormItem: () => {
        return <DebounceSelect placeholder="Chọn phòng ban" fetchOptions={getListDepartment} />;
      }
    },
    {
      hideInTable: true,
      dataIndex: "locationId",
      title: "Địa điểm làm việc",
      renderFormItem: () => {
        return <DebounceSelect placeholder="Chọn địa điểm làm việc" fetchOptions={getListLocation} />;
      }
    },
    {
      dataIndex: "status",
      title: "Trạng thái",
      valueType: "select",
      valueEnum: {
        ACCEPTED: {text: "Đã duyệt", value: "ACCEPTED"},
        CANCELED: {text: "Đã huỷ", value: "CANCELED"},
        INITIAL: {text: "Dự thảo", value: "INITIAL"},
        REJECTED: {text: "Từ chối", value: "REJECTED"},
      },
      hideInTable: true
    },
    {
      title: "Nhân viên",
      dataIndex: ["employee", "name"],
      search: false,
    },
    {
      dataIndex: ["department", "name"],
      title: "Phòng ban",
      search: false,
    },
    {
      dataIndex: "fromDate",
      title: "Từ ngày",
      search: false,
      render: (_: any, value: any) => {
        return formatOnlyDate(value.fromDate);
      },
    },
    {
      dataIndex: "fromDate",
      title: "Từ ngày",
      valueType: "date",
      hideInTable: true
    },
    {
      dataIndex: "toDate",
      title: "Tới ngày",
      search: false,
      render: (_: any, value: any) => {
        return formatOnlyDate(value.toDate);
      },
    },
    {
      dataIndex: "toDate",
      title: "Tới ngày",
      valueType: "date",
      hideInTable: true
    },
    {
      dataIndex: "maxNumberOfShiftsInMonth",
      title: "Địa điểm làm việc",
      search: false,
    },
    {
      dataIndex: "maxNumberOfShiftsInMonth",
      title: "Tối đa",
      search: false,
    },
    {
      dataIndex: "numberOfShiftsInMonth",
      title: "Đã nhận",
      search: false,
    },
    {
      dataIndex: "---",
      title: "Trống",
      search: false,
    },
    {
      dataIndex: ["priorityLocation", "name"],
      title: "Cơ sở ưu tiên",
      search: false,
    },
    {
      dataIndex: ["priorityLocation", "name"],
      title: "Cơ sở có thể nhận lịch",
      search: false,
    },
    {
      dataIndex: "note",
      title: "Lưu ý khác",
      search: false,
    },
    {
      dataIndex: "status",
      title: "Trạng thái",
      search: false,
      render: (_, entity) => {
        const item = entity as WorkScheduleDto & {status: UpdateWorkScheduleDto["status"];};
        const status = item.status as WorkingScheduleStatus;
        const color: TagProps["color"] = status === "ACCEPTED" ? "green" : status === "REJECTED" ? "red" : "default";

        return <Tag color={color}>{statuses[status]}</Tag>;
      },
    },
    {
      title: "Thao tác",
      align: "center",
      search: false,
      fixed: "right",
      width: 80,
      render: (_, entity) => {
        return (
          <Space size={10}>
            <Tooltip title={`Cập nhật ${DEFINE_MODULE.name}`}>
              <EditTwoTone onClick={() => onClickEditButton(entity._id)} />
            </Tooltip>
            <Tooltip title={`Xoá ${DEFINE_MODULE.name}`}>
              <Popconfirm
                title={`Bạn có chắc rằng muốn xoá ${DEFINE_MODULE.name} này không?`}
                onConfirm={() => onClickDeleteButton(entity._id)}
                okText="Có"
                cancelText="Không"
              >
                <DeleteTwoTone twoToneColor={"red"} />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const protableProps: TableProps = {
    actionRef,
    DEFINE_MODULE,
    contextHolder,
    fetchData,
    columns,
    deleteMany: onDeleteMany,
    archiveMany: onArchiveMany,
    unArchiveMany: onUnarchiveMany,
    createForm: {
      isCreate,
      setCreate,
      component: (
        <UpsertForm
          key="CREATE"
          onCancel={() => setCreate(false)}
          modalVisible={isCreate}
          onSubmit={onSubmit}
          actionType="CREATE"
          loadingBtn={loadingBtn}
        />
      ),
    },
    updateForm: {
      isUpdate,
      component: (
        <UpsertForm
          key="UPDATE"
          onCancel={() => setUpdate(false)}
          modalVisible={isUpdate}
          onSubmit={onSubmit}
          actionType="UPDATE"
          loadingBtn={loadingBtn}
          values={values}
        />
      ),
    },
  };

  return <Table {...protableProps} />;

  // return (
  //   <PageContainer>
  //     <Helmet title="Đăng kí lịch làm việc" />
  //     {contextHolder}
  //     <ProTable<WorkScheduleDto>
  //       headerTitle={`Danh sách ${DEFINE_MODULE.name}`}
  //       actionRef={actionRef}
  //       bordered
  //       rowKey="_id"
  //       search={{
  //         labelWidth: "auto",
  //         optionRender: (_, { form }) => [
  //           <Button
  //             key="rest"
  //             onClick={() => {
  //               form?.resetFields()
  //             }}
  //           >
  //             Làm mới
  //           </Button>,
  //           <Button
  //             key="search"
  //             type="primary"
  //             onClick={() => {
  //               form?.submit()
  //             }}
  //           >
  //             Tìm kiếm
  //           </Button>,
  //         ],
  //         collapseRender: collapsed => {
  //           return <CollapseRender collapsed={collapsed} />
  //         },
  //       }}
  //       request={params => {
  //         const { pageSize, current, keyword, ...rest } = params
  //         return fetchData({
  //           ...(pageSize ? { limit: String(pageSize) } : {}),
  //           ...(pageSize ? { offset: String((Number(current) - 1) * pageSize) } : {}),
  //           ...rest,
  //         })
  //       }}
  //       options={{ density: false, fullScreen: false }}
  //       pagination={{
  //         showTotal: (total, range) => `${range[0]}-${range[1]} trên ${total} ${DEFINE_MODULE.name}`,
  //       }}
  //       toolBarRender={() => [
  //         <Button type="primary" onClick={() => setCreate(true)}>
  //           <PlusOutlined /> Tạo mới
  //         </Button>,
  //       ]}
  //       columns={columns}
  //       sticky={{ offsetScroll: 10 }}
  //       tableAlertRender={() => {
  //         return <>Đã chọn mục</>
  //       }}
  //       scroll={{ x: 1300, y: 430 }}
  //       locale={{
  //         emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Không có dữ liệu"} />,
  //       }}
  //       rowSelection={{
  //         onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
  //           console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows)
  //         },
  //         getCheckboxProps: (record: any) => ({
  //           disabled: record.name === "Disabled User", // Column configuration not to be checked
  //           name: record.name,
  //         }),
  //       }}
  //     />
  //     {isCreate && (
  //       <UpsertForm
  //         key="CREATE"
  //         onCancel={() => setCreate(false)}
  //         modalVisible={isCreate}
  //         onSubmit={onSubmit}
  //         actionType="CREATE"
  //         loadingBtn={loadingBtn}
  //       />
  //     )}
  //     {isUpdate && (
  //       <UpsertForm
  //         key="UPDATE"
  //         onCancel={() => setUpdate(false)}
  //         modalVisible={isUpdate}
  //         onSubmit={onSubmit}
  //         actionType="UPDATE"
  //         loadingBtn={loadingBtn}
  //         values={values}
  //       />
  //     )}
  //   </PageContainer>
  // )
};

export default List;
