export const configs = {
  apiHost:
    process.env.NODE_ENV === "production"
      ? "https://api-stag.mtb.dansolutions.vn/"
      : "https://api-stag.mtb.dansolutions.vn/",
  portalHost: process.env.NODE_ENV === "production" ? "https://stg.mtb.dansolutions.vn" : "http://localhost:3000",
  apiUrl:
    process.env.NODE_ENV === "production"
      ? "https://api-stag.mtb.dansolutions.vn"
      : "https://api-stag.mtb.dansolutions.vn",
}
