import size from "lodash/size"
import { PAGINATION_DEFAULT, GETLIST_DEFAULT } from "../constants"

export const getInitParams = (search = "") => {
  const paramsFromUrl = window.location.search
    .substring(1)
    .split("&")
    .map(item => item.split("="))
  if (!paramsFromUrl[0][0]) {
    return {
      ...PAGINATION_DEFAULT,
      ...GETLIST_DEFAULT,
    }
  }

  const result = paramsFromUrl.reduce((result: any, quote: any) => {
    result[quote[0]] = quote[1]
    return result
  }, {})

  const params = {
    ...PAGINATION_DEFAULT,
    ...result,
  }

  return params
}

export const convertObjectParamsToStringParams = (object: any) => {
  const arrayParams = Object.entries(object)
  return arrayParams.reduce((result, quote, key) => {
    result = result + `${quote[0]}=${quote[1]}${key !== size(arrayParams) - 1 ? "&" : ""}`
    return result
  }, "")
}

export const parseJwt = (token: string) => {
  try {
    const base64Url = token.split(".")[1]
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(""),
    )

    return JSON.parse(jsonPayload)
  } catch (error) {
    return null
  }
}
