import { EduPaths } from "@/modules/edu"
import i18n from "i18n-js"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import { ActionTypes } from "../../constants"
import * as actionTypes from "../../constants/ActionTypes"
import history from "../../navigation"
import {
  archiveManyWorkScheduleApi,
  createWorkScheduleApi,
  deleteManyWorkScheduleApi,
  deleteWorkScheduleApi,
  getWorkScheduleApi,
  getWorkScheduleDetailApi,
  updateWorkScheduleApi,
} from "../../network"
import { convertObjectParamsToStringParams, getInitParams } from "../../utils"
import { getWorkScheduleState } from "../selectors"
import { IWorkScheduleState } from "../types"

interface IPayload {
  type: string
  payload: any
}
interface IResponse {
  data: any
  total: number
}

function* getListWorkSchedule(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const workSchedule: IWorkScheduleState = yield select<any>(getWorkScheduleState)

    // validate and set requesting
    if (workSchedule.isLoadingRequest) return
    yield put({
      type: ActionTypes.SET_LOADING_WORK_SCHEDULE,
      payload: { isLoadingRequest: true },
    })

    const { data, total }: IResponse = yield call(getWorkScheduleApi, action.payload)
    yield history.push(`?${convertObjectParamsToStringParams(action.payload)}`)
    yield put({
      type: ActionTypes.GET_LIST_WORK_SCHEDULE_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({
      type: ActionTypes.GET_LIST_WORK_SCHEDULE_FAILED,
      payload: error,
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_WORK_SCHEDULE,
      payload: { isLoadingRequest: false },
    })
  }
}

function* createWorkSchedule(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const teacher: IWorkScheduleState = yield select<any>(getWorkScheduleState)

    // validate and set requesting
    if (teacher.isCreateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_WORK_SCHEDULE,
      payload: { isCreateRequest: true },
    })

    // call api create
    const { data, total }: IResponse = yield call(createWorkScheduleApi, action.payload)

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Thêm mới thành công",
        message: data?.message || "",
        type: "success",
      },
    })

    // navigate
    history.push("/working-schedules")
  } catch (error) {
    yield put({
      type: actionTypes.CREATE_WORK_SCHEDULE_FAILED,
      payload: error,
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_WORK_SCHEDULE,
      payload: { isCreateRequest: false },
    })
  }
}

function* deleteOneWorkSchedule(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const workSchedule: IWorkScheduleState = yield select<any>(getWorkScheduleState)

    // validate and set requesting
    if (workSchedule.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_WORK_SCHEDULE,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(deleteWorkScheduleApi, action.payload.id)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteAction.deleteSuccess"),
          message: i18n.t("common.deleteAction.deleteSuccess"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_WORK_SCHEDULE,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_WORK_SCHEDULE,
      payload: { isDeleteRequest: false },
    })
  }
}

function* updateDetailWorkSchedule(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const workSchedule: IWorkScheduleState = yield select<any>(getWorkScheduleState)

    // validate and set requesting
    if (workSchedule.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_WORK_SCHEDULE,
      payload: { isUpdateRequest: true },
    })

    // call api update
    const { data, total }: IResponse = yield call(
      updateWorkScheduleApi,
      action.payload.workScheduleId,
      action.payload.data,
    )

    // yield put({
    //   type: actionTypes.UPDATE_DETAIL_USER_SUCCESS,
    // });

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thành công",
        message: data?.message || "",
        type: "success",
      },
    })
    history.push(EduPaths.WORK_SCHEDULE.LIST.PATH)
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_DETAIL_WORK_SCHEDULE_FAILED,
      payload: error,
    })
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thất bại",
        message: `${error}` || "",
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_WORK_SCHEDULE,
      payload: { isUpdateRequest: false },
    })
  }
}

function* getDetailWorkSchedule(action: IPayload) {
  try {
    const { data, total }: IResponse = yield call(getWorkScheduleDetailApi, action.payload)

    yield put({
      type: actionTypes.GET_DETAIL_WORK_SCHEDULE_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({
      type: actionTypes.GET_DETAIL_WORK_SCHEDULE_FAILED,
      payload: error,
    })
  }
}

function* deleteManyWorkSchedule(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const workSchedule: IWorkScheduleState = yield select<any>(getWorkScheduleState)

    // validate and set requesting
    if (workSchedule.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_WORK_SCHEDULE,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(deleteManyWorkScheduleApi, action.payload.ids)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteManyAction.title"),
          message: i18n.t("common.deleteManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_WORK_SCHEDULE,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_WORK_SCHEDULE,
      payload: { isDeleteRequest: false },
    })
  }
}

function* archiveManyWorkSchedule(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const workSchedule: IWorkScheduleState = yield select<any>(getWorkScheduleState)

    // validate and set requesting
    if (workSchedule.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_WORK_SCHEDULE,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(archiveManyWorkScheduleApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.archiveManyAction.title"),
          message: i18n.t("common.archiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_WORK_SCHEDULE,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_WORK_SCHEDULE,
      payload: { isUpdateRequest: false },
    })
  }
}

function* unarchiveManyWorkSchedule(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const workSchedule: IWorkScheduleState = yield select<any>(getWorkScheduleState)

    // validate and set requesting
    if (workSchedule.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_WORK_SCHEDULE,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(archiveManyWorkScheduleApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.unarchiveManyAction.title"),
          message: i18n.t("common.unarchiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_WORK_SCHEDULE,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_WORK_SCHEDULE,
      payload: { isUpdateRequest: false },
    })
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_LIST_WORK_SCHEDULE, getListWorkSchedule),
    takeLatest(actionTypes.GET_DETAIL_WORK_SCHEDULE, getDetailWorkSchedule),
    takeLatest(ActionTypes.CREATE_WORK_SCHEDULE, createWorkSchedule),
    takeLatest(actionTypes.DELETE_ONE_WORK_SCHEDULE, deleteOneWorkSchedule),
    takeLatest(actionTypes.UPDATE_DETAIL_WORK_SCHEDULE, updateDetailWorkSchedule),
    takeLatest(actionTypes.DELETE_MANY_WORK_SCHEDULE, deleteManyWorkSchedule),
    takeLatest(actionTypes.ARCHIVE_MANY_WORK_SCHEDULE, archiveManyWorkSchedule),
    takeLatest(actionTypes.UNARCHIVE_MANY_WORK_SCHEDULE, unarchiveManyWorkSchedule),
  ])
}
