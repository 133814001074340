import { GET_LIST_ACTION_SUCCESS, PAGINATION_DEFAULT } from "../../constants"
import { IActionState } from "../types"

interface ActionProps {
  type: string
  payload: any
}

const initState: IActionState = {
  list: {
    actions: [],
    total: 0,
    limit: PAGINATION_DEFAULT.limit,
    offset: PAGINATION_DEFAULT.offset,
  },
  detail: {
    actions: {},
  },
}

export const role = (state: IActionState = initState, action: ActionProps) => {
  const { type, payload } = action

  switch (type) {
    case GET_LIST_ACTION_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          actions: payload.data,
          total: payload.total,
        },
      }
    }

    default: {
      return state
    }
  }
}
