import {DebounceSelect} from "@/components/antd/form/DebounceSelect";
import {ACTION_METHOD_OBJ} from "@/constants";
import {layout} from "@/constants/layout";
import {API} from "@/network/http";
import {BaseUpsertFormProps} from "@/typing/common";
import {Button, Card, Col, Form, InputNumber, Modal, Row} from "antd";
import React, {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import {DEFINE_MODULE} from "../constants";

const FormItem = Form.Item;

const UpsertForm: React.FC<BaseUpsertFormProps> = forwardRef((props, ref) => {
  const {modalVisible, onCancel, onSubmit, actionType, values, loadingBtn} = props;
  const roomRef = useRef<any>();
  const [form] = Form.useForm();

  // as the second argument
  useImperativeHandle(ref, () => ({
    onResetData() {
      // reset sale table
      form.resetFields();
    },
  }));

  const onSelectLocationTrigger = () => {
    roomRef.current.triggerFetch();
  };

  const getSubjects = async (searchValue?: string) => {
    const response = await API.subjects.subjectControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const getLocations = async (searchValue?: string) => {
    const response = await API.locations.locationControllerGetMany({name: searchValue, });
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const getRooms = async (searchValue?: string) => {
    if (!form.getFieldValue('locationId')) return [];
    const response = await API.rooms.roomControllerGetMany({code: searchValue, locationId: form.getFieldValue('locationId')});
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.code,
      })) || []
    );
  };

  useEffect(() => {
    if (actionType === "UPDATE") {
      form.setFieldsValue({
        roomId: values.roomId,
        subjectId: values.subjectId,
        locationId: values.locationId,
        maxQuantity: values.maxQuantity,
      });
    }
  }, []);

  const handleSubmit = async () => {
    const fieldsValidation = await form.validateFields();
    if (!fieldsValidation) {
      return;
    }
    const formValues = {...form.getFieldsValue()};
    if (actionType === "CREATE") {
      onSubmit(formValues, actionType);
    }
    if (actionType === "UPDATE") {
      onSubmit(formValues, actionType, values._id);
    }
  };

  const onResetForm = (): void => {
    // reset sale table
    form.resetFields();
  };

  const renderContent = () => {
    return (
      <Card style={{paddingRight: "20px"}}>
        <Row gutter={24}>

          <Col span={24}>
            <FormItem
              name="subjectId"
              label="Môn học"
              rules={[{required: true, message: "Chọn môn học"}]}
            >
              <DebounceSelect
                placeholder="Chọn môn học"
                fetchOptions={getSubjects}
              />
            </FormItem>
          </Col>

          <Col span={24}>
            <FormItem
              name="locationId"
              label="Cơ sở"
              rules={[{required: true, message: "Chọn cơ sở"}]}
            >
              <DebounceSelect
                selectTrigger={onSelectLocationTrigger}
                placeholder="Chọn cơ sở"
                fetchOptions={getLocations}
              />
            </FormItem>
          </Col>

          <Col span={24}>
            <FormItem
              name="roomId"
              label="Phòng học"
              rules={[{required: true, message: "Chọn phòng học"}]}
            >
              <DebounceSelect
                ref={roomRef}
                placeholder="Chọn phòng học"
                fetchOptions={getRooms}
              />
            </FormItem>
          </Col>

          <Col span={24}>
            <FormItem
              name="maxQuantity"
              label="Sĩ số tối đa"
              rules={[{required: true, message: "Nhập sĩ số"}]}
            >
              <InputNumber
                style={{width: '100%'}}
                placeholder="Nhập sĩ số"
                max={10000}
              />
            </FormItem>
          </Col>
        </Row>
      </Card>
    );
  };

  const renderFooter = () => {
    return (
      <>
        {props.actionType === "CREATE" && <Button onClick={() => onResetForm()}>Làm mới</Button>}
        <Button loading={loadingBtn} type="primary" onClick={handleSubmit}>
          Đồng ý
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal
        bodyStyle={{background: "#F0F2F5"}}
        width={700}
        destroyOnClose
        maskClosable={false}
        title={`${ACTION_METHOD_OBJ[actionType]} ${DEFINE_MODULE.name}`}
        visible={modalVisible}
        onCancel={() => {
          onResetForm();
          onCancel();
        }}
        footer={renderFooter()}
      >
        <Form {...layout} form={form}>
          {renderContent()}
        </Form>
      </Modal>
    </>
  );
});

export default UpsertForm;
