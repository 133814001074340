import { isString } from "lodash"

export const convertDate = (dateEnum: string) => {
  switch (dateEnum) {
    case "MORNING":
      return { name: "Sáng", value: "MORNING" }
    case "AFTERNOON":
      return { name: "Chiều", value: "AFTERNOON" }
    case "EVENING":
      return { name: "Tối", value: "EVENING" }
    default:
      return null
  }
}

export const convertDateName = (dateEnum: string) => {
  if (!isString(dateEnum)) return ""
  const toLowerCase = dateEnum.toLowerCase()
  switch (toLowerCase) {
    case "monday":
      return "Thứ 2"
    case "tuesday":
      return "Thứ 3"
    case "wednesday":
      return "Thứ 4"
    case "thursday":
      return "Thứ 5"
    case "friday":
      return "Thứ 6"
    case "saturday":
      return "Thứ 7"
    case "sunday":
      return "Chủ nhật"
    default:
      return ""
  }
}
