import { deleteRequest, getRequest, postRequest, putRequest } from "../http"

interface IActions {
  name?: string
  key?: string
  ids?: string
  limit?: number
  offset?: number
}

interface IActionCreate {
  name: string
  key: string
  url: string
  method: string
}

interface IActionUpdate {
  name: string
  key: string
  url: string
  method: string
}

export const getActionsApi = async (data?: IActions) => {
  const url = "/actions"
  return await getRequest(url, { params: data })
}

export const getActionDetailApi = async (id: string) => {
  const url = `/actions/${id}`
  return await getRequest(url)
}

export const createActionApi = async (data: IActionCreate) => {
  const url = `/actions`
  return await postRequest(url, data)
}

export const updateActionApi = async (id: string, data: IActionUpdate) => {
  const url = `/actions/${id}`
  return await putRequest(url, data)
}

export const deleteActionApi = async (id: string) => {
  const url = `/actions/${id}`
  return await deleteRequest(url)
}
