import { EduPaths } from "@/modules/edu"
import i18n from "i18n-js"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import * as actionTypes from "../../constants/ActionTypes"
import history from "../../navigation"
import {
  archiveManyAbsentScheduleApi,
  createAbsentScheduleApi,
  deleteAbsentScheduleApi,
  deleteManyAbsentScheduleApi,
  getAbsentScheduleDetailApi,
  getAbsentSchedulesApi,
  updateAbsentScheduleApi,
  updateStatusAbsentScheduleApi,
} from "../../network"
import { convertObjectParamsToStringParams, getInitParams } from "../../utils"
import { getAbsentScheduleState } from "../selectors"
import { IAbsentScheduleState } from "../types"

interface IPayload {
  type: string
  payload: any
}

interface IResponse {
  data: any
  total: number
}

function* getListAbsentSchedule(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const absentSchedule: IAbsentScheduleState = yield select<any>(getAbsentScheduleState)

    // validate and set requesting
    if (absentSchedule.isLoadingRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isLoadingRequest: true },
    })

    // get list
    const { data, total }: IResponse = yield call(getAbsentSchedulesApi, action.payload)
    yield history.push(`?${convertObjectParamsToStringParams(action.payload)}`)

    // update to state
    yield put({
      type: actionTypes.GET_LIST_ABSENT_SCHEDULE_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({ type: actionTypes.GET_LIST_ABSENT_SCHEDULE_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isLoadingRequest: false },
    })
  }
}

function* getDetailAbsentSchedule(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const absentSchedule: IAbsentScheduleState = yield select<any>(getAbsentScheduleState)

    // validate and set requesting
    if (absentSchedule.isLoadingRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isLoadingRequest: true },
    })

    // call api
    const { data, total }: IResponse = yield call(getAbsentScheduleDetailApi, action.payload)

    // update to state
    yield put({
      type: actionTypes.GET_DETAIL_ABSENT_SCHEDULE_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({ type: actionTypes.GET_DETAIL_ABSENT_SCHEDULE_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isLoadingRequest: false },
    })
  }
}

function* updateStatusAbsentSchedule(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const absentSchedule: IAbsentScheduleState = yield select<any>(getAbsentScheduleState)

    // validate and set requesting
    if (absentSchedule.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isUpdateRequest: true },
    })

    // call api update
    const { data }: IResponse = yield call(
      updateAbsentScheduleApi,
      action.payload.absentScheduleId,
      action.payload.data,
    )

    yield put({
      type: actionTypes.UPDATE_STATUS_ABSENT_SCHEDULE_SUCCESS,
    })

    // update to state
    yield put({
      type: actionTypes.GET_DETAIL_ABSENT_SCHEDULE_SUCCESS,
      payload: { data: null, total: 0 },
    })

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật trạng thái thành công",
        message: data?.message || "",
        type: "success",
      },
    })
    history.push(EduPaths.ABSENT_SCHEDULE.LIST.PATH)
  } catch (error) {
    console.log("e: ", error)
    yield put({ type: actionTypes.UPDATE_STATUS_ABSENT_SCHEDULE_SUCCESS, payload: error })
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thất bại",
        message: `${error}` || "",
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isUpdateRequest: false },
    })
  }
}

function* updateDetailAbsentSchedule(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const absentSchedule: IAbsentScheduleState = yield select<any>(getAbsentScheduleState)

    // validate and set requesting
    if (absentSchedule.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isUpdateRequest: true },
    })

    // call api update
    const { data }: IResponse = yield call(
      updateStatusAbsentScheduleApi,
      action.payload.absentScheduleId,
      action.payload.data,
    )

    yield put({
      type: actionTypes.UPDATE_DETAIL_ABSENT_SCHEDULE_SUCCESS,
    })

    // update to state
    yield put({
      type: actionTypes.GET_DETAIL_ABSENT_SCHEDULE_SUCCESS,
      payload: { data: null, total: 0 },
    })

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thành công",
        message: data?.message || "",
        type: "success",
      },
    })
    history.push(EduPaths.ABSENT_SCHEDULE.LIST.PATH)
  } catch (error) {
    console.log("e: ", error)
    yield put({ type: actionTypes.UPDATE_DETAIL_ABSENT_SCHEDULE_FAILED, payload: error })
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thất bại",
        message: `${error}` || "",
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isUpdateRequest: false },
    })
  }
}

function* createAbsentSchedule(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const absentSchedule: IAbsentScheduleState = yield select<any>(getAbsentScheduleState)

    // validate and set requesting
    if (absentSchedule.isCreateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isCreateRequest: true },
    })

    // call api create
    const { data, total }: IResponse = yield call(createAbsentScheduleApi, action.payload)

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Thêm mới thành công",
        message: data?.message || "",
        type: "success",
      },
    })

    // navigate
    history.push("/absent-schedule")
  } catch (error) {
    yield put({ type: actionTypes.CREATE_ABSENT_SCHEDULE_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isCreateRequest: false },
    })
  }
}

function* deleteOneAbsentSchedule(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const absentSchedule: IAbsentScheduleState = yield select<any>(getAbsentScheduleState)

    // validate and set requesting
    if (absentSchedule.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(deleteAbsentScheduleApi, action.payload.id)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteAction.deleteSuccess"),
          message: i18n.t("common.deleteAction.deleteSuccess"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_ABSENT_SCHEDULE,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isDeleteRequest: false },
    })
  }
}

function* deleteManyAbsentSchedule(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const absentSchedule: IAbsentScheduleState = yield select<any>(getAbsentScheduleState)

    // validate and set requesting
    if (absentSchedule.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(deleteManyAbsentScheduleApi, action.payload.ids)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteManyAction.title"),
          message: i18n.t("common.deleteManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_ABSENT_SCHEDULE,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isDeleteRequest: false },
    })
  }
}

function* archiveManyAbsentSchedule(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const absentSchedule: IAbsentScheduleState = yield select<any>(getAbsentScheduleState)

    // validate and set requesting
    if (absentSchedule.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(archiveManyAbsentScheduleApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.archiveManyAction.title"),
          message: i18n.t("common.archiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_ABSENT_SCHEDULE,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isUpdateRequest: false },
    })
  }
}

function* unarchiveManyAbsentSchedule(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const absentSchedule: IAbsentScheduleState = yield select<any>(getAbsentScheduleState)

    // validate and set requesting
    if (absentSchedule.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(archiveManyAbsentScheduleApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.unarchiveManyAction.title"),
          message: i18n.t("common.unarchiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_ABSENT_SCHEDULE,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_ABSENT_SCHEDULE,
      payload: { isUpdateRequest: false },
    })
  }
}

export default function* root() {
  yield all([
    takeLatest(actionTypes.GET_LIST_ABSENT_SCHEDULE, getListAbsentSchedule),
    takeLatest(actionTypes.GET_DETAIL_ABSENT_SCHEDULE, getDetailAbsentSchedule),
    takeLatest(actionTypes.UPDATE_DETAIL_ABSENT_SCHEDULE, updateDetailAbsentSchedule),
    takeLatest(actionTypes.UPDATE_STATUS_ABSENT_SCHEDULE, updateStatusAbsentSchedule),
    takeLatest(actionTypes.CREATE_ABSENT_SCHEDULE, createAbsentSchedule),
    takeLatest(actionTypes.DELETE_ONE_ABSENT_SCHEDULE, deleteOneAbsentSchedule),
    takeLatest(actionTypes.DELETE_MANY_ABSENT_SCHEDULE, deleteManyAbsentSchedule),
    takeLatest(actionTypes.ARCHIVE_MANY_ABSENT_SCHEDULE, archiveManyAbsentSchedule),
    takeLatest(actionTypes.UNARCHIVE_MANY_ABSENT_SCHEDULE, unarchiveManyAbsentSchedule),
  ])
}
