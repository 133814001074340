import { API } from "@/network/http"
import { SubjectDto } from "@/__generated__/api-v1"
import { PageContainer } from "@ant-design/pro-layout"
import type { ProColumns } from "@ant-design/pro-table"
import ProTable from "@ant-design/pro-table"
import { Empty } from "antd"
import { FC } from "react"

const ListReplace: FC = () => {
  const fetchData = async (queries: Parameters<typeof API.subjects.subjectControllerGetMany>["0"]) => {
    return []
    // return (await API.subjects.subjectControllerGetMany({ ...queries })).data
  }

  const columns: ProColumns<any>[] = [
    {
      dataIndex: "date",
      title: "Ngày",
      search: false,
    },
    {
      dataIndex: "locationId",
      title: "Cơ sở",
      search: false,
    },
    {
      dataIndex: "day",
      title: "Thứ",
      search: false,
    },
    {
      dataIndex: "role",
      title: "Vai trò",
      search: false,
    },
    {
      dataIndex: "employeeReplaceId",
      title: "Nhân sự thay thế",
      search: false,
    }
  ]


  return (
    <PageContainer>
      <ProTable<SubjectDto>
        headerTitle={`Lịch làm việc liên quan`}
        bordered
        rowKey="_id"
        search={false}
        request={params => {
          const { pageSize, current, keyword, ...rest } = params
          return fetchData({
            ...(pageSize ? { limit: String(pageSize) } : {}),
            ...(pageSize ? { offset: String((Number(current) - 1) * pageSize) } : {}),
            ...rest,
          })
        }}
        options={{ density: false, fullScreen: false }}
        pagination={false}
        toolBarRender={false}
        columns={columns}
        sticky={{ offsetScroll: 10 }}
        scroll={{ x: 1300, y: 430 }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Không có dữ liệu"} />,
        }}
      />
    </PageContainer>
  )
}

export default ListReplace
