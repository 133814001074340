import { MenuItem, menuItems } from "@/components/layout/menuItems"
import { Breadcrumb } from "antd"
import { split, startsWith, trimStart } from "lodash"
import { FC } from "react"
import { useLocation } from "react-router-dom"

export const Breadcrumbs: FC = () => {
  const location = useLocation()
  const parts = split(trimStart(location.pathname, "/"), "/")
  const parent = menuItems.find(
    e =>
      // @ts-ignore
      (e?.parent === parts[0] || !e?.parent) && e.children.find((e: MenuItem) => startsWith(location.pathname, e.key)),
  )

  // @ts-ignore
  const children: MenuItem | null = parent ? parent.children.find(e => startsWith(location.pathname, e.key)) : null

  return (
    <Breadcrumb style={{ margin: "16px 0" }}>
      <Breadcrumb.Item>MTB</Breadcrumb.Item>
      {parent && (
        // @ts-ignore
        <Breadcrumb.Item>{parent?.label}</Breadcrumb.Item>
      )}
      {/* @ts-ignore */}
      {children && <Breadcrumb.Item>{children?.label}</Breadcrumb.Item>}
    </Breadcrumb>
  )
}
