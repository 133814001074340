import { API } from "@/network/http"
import { SubjectDto } from "@/__generated__/api-v1"
import { useCallback, useEffect, useState } from "react"

type SubjectQuery = Parameters<typeof API.subjects.subjectControllerGetMany>["0"]
export const useSubjects = (init = true) => {
  const [subjects, setSubjects] = useState<SubjectDto[]>([])

  const fetchSubjects = useCallback(async (query: SubjectQuery = { limit: "100" }) => {
    const response = await API.subjects.subjectControllerGetMany(query)

    setSubjects(response.data.data)
    return response.data.data
  }, [])

  useEffect(() => {
    if (init) {
      fetchSubjects(undefined)
    }
  }, [init])

  return { fetchSubjects, subjects }
}
