import { Upsert } from "@/modules/hr/pages/employee/views/Upsert"
import { Route, Routes } from "react-router-dom"
import List from "./views/List"

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<List />}>
        <Route path="create" element={<Upsert />} />
        <Route path=":id" element={<Upsert />} />
      </Route>
    </Routes>
  )
}
