import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../http"

interface ITeacher {
  name?: string
  limit?: number
  offset?: number
}

interface ITeacherCreate {
  name: string
  employeeId: string
  teacherRoleIds: string[]
}

interface ITeacherUpdate {
  name: string
  employeeId: string
  teacherRoleIds: string[]
}

interface ITeacherArchive {
  ids: Array<string>
  isArchived: boolean
}

export const getTeacherApi = async (data?: ITeacher) => {
  const url = "/teachers"
  return await getRequest(url, { params: data })
}

export const getTeacherDetailApi = async (id: string) => {
  const url = `/teachers/${id}`
  return await getRequest(url)
}

export const createTeacherApi = async (data: ITeacherCreate) => {
  const url = `/teachers`
  return await postRequest(url, data)
}

export const updateTeacherApi = async (id: string, data: ITeacherUpdate) => {
  const url = `/teachers/${id}`
  return await putRequest(url, data)
}

export const archiveManyTeacherApi = async (data: ITeacherArchive) => {
  const url = `/teachers`
  return await patchRequest(url, data)
}

export const deleteTeacherApi = async (id: string) => {
  const url = `/teachers/${id}`
  return await deleteRequest(url)
}

export const deleteManyTeacherApi = async (ids: string[]) => {
  const url = `/teachers`
  return await deleteRequest(url, { data: { ids } })
}
