import { DebounceSelect } from "@/components/antd/form/DebounceSelect"
import { useTeachers } from "@/modules/edu/pages/configuration/teacher/hooks"
import { SelectOptionSearchProps } from "@/typing/common"
import { Button, Col, Form, Modal, Row } from "antd"
import { useCallback, useRef } from "react"

interface Props<T> {
  name: T
  visible: boolean
  onClose: () => void
  onSubmit: (name: T, item: SelectOptionSearchProps) => void
}

const FormItem = Form.Item

export const MoreStaffModal = <T,>(props: Props<T>) => {
  const [form] = Form.useForm()
  const ref = useRef()

  const { fetchTeachers, teachers } = useTeachers(false)
  const fetcher = useCallback(
    async (query: string) => {
      const data = await fetchTeachers({ name: query })
      return data?.map(item => ({
        key: item?.employeeId,
        value: item?.employeeId,
        name: item?.employee?.name,
      }))
    },
    [fetchTeachers],
  )

  const onResetForm = () => {
    form.resetFields()
  }

  const onSubmit = () => {
    const id = form.getFieldValue("staff")
    const teacher = teachers.find(e => {
      return e.employeeId === id
    })!

    props.onSubmit(props.name, {
      key: teacher.employeeId,
      label: teacher.employee.name,
      value: teacher.employeeId,
      id: teacher.employeeId,
    })
  }

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      title={`Tìm kiếm nhân viên`}
      visible={props.visible}
      onCancel={() => {
        onResetForm()
        props.onClose()
      }}
      footer={
        <>
          <Button
            onClick={() => {
              onResetForm()
              props.onClose()
            }}
          >
            Đóng
          </Button>
          <Button
            type="primary"
            onClick={() => {
              onSubmit()
              onResetForm()
              props.onClose()
            }}
          >
            Chọn
          </Button>
        </>
      }
    >
      <Form form={form}>
        <Row gutter={12}>
          <Col span={24}>
            <FormItem name="staff">
              <DebounceSelect ref={ref} placeholder="Tìm kiếm" fetchOptions={fetcher} />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
