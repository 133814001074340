import FormItemData from "@/components/antd/form";
import {ACTION_METHOD_OBJ} from "@/constants";
import {layout} from "@/constants/layout";
import {API} from "@/network/http";
import {BaseUpsertFormProps} from "@/typing/common";
import {Button, Card, Form, Modal, Row} from "antd";
import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import {DEFINE_MODULE} from "../constants";

const UpsertForm: React.FC<BaseUpsertFormProps> = forwardRef((props, ref) => {
  const {modalVisible, onCancel, onSubmit, actionType, values, loadingBtn} = props;
  const [form] = Form.useForm();

  // as the second argument
  useImperativeHandle(ref, () => ({
    onResetData() {
      // reset sale table
      form.resetFields();
    },
  }));

  const getSubjectType = async (searchValue?: string) => {
    const response = await API.subjectTypes.subjectTypeControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const getSubjectObject = async (searchValue?: string) => {
    const response = await API.subjectObjects.subjectObjectControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const getSubjectForm = async (searchValue?: string) => {
    const response = await API.subjectForms.subjectFormControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const getSubjectLevel = async (searchValue?: string) => {
    const response = await API.subjectLevels.subjectLevelControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  useEffect(() => {
    if (actionType === "UPDATE") {
      form.setFieldsValue({
        name: values.name,
        code: values.code,
        numberOfLessons: values.numberOfLessons,
        subjectTypeId: values.subjectTypeId,
        subjectObjectId: values.subjectObjectId,
        subjectLevelId: values.subjectLevelId,
        subjectFormId: values.subjectFormId,
      });
    }
  }, []);

  const handleSubmit = async () => {
    const fieldsValidation = await form.validateFields();
    if (!fieldsValidation) {
      return;
    }
    const formValues = {...form.getFieldsValue()};
    if (actionType === "CREATE") {
      onSubmit(formValues, actionType);
    }
    if (actionType === "UPDATE") {
      onSubmit(formValues, actionType, values._id);
    }
  };

  const onResetForm = (): void => {
    // reset sale table
    form.resetFields();
  };

  const renderContentList = [
    {
      inputType: "input",
      name: "name",
      label: "Tên môn học",
      placeholder: "Nhập tên môn học",
      rules: [{required: true, message: "Nhập tên môn học"}],
    },
    {
      inputType: "input",
      name: "code",
      label: "Mã môn học",
      placeholder: "Nhập mã môn học",
      rules: [{required: true, message: "Nhập mã môn học"}],
    },
    {
      inputType: "number",
      name: "numberOfLessons",
      label: "Số buổi",
      placeholder: "Nhập số buổi",
      rules: [{required: true, message: "Nhập số buổi"}],
    },
    {
      inputType: "selectsearch",
      name: "subjectTypeId",
      label: "Phân loại",
      placeholder: "Chọn phân loại",
      fetchOptions: getSubjectType,
      rules: [{required: true, message: "Chọn phân loại"}],
    },
    {
      inputType: "selectsearch",
      name: "subjectObjectId",
      label: "Đối tượng",
      placeholder: "Chọn đối tượng",
      fetchOptions: getSubjectObject,
      rules: [{required: true, message: "Chọn đối tượng"}],
    },
    {
      inputType: "selectsearch",
      name: "subjectFormId",
      label: "Hình thức",
      placeholder: "Chọn hình thức",
      fetchOptions: getSubjectForm,
      rules: [{required: true, message: "Chọn hình thức"}],
    },
    {
      inputType: "selectsearch",
      name: "subjectLevelId",
      label: "Trình độ",
      placeholder: "Chọn trình độ",
      fetchOptions: getSubjectLevel,
      rules: [{required: true, message: "Chọn trình độ"}],
    },
  ];

  const renderContent = () => {
    return (
      <Card style={{paddingRight: "20px"}}>
        <Row gutter={24}>{renderContentList.map(item => item && <FormItemData key={item.name} {...item} />)}</Row>
      </Card>
    );
  };

  const renderFooter = () => {
    return (
      <>
        {props.actionType === "CREATE" && <Button onClick={() => onResetForm()}>Làm mới</Button>}
        <Button loading={loadingBtn} type="primary" onClick={handleSubmit}>
          Đồng ý
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal
        bodyStyle={{background: "#F0F2F5"}}
        width={700}
        destroyOnClose
        maskClosable={false}
        title={`${ACTION_METHOD_OBJ[actionType]} ${DEFINE_MODULE.name}`}
        visible={modalVisible}
        onCancel={() => {
          onResetForm();
          onCancel();
        }}
        footer={renderFooter()}
      >
        <Form {...layout} form={form}>
          {renderContent()}
        </Form>
      </Modal>
    </>
  );
});

export default UpsertForm;
