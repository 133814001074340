import { Paths, PERMISSIONS } from "@/constants"
import { EduPaths } from "@/modules/edu"
import { generateEduPath } from "@/modules/edu/utils"
import { HrPaths } from "@/modules/hr"
import { generateHrPath } from "@/modules/hr/utils"
import type { ItemType } from "antd/lib/menu/hooks/useItems"
import { useLocation } from "react-router-dom"

export type MenuItem = ItemType & {
  path?: string
  permissionkey: string
  parent?: string
}

export const coreItems: MenuItem[] = [
  {
    key: Paths.USER.LIST.PATH,
    label: Paths.USER.LIST.LABEL,
    permissionkey: PERMISSIONS.USER_GET_MANY,
  },
  {
    key: Paths.ROLE.LIST.PATH,

    label: Paths.ROLE.LIST.LABEL,
    permissionkey: PERMISSIONS.ROLE_GET_MANY,
  },
  // {
  //   key: Paths.PERMISSION.LIST.PATH,
  //
  //   label: "Quyền hạn",
  //   permissionkey: PERMISSIONS.PERMISSION_GET_MANY,
  // },
]

export const hrItems: MenuItem[] = [
  {
    key: generateHrPath(HrPaths.DEPARTMENT.LIST.PATH),
    label: HrPaths.DEPARTMENT.LIST.LABEL,
    permissionkey: PERMISSIONS.DEPARTMENT_GET_MANY,
  },
  {
    key: generateHrPath(HrPaths.EMPLOYEE.LIST.PATH),
    label: HrPaths.EMPLOYEE.LIST.LABEL,
    permissionkey: PERMISSIONS.EMPLOYEE_GET_MANY,
  },
]

export const eduConfigurationItems: MenuItem[] = [
  {
    key: generateEduPath(EduPaths.LOCATION.LIST.PATH),
    label: EduPaths.LOCATION.LIST.LABEL,
    permissionkey: PERMISSIONS.LOCATION_GET_MANY,
  },
  {
    key: generateEduPath(EduPaths.ROOM.LIST.PATH),
    label: EduPaths.ROOM.LIST.LABEL,
    permissionkey: PERMISSIONS.ROOM_GET_MANY,
  },
  {
    key: generateEduPath(EduPaths.SUBJECT.LIST.PATH),
    label: EduPaths.SUBJECT.LIST.LABEL,
    permissionkey: PERMISSIONS.SUBJECT_GET_MANY,
  },
  {
    key: generateEduPath(EduPaths.CLASS_NUMBER.LIST.PATH),
    label: EduPaths.CLASS_NUMBER.LIST.LABEL,
    permissionkey: PERMISSIONS.CLASS_NUMBER_GET_MANY,
  },
  {
    key: generateEduPath(EduPaths.SCHOOL_HOUR.LIST.PATH),
    label: EduPaths.SCHOOL_HOUR.LIST.LABEL,
    permissionkey: PERMISSIONS.SCHOOL_HOUR_GET_MANY,
  },
  {
    key: generateEduPath(EduPaths.TEACHER_ROLE.LIST.PATH),
    label: EduPaths.TEACHER_ROLE.LIST.LABEL,
    permissionkey: PERMISSIONS.TEACHER_ROLE_GET_MANY,
  },
]

export const eduOperationItems: MenuItem[] = [
  {
    key: generateEduPath(EduPaths.TEACHER.LIST.PATH),
    label: EduPaths.TEACHER.LIST.LABEL,
    permissionkey: PERMISSIONS.TEACHER_GET_MANY,
  },
  {
    key: generateEduPath(EduPaths.ABSENT_SCHEDULE.LIST.PATH),
    label: EduPaths.ABSENT_SCHEDULE.LIST.LABEL,
    permissionkey: PERMISSIONS.ABSENT_SCHEDULE_GET_MANY,
  },
  {
    key: generateEduPath(EduPaths.WORK_SCHEDULE.LIST.PATH),
    label: EduPaths.WORK_SCHEDULE.LIST.LABEL,
    permissionkey: PERMISSIONS.WORK_SCHEDULE_GET_MANY,
  },
  {
    key: generateEduPath(EduPaths.CLASSES.LIST.PATH),
    label: EduPaths.CLASSES.LIST.LABEL,
    permissionkey: PERMISSIONS.CLASSES_GET_MANY,
  },
  {
    key: generateEduPath(EduPaths.WORK_TIME.LIST.PATH),
    label: EduPaths.WORK_TIME.LIST.LABEL,
    permissionkey: PERMISSIONS.WORK_TIME_GET_MANY,
  },
  {
    key: generateEduPath(EduPaths.AVAILABILITY.LIST.PATH),
    label: EduPaths.AVAILABILITY.LIST.LABEL,
    permissionkey: PERMISSIONS.AVAILABILITY_CHECK,
  },
  {
    key: generateEduPath(EduPaths.TEACH_SCHEDULE.LIST.PATH),
    label: EduPaths.TEACH_SCHEDULE.LIST.LABEL,
    permissionkey: PERMISSIONS.TEACH_SCHEDULE_GET_MANY,
  },
]

export const menuItems: Array<ItemType & { parent?: string }> = [
  { label: "Quản Lí Chung", key: "core", children: coreItems },
  { label: "Nhân Sự", key: "hr", parent: "hr", children: hrItems },
  { label: "Cấu Hình", key: "edu-configuration", parent: "edu", children: eduConfigurationItems },
  { label: "Vận Hành", key: "edu-operation", parent: "edu", children: eduOperationItems },
]

export const WIDE_LAYOUT_ROUTES = ["/edu/work-times"]

export const useIsWideLayout = (): boolean => {
  const location = useLocation()

  return !!WIDE_LAYOUT_ROUTES.find(e => location.pathname.startsWith(e))
}
