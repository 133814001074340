import { API } from "@/network/http"
import { EmployeeDto } from "@/__generated__/api-v1"
import { useCallback, useEffect, useState } from "react"

type EmployeesQuery = Parameters<typeof API.employees.employeeControllerGetMany>["0"]
export const useEmployees = (init = true) => {
  const [employees, setEmployees] = useState<EmployeeDto[]>([])

  const fetchEmployees = useCallback(async (query: EmployeesQuery = { limit: "100" }) => {
    const response = await API.employees.employeeControllerGetMany(query)

    setEmployees(response.data.data)
    return response.data.data
  }, [])

  useEffect(() => {
    if (init) {
      fetchEmployees(undefined)
    }
  }, [init])

  return { fetchEmployees, employees }
}

type EmployeeQuery = Parameters<typeof API.employees.employeeControllerGetOne>["0"]
export const useEmployee = () => {
  const [employee, setEmployee] = useState<EmployeeDto>()

  const fetchEmployee = useCallback(async (query: EmployeeQuery) => {
    const response = await API.employees.employeeControllerGetOne(query)

    setEmployee(response.data.data)
    return response.data.data
  }, [])

  return { fetchEmployee, employee }
}
