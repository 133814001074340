import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../http"

interface IWorkSchedule {
  name?: string
  limit?: number
  offset?: number
}

interface IList {
  type: string
  monday: boolean
  tuesday: boolean
  wednesday: boolean
  thursday: boolean
  friday: boolean
  saturday: boolean
  sunday: boolean
}

interface IWorkScheduleCreate {
  employeeId: string
  fromDate: string
  toDate: string
  maxNumberOfShiftsInMonth: number
  numberOfShiftsInMonth: number
  note: string
  list: IList[]
}

interface IWorkScheduleUpdate {
  name: string
  employeeId: string
}

interface IWorkScheduleArchive {
  ids: Array<string>
  isArchived: boolean
}

export const getWorkScheduleApi = async (data?: IWorkSchedule) => {
  const url = "/work-schedules"
  return await getRequest(url, { params: data })
}

export const getWorkScheduleDetailApi = async (id: string) => {
  const url = `/work-schedules/${id}`
  return await getRequest(url)
}

export const createWorkScheduleApi = async (data: IWorkScheduleCreate) => {
  const url = `/work-schedules`
  return await postRequest(url, data)
}

export const updateWorkScheduleApi = async (id: string, data: IWorkScheduleUpdate) => {
  const url = `/work-schedules/${id}`
  return await putRequest(url, data)
}

export const archiveManyWorkScheduleApi = async (data: IWorkScheduleArchive) => {
  const url = `/work-schedules`
  return await patchRequest(url, data)
}

export const deleteWorkScheduleApi = async (id: string) => {
  const url = `/work-schedules/${id}`
  return await deleteRequest(url)
}

export const deleteManyWorkScheduleApi = async (ids: string[]) => {
  const url = `/work-schedules`
  return await deleteRequest(url, { data: { ids } })
}
