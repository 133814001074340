export const ACCESS_TOKEN = "access-token"
export const IS_AUTHENTICATED = "is-authenticated"
export const USER_INFO = "user-info"
export const PAGINATION_DEFAULT = { limit: 20, offset: 0 }
export const GETLIST_DEFAULT = { isArchived: false }
export const FORMAT_MOMENT = {
  DDMMYYY: "DD-MM-YYYY HH:MM:SS",
}

export const ACTION_METHOD_OBJ = {
  CREATE: "Tạo",
  UPDATE: "Cập nhật",
  READ: "Chi tiết",
  DELETE: "Xóa",
  ARCHIVE: "Lưu trữ",
  UNARCHIVE: "Hủy lưu trữ",
  ERROR: "Có lỗi xảy ra, vui lòng thử lại!",
}

export const PHONE_REGEX = /((^(\+84|84|0|0084){1})(3|5|7|8|9))+([0-9]{8})$/
export const NORMAL_CHARACTER_REGEX = /^([a-zA-Z0-9 _ \@.]+)$/
export const PERMISSIONS = {
  // user
  USER_GET_MANY: "user.get_many",
  USER_GET_ONE: "user.get_one",
  USER_CREATE: "user.create",
  USER_UPDATE: "user.update",
  USER_DELETE: "user.delete",

  // room
  ROOM_GET_MANY: "room.get_many",
  ROOM_GET_ONE: "room.get_one",
  ROOM_CREATE: "room.create",
  ROOM_UPDATE: "room.update",
  ROOM_DELETE: "room.delete",

  // subject
  SUBJECT_GET_MANY: "subject.get_many",
  SUBJECT_GET_ONE: "subject.get_one",
  SUBJECT_CREATE: "subject.create",
  SUBJECT_UPDATE: "subject.update",
  SUBJECT_DELETE: "subject.delete",

  // location
  LOCATION_GET_MANY: "location.get_many",
  LOCATION_GET_ONE: "location.get_one",
  LOCATION_CREATE: "location.create",
  LOCATION_UPDATE: "location.update",
  LOCATION_DELETE: "location.delete",

  // role
  ROLE_GET_MANY: "role.get_many",
  ROLE_GET_ONE: "role.get_one",
  ROLE_CREATE: "role.create",
  ROLE_UPDATE: "role.update",
  ROLE_DELETE: "role.delete",

  // role
  PERMISSION_GET_MANY: "permission.get_many",
  PERMISSION_GET_ONE: "permission.get_one",
  PERMISSION_CREATE: "permission.create",
  PERMISSION_UPDATE: "permission.update",
  PERMISSION_DELETE: "permission.delete",

  // department
  DEPARTMENT_GET_MANY: "department.get_many",
  DEPARTMENT_GET_ONE: "department.get_one",
  DEPARTMENT_CREATE: "department.create",
  DEPARTMENT_UPDATE: "department.update",
  DEPARTMENT_DELETE: "department.delete",

  // course
  COURSE_GET_MANY: "course.get_many",
  COURSE_GET_ONE: "course.get_one",
  COURSE_CREATE: "course.create",
  COURSE_UPDATE: "course.update",
  COURSE_DELETE: "course.delete",

  // school hour
  SCHOOL_HOUR_GET_MANY: "school_hour.get_many",
  SCHOOL_HOUR_GET_ONE: "school_hour.get_one",
  SCHOOL_HOUR_CREATE: "school_hour.create",
  SCHOOL_HOUR_UPDATE: "school_hour.update",
  SCHOOL_HOUR_DELETE: "school_hour.delete",

  // absent schedule
  ABSENT_SCHEDULE_GET_MANY: "absent_schedule.get_many",
  ABSENT_SCHEDULE_GET_ONE: "absent_schedule.get_one",
  ABSENT_SCHEDULE_CREATE: "absent_schedule.create",
  ABSENT_SCHEDULE_UPDATE: "absent_schedule.update",
  ABSENT_SCHEDULE_DELETE: "absent_schedule.delete",
  ABSENT_SCHEDULE_VIEW_DETAIL: "absent_schedule.view_detail",

  // employee
  EMPLOYEE_GET_MANY: "employee.get_many",
  EMPLOYEE_GET_ONE: "employee.get_one",
  EMPLOYEE_CREATE: "employee.create",
  EMPLOYEE_UPDATE: "employee.update",
  EMPLOYEE_DELETE: "employee.delete",

  // teacher role
  TEACHER_ROLE_GET_MANY: "teacher_role.get_many",
  TEACHER_ROLE_GET_ONE: "teacher_role.get_one",
  TEACHER_ROLE_CREATE: "teacher_role.create",
  TEACHER_ROLE_UPDATE: "teacher_role.update",
  TEACHER_ROLE_DELETE: "teacher_role.delete",

  // class number
  CLASS_NUMBER_GET_MANY: "classNumber.get_many",
  CLASS_NUMBER_GET_ONE: "classNumber.get_one",
  CLASS_NUMBER_CREATE: "classNumber.create",
  CLASS_NUMBER_UPDATE: "classNumber.update",
  CLASS_NUMBER_DELETE: "classNumber.delete",

  // role
  TEACHER_GET_MANY: "teacher.get_many",
  TEACHER_GET_ONE: "teacher.get_one",
  TEACHER_CREATE: "teacher.create",
  TEACHER_UPDATE: "teacher.update",
  TEACHER_DELETE: "teacher.delete",

  // work schedule
  WORK_SCHEDULE_GET_MANY: "workSchedule.get_many",
  WORK_SCHEDULE_GET_ONE: "workSchedule.get_one",
  WORK_SCHEDULE_CREATE: "workSchedule.create",
  WORK_SCHEDULE_UPDATE: "workSchedule.update",
  WORK_SCHEDULE_DELETE: "workSchedule.delete",

  // classes
  CLASSES_GET_MANY: "classes.get_many",
  CLASSES_GET_ONE: "classes.get_one",
  CLASSES_CREATE: "classes.create",
  CLASSES_UPDATE: "classes.update",
  CLASSES_DELETE: "classes.delete",

  // work time
  WORK_TIME_GET_MANY: "work_time.get_many",
  WORK_TIME_GET_ONE: "work_time.get_one",
  WORK_TIME_CREATE: "work_time.create",
  WORK_TIME_UPDATE: "work_time.update",
  WORK_TIME_DELETE: "work_time.delete",

  // availability
  AVAILABILITY_CHECK: "availability.check",

  // teach schedule
  TEACH_SCHEDULE_GET_MANY: "teachSchedule.get_many",
  TEACH_SCHEDULE_GET_ONE: "teachSchedule.get_one",
  TEACH_SCHEDULE_CREATE: "teachSchedule.create",
  TEACH_SCHEDULE_UPDATE: "teachSchedule.update",
  TEACH_SCHEDULE_DELETE: "teachSchedule.delete",
}

export const DATE_OF_WEEK_OPTIONS = [
  {
    id: "",
    value: "",
    label: "Chọn",
    text: "Chọn",
  },
  {
    id: "monday",
    value: "monday",
    label: "Thứ 2",
    text: "Thứ 2",
  },
  {
    id: "tuesday",
    value: "tuesday",
    label: "Thứ 3",
    text: "Thứ 3",
  },
  {
    id: "wednesday",
    value: "wednesday",
    label: "Thứ 4",
    text: "Thứ 4",
  },
  {
    id: "thursday",
    value: "thursday",
    label: "Thứ 5",
    text: "Thứ 5",
  },
  {
    id: "friday",
    value: "friday",
    label: "Thứ 6",
    text: "Thứ 6",
  },
  {
    id: "saturday",
    value: "saturday",
    label: "Thứ 7",
    text: "Thứ 7",
  },
  {
    id: "sunday",
    value: "sunday",
    label: "Chủ nhật",
    text: "Chủ nhật",
  },
]

export const WORK_TIME_STATUS_OPTIONS = [
  {
    id: "",
    value: "",
    label: "Chọn",
  },
  {
    id: "INIT",
    value: "INIT",
    label: "Khởi tạo",
  },
  {
    id: "ACTIVE",
    value: "ACTIVE",
    label: "Đã xử lý",
  },
  {
    id: "CANCELED",
    value: "CANCELED",
    label: "Đã huỷ",
  },
]
