import {StudyScheduleDto} from "@/__generated__/api-v1";
import {Button, Modal} from "antd";
import {Link} from "react-router-dom";


interface ModalWarningProps {
  visible: boolean;
  data: StudyScheduleDto[];
  onCancel: () => void;
}

const ModalWarning: React.FC<ModalWarningProps> = ({visible, data, onCancel}) => {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title="Thông báo"
      bodyStyle={{maxHeight: 600, overflow: 'auto'}}
    >
      {data?.map(item => <div key={item._id}>
        <div>
          Phòng <b style={{color: 'red'}}>{item.room?.code}</b>{' '}
          không đáp ứng <b style={{color: 'red'}}> Buổi {item.lesson}</b>{' '}
          ngày <b style={{color: 'red'}}>{item.schoolHour?.startHour}</b>
        </div>
        <div>
          Giáo viên <b style={{color: 'red'}}> {item.lecturer.name}</b>{' '}
          không đáp ứng <b style={{color: 'red'}}>Buổi {item.lesson}</b>{' '}
          ngày <b style={{color: 'red'}}>{item.schoolHour?.startHour}</b>
        </div>
        {item.tutors && item.tutors.map && item.tutors.map((item2 => <div key={`${item._id}${item2._id}`}>
          Trợ giảng <b style={{color: 'red'}}>{item2.name}</b>{' '}
          không đáp ứng <b style={{color: 'red'}}>Buổi {item.lesson}</b>{' '}
          ngày <b style={{color: 'red'}}>{item.schoolHour?.startHour}</b>
        </div>))}

        <Link to={`/edu/classes?_id=${item.classId}&showModal=true`} target="_blank" rel="noopener noreferrer">
          <Button >Chi tiết</Button>
        </Link>
      </div>)}
    </Modal>
  );
};

export default ModalWarning;
