import { NOTIFICATION } from "../../constants"

interface IAuthState {
  notification: {
    isNoti: boolean | false
    title: string | null
    message: string | null
    type: string | null
  }
}

interface ActionProps {
  type: string
  payload: any
}

const initState: IAuthState = {
  notification: {
    isNoti: false,
    title: null,
    message: null,
    type: null,
  },
}

export const common = (state = initState, action: ActionProps) => {
  const { type, payload } = action

  switch (type) {
    case NOTIFICATION: {
      return {
        ...state,
        notification: {
          ...state.notification,
          isNoti: !state.notification.isNoti,
          ...payload,
        },
      }
    }
    default: {
      return state
    }
  }
}
