import { SESSION_IN_DAY_ARRAY } from "@/modules/edu/pages/operation/work-time/constants"
import { SearchBarType } from "@/modules/edu/pages/operation/work-time/type"
import { SearchOutlined } from "@ant-design/icons"
import { Form, Select } from "antd"
import { memo } from "react"

const TimeShiftSearch: React.FC<SearchBarType> = ({ style, name, label }) => {
  return (
    <Form.Item name={name} label={label} style={{ marginBottom: "12px" }}>
      <Select
        mode="multiple"
        style={style ? style : { width: "100%" }}
        placeholder={
          <>
            <SearchOutlined /> Loại ca{" "}
          </>
        }
      >
        {SESSION_IN_DAY_ARRAY.map((item, idx) => (
          <Select.Option key={`shifts_${idx}`} value={item.value}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default memo(TimeShiftSearch)
