/* eslint-disable react-hooks/exhaustive-deps */
import FormItemData from "@/components/antd/form"
import { ACTION_METHOD_OBJ } from "@/constants"
import { layout } from "@/constants/layout"
import { convertDate, convertDateName } from "@/modules/edu/pages/operation/work-time/handler"
import { TimeTableItemProps } from "@/modules/edu/pages/operation/work-time/type"
import { API } from "@/network/http"
import { BaseUpsertFormProps, SelectOptionProps, SelectOptionSearchProps } from "@/typing/common"
import { formatDate } from "@/utils"
import { ClassDto, CreateStudyScheduleDto } from "@/__generated__/api-v1"
import { Button, Card, Col, Form, Modal, Row, Typography } from "antd"
import React, { memo, useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { DEFINE_MODULE } from "../constants"

interface ClassInfoProps {
  locations: SelectOptionProps[]
  rooms: SelectOptionProps[]
  lecturer: SelectOptionProps[]
  takeCares: SelectOptionProps[]
  tutors: SelectOptionProps[]
  interns: SelectOptionProps[]
  subjects: SelectOptionProps[]
  schoolHours: SelectOptionProps[]
}

interface UpsertFormProps extends BaseUpsertFormProps {
  values?: TimeTableItemProps
  notifyApi: any
  actionRef: any
  id?: string | number
}

const UpsertForm: React.FC<UpsertFormProps> = props => {
  const { modalVisible, onCancel, actionType, values, notifyApi, actionRef, id: editId } = props
  const [classInfo, setClassInfo] = useState<ClassInfoProps>({
    locations: [],
    rooms: [],
    lecturer: [],
    takeCares: [],
    tutors: [],
    interns: [],
    subjects: [],
    schoolHours: [],
  })
  const [disabledField, setDisableField] = useState<boolean>(true)
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false)
  const [loadedScreen, setLoadedScreen] = useState<boolean>(false)
  const [form] = Form.useForm<any>()
  const watchClassId = Form.useWatch("classId", form)
  const watchStartAt = Form.useWatch("startAt", form)

  const onSelectClass = (option: SelectOptionSearchProps) => {
    const classOption = option.option as ClassDto
    const listSubject = []
    // @ts-ignore
    for (let i = 1; i <= classOption.subject.numberOfLessons; i++) {
      listSubject.push({
        value: i,
        name: `Buổi ${i}`,
      })
    }
    const classInfoInput: ClassInfoProps = {
      locations: [
        {
          value: classOption.location._id,
          name: classOption.location.name,
        },
      ],
      rooms: [
        {
          value: classOption.room._id,
          name: classOption.room.code,
        },
      ],
      lecturer: [
        {
          value: classOption.lecturer._id,
          name: classOption.lecturer.name,
        },
      ],
      takeCares:
        classOption.takeCares?.map(item => ({
          value: item._id,
          name: item.name,
        })) || [],
      tutors:
        classOption.tutors.map(tutor => ({
          value: tutor._id,
          name: tutor.name,
        })) || [],
      interns:
        classOption.interns.map(intern => ({
          value: intern._id,
          name: intern.name,
        })) || [],
      schoolHours:
        classOption.schoolHours.map(schoolHour => ({
          value: schoolHour._id,
          name: schoolHour.timeFrame,
          option: schoolHour,
        })) || [],
      subjects: listSubject,
    }
    form.setFieldsValue({
      roomId: classOption.roomId,
      locationId: classOption.locationId,
      lecturerId: classOption.lecturerId,
      tutorId: classOption.tutorIds,
      takeCareId: classOption.takeCareIds,
      internId: classOption.internIds,
    })

    setClassInfo(classInfoInput)
    setDisableField(false)
  }

  const onSelectSchoolHour = (option: SelectOptionProps) => {
    form.setFieldsValue({
      shift: convertDate(option.option?.studySchedule)?.name,
    })
  }

  const fetchDetail = async () => {
    return (await API.studySchedules.studyScheduleControllerGetOne(`${values?.id}`)).data.data
  }

  useEffect(() => {
    if (actionType === "CREATE") {
      form.setFieldsValue({
        startAt: values?.startAt,
        dayInWeek: values?.dayInWeek.name,
      })
      setLoadedScreen(true)
    }

    if (actionType === "UPDATE") {
      fetchDetail()
        .then(async result => {
          const listClass = await getListClass()
          const foundClass = listClass.find(item => item.value === result.classId)
          if (foundClass) {
            onSelectClass({
              value: foundClass.value,
              option: foundClass.option,
              label: foundClass.name,
              id: foundClass.key,
            })
          }
          form.setFieldsValue({
            classId: result.classId,
            startAt: values?.startAt,
            dayInWeek: values?.dayInWeek.name,
            schoolHourId: result.schoolHourId,
            lesson: result.lesson,
            locationId: values?.locationId,
            roomId: result.roomId,
            lecturerId: result.lecturerId,
            tutorIds: result.tutorIds,
            shift: convertDate(result.shift)?.name,
            // @ts-ignore
            takeCareIds: result.takeCareIds ?? [],
            internIds: result.internIds,
          })
        })
        .finally(() => {
          setLoadedScreen(true)
        })
    }
  }, [])

  useEffect(() => {
    if (!values && !editId && watchStartAt) {
      form.setFieldsValue({
        dayInWeek: convertDateName(formatDate(watchStartAt, "dddd")),
      })
    }
  }, [watchStartAt])

  const getListClass = useCallback(
    async (searchValue?: string) => {
      const response = await API.classes.classControllerGetMany({
        name: searchValue,
        roomId: values?.roomId,
        locationId: values?.locationId,
      })
      return (
        response?.data?.data?.map((item: any) => ({
          value: item._id,
          key: item._id,
          name: item.name,
          option: item,
        })) || []
      )
    },
    [watchClassId],
  )

  const handleSubmit = async () => {
    const fieldsValidation = await form.validateFields()
    if (!fieldsValidation) {
      return
    }
    const formValues = { ...form.getFieldsValue() }
    const dataSend = {
      lesson: formValues.lesson,
      classId: formValues.classId,
      roomId: formValues.roomId,
      locationId: formValues.locationId,
      schoolHourId: formValues.schoolHourId,
      lecturerId: formValues.lecturerId,
      tutorIds: formValues.tutorIds
        ? Array.isArray(formValues.tutorIds)
          ? formValues.tutorIds
          : [formValues.tutorIds]
        : null,
      internIds: formValues.internIds
        ? Array.isArray(formValues.internIds)
          ? formValues.internIds
          : [formValues.internIds]
        : null,
      date: formValues?.startAt.toISOString(),
      dayOfWeek: values?.dayInWeek?.value || formatDate(watchStartAt, "dddd")?.toLowerCase(),
    }
    try {
      setLoadingBtn(true)
      const reqData =
        actionType === "CREATE"
          ? await API.studySchedules.studyScheduleControllerCreateOne(dataSend as CreateStudyScheduleDto)
          : await API.studySchedules.studyScheduleControllerUpdateOne(
              `${values?.id}` || "",
              dataSend as CreateStudyScheduleDto,
            )
      if (reqData && reqData.data) {
        notifyApi.success({
          message: "Thông báo",
          description: `${ACTION_METHOD_OBJ[actionType]} thành công`,
          placement: "topRight",
        })
        actionRef.current?.reload()
        onCancel()
      }
    } catch (err: any) {
      let msg = `${ACTION_METHOD_OBJ[actionType]} thất bại`
      if (err?.error?.code === 1651) {
        msg = "Buổi học đã tồn tại!"
      }
      notifyApi.error({
        message: "Thông báo",
        description: msg,
        placement: "topRight",
      })
    } finally {
      setLoadingBtn(false)
    }
  }

  const onResetForm = (): void => {
    // reset sale table
    form.resetFields()
  }

  const timeTableInfo = {
    left: [
      {
        inputType: "selectsearch",
        name: "classId",
        label: "Lớp",
        fetchOptions: getListClass,
        selectTrigger: onSelectClass,
        rules: [{ required: true, message: "Chọn lớp" }],
      },
      {
        inputType: "select",
        name: "lesson",
        label: "Buổi",
        placeholder: "---",
        options: classInfo.subjects,
        disabled: disabledField,
        rules: [{ required: true }],
      },
      {
        inputType: "datetime",
        name: "startAt",
        label: "Ngày",
        disabled: values || editId,
      },
      {
        inputType: "text",
        name: "dayInWeek",
        label: "Thứ",
      },
      {
        inputType: "select",
        name: "schoolHourId",
        label: "Ca",
        placeholder: "---",
        options: classInfo.schoolHours,
        selectTrigger: onSelectSchoolHour,
        rules: [{ required: true, message: "Chọn ca" }],
      },
      {
        inputType: "text",
        name: "shift",
        label: "Loại ca",
        readOnlyValue: "",
      },
    ],
    right: [
      {
        inputType: "select",
        name: "locationId",
        label: "Cơ sở",
        options: classInfo.locations,
        // disabled: true,
      },
      {
        inputType: "select",
        name: "roomId",
        label: "Phòng học",
        options: classInfo.rooms,
        // disabled: true,
      },
      {
        inputType: "select",
        name: "lecturerId",
        label: "Giáo viên",
        options: classInfo.lecturer,
        // disabled: true,
      },
      {
        inputType: "select",
        name: "tutorIds",
        label: "Trợ giảng",
        options: classInfo.tutors,
        // disabled: true,
      },
      {
        inputType: "select",
        name: "internIds",
        label: "Thực tập",
        options: classInfo.interns,
        // disabled: true,
      },
      {
        inputType: "select",
        name: "takeCareId",
        label: "Chăm sóc lớp",
        options: classInfo.takeCares,
        // disabled: true,
      },
    ],
  }

  const observeStudenInfo = {
    left: [
      {
        label: "Sĩ số tối đa lớp",
        name: "maxMemberLesson",
        readOnlyValue: 0,
      },
      {
        label: "Ghi danh",
        name: "registered",
        readOnlyValue: 0,
      },
      {
        label: "Đã tuyển",
        name: "recruited",
        readOnlyValue: 0,
      },
      {
        label: "Chuyển vào",
        name: "chargedIn",
        readOnlyValue: 0,
      },
      {
        label: "Chuyển đi",
        name: "chargedOut",
        readOnlyValue: 0,
      },
    ],
    right: [
      {
        label: "Sĩ số tối đa buổi",
        name: "maxMemberSchedule",
        readOnlyValue: 0,
      },
      {
        label: "Đã tuyển",
        name: "recruited",
        readOnlyValue: 0,
      },
      {
        label: "Học bù",
        name: "makeUpLessons",
        readOnlyValue: 0,
      },
      {
        label: "Vắng",
        name: "absent",
        readOnlyValue: 0,
      },
      {
        label: "Thực học dự kiến",
        name: "expectedStudy",
        readOnlyValue: 0,
      },
    ],
  }

  const renderContent = () => {
    return (
      <Card style={{ paddingRight: "20px" }}>
        <Row>
          <Col span={8}>
            <Typography.Title level={5} style={{ marginBottom: "20px", marginTop: "20px" }}>
              Thông tin buổi học
            </Typography.Title>
          </Col>
          {values?.classId && (
            <Col span={8} offset={8} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
              <Link to={`/edu/classes?_id=${values?.classId}&showPopup=true`} style={{ textDecoration: "underline" }}>
                Thông tin lớp học
              </Link>
            </Col>
          )}
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            {timeTableInfo.left.map((item, idx) => item && <FormItemData key={`info_left_${idx}`} {...item} />)}
          </Col>
          <Col span={12}>
            {timeTableInfo.right.map((item, idx) => item && <FormItemData key={`info_right_${idx}`} {...item} />)}
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Typography.Title level={5} style={{ marginBottom: "20px", marginTop: "20px" }}>
              Theo dõi học viên theo lớp
            </Typography.Title>
            {observeStudenInfo.left.map(
              (item, idx) =>
                item && (
                  <Form.Item
                    key={`observe_left_${item.label}_${idx}`}
                    label={item.label}
                    shouldUpdate={(prevValues, curValues) => prevValues[item.name] !== curValues[item.name]}
                  >
                    {({ getFieldValue }) => {
                      const textField = getFieldValue(item.name)
                      return (
                        <Typography.Text className="ant-form-text" type="secondary">
                          {textField ? textField : item.readOnlyValue}
                        </Typography.Text>
                      )
                    }}
                  </Form.Item>
                ),
            )}
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ marginBottom: "20px", marginTop: "20px" }}>
              Theo dõi học viên theo buổi
            </Typography.Title>
            {observeStudenInfo.right.map(
              (item, idx) =>
                item && (
                  <Form.Item
                    key={`observe_right_${item.label}_${idx}`}
                    label={item.label}
                    shouldUpdate={(prevValues, curValues) => prevValues[item.name] !== curValues[item.name]}
                  >
                    {({ getFieldValue }) => {
                      const textField = getFieldValue(item.name)
                      return (
                        <Typography.Text className="ant-form-text" type="secondary">
                          {textField ? textField : item.readOnlyValue}
                        </Typography.Text>
                      )
                    }}
                  </Form.Item>
                ),
            )}
          </Col>
        </Row>
      </Card>
    )
  }

  const renderFooter = () => {
    return (
      <>
        {/* {props.actionType === "CREATE" && <Button onClick={() => onResetForm()}>Làm mới</Button>} */}
        <Button loading={loadingBtn} type="primary" onClick={handleSubmit}>
          Đồng ý
        </Button>
      </>
    )
  }

  if (!loadedScreen) {
    return null
  }

  return (
    <>
      <Modal
        bodyStyle={{ background: "#F0F2F5" }}
        width={1200}
        destroyOnClose
        maskClosable={false}
        title={`${ACTION_METHOD_OBJ[actionType]} ${DEFINE_MODULE.name}`}
        visible={modalVisible}
        onCancel={() => {
          onResetForm()
          onCancel()
        }}
        footer={renderFooter()}
      >
        <Form {...layout} labelWrap form={form}>
          {renderContent()}
        </Form>
      </Modal>
    </>
  )
}

export default memo(UpsertForm)
