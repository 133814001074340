import { useAuthContext } from "@/components/auth"
import { menuItems } from "@/components/layout/menuItems"
import { RootState } from "@/redux/store"
import { Layout, Menu } from "antd"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

export const Header = () => {
  const navigate = useNavigate()

  const auth = useAuthContext()
  const profile: any = useSelector<RootState>((state: RootState) => state.profile)

  return (
    <Layout.Header className="layout">
      <a className="logo" onClick={() => navigate("/")} href="#">
        <img src="/images/logo-mtb.png" alt="Logo" />
      </a>
      <Menu
        mode="horizontal"
        defaultSelectedKeys={["2"]}
        items={menuItems}
        onSelect={info => {
          navigate(info.key)
        }}
      />

      {/* <Dropdown overlay={rightMenu}>
        <a onClick={e => e.preventDefault()}>Hi, {profile.data.username}</a>
      </Dropdown> */}
    </Layout.Header>
  )
}
