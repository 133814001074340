export const DEFINE_MODULE = {
  name: "thời khoá biểu",
  fields: "workTimes",
}

export const SESSION_IN_DAY = {
  MORNING: { name: "Sáng", text: "Sáng", value: "MORNING" },
  AFTERNOON: { name: "Chiều", text: "Chiều", value: "AFTERNOON" },
  EVENING: { name: "Tối", text: "Tối", value: "EVENING" },
}

export const SESSION_IN_DAY_ARRAY = [
  { name: "Sáng", value: "MORNING" },
  { name: "Chiều", value: "AFTERNOON" },
  { name: "Tối", value: "EVENING" },
]

export const CARD_COLOR = {
  NORMAL: { color: "inherit" },
  STUDYING: { color: "#ffa92b80" },
  GRADUATED: { color: "#80808047" },
}

export const CARD_COLORS = {
  DRAFT: { color: "#80808047" },
  MAIN: { color: "transparent" },
  INVALID: { color: "rgb(251 186 171)" },
}

export const DAY_IN_WEEK = [
  { name: "Thứ 2", value: "monday" },
  { name: "Thứ 3", value: "tuesday" },
  { name: "Thứ 4", value: "wednesday" },
  { name: "Thứ 5", value: "thursday" },
  { name: "Thứ 6", value: "friday" },
  { name: "Thứ 7", value: "saturday" },
  { name: "Chủ nhật", value: "sunday" },
]
