import {ACTION_METHOD_OBJ} from "@/constants";
import {HrPaths} from "@/modules/hr";
import UpsertForm from "@/modules/hr/pages/employee/views/UpsertForm";
import {generateHrPath} from "@/modules/hr/utils";
import {API} from "@/network/http";
import {CRUD_ACTION} from "@/types";
import {useGoBack} from "@/utils/use-go-back";
import {ActionType} from "@ant-design/pro-table";
import {notification} from "antd";
import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

export const Upsert = () => {
  const {goBack} = useGoBack(generateHrPath(HrPaths.EMPLOYEE.LIST.PATH));
  const navigate = useNavigate();
  const {id} = useParams();
  const actionRef = useRef<ActionType>();
  const [notifyApi, contextHolder] = notification.useNotification();

  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [isCreate, setCreate] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [values, setValues] = useState<any>();

  const onSubmit = async (val: any, type: CRUD_ACTION, _id?: string) => {
    try {
      setLoadingBtn(true);
      const reqData =
        type === "CREATE"
          ? await API.employees.employeeControllerCreateOne(val)
          : await API.employees.employeeControllerUpdateOne(_id || "", val);
      if (reqData && reqData.data) {
        if (type === "CREATE") setCreate(false);
        if (type === "UPDATE") setUpdate(false);
        notifyApi.success({
          message: "Thông báo",
          description: `${ACTION_METHOD_OBJ[type]} thành công`,
          placement: "topRight",
        });
        actionRef.current?.reload();
      }
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
    finally {
      setLoadingBtn(false);
    }
  };

  useEffect(() => {
    async function init() {
      if (id) {
        setUpdate(true);
        const detailData = await API.employees.employeeControllerGetOne(id);
        setValues(detailData.data.data);
      } else {
        setCreate(true);
      }
    }

    init();
  }, []);

  const onCancel = () => goBack();

  return (
    <>
      {contextHolder}
      {isCreate && (
        <UpsertForm
          key="CREATE"
          onCancel={onCancel}
          modalVisible={true}
          onSubmit={onSubmit}
          actionType="CREATE"
          loadingBtn={loadingBtn}
          values={undefined}
        />
      )}

      {isUpdate && (
        <UpsertForm
          key="CREATE"
          onCancel={onCancel}
          modalVisible={true}
          onSubmit={onSubmit}
          actionType="UPDATE"
          loadingBtn={loadingBtn}
          values={values}
        />
      )}
    </>
  );
};
