import { Segmented } from "antd"
import { FC, ReactNode } from "react"

interface State {
  label: string
  active?: boolean
}

export interface EntityStatesProps {
  children?: ReactNode
  states: State[]
}

export const EntityStates: FC<EntityStatesProps> = props => {
  return (
    <Segmented
      options={props.states.map(e => ({ label: e.label, value: e.label, disabled: !e.active }))}
      defaultValue={props.states.find(e => e.active)?.label}
      value={props.states.find(e => e.active)?.label}
    />
  )
}
