import {Table, TableProps} from "@/components/antd/table/Table";
import {ACTION_METHOD_OBJ} from "@/constants";
import {DEFINE_MODULE} from "@/modules/core/pages/user-role/constants";
import {API} from "@/network/http";
import {CRUD_ACTION} from "@/types";
import {formatToLocalDate} from "@/utils";
import {RoleDto} from "@/__generated__/api-v1";
import {DeleteTwoTone, EditTwoTone} from "@ant-design/icons";
import type {ActionType, ProColumns} from "@ant-design/pro-table";
import {Badge, notification, Popconfirm, Space, Tooltip, Typography} from "antd";
import {FC, useRef, useState} from "react";
import UpsertForm from "./UpsertForm";

const {Text} = Typography;

const List: FC = () => {
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [isCreate, setCreate] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [values, setValues] = useState<any>();
  const actionRef = useRef<ActionType>();
  const [notifyApi, contextHolder] = notification.useNotification();

  const onClickEditButton = async (id: string) => {
    const detailData = await API.roles.roleControllerGetOne(id);
    setValues(detailData.data.data);
    setUpdate(true);
  };

  const onClickDeleteButton = async (id: string) => {
    try {
      await API.roles.roleControllerDeleteOne(id);
      notifyApi.success({message: `${ACTION_METHOD_OBJ['DELETE']} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const onSubmit = async (val: any, type: CRUD_ACTION, _id?: string) => {
    try {
      setLoadingBtn(true);
      const reqData =
        type === "CREATE"
          ? await API.roles.roleControllerCreateOne(val)
          : await API.roles.roleControllerUpdateOne(_id || "", val);
      if (reqData && reqData.data) {
        if (type === "CREATE") setCreate(false);
        if (type === "UPDATE") setUpdate(false);
        notifyApi.success({
          message: "Thông báo",
          description: `${ACTION_METHOD_OBJ[type]} thành công`,
          placement: "topRight",
        });
        actionRef.current?.reload();
      }
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
    finally {
      setLoadingBtn(false);
    }
  };

  const fetchData = async (queries: Parameters<typeof API.roles.roleControllerGetMany>["0"]) => {
    return (await API.roles.roleControllerGetMany({...queries})).data;
  };

  const onDeleteMany = async (ids: string[]) => {
    try {
      await API.roles.roleControllerDeleteMany(ids);
      notifyApi.success({message: `${ACTION_METHOD_OBJ['DELETE']} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
    // dispatch(deleteManyUser({ ids }))
  };

  const onArchiveMany = async (ids: string[]) => {
    try {
      await API.roles.roleControllerUpdateMany({ids, isArchived: true});
      notifyApi.success({message: `${ACTION_METHOD_OBJ['ARCHIVE']} ${ids.length} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
    // dispatch(archiveManyUser({ ids, isArchived: true }))
  };

  const onUnarchiveMany = async (ids: string[]) => {
    try {
      await API.roles.roleControllerUpdateMany({ids, isArchived: false});
      notifyApi.success({message: `${ACTION_METHOD_OBJ['UNARCHIVE']} ${ids.length} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
    // dispatch(unarchiveManyUser({ ids, isArchived: false }))
  };

  const columns: ProColumns<RoleDto>[] = [
    {
      dataIndex: "name",
      title: "Tên",
      renderText: (_, entity) => <Badge color="warning">{entity.name}</Badge>,
    },
    {
      dataIndex: "description",
      title: "Mô tả",
    },
    {
      title: "Khởi tạo",
      dataIndex: "createdAt",
      search: false,
      // valueType: "date",
      render: (time: any) => <Text>{formatToLocalDate(time)}</Text>,
    },
    {
      title: "Khởi tạo",
      hideInTable: true,
      dataIndex: "createdAt",
      valueType: "dateTime",
    },
    {
      title: "Thao tác",
      align: "center",
      search: false,
      fixed: "right",
      width: 80,
      render: (_, entity) => {
        return (
          <Space size={10}>
            <Tooltip title="Cập nhật vai trò">
              <EditTwoTone onClick={() => onClickEditButton(entity._id)} />
            </Tooltip>
            <Tooltip title="Xoá vai trò">
              <Popconfirm
                title="Bạn có chắc rằng muốn xoá vai trò này không?"
                onConfirm={() => onClickDeleteButton(entity._id)}
                okText="Có"
                cancelText="Không"
              >
                <DeleteTwoTone twoToneColor={"red"} />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const protableProps: TableProps = {
    actionRef,
    DEFINE_MODULE,
    contextHolder,
    fetchData,
    columns,
    deleteMany: onDeleteMany,
    archiveMany: onArchiveMany,
    unArchiveMany: onUnarchiveMany,
    createForm: {
      isCreate,
      setCreate,
      component: (
        <UpsertForm
          notify={notifyApi}
          key="CREATE"
          onCancel={() => setCreate(false)}
          modalVisible={isCreate}
          onSubmit={onSubmit}
          actionType="CREATE"
          loadingBtn={loadingBtn}
        />
      ),
    },
    updateForm: {
      isUpdate,
      component: (
        <UpsertForm
          notify={notifyApi}
          key="UPDATE"
          onCancel={() => setUpdate(false)}
          modalVisible={isUpdate}
          onSubmit={onSubmit}
          actionType="UPDATE"
          loadingBtn={loadingBtn}
          values={values}
        />
      ),
    },
  };

  return <Table {...protableProps} />;
};

export default List;
