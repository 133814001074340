import {Table, TableProps} from "@/components/antd/table/Table";
import {ACTION_METHOD_OBJ} from "@/constants";
import {DEFINE_MODULE} from "@/modules/hr/pages/department/constants";
import {API} from "@/network/http";
import {CRUD_ACTION} from "@/types";
import {formatToLocalDate} from "@/utils";
import {DepartmentDto} from "@/__generated__/api-v1";
import {DeleteTwoTone, EditTwoTone} from "@ant-design/icons";
import type {ActionType, ProColumns} from "@ant-design/pro-table";
import {notification, Popconfirm, Space, Tooltip} from "antd";
import {FC, useRef, useState} from "react";
import UpsertForm from "./UpsertForm";

const List: FC = () => {
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [isCreate, setCreate] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [values, setValues] = useState<any>();
  const actionRef = useRef<ActionType>();
  const [notifyApi, contextHolder] = notification.useNotification();

  const onClickEditButton = async (id: string) => {
    const detailData = await API.departments.departmentControllerGetOne(id);
    setValues(detailData.data.data);
    setUpdate(true);
  };

  const onClickDeleteButton = async (id: string) => {
    try {
      await API.departments.departmentControllerDeleteOne(id);
      notifyApi.success({message: `${ACTION_METHOD_OBJ['DELETE']} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const onSubmit = async (val: any, type: CRUD_ACTION, _id?: string) => {
    try {
      setLoadingBtn(true);
      const reqData =
        type === "CREATE"
          ? await API.departments.departmentControllerCreateOne(val)
          : await API.departments.departmentControllerUpdateOne(_id || "", val);
      if (reqData && reqData.data) {
        if (type === "CREATE") setCreate(false);
        if (type === "UPDATE") setUpdate(false);
        notifyApi.success({
          message: "Thông báo",
          description: `${ACTION_METHOD_OBJ[type]} thành công`,
          placement: "topRight",
        });
        actionRef.current?.reload();
      }
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
    finally {
      setLoadingBtn(false);
    }
  };

  const fetchData = async (queries: Parameters<typeof API.departments.departmentControllerGetMany>["0"]) => {
    return (await API.departments.departmentControllerGetMany({...queries})).data;
  };

  const onDeleteMany = async (ids: string[]) => {
    try {
      await API.departments.departmentControllerDeleteMany({ids});
      notifyApi.success({message: `${ACTION_METHOD_OBJ['DELETE']} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const onArchiveMany = async (ids: string[]) => {
    try {
      await API.departments.departmentControllerUpdateMany({ids, isArchived: true});
      notifyApi.success({message: `${ACTION_METHOD_OBJ['ARCHIVE']} ${ids.length} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const onUnarchiveMany = async (ids: string[]) => {
    try {
      await API.departments.departmentControllerUpdateMany({ids, isArchived: false});
      notifyApi.success({message: `${ACTION_METHOD_OBJ['UNARCHIVE']} ${ids.length} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const columns: ProColumns<DepartmentDto>[] = [
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      fieldProps: {
        placeholder: "Tên",
      },
    },
    {
      title: "Phòng ban mặc định",
      dataIndex: "isDefault",
      key: "isDefault",
      search: false,
      renderText: (_, entity) => {
        return entity.isDefault ? "Có" : "Không";
      },
    },
    {
      title: "Phòng ban chuyên môn",
      dataIndex: "isSpecialize",
      key: "isSpecialize",
      search: false,
      renderText: (_, entity) => {
        return entity.isSpecialize ? "Có" : "Không";
      },
    },
    {
      title: "Phòng ban vận hành",
      dataIndex: "isOperator",
      key: "isOperator",
      search: false,
      renderText: (_, entity) => {
        return entity.isOperator ? "Có" : "Không";
      },
    },
    {
      title: "Khởi tạo",
      dataIndex: "createdAt",
      key: "createdAt",
      search: false,
      renderText: (_, entity) => {
        return <p>{formatToLocalDate(entity.createdAt || "")}</p>;
      },
    },
    {
      title: "Thao tác",
      align: "center",
      search: false,
      fixed: "right",
      width: 80,
      render: (_, entity) => {
        return (
          <Space size={10}>
            <Tooltip title="Cập nhật phòng ban">
              <EditTwoTone onClick={() => onClickEditButton(entity._id)} />
            </Tooltip>
            <Tooltip title="Xoá phòng ban">
              <Popconfirm
                title="Bạn có chắc rằng muốn xoá phòng ban này không?"
                onConfirm={() => onClickDeleteButton(entity._id)}
                okText="Có"
                cancelText="Không"
              >
                <DeleteTwoTone twoToneColor={"red"} />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const protableProps: TableProps = {
    actionRef,
    DEFINE_MODULE,
    contextHolder,
    fetchData,
    columns,
    deleteMany: onDeleteMany,
    archiveMany: onArchiveMany,
    unArchiveMany: onUnarchiveMany,
    createForm: {
      isCreate,
      setCreate,
      component: (
        <UpsertForm
          key="CREATE"
          onCancel={() => setCreate(false)}
          modalVisible={isCreate}
          onSubmit={onSubmit}
          actionType="CREATE"
          loadingBtn={loadingBtn}
        />
      ),
    },
    updateForm: {
      isUpdate,
      component: (
        <UpsertForm
          key="UPDATE"
          onCancel={() => setUpdate(false)}
          modalVisible={isUpdate}
          onSubmit={onSubmit}
          actionType="UPDATE"
          loadingBtn={loadingBtn}
          values={values}
        />
      ),
    },
  };

  return <Table {...protableProps} />;

  // return (
  //   <PageContainer>
  //     {contextHolder}
  //     <ProTable<DepartmentDto>
  //       headerTitle="Danh sách phòng ban"
  //       actionRef={actionRef}
  //       bordered
  //       rowKey="_id"
  //       search={{
  //         labelWidth: "auto",

  //         optionRender: (_, { form }) => [
  //           <Button
  //             key="rest"
  //             onClick={() => {
  //               form?.resetFields()
  //             }}
  //           >
  //             Làm mới
  //           </Button>,
  //           <Button
  //             key="search"
  //             type="primary"
  //             onClick={() => {
  //               form?.submit()
  //             }}
  //           >
  //             Tìm kiếm
  //           </Button>,
  //         ],
  //         collapseRender: collapsed => {
  //           return <CollapseRender collapsed={collapsed} />
  //         },
  //       }}
  //       request={params => {
  //         const { pageSize, current, keyword, ...rest } = params

  //         return fetchData({
  //           ...(pageSize ? { limit: String(pageSize) } : {}),
  //           ...(pageSize ? { offset: String((Number(current) - 1) * pageSize) } : {}),
  //           ...rest,
  //         })
  //       }}
  //       options={{ density: false, fullScreen: false }}
  //       pagination={{
  //         showTotal: (total, range) => `${range[0]}-${range[1]} trên ${total} phòng ban`,
  //       }}
  //       toolBarRender={() => [
  //         <Button type="primary" onClick={() => setCreate(true)}>
  //           <PlusOutlined /> Tạo mới
  //         </Button>,
  //       ]}
  //       columns={columns}
  //       sticky={{ offsetScroll: 10 }}
  //       tableAlertRender={() => {
  //         return <>Đã chọn mục</>
  //       }}
  //       scroll={{ x: 1300, y: 430 }}
  //       locale={{
  //         emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Không có dữ liệu"} />,
  //       }}
  //       rowSelection={{
  //         onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
  //           console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows)
  //         },
  //         getCheckboxProps: (record: any) => ({
  //           disabled: record.name === "Disabled User", // Column configuration not to be checked
  //           name: record.name,
  //         }),
  //       }}
  //     />
  //     {isCreate && (
  //       <UpsertForm
  //         key="CREATE"
  //         onCancel={() => setCreate(false)}
  //         modalVisible={isCreate}
  //         onSubmit={onSubmit}
  //         actionType="CREATE"
  //         loadingBtn={loadingBtn}
  //       />
  //     )}
  //     {isUpdate && (
  //       <UpsertForm
  //         key="UPDATE"
  //         onCancel={() => setUpdate(false)}
  //         modalVisible={isUpdate}
  //         onSubmit={onSubmit}
  //         actionType="UPDATE"
  //         loadingBtn={loadingBtn}
  //         values={values}
  //       />
  //     )}
  //   </PageContainer>
  // )
};

export default List;
