import {DebounceSelect} from "@/components/antd/form/DebounceSelect";
import {Table, TableProps} from "@/components/antd/table/Table";
import {ACTION_METHOD_OBJ} from "@/constants";
import {API} from "@/network/http";
import {CRUD_ACTION} from "@/types";
import {ClassNumberDto} from "@/__generated__/api-v1";
import {DeleteTwoTone, EditTwoTone} from "@ant-design/icons";
import type {ActionType, ProColumns} from "@ant-design/pro-table";
import {notification, Popconfirm, Space, Tooltip} from "antd";
import {FC, useRef, useState} from "react";
import {DEFINE_MODULE} from "../constants";
import UpsertForm from "./UpsertForm";


const List: FC = () => {
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [isCreate, setCreate] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [values, setValues] = useState<any>();

  const actionRef = useRef<ActionType>();

  const [notifyApi, contextHolder] = notification.useNotification();

  const onClickEditButton = async (id: string) => {
    // const detailData = await API.classNumbers.subjectControllerGetOne(id)
    const detailData = await API.classNumbers.classNumberControllerGetOne(id);
    setValues(detailData.data.data);
    setUpdate(true);
  };

  const onClickDeleteButton = async (id: string) => {
    try {
      await API.classNumbers.classNumberControllerDeleteOne(id);
      notifyApi.success({message: `${ACTION_METHOD_OBJ['DELETE']} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const onSubmit = async (formData: any, type: CRUD_ACTION, _id?: string) => {
    try {
      // set loading
      if (loadingBtn) return false;
      setLoadingBtn(true);

      console.log({formData});
      // override data
      formData.maxQuantity = Number(formData.maxQuantity);
      formData.roomId = formData.roomId?.value || formData.roomId;
      formData.subjectId = formData.subjectId?.value || formData.subjectId;
      formData.locationId = formData.locationId?.value || formData.locationId;

      // call api
      const reqData =
        type === "CREATE"
          ? await API.classNumbers.classNumberControllerCreateOne(formData)
          : await API.classNumbers.classNumberControllerUpdateOne(_id || "", formData);

      // process response
      if (reqData && reqData.data) {
        if (type === "CREATE") setCreate(false);
        if (type === "UPDATE") setUpdate(false);

        // show notification
        notifyApi.success({
          message: "Thông báo",
          description: `${ACTION_METHOD_OBJ[type]} thành công`,
          placement: "topRight",
        });

        // reload this page
        actionRef.current?.reload();
      }
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
    finally {
      setLoadingBtn(false);
    }
  };

  // get list
  const fetchData = async (queries: Parameters<typeof API.classNumbers.classNumberControllerGetMany>["0"]) => {
    return (await API.classNumbers.classNumberControllerGetMany({...queries})).data;
  };

  const onDeleteMany = async (ids: string[]) => {
    try {
      await API.classNumbers.classNumberControllerDeleteMany({ids});
      notifyApi.success({message: `${ACTION_METHOD_OBJ['DELETE']} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const onArchiveMany = async (ids: string[]) => {
    try {
      await API.classNumbers.classNumberControllerUpdateMany({ids, isArchived: true});
      notifyApi.success({message: `${ACTION_METHOD_OBJ['ARCHIVE']} ${ids.length} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const onUnarchiveMany = async (ids: string[]) => {
    try {
      await API.classNumbers.classNumberControllerUpdateMany({ids, isArchived: false});
      notifyApi.success({message: `${ACTION_METHOD_OBJ['UNARCHIVE']} ${ids.length} ${DEFINE_MODULE.name} thành công`});
      actionRef.current?.reload();
    }
    catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      });
    }
  };

  const getListSubject = async (searchValue?: string) => {
    const response = await API.subjects.subjectControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const getListLocation = async (searchValue?: string) => {
    const response = await API.locations.locationControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const getListRoom = async (searchValue?: string) => {
    const response = await API.rooms.roomControllerGetMany({
      code: searchValue
    });
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const columns: ProColumns<ClassNumberDto>[] = [
    {
      hideInTable: true,
      dataIndex: "subjectId",
      title: "Môn học",
      renderFormItem: () => {
        return <DebounceSelect placeholder="Chọn môn học" fetchOptions={getListSubject} />;
      }
    },
    {
      hideInTable: true,
      dataIndex: "locationId",
      title: "Cơ sở",
      renderFormItem: () => {
        return <DebounceSelect placeholder="Chọn cơ sở" fetchOptions={getListLocation} />;
      }
    },
    {
      hideInTable: true,
      dataIndex: "roomId",
      title: "Phòng học",
      renderFormItem: () => {
        return <DebounceSelect placeholder="Chọn phòng học" fetchOptions={getListRoom} />;
      }
    },
    {
      dataIndex: ["subject", "name"], // tương đương subjectType.name
      title: "Môn học",
      search: false
    },
    {
      dataIndex: ["location", "name"],
      title: "Cơ sở",
      search: false
    },
    {
      title: "Phòng học",
      dataIndex: ["room", "code"],
      search: false
    },
    {
      title: "Sĩ số tối đa",
      dataIndex: "maxQuantity",
      search: false,
    },
    {
      title: "Thao tác",
      align: "center",
      search: false,
      fixed: "right",
      width: 80,
      render: (_, entity) => {
        return (
          <Space size={10}>
            <Tooltip title={`Cập nhật ${DEFINE_MODULE.name}`}>
              <EditTwoTone onClick={() => onClickEditButton(entity._id)} />
            </Tooltip>
            <Tooltip title={`Xoá ${DEFINE_MODULE.name}`}>
              <Popconfirm
                title={`Bạn có chắc rằng muốn xoá ${DEFINE_MODULE.name} này không?`}
                onConfirm={() => onClickDeleteButton(entity._id)}
                okText="Có"
                cancelText="Không"
              >
                <DeleteTwoTone twoToneColor="red" />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const protableProps: TableProps = {
    actionRef,
    DEFINE_MODULE,
    contextHolder,
    fetchData,
    columns,
    deleteMany: onDeleteMany,
    archiveMany: onArchiveMany,
    unArchiveMany: onUnarchiveMany,
    createForm: {
      isCreate,
      setCreate,
      component: (
        <UpsertForm
          notify={notifyApi}
          key="CREATE"
          onCancel={() => setCreate(false)}
          modalVisible={isCreate}
          onSubmit={onSubmit}
          actionType="CREATE"
          loadingBtn={loadingBtn}
        />
      ),
    },
    updateForm: {
      isUpdate,
      component: (
        <UpsertForm
          notify={notifyApi}
          key="UPDATE"
          onCancel={() => setUpdate(false)}
          modalVisible={isUpdate}
          onSubmit={onSubmit}
          actionType="UPDATE"
          loadingBtn={loadingBtn}
          values={values}
        />
      ),
    },
  };

  return <Table {...protableProps} />;
};

export default List;
