import i18n from "i18n-js"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import { Paths, USER_EXITS } from "../../constants"
import * as actionTypes from "../../constants/ActionTypes"
import history from "../../navigation"
import {
  archiveManyUserApi,
  createUserApi,
  deleteManyUserApi,
  deleteUserApi,
  getUserDetailApi,
  getUsersApi,
  updateUserApi,
} from "../../network"
import { convertObjectParamsToStringParams, getInitParams } from "../../utils"
import { getUserState } from "../selectors"
import { IUserState } from "../types"

interface IPayload {
  type: string
  payload: any
}

interface IResponse {
  data: any
  total: number
}

function* getListUser(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const user: IUserState = yield select<any>(getUserState)

    // validate and set requesting
    if (user.isLoadingRequest) return
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isLoadingRequest: true },
    })

    // get list
    const { data, total }: IResponse = yield call(getUsersApi, action.payload)
    yield history.push(`?${convertObjectParamsToStringParams(action.payload)}`)

    // update to state
    yield put({
      type: actionTypes.GET_LIST_USER_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({ type: actionTypes.GET_LIST_USER_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isLoadingRequest: false },
    })
  }
}

function* getDetailUser(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const user: IUserState = yield select<any>(getUserState)

    // validate and set requesting
    if (user.isLoadingRequest) return
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isLoadingRequest: true },
    })

    // call api
    const { data, total }: IResponse = yield call(getUserDetailApi, action.payload)

    // update to state
    yield put({
      type: actionTypes.GET_DETAIL_USER_SUCCESS,
      payload: { data, total },
    })
  } catch (error) {
    yield put({ type: actionTypes.GET_DETAIL_USER_FAILED, payload: error })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isLoadingRequest: false },
    })
  }
}

function* updateDetailUser(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const user: IUserState = yield select<any>(getUserState)

    // validate and set requesting
    if (user.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isUpdateRequest: true },
    })

    // call api update
    const { data, total }: IResponse = yield call(updateUserApi, action.payload.userId, action.payload.data)

    yield put({
      type: actionTypes.UPDATE_DETAIL_USER_SUCCESS,
    })

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thành công",
        message: data?.message || "",
        type: "success",
      },
    })
    history.push(Paths.USER.LIST.PATH)
  } catch (error) {
    console.log("e: ", error)
    yield put({ type: actionTypes.UPDATE_DETAIL_USER_FAILED, payload: error })
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Cập nhật thông tin thất bại",
        message: `${error}` || "",
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isUpdateRequest: false },
    })
  }
}

function* createUser(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const user: IUserState = yield select<any>(getUserState)

    // validate and set requesting
    if (user.isCreateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isCreateRequest: true },
    })

    // call api create
    const { data, total }: IResponse = yield call(createUserApi, action.payload)

    // show notification
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: "Thêm mới thành công",
        message: data?.message || "",
        type: "success",
      },
    })

    // navigate
    history.push("/users")
  } catch (error: any) {
    // yield put({ type: actionTypes.CREATE_USER_FAILED, payload: error });

    if (error && error.data && error.data.code === USER_EXITS) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: "Lỗi",
          message: "Tên đăng nhập đã tồn tại!",
          type: "error",
        },
      })
    }
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isCreateRequest: false },
    })
  }
}

function* deleteOneUser(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const user: IUserState = yield select<any>(getUserState)

    // validate and set requesting
    if (user.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(deleteUserApi, action.payload.id)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteAction.deleteSuccess"),
          message: i18n.t("common.deleteAction.deleteSuccess"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_USER,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isDeleteRequest: false },
    })
  }
}

function* deleteManyUser(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const user: IUserState = yield select<any>(getUserState)

    // validate and set requesting
    if (user.isDeleteRequest) return
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isDeleteRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(deleteManyUserApi, action.payload.ids)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.deleteManyAction.title"),
          message: i18n.t("common.deleteManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_USER,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isDeleteRequest: false },
    })
  }
}

function* archiveManyUser(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const user: IUserState = yield select<any>(getUserState)

    // validate and set requesting
    if (user.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(archiveManyUserApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.archiveManyAction.title"),
          message: i18n.t("common.archiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_USER,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isUpdateRequest: false },
    })
  }
}

function* unarchiveManyUser(action: IPayload) {
  try {
    // get state
    // @ts-ignore
    const user: IUserState = yield select<any>(getUserState)

    // validate and set requesting
    if (user.isUpdateRequest) return
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isUpdateRequest: true },
    })

    // call api delete
    const { data, total }: IResponse = yield call(archiveManyUserApi, action.payload)

    // if success
    if (data) {
      // show notification
      yield put({
        type: actionTypes.NOTIFICATION,
        payload: {
          title: i18n.t("common.unarchiveManyAction.title"),
          message: i18n.t("common.unarchiveManyAction.message"),
          type: "success",
        },
      })

      // reload list
      yield put({
        type: actionTypes.GET_LIST_USER,
        payload: getInitParams(),
      })
    }
  } catch (error: any) {
    console.log(error)
    yield put({
      type: actionTypes.NOTIFICATION,
      payload: {
        title: i18n.t("common.error"),
        message: error?.data?.message || error.message,
        type: "error",
      },
    })
  } finally {
    yield put({
      type: actionTypes.SET_LOADING_USER,
      payload: { isUpdateRequest: false },
    })
  }
}

export default function* root() {
  yield all([
    takeLatest(actionTypes.GET_LIST_USER, getListUser),
    takeLatest(actionTypes.GET_DETAIL_USER, getDetailUser),
    takeLatest(actionTypes.UPDATE_DETAIL_USER, updateDetailUser),
    takeLatest(actionTypes.CREATE_USER, createUser),
    takeLatest(actionTypes.DELETE_ONE_USER, deleteOneUser),
    takeLatest(actionTypes.DELETE_MANY_USER, deleteManyUser),
    takeLatest(actionTypes.ARCHIVE_MANY_USER, archiveManyUser),
    takeLatest(actionTypes.UNARCHIVE_MANY_USER, unarchiveManyUser),
  ])
}
