import { SelectOptionProps } from "@/typing/common"

export const DEFINE_MODULE = {
  name: "lịch giảng dạy",
  fields: "teach_schedule",
}

export const DAY_OF_WEEK: SelectOptionProps[] = [
  {
    key: "monday",
    value: "monday",
    name: "Thứ hai",
  },
  {
    key: "tuesday",
    value: "tuesday",
    name: "Thứ ba",
  },
  {
    key: "wednesday",
    value: "wednesday",
    name: "Thứ tư",
  },
  {
    key: "thursday",
    value: "thursday",
    name: "Thứ năm",
  },
  {
    key: "friday",
    value: "friday",
    name: "Thứ sáu",
  },
  {
    key: "saturday",
    value: "saturday",
    name: "Thứ bảy",
  },
  {
    key: "sunday",
    value: "sunday",
    name: "Chủ nhật",
  },
]

export const DAY_OF_WEEK_OBJ: any = {
  monday: { text: "Thứ hai", value: "monday" },
  tuesday: { text: "Thứ ba", value: "tuesday" },
  wednesday: { text: "Thứ tư", value: "wednesday" },
  thursday: { text: "Thứ năm", value: "thursday" },
  friday: { text: "Thứ sáu", value: "friday" },
  saturday: { text: "Thứ bảy", value: "saturday" },
  sunday: { text: "Chủ nhật", value: "sunday" },
}

export const DAY_OF_WEEK_ARR = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]

export const STUDY_SCHEDULE_STATUS = {
  VALID: "VALID",
  INVALID: "INVALID",
}

export const STUDY_SCHEDULE_FIELD_NAME = {
  LECTURER_ID: "lecturerId",
  TUTOR_IDS: "tutorIds",
  INTERN_IDS: "internIds",
}

export const STUDY_SCHEDULE_FIELD_ERROR_NAME = {
  MAX_NUMBER_OF_SHIFTS_IN_MONTH: "maxNumberOfShiftsInMonth",
  WORK_SCHEDULE_NOT_FOUND: "work_schedule_not_found",
  SHIFT: "shift",
}
