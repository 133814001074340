export type UserStatus = "ACTIVE" | "BLOCKED" | "DELETED";

export const DEFINE_MODULE = {
  name: "nhân viên",
  fields: "users"
};

export const USER_STATUS_ENUM = {
  ACTIVE: {
    key: "0",
    name: "Hoạt động",
    color: "success",
    text: "Hoạt động",
    value: "ACTIVE",
  },
  BLOCKED: {
    key: "1",
    name: "Đã khoá",
    color: "error",
    text: "Đã khoá",
    value: "BLOCKED",
  },
  DELETED: {
    key: "2",
    name: "Đã xoá",
    color: "error",
    text: "Đã xoá",
    value: "DELETED",
  },
};

export const USER_STATUS_ARR = [
  {
    key: "ACTIVE",
    value: "ACTIVE",
    name: "Hoạt động",
  },
  {
    key: "BLOCKED",
    value: "BLOCKED",
    name: "Đã khoá",
  },
  {
    key: "DELETED",
    value: "DELETED",
    name: "Đã xoá",
  },
];
