import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../http"

interface IPermission {
  id: string
  actionIds: Array<string>
}

interface IRoles {
  name?: string
  description?: string
  limit?: number
  offset?: number
}

interface IRoleCreate {
  name: string
  description: string
  permissions: Array<IPermission>
}

interface IRoleUpdate {
  name: string
  description: string
  permissions: Array<IPermission>
}

interface IRoleArchive {
  ids: Array<string>
  isArchived: boolean
}

export const getRolesApi = async (data?: IRoles) => {
  const url = "/roles"
  return await getRequest(url, { params: data })
}

export const getRoleDetailApi = async (id: string) => {
  const url = `/roles/${id}`
  return await getRequest(url)
}

export const createRoleApi = async (data: IRoleCreate) => {
  const url = `/roles`
  return await postRequest(url, data)
}

export const updateRoleApi = async (id: string, data: IRoleUpdate) => {
  const url = `/roles/${id}`
  return await putRequest(url, data)
}

export const archiveManyRoleApi = async (data: IRoleArchive) => {
  const url = `/roles`
  return await patchRequest(url, data)
}

export const deleteRoleApi = async (id: string) => {
  const url = `/roles/${id}`
  return await deleteRequest(url)
}

export const deleteManyRoleApi = async (ids: string[]) => {
  const url = `/roles`
  return await deleteRequest(url, { data: { ids } })
}
