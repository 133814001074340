import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../http"

interface IEmployees {
  name?: string
  type?: string
  key?: string
  ids?: string
  limit?: number
  offset?: number
}

interface IEmployeeCreate {
  userId: string
  departmentId: string
  name: string
  avatar?: string
  mobilePhone?: string
  facebook?: string
  workEmail?: string
  workAddress?: string
  startWorkingDate?: string
  jobId?: string
  managerId?: string
  type?: string
  subjects?: ISubjectItem[]
}

interface ISubjectItem {
  name: string
  role: "TEACHER" | "TUTORS" | "INTERSHIP"
  subjectId: string
  startWorkingDate: string
}

interface IEmployeeUpdate {
  userId: string
  departmentId: string
  name: string
  avatar?: string
  mobilePhone?: string
  facebook?: string
  workEmail?: string
  workAddress?: string
  startWorkingDate?: string
  jobId?: string
  managerId?: string
  subjects?: ISubjectItem[]
}

interface IAvalibility {
  filterFields: string[]
  sortFields: string[]
  offset: string
  limit: string
  ids: string[]
  sort: string
  areas: string[]
  fromDate: string
  toDate: string
  locationIds: string[]
  subjectIds: string[]
  daysOfWeek: string[]
  shifts: string[]
  roles: string[]
}

interface IEmployeeArchive {
  ids: Array<string>
  isArchived: boolean
}

const url = "/employees"

export const getEmployeesApi = async (data?: IEmployees) => {
  return await getRequest(url, { params: data })
}

export const getAvailableEmployeeApi = async (data: IAvalibility) => {
  return await postRequest("/availability", data)
}

export const getEmployeeDetailApi = async (id: string) => {
  return await getRequest(`${url}/${id}`)
}

export const createEmployeeApi = async (data: IEmployeeCreate) => {
  return await postRequest(url, data)
}

export const updateEmployeeApi = async (id: string, data: IEmployeeUpdate) => {
  return await putRequest(`${url}/${id}`, data)
}

export const archiveManyEmployeeApi = async (data: IEmployeeArchive) => {
  return await patchRequest(url, data)
}

export const deleteEmployeeApi = async (id: string) => {
  return await deleteRequest(`${url}/${id}`)
}

export const deleteManyEmployeeApi = async (ids: string[]) => {
  return await deleteRequest(url, { data: { ids } })
}
