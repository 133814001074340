import FormItemData from "@/components/antd/form";
import {SearchSelectTree} from "@/components/antd/Tree/SearchSelectTree";
import {ACTION_METHOD_OBJ} from "@/constants";
import {API} from "@/network/http";
import {BaseUpsertFormProps} from "@/typing/common";
import {Button, Card, Col, Form, Modal, Row} from "antd";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";

const FormItem = Form.Item;

const UpsertForm: React.FC<BaseUpsertFormProps> = forwardRef((props, ref) => {
  const {modalVisible, onCancel, onSubmit, actionType, values, loadingBtn} = props;

  const [listPermission, setListPermission] = useState<any[]>([]);
  const [actions, setActions] = useState<string[]>([]);
  const [expandKs, setExpandKs] = useState<string[]>([]);

  const [form] = Form.useForm();

  // as the second argument
  useImperativeHandle(ref, () => ({
    onResetData() {
      // reset sale table
      form.resetFields();
    },
  }));
  useEffect(() => {
    async function fetchPermission() {
      const getData = await API.permissions.permissionControllerGetMany({limit: "100"});
      if (getData.data?.total !== 0) {
        const expand: string[] = [];
        const setData = getData.data?.data?.map((it: any) => {
          expand.push(it._id);
          return {
            _id: it._id,
            title: it.name,
            key: it._id,
            children: it?.actionIds?.map((it2: any) => {
              return {
                _id: it2,
                key: it2,
                title: it2,
              };
            }),
          };
        });
        setExpandKs(expand);
        setListPermission(setData);
      }
    }

    fetchPermission();
    if (actionType === "UPDATE") {
      form.setFieldsValue({
        name: values?.name,
        description: values?.description,
        permissions: values?.permissions || [],
      });
    }
  }, []);

  const getCheckValue = (value: any) => {
    setActions(value);
  };

  const handleSubmit = async () => {
    const fieldsValidation = await form.validateFields();
    if (!fieldsValidation) {
      return;
    }
    const formValues = {...form.getFieldsValue()};
    if (actionType === "CREATE") {
      onSubmit(formValues, actionType);
    }
    if (actionType === "UPDATE") {
      onSubmit(formValues, actionType, values._id);
    }
  };

  const onResetForm = (): void => {
    // reset sale table
    form.resetFields();
  };

  const renderContentList = [
    {
      inputType: "input",
      name: "name",
      label: "Tên vai trò",
      placeholder: "Nhập tên vai trò",
      rules: [{required: true, message: "Nhập tên vai trò"}],
    },
    {
      inputType: "textarea",
      name: "description",
      label: "Mô tả",
      placeholder: "Nhập mô tả",
    },
  ];

  const renderContent = () => {
    return (
      <Card style={{paddingRight: "20px"}}>
        <Row gutter={24}>
          {renderContentList.map(item => item && <FormItemData key={item.name} {...item} />)}
          <Col span={24}>
            <FormItem name="permissions" label="Quyền hạn">
              {listPermission.length !== 0 && (
                <SearchSelectTree
                  expandKs={expandKs}
                  checkedKs={actions}
                  onCheckedEmit={getCheckValue}
                  treeData={listPermission}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </Card>
    );
  };

  const renderFooter = () => {
    return (
      <>
        {props.actionType === "CREATE" && <Button onClick={() => onResetForm()}>Làm mới</Button>}
        <Button loading={loadingBtn} type="primary" onClick={handleSubmit}>
          Đồng ý
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal
        bodyStyle={{background: "#F0F2F5"}}
        width={700}
        destroyOnClose
        maskClosable={false}
        title={`${ACTION_METHOD_OBJ[actionType]} vai trò`}
        visible={modalVisible}
        onCancel={() => {
          onResetForm();
          onCancel();
        }}
        footer={renderFooter()}
      >
        <Form form={form}>{renderContent()}</Form>
      </Modal>
    </>
  );
});

export default UpsertForm;
