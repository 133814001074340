import { DebounceSelect } from "@/components/antd/form/DebounceSelect"
import { DEFINE_MODULE } from "@/modules/edu/pages/configuration/teacher/constants"
import { SearchBarType } from "@/modules/edu/pages/operation/work-time/type"
import { API } from "@/network/http"
import { SearchOutlined } from "@ant-design/icons"
import { Form } from "antd"
import { memo } from "react"

const TeacherSearch: React.FC<SearchBarType> = ({ style, name, label }) => {
  const getListTeacher = async (searchValue?: string) => {
    const response = await API.employees.employeeControllerGetMany({ name: searchValue })
    // API.teachers.teacherControllerGetMany({ name: searchValue })
    return (
      response?.data?.data?.filter((item: any) => item?.job?.name.toLowerCase() == DEFINE_MODULE.name).map((item: any) => ({
        key: item?._id,
        value: item?._id,
        name: item?.name,
      })) || []
    )
  }

  return (
    <Form.Item name={name} label={label} style={{ marginBottom: "12px" }}>
      <DebounceSelect
        mode="multiple"
        placeholder={
          <>
            <SearchOutlined /> Giáo viên{" "}
          </>
        }
        fetchOptions={getListTeacher}
      />
    </Form.Item>
  )
}

export default memo(TeacherSearch)
