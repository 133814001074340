import { CreateWorkTimeDto } from "@/__generated__/api-v1"

export const DEFINE_MODULE = {
  name: "giờ học",
  fields: "schoolHours",
}

export const WORKING_HOUR_TRANSLATIONS: Record<CreateWorkTimeDto["dateOfWeek"], string> = {
  monday: "Thứ 2",
  tuesday: "Thứ 3",
  wednesday: "Thứ 4",
  thursday: "Thứ 5",
  friday: "Thứ 6",
  saturday: "Thứ 7",
  sunday: "Chủ nhật",
}
