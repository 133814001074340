import moment, { Moment } from "moment"

export const TEACH_SCHEDULE_STATUS = {
  INIT: "Nháp",
  ACTIVE: "Dự thảo",
  CANCELED: "Đã huỷ",
}

export type TeachScheduleStatusType = keyof typeof TEACH_SCHEDULE_STATUS

export const getDayInWeek = (date: Moment) => {
  const getDay = new Date(moment(date).format("YYYY-MM-DD HH:mm:ss")).getDay()
  if (getDay < 6) {
    return `Thứ ${getDay + 2}`
  }
  return `Chủ nhật`
}
