import { SearchBarType } from "@/modules/edu/pages/operation/work-time/type"
import { SearchOutlined } from "@ant-design/icons"
import { Form, Select } from "antd"
import { memo } from "react"

const AreaSearch: React.FC<SearchBarType> = ({ style, name }) => {
  return (
    <Form.Item name={name}>
      <Select
        mode="multiple"
        style={style ? style : { width: "100%" }}
        placeholder={
          <>
            <SearchOutlined /> Khu vực{" "}
          </>
        }
      >
        <Select.Option value="MB">Miền Bắc</Select.Option>
        <Select.Option value="MN">Miền Nam</Select.Option>
      </Select>
    </Form.Item>
  )
}

export default memo(AreaSearch)
