import {CollapseRender} from "@/components/antd/CollapseRender";
import {DefineModuleProps} from "@/typing/common";
import {PlusOutlined} from "@ant-design/icons";
import {PageContainer} from "@ant-design/pro-layout";
import ProTable, {ProColumns} from "@ant-design/pro-table";
import {Button, Empty, Space} from "antd";

export interface TableProps {
  actionRef: any;
  DEFINE_MODULE: DefineModuleProps;
  contextHolder: React.ReactElement;
  columns: ProColumns<any>[];
  createForm?: {
    isCreate: boolean;
    setCreate: (val: any) => void;
    component: React.ReactNode;
  };
  updateForm?: {
    isUpdate: boolean;
    component: React.ReactNode;
  };
  search?: any;
  disabledSearch?: boolean;
  disabledToolBar?: boolean;
  rowKey?: string;
  fetchData: (params: any) => Promise<any>;
  deleteMany?: (ids: string[]) => void;
  archiveMany?: (ids: string[]) => void;
  unArchiveMany?: (ids: string[]) => void;
}

export const Table = (customProps: TableProps) => {
  const {actionRef, columns, contextHolder, DEFINE_MODULE, createForm, updateForm,
    fetchData, deleteMany, archiveMany, unArchiveMany
  } = customProps;

  return (
    <PageContainer>
      {contextHolder}
      <ProTable<any>
        headerTitle={`Danh sách ${DEFINE_MODULE.name}`}
        actionRef={actionRef}
        bordered
        rowKey={customProps.rowKey ?? "_id"}
        search={customProps.disabledSearch ? false : customProps.search || {
          labelWidth: "auto",

          resetText: "Làm mới",
          collapseRender: collapsed => {
            return <CollapseRender collapsed={collapsed} />;
          },
        }}
        options={{density: false, fullScreen: false}}
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} trên ${total} ${DEFINE_MODULE.name}`,
        }}
        toolBarRender={customProps.disabledToolBar ? false : () => [
          <Button type="primary" onClick={() => createForm?.setCreate(true)}>
            <PlusOutlined /> Tạo mới
          </Button>,
        ]}
        columns={columns}
        sticky={{offsetScroll: 10}}
        scroll={{x: 1300, y: 430}}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Không có dữ liệu"} />,
        }}
        rowSelection={{
          onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
          },

          getCheckboxProps: (record: any) => ({
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
          }),
        }}
        tableAlertRender={({selectedRowKeys}) => (
          <Space size={24}>
            <span>Đang chọn {selectedRowKeys.length} {DEFINE_MODULE.name}</span>
          </Space>
        )}
        tableAlertOptionRender={({selectedRowKeys, onCleanSelected}) => {
          return (
            <Space size={16}>
              <Button type="primary" onClick={() => {
                const listId = selectedRowKeys as string[];
                if (deleteMany) {
                  deleteMany(listId);
                  onCleanSelected();
                }

              }} danger>
                Xóa {selectedRowKeys.length} {DEFINE_MODULE.name}
              </Button>
              <Button type="primary" onClick={() => {
                const listId = selectedRowKeys as string[];
                if (archiveMany) {
                  archiveMany(listId);
                  onCleanSelected();
                }
              }} danger>
                Lưu trữ {selectedRowKeys.length} {DEFINE_MODULE.name}
              </Button>
              <Button type="primary" onClick={() => {
                const listId = selectedRowKeys as string[];
                if (unArchiveMany) {
                  unArchiveMany(listId);
                  onCleanSelected();
                }
              }} danger>
                Hủy lưu trữ {selectedRowKeys.length} {DEFINE_MODULE.name}
              </Button>
            </Space>
          );
        }}
        cardBordered
        request={async (params, sort, filter) => {
          const requestData = {
            ...params,
            offset: ((params?.current || 1) - 1) * (params?.pageSize || 20),
            limit: params.pageSize
          };
          delete requestData.current;
          delete requestData.pageSize;
          return fetchData({
            ...requestData
          });
        }}
        columnsState={{
          persistenceKey: "pro-table-singe-demos",
          persistenceType: "localStorage",
          onChange(value) {
            console.log("value: ", value);
          }
        }}
        form={{
          syncToUrl: (values, type) => {
            return values;
          }
        }}
        dateFormatter="string"
      />
      {createForm?.isCreate && createForm.component
      }
      {updateForm?.isUpdate && updateForm.component}
    </PageContainer>
  );
};
