import { combineReducers } from "redux"
import { absentSchedule } from "./absent-schedule"
import { role as action } from "./actions"
import { auth } from "./auth"
import { common } from "./common"
import { course } from "./course"
import { currentModalReducer as currentModal } from "./current-modal"
import { department } from "./department"
import { employee } from "./employee"
import { permission } from "./permission"
import { profile } from "./profile"
import { role } from "./role"
import { room } from "./room"
import { schoolHour } from "./school-hour"
import { subject } from "./subject"
import { teacher } from "./teacher"
import { teacherRole } from "./teacher-role"
import { user } from "./user"
import { workSchedule } from "./work-schedule"
import { workTime } from "./work-time"

export default combineReducers({
  user,
  room,
  subject,
  profile,
  common,
  role,
  permission,
  action,
  auth,
  department,
  course,
  schoolHour,
  teacherRole,
  employee,
  teacher,
  absentSchedule,
  workSchedule,
  workTime,
  currentModal,
})
