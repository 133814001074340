import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../http"

interface IUsers {
  name?: string
  email?: string
  roleId?: string
  limit?: number
  offset?: number
}

interface IUserCreate {
  name: string
  email: string
  roleId: string
  photo?: string | null
  password: string
  type: string
  status: string
}

interface IUserUpdate {
  name?: string
  email?: string
  roleId?: string
  photo?: string | null
  password?: string
  type?: string
  status?: string
}

interface IUserArchive {
  ids: Array<string>
  isArchived: boolean
}

export const getUsersApi = async (data?: IUsers) => {
  const url = "/users"
  return await getRequest(url, { params: data })
}

export const getUserDetailApi = async (id: string) => {
  const url = `/users/${id}`
  return await getRequest(url)
}

export const createUserApi = async (data: IUserCreate) => {
  const url = `/users`
  return await postRequest(url, data)
}

export const updateUserApi = async (id: string, data: IUserUpdate) => {
  const url = `/users/${id}`
  return await putRequest(url, data)
}

export const archiveManyUserApi = async (data: IUserArchive) => {
  const url = `/users`
  return await patchRequest(url, data)
}

export const deleteUserApi = async (id: string) => {
  const url = `/users/${id}`
  return await deleteRequest(url)
}

export const deleteManyUserApi = async (ids: string[]) => {
  const url = `/users`
  return await deleteRequest(url, { data: { ids } })
}
