/* eslint-disable react-hooks/exhaustive-deps */
import { handleGetDateRange } from "@/utils"
import { PlusCircleOutlined, QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons"
import { Button, Card, Col, DatePicker, Form, Row, Select, Typography } from "antd"
import { useEffect, useState } from "react"
import ModalCheck from "./check"
import AreaSearch from "./search/AreaSearchComp"
import LocationSearch from "./search/LocationSearchComp"
import RoomSearch from "./search/RoomSearchComp"
import SubjectSearch from "./search/SubjectSearchComp"
import TeacherSearch from "./search/TeacherSearchComp"
import TimeShiftSearch from "./search/TimeShiftSearchComp"

const { Title } = Typography

const stylesSearch = {
  width: "100%",
  minWidth: "100px",
}

const stylesSearchDate = {
  width: "100%",
  minWidth: "130px",
}

export const getDefaultDateRange = () => {
  const { fromDate, toDate } = handleGetDateRange({
    poles: "p",
    dateType: "w",
    numDate: 0,
  })

  return { fromDate, toDate }
}

interface SearchBarProps {
  triggerSearch: (pagination: any, filters: any) => void
  handleCreate: () => void
  onReload: () => void
  setFilterTable: any
}

const SearchBar: React.FC<SearchBarProps> = ({ triggerSearch, handleCreate, onReload, setFilterTable }) => {
  const [form] = Form.useForm()
  const [disableDatePicker, setDisableDatePicker] = useState<boolean>(false)
  const [showModalCheck, setShowModalCheck] = useState<boolean>(false)

  function handleSearch() {
    const querySearch = form?.getFieldsValue()
    if (querySearch.fromDate || querySearch.toDate) {
      if (!querySearch.fromDate) {
        form.setFields([
          { name: "toDate", errors: [] },
          { name: "fromDate", errors: ["Chọn ngày bắt đầu"] },
        ])
        return
      }
      if (!querySearch.toDate) {
        form.setFields([
          { name: "fromDate", errors: [] },
          { name: "toDate", errors: ["Chọn ngày kết thúc"] },
        ])
        return
      }
      const startTime = querySearch.fromDate.valueOf()
      const endTime = querySearch.toDate.valueOf()
      if (startTime > endTime) {
        form.setFields([
          { name: "fromDate", errors: ["Ngày bắt đầu phải nhỏ hơn"] },
          { name: "toDate", errors: ["Ngày kết thúc phải lớn hơn"] },
        ])
        return
      }
    } else {
      form.setFields([
        { name: "fromDate", errors: [] },
        { name: "toDate", errors: [] },
      ])
    }
    const formatQuery = Object.fromEntries(
      Object.entries(querySearch).filter(([k, v]: any) => {
        if (Array.isArray(v) && v.length === 0) {
          return false
        }
        return true
      }),
    )
    setFilterTable(formatQuery)
    triggerSearch(null, formatQuery)
  }

  useEffect(() => {
    const { fromDate, toDate } = getDefaultDateRange()

    form.setFieldsValue({
      fromDate,
      toDate,
    })
  }, [])

  const handleSelectDateRange = (e: string) => {
    setDisableDatePicker(true)
    const splitStr = e.split("-")
    const { fromDate, toDate } = handleGetDateRange({
      poles: splitStr[0] as "p" | "n",
      dateType: splitStr[1] as "d" | "w" | "M" | "y",
      numDate: Number(splitStr[2]),
    })

    form?.setFieldsValue({
      fromDate,
      toDate,
    })
  }

  const handleDeselectDateRange = () => {
    form?.setFieldsValue({
      fromDate: null,
      toDate: null,
    })
    setDisableDatePicker(false)
  }

  const onAddClass = () => handleCreate()

  const listSearchBar = [
    { label: "Khu vực", name: "areas", component: <AreaSearch style={stylesSearch} name="areas" /> },
    {
      label: "Cơ sở",
      name: "locationIds",
      span: 4,
      component: <LocationSearch style={stylesSearch} name="locationIds" />,
    },
    { label: "Phòng", name: "roomIds", span: 4, component: <RoomSearch style={stylesSearch} name="roomIds" /> },
    {
      label: "Môn học",
      name: "subjectIds",
      span: 5,
      component: <SubjectSearch style={stylesSearch} name="subjectIds" />,
    },
    { label: "Loại ca", name: "shifts", span: 5, component: <TimeShiftSearch style={stylesSearch} name="shifts" /> },
    {
      label: "Giáo viên",
      name: "teacherIds",
      span: 5,
      component: <TeacherSearch style={stylesSearch} name="teacherIds" />,
    },
  ]

  return (
    <Card style={{ border: "none" }} bodyStyle={{ padding: "12px 0" }}>
      <Form form={form}>
        <Row>
          <Col span={4}>
            <Row>
              <Button icon={<PlusCircleOutlined />} type="primary" onClick={() => onAddClass()}>
                Thêm khóa học
              </Button>
            </Row>
            <Row style={{ marginTop: 4 }}>
              <Button icon={<QuestionCircleOutlined />} onClick={() => setShowModalCheck(true)}>
                Kiểm tra
              </Button>
            </Row>
          </Col>
          <Col span={20}>
            <Row gutter={24}>
              <Col span={24}>
                <Row>
                  <Col span={5}></Col>
                  <Col span={19}>
                    <Row gutter={12}>
                      <Col xl={8} lg={24} md={24} sm={24} xs={24}>
                        <Select
                          style={stylesSearchDate}
                          placeholder="Ngày"
                          allowClear
                          onSelect={handleSelectDateRange}
                          onDeselect={handleDeselectDateRange}
                        >
                          {/* n : negative */}
                          {/* p : positive */}
                          {/* d : day */}
                          {/* w : week */}
                          {/* M : month */}
                          <Select.Option value="p-d-0">Hôm nay</Select.Option>
                          <Select.Option value="p-d-1">Ngày mai</Select.Option>
                          <Select.Option value="p-M-0">Tháng này</Select.Option>
                          <Select.Option value="p-w-0">Tuần này</Select.Option>
                          <Select.Option value="n-w-1">Tuần trước</Select.Option>
                          <Select.Option value="p-w-1">Tuần tới</Select.Option>
                          <Select.Option value="n-M-1">Tháng trước</Select.Option>
                          <Select.Option value="p-M-1">Tháng tới</Select.Option>
                        </Select>
                      </Col>
                      <Col xl={8} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item name="fromDate" label="Từ" style={{ marginBottom: "12px" }}>
                          {/* @ts-ignore */}
                          <DatePicker
                            disabled={disableDatePicker}
                            style={stylesSearchDate}
                            onSelect={() => {
                              form.setFields([{ name: "fromDate", errors: [] }])
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={8} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item name="toDate" label="Đến" style={{ marginBottom: "12px" }}>
                          {/* @ts-ignore */}
                          <DatePicker
                            disabled={disableDatePicker}
                            style={stylesSearchDate}
                            onSelect={() => {
                              form.setFields([{ name: "toDate", errors: [] }])
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={24} style={{ display: "flex", justifyContent: "flex-end" }}>
              {listSearchBar.map((item, idx) => (
                <Col key={`search_bar_${idx}`} span={item.span}>
                  {item.component}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col offset={20} span={4} style={{ textAlign: "right" }}>
            {/* <Space direction="horizontal" align="end"> */}
            {/* <Button onClick={() => onReload()}>Làm mới</Button> */}
            <Button type="primary" onClick={handleSearch} icon={<SearchOutlined />}>
              Tìm kiếm
            </Button>
            {/* </Space> */}
          </Col>
        </Row>
      </Form>
      {showModalCheck && <ModalCheck modalVisible={showModalCheck} onCancel={() => setShowModalCheck(false)} />}
    </Card>
  )
}

export default SearchBar
