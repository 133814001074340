import { API_NOT_FOUND } from "../../constants"

export class ApiNotFound extends Error {
  data: any
  isLogAble: boolean
  code: number = API_NOT_FOUND

  constructor(message: string, name = "", data?: any) {
    super(message)
    this.name = name
    this.data = data
    this.isLogAble = true
  }
}
