import { IDeleteManyCourse, IGetCourse, IPatchCourse, IUpsertCourse } from "../../typing/course"
import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../http"

const coureUrl = "/courses"

const getCoursesApi = async (data?: IGetCourse) => {
  return await getRequest(coureUrl, { params: data })
}

const getCourseDetailApi = async (id: string) => {
  const url = `${coureUrl}/${id}`
  return await getRequest(url)
}

const createCourseApi = async (data: IUpsertCourse) => {
  return await postRequest(coureUrl, data)
}

const updateCourseApi = async (id: string, data: IUpsertCourse) => {
  const url = `${coureUrl}/${id}`
  return await putRequest(url, data)
}

const archiveManyCourseApi = async (data: IPatchCourse) => {
  return await patchRequest(coureUrl, data)
}

const deleteCourseApi = async (id: string) => {
  const url = `${coureUrl}/${id}`
  return await deleteRequest(url)
}

const deleteManyCourseApi = async (data: IDeleteManyCourse) => {
  return await deleteRequest(coureUrl, { data })
}

export {
  getCoursesApi,
  getCourseDetailApi,
  createCourseApi,
  updateCourseApi,
  archiveManyCourseApi,
  deleteCourseApi,
  deleteManyCourseApi,
}
