import { API } from "@/network/http"
import { SelectOptionProps } from "@/typing/common"
import { Col, Form, Input, Select } from "antd"
import { cloneDeep } from "lodash"

const FormItem = Form.Item
const { Option } = Select

export default function AddressInput(props: any) {
  const { form, location, setLocation } = props

  const onSelectProvince = async (provinceId: any) => {
    const getD = await API.districts.masterDataControllerGetManyDistricts({ provinceId, limit: "100" })
    const districtMapping =
      getD.data.data.map(
        d =>
          ({
            key: d.id,
            value: d.id,
            name: d.name,
          } as any),
      ) ?? []
    const newLocation = cloneDeep({ ...location, district: districtMapping, ward: [] })
    form.setFieldsValue({
      location: {
        ...form.getFieldValue("location"),
        district: null,
        ward: null,
      },
    })
    setLocation(newLocation)
  }

  const onSelectDistrict = async (districtId: any) => {
    const getW = await API.wards.masterDataControllerGetManyWards({ districtId, limit: "100" })
    const wardMapping =
      getW.data.data.map(
        d =>
          ({
            key: d.id,
            value: d.id,
            name: d.name,
          } as any),
      ) ?? []
    const newLocation = cloneDeep({ ...location, ward: wardMapping })
    form.setFieldsValue({
      location: {
        ...form.getFieldValue("location"),
        ward: null,
      },
    })
    setLocation(newLocation)
  }

  return (
    <Col span={24}>
      <FormItem required={true} label="Địa chỉ">
        <Input.Group compact>
          <FormItem name={["location", "address"]} noStyle rules={[{ required: true, message: "Nhập địa chỉ" }]}>
            <Input style={{ width: "40%" }} placeholder="Vui lòng nhập địa chỉ" />
          </FormItem>
          <FormItem name={["location", "province"]} noStyle rules={[{ required: true, message: "Chọn tỉnh/ thành" }]}>
            <Select style={{ width: "20%" }} placeholder="Chọn tỉnh/ thành" showSearch onChange={onSelectProvince}>
              {location.province.map((province: SelectOptionProps) => (
                <Option key={province.key} value={province.value}>
                  {province.name}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem name={["location", "district"]} noStyle rules={[{ required: true, message: "Chọn quận/ huyện" }]}>
            <Select style={{ width: "20%" }} placeholder="Chọn quận/ huyện" showSearch onChange={onSelectDistrict}>
              {location.district.map((district: SelectOptionProps) => (
                <Option key={district.key} value={district.value}>
                  {district.name}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem name={["location", "ward"]} noStyle rules={[{ required: true, message: "Chọn phường/ xã" }]}>
            <Select style={{ width: "20%" }} placeholder="Chọn phường/ xã" showSearch>
              {location.ward.map((ward: SelectOptionProps) => (
                <Option key={ward.key} value={ward.value}>
                  {ward.name}
                </Option>
              ))}
            </Select>
          </FormItem>
        </Input.Group>
      </FormItem>
    </Col>
  )
}
