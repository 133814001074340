import FormItemData from "@/components/antd/form/";
import {ACTION_METHOD_OBJ} from "@/constants";
import {layout} from "@/constants/layout";
import {AbsentScheduleStatus} from "@/modules/edu/pages/operation/absent-schedule/types";
import ListReplace from "@/modules/edu/pages/operation/absent-schedule/views/ListReplace";
import {useEmployees} from "@/modules/hr/pages/employee/hooks";
import {API} from "@/network/http";
import {BaseUpsertFormProps} from "@/typing/common";
import {EmployeeDto} from "@/__generated__/api-v1";
import {Button, Card, Col, Divider, Form, Modal, Row} from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import {take, takeRightWhile} from "lodash";
import moment from "moment";
import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import {DEFINE_MODULE} from "../constants";

const UpsertForm: React.FC<BaseUpsertFormProps> = forwardRef((props, ref) => {
  const {modalVisible, onCancel, onSubmit, actionType, values, loadingBtn} = props;
  const [form] = Form.useForm();

  // as the second argument
  useImperativeHandle(ref, () => ({
    onResetData() {
      // reset sale table
      form.resetFields();
    },
  }));

  const {employees, fetchEmployees} = useEmployees();
  const getListEmployee = async (searchValue?: string) => {
    const data = await fetchEmployees({name: searchValue});
    return (
      data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const getListDepartment = async (searchValue?: string) => {
    const response = await API.departments.departmentControllerGetMany({name: searchValue});
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    );
  };

  const getListSchoolHour = async (searchValue?: string) => {
    const response = await API.schoolHours.schoolHourControllerGetMany({limit: "100"});
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.timeFrame,
      })) || []
    );
  };

  useEffect(() => {
    if (actionType === "UPDATE") {
      form.setFieldsValue({
        employeeId: values.employeeId,
        departmentId: values.employee.departmentId,
        fromDate: moment(values.fromDate),
        toDate: moment(values.toDate),
        schoolHourIds: values.schoolHourIds,
        shift: values.shift,
        reason: values.reason,
        status: values.status,
      });
    }
  }, []);

  const handleSubmit = async (status?: AbsentScheduleStatus) => {
    const fieldsValidation = await form.validateFields();
    if (!fieldsValidation) {
      return;
    }
    const formValues = {...form.getFieldsValue(), ...(status && {status})};
    if (actionType === "CREATE") {
      onSubmit(formValues, actionType);
    }
    if (actionType === "UPDATE") {
      onSubmit(formValues, actionType, values._id);
    }
  };

  const onResetForm = (): void => {
    // reset sale table
    form.resetFields();
  };

  const watchedEmployeeId = Form.useWatch("employeeId", form);
  useEffect(() => {
    if (watchedEmployeeId) {
      const employee = employees.find(e => e._id === watchedEmployeeId) as EmployeeDto & {
        department: {name: string; _id: string;};
      };
      form.setFieldsValue({departmentId: employee?.department._id});
    }
  }, [watchedEmployeeId, employees]);

  const renderContentList = [
    {
      inputType: "selectsearch",
      name: "employeeId",
      label: "Nhân viên",
      placeholder: "Chọn nhân viên",
      fetchOptions: getListEmployee,
      rules: [{required: true, message: "Chọn nhân viên"}],
    },
    {
      inputType: "selectsearch",
      name: "departmentId",
      label: "Phòng ban",
      placeholder: "Chọn phòng ban",
      fetchOptions: getListDepartment,
      rules: [{required: true, message: "Chọn phòng ban"}],
    },
    {
      inputType: "textarea",
      name: "reason",
      label: "Lý do",
      placeholder: "Nhập lý do",
      rules: [{required: true, message: "Nhập lý do"}],
    },
    {
      inputType: "datetime",
      name: "fromDate",
      label: "Từ ngày",
      placeholder: "Chọn từ ngày",
      showTime: false,
      rules: [{required: true, message: "Chọn từ ngày"}],
      rightCol: true,
    },
    {
      inputType: "datetime",
      name: "toDate",
      label: "Đến ngày",
      placeholder: "Chọn đến ngày",
      showTime: false,
      rules: [{required: true, message: "Chọn đến ngày"}],
      rightCol: true,
    },
    {
      inputType: "select",
      name: "shift",
      label: "Loại ca nghỉ",
      mode: "multiple",
      placeholder: "Chọn loại ca",
      options: [
        {
          value: "MORNING",
          key: "MORNING",
          name: "Sáng",
        },
        {
          value: "AFTERNOON",
          key: "AFTERNOON",
          name: "Chiều",
        },
        {
          value: "EVENING",
          key: "EVENING",
          name: "Tối",
        },
      ],
      rules: [{required: true, message: "Chọn loại ca"}],
      rightCol: true,
    },
    {
      inputType: "selectsearch",
      name: "schoolHourIds",
      label: "Ca nghỉ",
      placeholder: "Chọn ca nghỉ",
      mode: "multiple",
      fetchOptions: getListSchoolHour,
      rightCol: true,
    },
  ];

  const renderContent = () => {
    return (
      <Card style={{paddingRight: "20px"}}>
        <Row gutter={[24, 24]}>
          <Col span={12} push={12} style={{display: "flex", justifyContent: "right"}}>
            <ButtonGroup>
              <Button
                type="primary"
                disabled={(values?.status as AbsentScheduleStatus) !== "INITIAL" && props.actionType === "UPDATE"}
              >
                Dự thảo
              </Button>
              <Button type="primary" disabled={(values?.status as AbsentScheduleStatus) !== "ACCEPTED"}>
                Đã duyệt
              </Button>
              <Button type="primary" disabled={!["CANCELED", "REJECTED"].includes(values?.status)}>
                Huỷ
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Divider />

        <Row gutter={[24, 24]}>
          <Col span={12}>
            {take(renderContentList, 3).map(item => item && <FormItemData key={item.name} {...item} />)}
          </Col>
          <Col span={12}>
            {takeRightWhile(renderContentList, "rightCol").map(
              item => item && <FormItemData key={item.name} {...item} />,
            )}
          </Col>
        </Row>
        <ListReplace />
      </Card>
    );
  };

  const renderFooter = () => {
    return (
      <>
        <Button loading={loadingBtn} type="ghost" danger onClick={() => handleSubmit("ACCEPTED")}>
          Huỷ
        </Button>
        {(values?.status as AbsentScheduleStatus) === "INITIAL" && (
          <>
            <Button loading={loadingBtn} type="primary" danger onClick={() => handleSubmit("CANCELED")}>
              Từ chối
            </Button>
            <Button loading={loadingBtn} type="primary" onClick={() => handleSubmit("ACCEPTED")}>
              Duyệt
            </Button>
          </>
        )}
        {props.actionType === "CREATE" && <Button onClick={() => onResetForm()}>Làm mới</Button>}
        <Button loading={loadingBtn} type="primary" onClick={() => handleSubmit()}>
          {props.actionType === "CREATE" ? "Tạo mới" : "Cập nhật"}
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal
        bodyStyle={{background: "#F0F2F5"}}
        width={1350}
        destroyOnClose
        maskClosable={false}
        title={`${ACTION_METHOD_OBJ[actionType]} ${DEFINE_MODULE.name}`}
        visible={modalVisible}
        onCancel={() => {
          onResetForm();
          onCancel();
        }}
        footer={renderFooter()}
      >
        <Form {...layout} labelWrap form={form}>
          {renderContent()}
        </Form>
      </Modal>
    </>
  );
});

export default UpsertForm;
