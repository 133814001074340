import FormItemData from "@/components/antd/form";
import {ACTION_METHOD_OBJ} from "@/constants";
import {layout} from "@/constants/layout";
import {BaseUpsertFormProps} from "@/typing/common";
import {Button, Card, Form, Modal, Row} from "antd";
import moment from "moment";
import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import {DEFINE_MODULE} from "../constants";

const UpsertForm: React.FC<BaseUpsertFormProps> = forwardRef((props, ref) => {
  const {modalVisible, onCancel, onSubmit, actionType, values, loadingBtn} = props;
  const [form] = Form.useForm();

  // as the second argument
  useImperativeHandle(ref, () => ({
    onResetData() {
      // reset sale table
      form.resetFields();
    },
  }));

  useEffect(() => {
    if (actionType === "UPDATE") {
      const startHourArray = values.startHourName.split(":");
      const startHourDate = moment(new Date().setHours(Number(startHourArray[0]), Number(startHourArray[1]), 0, 0));

      const endHourArray = values.endHourName.split(":");
      const endHourDate = moment(new Date().setHours(Number(endHourArray[0]), Number(endHourArray[1]), 0, 0));
      form.setFieldsValue({
        timeFrame: values.timeFrame,
        startHourName: startHourDate,
        endHourName: endHourDate,
        studySchedule: values.studySchedule,
        startHour: startHourDate,
        endHour: endHourDate,
      });
    }
  }, []);

  const handleSubmit = async () => {
    const fieldsValidation = await form.validateFields();
    if (!fieldsValidation) {
      return;
    }
    const formValues = {...form.getFieldsValue()};
    if (actionType === "CREATE") {
      onSubmit(formValues, actionType);
    }
    if (actionType === "UPDATE") {
      onSubmit(formValues, actionType, values._id);
    }
  };

  const onResetForm = (): void => {
    // reset sale table
    form.resetFields();
  };

  const renderContentList = [
    {
      inputType: "select",
      options: [
        {value: "MORNING", key: "Sáng", name: "Sáng"},
        {value: "AFTERNOON", key: "Chiều", name: "Chiều"},
        {value: "EVENING", key: "Tối", name: "Tối"},
      ],
      name: "studySchedule",
      label: "Buổi",
      placeholder: "Chọn buổi",
      rules: [{required: true, message: "Chọn buổi"}],
    },
    {
      inputType: "time",
      name: "startHourName",
      label: "Từ giờ",
      placeholder: "Chọn giờ bắt đầu",
      format: "HH:mm",
      showTime: {
        hideDisabledOptions: true,
        defaultValue: [moment("00:00", "HH:mm"), moment("11:59", "HH:mm")],
      },
      rules: [{required: true, message: "Chọn giờ bắt đầu"}],
    },
    {
      inputType: "time",
      name: "endHourName",
      label: "Đến giờ",
      placeholder: "Chọn giờ kết thúc",
      format: "HH:mm",
      showTime: {
        hideDisabledOptions: true,
        defaultValue: [moment("00:00", "HH:mm"), moment("11:59", "HH:mm")],
      },
      rules: [{required: true, message: "Chọn giờ kết thúc"}],
    },
  ];

  const renderContent = () => {
    return (
      <Card style={{paddingRight: "20px"}}>
        <Row gutter={24}>{renderContentList.map(item => item && <FormItemData key={item.name} {...item} />)}</Row>
      </Card>
    );
  };

  const renderFooter = () => {
    return (
      <>
        {props.actionType === "CREATE" && <Button onClick={() => onResetForm()}>Làm mới</Button>}
        <Button loading={loadingBtn} type="primary" onClick={handleSubmit}>
          Đồng ý
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal
        bodyStyle={{background: "#F0F2F5"}}
        width={700}
        destroyOnClose
        maskClosable={false}
        title={`${ACTION_METHOD_OBJ[actionType]} ${DEFINE_MODULE.name}`}
        visible={modalVisible}
        onCancel={() => {
          onResetForm();
          onCancel();
        }}
        footer={renderFooter()}
      >
        <Form {...layout} form={form}>
          {renderContent()}
        </Form>
      </Modal>
    </>
  );
});

export default UpsertForm;
