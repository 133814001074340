/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ResponseSuccessDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: object
}

export interface ResponseErrorDto {
  /** type error */
  type: string

  /** error code */
  code: number

  /** Error sub code */
  subCode: number

  /** Sub title */
  subTitle: string

  /** Error message */
  message: string

  /** Error name */
  name: string

  /** Error data */
  data: object
}

export interface ActionDto {
  /** Object Id */
  _id: string

  /** name */
  name: string

  /** key */
  key: string

  /** url */
  url: string

  /** method */
  method: string

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyActionDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: ActionDto[]
}

export interface ResponseGetOneActionDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: ActionDto
}

export interface CreateActionDto {
  /** Name */
  name: string

  /** Key */
  key: string

  /** Url */
  url: string

  /** Method */
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE"
}

export interface DeleteOneDto {
  /** deleted count */
  deletedCount: number
}

export interface ResponseDeleteSuccessDto {
  /** Total documents */
  total: number

  /** Data is object. */
  data: DeleteOneDto
}

export interface LoginDto {
  /** Username */
  username: string

  /** Password */
  password: string
}

export interface ResponseLogin {
  /** token */
  token: string
}

export interface ResponseLoginDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: ResponseLogin
}

export interface ForgotPasswordDto {
  /** Email */
  email: string
}

export interface ResponseForgotPasswordDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: ForgotPasswordDto
}

export interface ResetPasswordDto {
  /** verifyCode */
  verifyCode: string

  /** password: min length is 6 characters */
  password: string
}

export interface ResponseResetPasswordDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: ResetPasswordDto
}

export interface ActionDtoShare {
  /** _id */
  _id: string

  /** name */
  name: string

  /** key */
  key: string

  /** method */
  method: string

  /** url */
  url: string
}

export interface PermissionDto {
  /** actions */
  actions: ActionDtoShare[]

  /** object id */
  _id: string

  /** name */
  name: string

  /** key */
  key: string

  /** action ids */
  actionIds: string[]

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyPermissionDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: PermissionDto[]
}

export interface ResponseGetOnePermissionDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: PermissionDto
}

export interface CreatePermissionDto {
  /** Name */
  name: string

  /** Key */
  key: string

  /** action ids */
  actionIds: string[]
}

export interface UpdateManyPermissionDto {
  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: string[]

  /** isArchived */
  isArchived: boolean
}

export interface UpdateSuccessDto {
  /** number of modified */
  nModified: number
}

export interface ResponseUpdateManyPermissionDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: UpdateSuccessDto
}

export interface DeleteManyPermissionDto {
  /** delete by ids. Example in body: {ids: [1,2,3]} */
  ids: string[]
}

export interface DeleteSuccessDto {
  /** deleted count */
  deletedCount: number
}

export interface ResponseDeleteManyPermissionDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: DeleteSuccessDto
}

export interface RolePermissionDto {
  /** permission id */
  _id: string

  /** name */
  name: string

  /** key */
  key: string
}

export interface ActionItem {
  /** id */
  _id: string

  /** name */
  name: string

  /** key */
  key: string

  /** method */
  method: string

  /** url */
  url: string
}

export interface RolePermissionItem {
  /** permission id */
  id: string

  /** permission */
  permission: RolePermissionDto

  /** list action ids */
  actionIds: string[]

  /** list action */
  actions: ActionItem[]
}

export interface RoleDto {
  /** object id */
  _id: string

  /** name */
  name: string

  /** list permissions */
  permissions: RolePermissionItem[]

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyRoleDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: RoleDto[]
}

export interface ResponseGetOneRoleDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: RoleDto
}

export interface CreateRoleDto {
  /** Name */
  name: string

  /** Description */
  description?: string

  /** permissions */
  permissions: string[]
}

export interface UpdateManyRoleDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManyRoleDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export interface RoleDtoShare {
  /** _id */
  _id: string

  /** name */
  name: string

  /** description */
  description: string
}

export interface UserDto {
  /** role */
  role: RoleDtoShare

  /** id */
  _id: string

  /** name */
  name: string

  /** code */
  code: string

  /** username */
  username: string

  /** email */
  email: string

  /** phone */
  phone: string

  /** type */
  type: string

  /** roleId */
  roleId: string

  /** status */
  status: string

  /** settings */
  settings: object

  /** id */
  filters: any[][]

  /** isRootAdmin */
  isRootAdmin: boolean

  /** lastLoginAt */
  lastLoginAt: string

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyUserDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: UserDto[]
}

export interface ResponseGetOneUserDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: UserDto
}

export interface CreateUserDto {
  /** Name */
  name: string

  /** username */
  username: string

  /** email */
  email?: string

  /** phone */
  phone: string

  /** password */
  password: string

  /** type */
  type?: "USER"

  /** roleId */
  roleId: string

  /** status */
  status: "ACTIVE" | "BLOCKED" | "DELETED"
}

export interface UpdateUserDto {
  /** Name */
  name: string

  /** email */
  email?: string

  /** phone */
  phone: string

  /** Enter password if you want update */
  password?: string

  /** type */
  type?: "USER"

  /** roleId */
  roleId: string

  /** status */
  status: "ACTIVE" | "BLOCKED" | "DELETED"

  /** settings */
  settings?: object

  /** filters */
  filters?: string[]
}

export interface UpdateManyUserDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManyUserDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export interface Action {
  /** object id */
  _id: string

  /** name */
  name: string

  /** key */
  key: string

  /** method */
  method: string

  /** url */
  url: string
}

export interface Permission {
  /** id */
  _id: string

  /** name */
  name: string

  /** key */
  key: string

  /** list action */
  actions: Action[]
}

export interface ProfileDto {
  /** object id */
  _id: string

  /** name */
  name: string

  /** phone */
  phone: string

  /** role id */
  roleId: string

  /** list permissions */
  permissions: Permission[]
}

export interface ResponseGetProfileDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: ProfileDto
}

export interface UpdateProfileDto {
  /** Name */
  name: string

  /** email */
  email?: string

  /** phone */
  phone: string

  /** Enter password if you want update */
  password?: string

  /** settings */
  settings?: object

  /** filters */
  filters?: string[]
}

export interface CreateCommandDto {
  /** Collection name */
  collectionName?:
    | "districts"
    | "provinces"
    | "settings"
    | "users"
    | "actions"
    | "permissions"
    | "roles"
    | "wards"
    | "school_hours"

  /** apiKey */
  apiKey: string
}

export interface ProvinceDtoShare {
  /** _id */
  _id: string

  /** id */
  id: number

  /** name */
  name: string
}

export interface DistrictDtoShare {
  /** _id */
  _id: string

  /** id */
  id: number

  /** name */
  name: string

  /** provinceId */
  provinceId: number
}

export interface WardDtoShare {
  /** _id */
  _id: string

  /** id */
  id: number

  /** name */
  name: string

  /** provinceId */
  provinceId: number

  /** districtId */
  districtId: number
}

export interface AreaDtoShare {
  /** _id */
  _id: string

  /** name */
  name: string

  /** nameSearch */
  nameSearch: string

  /** code */
  code: string

  /** areaTypeId */
  areaTypeId: string
}

export interface LocationDto {
  /** province */
  province: ProvinceDtoShare

  /** district */
  district: DistrictDtoShare

  /** ward */
  ward: WardDtoShare

  /** area */
  area: AreaDtoShare

  /** object id */
  _id: string

  /** name */
  name: string

  /** nameSearch */
  nameSearch: string

  /** code */
  code: string

  /** provinceId */
  provinceId: number

  /** districtId */
  districtId: number

  /** wardId */
  wardId: number

  /** address */
  address: string

  /** full Address */
  fullAddress: string

  /** parking address */
  parkingAddress: string

  /** hotline */
  hotline: string

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyLocationDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: LocationDto[]
}

export interface ResponseGetOneLocationDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: LocationDto
}

export interface CreateLocationDto {
  /** Name */
  name: string

  /** code */
  code: string

  /** province id */
  provinceId?: number

  /** district id */
  districtId?: number

  /** ward id */
  wardId?: number

  /** address */
  address?: string

  /** full address */
  fullAddress?: string

  /** parking address */
  parkingAddress?: string

  /** hotline */
  hotline?: string
}

export interface UpdateManyLocationDto {
  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: string[]

  /** isArchived */
  isArchived: boolean
}

export interface ResponseUpdateManyLocationDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: UpdateSuccessDto
}

export interface DeleteManyLocationDto {
  /** delete by ids. Example in body: {ids: [1,2,3]} */
  ids: string[]
}

export interface ResponseDeleteManyLocationDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: DeleteSuccessDto
}

export interface SchoolHourDto {
  /** object id */
  _id: string

  /** time frame */
  timeFrame: string

  /** start hour */
  startHour: number

  /** start hour name */
  startHourName: string

  /** end hour */
  endHour: number

  /** end hour name */
  endHourName: string

  /** study schedule */
  studySchedule: string

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManySchoolHourDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: SchoolHourDto[]
}

export interface ResponseGetOneSchoolHourDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: SchoolHourDto
}

export interface CreateSchoolHourDto {
  /** time frame */
  timeFrame: string

  /** star hour */
  startHour: number

  /** end hour */
  endHour: number

  /** study schedule */
  studySchedule: "MORNING" | "AFTERNOON" | "EVENING"
}

export interface UpdateManySchoolHourDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManySchoolHourDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export interface LocationDtoShare {
  /** _id */
  _id: string

  /** name */
  name: string

  /** code */
  code: string

  /** fullAddress */
  fullAddress: string
}

export interface RoomDto {
  /** location */
  location: LocationDtoShare

  /** object id */
  _id: string

  /** code */
  code: string

  /** location id */
  locationId: string

  /** max quantity */
  maxQuantity: number

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyRoomDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: RoomDto[]
}

export interface ResponseGetOneRoomDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: RoomDto
}

export interface CreateRoomDto {
  /** code */
  code: string

  /** location id */
  locationId: string

  /** max quantity */
  maxQuantity: number
}

export interface UpdateManyRoomDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManyRoomDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export interface SubjectItemDtoShare {
  /** _id */
  _id: string

  /** name */
  name: string
}

export interface SubjectDto {
  /** subjectForm */
  subjectForm: SubjectItemDtoShare

  /** subjectLevel */
  subjectLevel: SubjectItemDtoShare

  /** subjectObject */
  subjectObject: SubjectItemDtoShare

  /** subjectType */
  subjectType: SubjectItemDtoShare

  /** id */
  _id: string

  /** name */
  name: string

  /** name search */
  nameSearch: string

  /** code */
  code: string

  /** number of lessons */
  numberOfLessons: number

  /** subject type id */
  subjectTypeId: string

  /** subject object id */
  subjectObjectId: string

  /** subject level id */
  subjectLevelId: string

  /** subject form id */
  subjectFormId: string

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManySubjectDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: SubjectDto[]
}

export interface ResponseGetOneSubjectDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: SubjectDto
}

export interface CreateSubjectDto {
  /** name */
  name: string

  /** code */
  code: string

  /** subject type id */
  subjectTypeId: string

  /** subject object id */
  subjectObjectId: string

  /** subject level id */
  subjectLevelId: string

  /** subject form id */
  subjectFormId: string

  /** number of lessons */
  numberOfLessons: number
}

export interface UpdateManySubjectDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManySubjectDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export type SubjectTypeDto = object

export interface ResponseGetManySubjectTypeDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: SubjectTypeDto[]
}

export interface ResponseGetOneSubjectTypeDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: SubjectTypeDto
}

export interface CreateSubjectTypeDto {
  /** name */
  name: string
}

export interface UpdateManySubjectTypeDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManySubjectTypeDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export type SubjectFormDto = object

export interface ResponseGetManySubjectFormDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: SubjectFormDto[]
}

export interface ResponseGetOneSubjectFormDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: SubjectFormDto
}

export interface CreateSubjectFormDto {
  /** name */
  name: string
}

export interface UpdateManySubjectFormDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManySubjectFormDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export type SubjectLevelDto = object

export interface ResponseGetManySubjectLevelDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: SubjectLevelDto[]
}

export interface ResponseGetOneSubjectLevelDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: SubjectLevelDto
}

export interface CreateSubjectLevelDto {
  /** name */
  name: string
}

export interface UpdateManySubjectLevelDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManySubjectLevelDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export type SubjectObjectDto = object

export interface ResponseGetManySubjectObjectDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: SubjectObjectDto[]
}

export interface ResponseGetOneSubjectObjectDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: SubjectObjectDto
}

export interface CreateSubjectObjectDto {
  /** name */
  name: string
}

export interface UpdateManySubjectObjectDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManySubjectObjectDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export interface RoomDtoShare {
  /** _id */
  _id: string

  /** code */
  code: string
}

export interface SubjectDtoShare {
  /** _id */
  _id: string

  /** name */
  name: string

  /** code */
  code: string
}

export interface ClassNumberDto {
  room: RoomDtoShare
  location: LocationDtoShare
  subject: SubjectDtoShare

  /** object id */
  _id: string

  /** roomId */
  roomId: string

  /** subjectId */
  subjectId: string

  /** locationId */
  locationId: string

  /** maxQuantity */
  maxQuantity: number

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyClassNumberDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: ClassNumberDto[]
}

export interface ResponseGetOneClassNumberDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: ClassNumberDto
}

export interface CreateClassNumberDto {
  /** roomId */
  roomId: string

  /** subjectId */
  subjectId: string

  /** locationId */
  locationId: string

  /** max quantity */
  maxQuantity: number
}

export interface UpdateManyClassNumberDto {
  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: string[]

  /** isArchived */
  isArchived: boolean
}

export interface ResponseUpdateManyClassNumberDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: UpdateSuccessDto
}

export interface DeleteManyClassNumberDto {
  /** delete by ids. Example in body: {ids: [1,2,3]} */
  ids: string[]
}

export interface ResponseDeleteManyClassNumberDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: DeleteSuccessDto
}

export interface DepartmentDtoShare {
  /** _id */
  _id: string

  /** name */
  name: string
}

export interface EmployeeDtoShare {
  /** _id */
  _id: string

  /** name */
  name: string

  /** userId */
  userId: string

  /** mobilePhone */
  mobilePhone: string
}

export interface DepartmentDto {
  parentDepartment: DepartmentDtoShare
  employeeManagement: EmployeeDtoShare

  /** object id */
  _id: string

  /** name */
  name: string

  /** name */
  nameSearch: string

  /** parentDepartmentId */
  parentDepartmentId: string

  /** employeeIdManagement */
  employeeIdManagement: string

  /** isDefault */
  isDefault: boolean

  /** isSpecialize */
  isSpecialize: boolean

  /** isOperator */
  isOperator: boolean

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyDepartmentDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: DepartmentDto[]
}

export interface ResponseGetOneDepartmentDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: DepartmentDto
}

export interface CreateDepartmentDto {
  /** name */
  name: string

  /** department id */
  parentDepartmentId?: string

  /** employee id management */
  employeeIdManagement: string

  /** isDefault */
  isDefault?: boolean

  /** isSpecialize */
  isSpecialize?: boolean

  /** isOperator */
  isOperator?: boolean
}

export interface UpdateManyDepartmentDto {
  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: string[]

  /** isArchived */
  isArchived: boolean
}

export interface ResponseUpdateManyDepartmentDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: UpdateSuccessDto
}

export interface DeleteManyDepartmentDto {
  /** delete by ids. Example in body: {ids: [1,2,3]} */
  ids: string[]
}

export interface ResponseDeleteManyDepartmentDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: DeleteSuccessDto
}

export interface ProvinceDto {
  /** id */
  id: number

  /** name */
  name: string

  /** code */
  code: string

  /** postalCode */
  postalCode: string
}

export interface ResponseGetManyProvinceDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: ProvinceDto[]
}

export interface DistrictDto {
  /** id */
  id: number

  /** name */
  name: string

  /** provinceId */
  provinceId: string

  /** postalCode */
  postalCode: string
}

export interface ResponseGetManyDistrictDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: DistrictDto[]
}

export interface WardDto {
  /** id */
  id: number

  /** name */
  name: string

  /** provinceId */
  provinceId: string

  /** districtId */
  districtId: string

  /** postalCode */
  postalCode: string
}

export interface ResponseGetManyWardDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: WardDto[]
}

export interface JobDto {
  department: DepartmentDtoShare

  /** object id */
  _id: string

  /** departmentId */
  departmentId: string

  /** name */
  name: string

  /** name search */
  nameSearch: string

  /** isSale */
  isSale: boolean

  /** noOfRecruitment */
  noOfRecruitment: number

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyJobDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: JobDto[]
}

export interface ResponseGetOneJobDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: JobDto
}

export interface CreateJobDto {
  /** departmentId */
  departmentId: string

  /** name */
  name: string

  /** isSale */
  isSale?: boolean

  /** noOfRecruitment */
  noOfRecruitment?: number
}

export interface UpdateManyJobDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManyJobDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export interface TeacherRoleDto {
  subject: SubjectDtoShare
  employee: EmployeeDtoShare
  province: ProvinceDtoShare

  /** object id */
  _id: string

  /** name */
  name: string

  /** name search */
  nameSearch: string

  /** subjectId */
  subjectId: string

  /** employeeId */
  employeeId: string

  /** provinceId */
  provinceId: number

  /** startWorkingDate */
  startWorkingDate: string

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyTeacherRoleDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: TeacherRoleDto[]
}

export interface ResponseGetOneTeacherRoleDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: TeacherRoleDto
}

export interface CreateTeacherRoleDto {
  /** name */
  name: string

  /** role */
  role: "LECTURER" | "TUTOR" | "INTERN" | "INTERN_LECTURER"

  /** subjectId */
  subjectId: string

  /** employeeId */
  employeeId: string

  /** provinceId */
  provinceId?: number

  /** startWorkingDate */
  startWorkingDate: string
}

export interface UpdateManyTeacherRoleDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManyTeacherRoleDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export interface UserDtoShare {
  /** _id */
  _id: string

  /** name */
  name: string

  /** phone */
  phone: string
}

export interface JobDtoShare {
  /** _id */
  _id: string

  /** name */
  name: string
}

export interface SubjectItemDto {
  /** name */
  name: string

  /** role */
  role: string

  /** subjectId */
  subjectId: string

  /** startDate */
  startDate: string
}

export interface EmployeeDto {
  user: UserDtoShare
  locations: LocationDtoShare[]
  no2Locations: LocationDtoShare[]
  no3Locations: LocationDtoShare[]
  department: DepartmentDtoShare
  job: JobDtoShare
  manager: EmployeeDtoShare

  /** object id */
  _id: string

  /** userId */
  userId: string

  /** name */
  name: string

  /** name search */
  nameSearch: string

  /** avatar */
  avatar: string

  /** mobilePhone */
  mobilePhone: string

  /** facebook */
  facebook: string

  /** workEmail */
  workEmail: string

  /** workAddress */
  workAddress: string

  /** startWorkingDate */
  startWorkingDate: string

  /** locationIds */
  locationIds: string[]

  /** no2LocationIds */
  no2LocationIds: string[]

  /** no3LocationIds */
  no3LocationIds: string[]

  /** departmentId */
  departmentId: string

  /** jobId */
  jobId: string

  /** managerId */
  managerId: string

  /** type */
  type: string

  /** subjects */
  subjects: SubjectItemDto[]

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyEmployeeDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: EmployeeDto[]
}

export interface ResponseGetOneEmployeeDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: EmployeeDto
}

export interface CreateEmployeeDto {
  /** userId */
  userId?: string

  /** departmentId */
  departmentId: string

  /** name */
  name: string

  /** avatar */
  avatar?: string

  /** mobilePhone */
  mobilePhone?: string

  /** facebook */
  facebook?: string

  /** workEmail */
  workEmail?: string

  /** workAddress */
  workAddress?: string

  /** startWorkingDate */
  startWorkingDate?: string

  /** jobId */
  jobId?: string

  /** managerId */
  managerId?: string

  /** location Ids */
  locationIds?: string

  /** no 2 location Ids */
  no2LocationIds?: string

  /** no 3 location Ids */
  no3LocationIds?: string

  /** type */
  type?: "TEACHER" | "STAFF"

  /** subjects */
  subjects?: SubjectItemDto[]
}

export interface UpdateManyEmployeeDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManyEmployeeDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export interface TeacherRoleDtoShare {
  /** _id */
  _id: string

  /** name */
  name: string

  /** role */
  role: string

  /** startWorkingDate */
  startWorkingDate: string
}

export interface TeacherDto {
  employee: EmployeeDtoShare
  teacherRoles: TeacherRoleDtoShare[]

  /** object id */
  _id: string

  /** employeeId */
  employeeId: string

  /** teacherRoleIds */
  teacherRoleIds: string

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyTeacherDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: TeacherDto[]
}

export interface ResponseGetOneTeacherDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: TeacherDto
}

export interface CreateTeacherDto {
  /** employeeId */
  employeeId: string
}

export interface UpdateManyTeacherDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManyTeacherDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export interface CourseDto {
  /** object id */
  _id: string

  /** name */
  name: string

  /** nameSearch */
  nameSearch: string

  /** description */
  description: string

  /** image */
  image: string

  /** content */
  content: string

  /** provinceId */
  provinceId: number

  /** lecturer id get from teacher api */
  lecturerId: string

  /** tuition fee */
  tuitionFee: number

  /** number of lessons */
  numberOfLessons: number

  /** status: INITIAL, SALE, POSTPONE, DELETED */
  status: string

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyCourseDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: CourseDto[]
}

export interface ResponseGetOneCourseDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: CourseDto
}

export interface CreateCourseDto {
  /** name */
  name: string

  /** description */
  description?: string

  /** image */
  image?: string

  /** content */
  content: string

  /** provinceId */
  provinceId: number

  /** lecturerId */
  lecturerId?: string

  /** tuition fee */
  tuitionFee: number

  /** number of lessons */
  numberOfLessons: number

  /** status: default is INITIAL */
  status?: "INITIAL" | "SALE" | "POSTPONE" | "DELETED"
}

export interface UpdateManyCourseDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManyCourseDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export interface SchoolHourDtoShare {
  /** _id */
  _id: string

  /** timeFrame */
  timeFrame: string

  /** startHourName */
  startHourName: string

  /** startHour */
  startHour: string

  /** endHour */
  endHour: string

  /** endHourName */
  endHourName: string
}

export interface AbsentScheduleDto {
  /** employee */
  employee: EmployeeDtoShare

  /** schoolHours */
  schoolHours: SchoolHourDtoShare[]

  /** object id */
  _id: string

  /** employeeId */
  employeeId: string

  /** fromDate */
  fromDate: string

  /** toDate */
  toDate: string

  /** shift */
  shift: string[]

  /** schoolHourIds */
  schoolHourIds: string[]

  /** reason */
  reason: string

  /** status */
  status: string

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyAbsentScheduleDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: AbsentScheduleDto[]
}

export interface ResponseGetOneAbsentScheduleDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: AbsentScheduleDto
}

export interface CreateAbsentScheduleDto {
  /** employeeId */
  employeeId: string

  /** fromDate */
  fromDate: string

  /** toDate */
  toDate: string

  /** shift */
  shift?: "MORNING" | "AFTERNOON" | "EVENING"

  /** schoolHourIds */
  schoolHourIds?: string[]

  /** reason */
  reason?: string
}

export interface UpdateAbsentScheduleDto {
  /** shift */
  shift?: "MORNING" | "AFTERNOON" | "EVENING"

  /** schoolHourIds */
  schoolHourIds?: string[]

  /** reason */
  reason?: string

  /** status */
  status?: "INITIAL" | "ACCEPTED" | "REJECTED" | "CANCELED"
}

export interface UpdateManyAbsentScheduleDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManyAbsentScheduleDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export interface IListDto {
  /** type */
  type: string

  /** monday */
  monday: boolean

  /** tuesday */
  tuesday: boolean

  /** wednesday */
  wednesday: boolean

  /** thursday */
  thursday: boolean

  /** friday */
  friday: boolean

  /** saturday */
  saturday: boolean

  /** sunday */
  sunday: boolean
}

export interface WorkScheduleDto {
  employee: EmployeeDtoShare
  priorityLocations: LocationDtoShare[]
  availableLocations: LocationDtoShare[]

  /** object id */
  _id: string

  /** employeeId */
  employeeId: string

  /** fromDate */
  fromDate: string

  /** toDate */
  toDate: string

  /** maxNumberOfShiftsInMonth */
  maxNumberOfShiftsInMonth: number

  /** numberOfShiftsInMonth */
  numberOfShiftsInMonth: number

  /** note */
  note: string

  /** priorityLocationIds */
  priorityLocationIds?: string

  /** availableLocationIds */
  availableLocationIds?: string

  /** list */
  list: IListDto[]

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyWorkScheduleDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: WorkScheduleDto[]
}

export interface ResponseGetOneWorkScheduleDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: WorkScheduleDto
}

export interface CreateWorkScheduleDto {
  /** employeeId */
  employeeId: string

  /** fromDate */
  fromDate: string

  /** toDate */
  toDate: string

  /** maxNumberOfShiftsInMonth */
  maxNumberOfShiftsInMonth: number

  /** priorityLocationIds */
  priorityLocationIds?: string[]

  /** availableLocationIds */
  availableLocationIds?: string

  /** note */
  note?: string

  /** list */
  list: IListDto[]
}

export interface UpdateWorkScheduleDto {
  /** employeeId */
  employeeId: string

  /** fromDate */
  fromDate: string

  /** toDate */
  toDate: string

  /** note */
  note?: string

  /** status */
  status?: "INITIAL" | "ACCEPTED" | "REJECTED" | "CANCELED"

  /** maxNumberOfShiftsInMonth */
  maxNumberOfShiftsInMonth: number

  /** priorityLocationIds */
  priorityLocationIds?: string[]

  /** availableLocationIds */
  availableLocationIds?: string[]

  /** list */
  list: IListDto[]
}

export interface UpdateManyWorkScheduleDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManyWorkScheduleDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export interface CheckAvailabilityDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** areas */
  areas?: string[]

  /** fromDate */
  fromDate?: string

  /** toDate */
  toDate?: string

  /** location ids */
  locationIds?: string[]

  /** subject ids */
  subjectIds?: string[]

  /** days of week. Ex: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] */
  daysOfWeek?: string[]

  /** shifts. Ex: ['MORNING', 'AFTERNOON', 'EVENING'] */
  shifts?: string[]

  /** shifts. Ex: ['LECTURER', 'TUTOR', 'INTERN', 'INTERN_LECTURER'] */
  roles?: string[]
}

export interface AvailabilityDto {
  /** employeeId */
  employeeId: string

  /** employeeName */
  employeeName: string

  /** fromDate */
  fromDate: string

  /** toDate */
  toDate: string

  /** shift */
  shift: string[]

  /** schoolHourIds */
  schoolHourIds: string[]

  /** reason */
  reason: string

  /** status */
  status: string

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseCheckAvailabilityDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: AvailabilityDto[]
}

export interface ClassTimeFrameDto {
  /** dayOfWeek */
  dayOfWeek: string

  /** schoolHourId */
  schoolHourId: string
}

export interface ClassDto {
  /** subject */
  subject: SubjectDtoShare

  /** location */
  location: LocationDtoShare

  /** room */
  room: RoomDtoShare

  /** schoolHours */
  schoolHours: SchoolHourDtoShare[]

  /** lecturer */
  lecturer: EmployeeDtoShare

  /** tutors */
  tutors: EmployeeDtoShare[]

  /** interns */
  interns: EmployeeDtoShare[]

  /** internLecturers */
  internLecturers: EmployeeDtoShare[]

  /** takeCares */
  takeCares: EmployeeDtoShare[]

  /** object id */
  _id: string

  /** timeFrames */
  timeFrames: ClassTimeFrameDto[]

  /** code */
  code: string

  /** name */
  name?: string

  /** graduationDate */
  graduationDate: string

  /** openingDate */
  openingDate: string

  /** subjectId */
  subjectId: string

  /** locationId */
  locationId: string

  /** roomId */
  roomId: string

  /** lecturerId */
  lecturerId: string

  /** tutorIds */
  tutorIds: string[]

  /** internIds */
  internIds: string[]

  /** internLecturerIds */
  internLecturerIds: string[]

  /** takeCareIds */
  takeCareIds: string[]

  /** schoolHourIds */
  schoolHourIds: string[]

  /** groupLink */
  groupLink: string

  /** groupChat */
  groupChat: string

  /** isNo1Location */
  isNo1Location: boolean

  /** maxMemberRoom */
  maxMemberRoom: number

  /** numberOfCurrent */
  numberOfCurrent: number

  /** numberRecruited */
  numberRecruited: number

  /** numberRemain */
  numberRemain: number

  /** lastShownUpCount */
  lastShownUpCount: number

  /** numberEndCourse */
  numberEndCourse: number

  /** attendingRate */
  attendingRate: number

  /** incomingRate */
  incomingRate: number

  /** outgoingRate */
  outgoingRate: number

  /** numberRegister */
  numberRegister: number

  /** revenueAfterDiscount */
  revenueAfterDiscount: number

  /** revenueReceived */
  revenueReceived: number

  /** revenueRemain */
  revenueRemain: number

  /** saleId */
  saleId: string

  /** status */
  status: string

  /** isGeneratedStudySchedule */
  isGeneratedStudySchedule: boolean

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyClassDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: ClassDto[]
}

export interface ResponseGetOneClassDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: ClassDto
}

export interface TimeFrameDto {
  /** dayOfWeek */
  dayOfWeek: string

  /** schoolHourId */
  schoolHourId: string
}

export interface CreateClassDto {
  /** code */
  code: string

  /** name */
  name: string

  /** graduationDate */
  graduationDate?: string

  /** openingDate */
  openingDate: string

  /** subjectId */
  subjectId: string

  /** locationId */
  locationId: string

  /** roomId */
  roomId: string

  /** lecturerId */
  lecturerId: string

  /** tutorIds */
  tutorIds?: string[]

  /** internIds */
  internIds?: string[]

  /** internLecturerIds */
  internLecturerIds?: string[]

  /** takeCareIds */
  takeCareIds?: string[]

  /** groupLink */
  groupLink?: string

  /** groupChat */
  groupChat?: string

  /** isNo1Location */
  isNo1Location?: boolean

  /** maxMemberRoom */
  maxMemberRoom?: number

  /** time frames */
  timeFrames: TimeFrameDto[]

  /** schedules */
  schedules?: string[]
}

export interface UpdateClassDto {
  /** code */
  code: string

  /** name */
  name: string

  /** graduationDate */
  graduationDate?: string

  /** openingDate */
  openingDate: string

  /** subjectId */
  subjectId: string

  /** locationId */
  locationId: string

  /** roomId */
  roomId: string

  /** lecturerId */
  lecturerId: string

  /** tutorIds */
  tutorIds?: string[]

  /** internIds */
  internIds?: string[]

  /** internLecturerIds */
  internLecturerIds?: string[]

  /** takeCareIds */
  takeCareIds?: string[]

  /** groupLink */
  groupLink?: string

  /** groupChat */
  groupChat?: string

  /** isNo1Location */
  isNo1Location?: boolean

  /** maxMemberRoom */
  maxMemberRoom?: number

  /** numberOfCurrent */
  numberOfCurrent?: number

  /** numberRecruited */
  numberRecruited?: number

  /** numberRemain */
  numberRemain?: number

  /** lastShownUpCount */
  lastShownUpCount?: number

  /** numberEndCourse */
  numberEndCourse?: number

  /** attendingRate */
  attendingRate?: number

  /** incomingRate */
  incomingRate?: number

  /** outgoingRate */
  outgoingRate?: number

  /** numberRegister */
  numberRegister?: number

  /** revenueAfterDiscount */
  revenueAfterDiscount?: number

  /** revenueReceived */
  revenueReceived?: number

  /** revenueRemain */
  revenueRemain?: number

  /** time frames */
  timeFrames: TimeFrameDto[]

  /** schedules */
  schedules?: string[]
}

export interface UpdateManyClassDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean

  /** status */
  status: object
}

export interface DeleteManyClassDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export interface ClassDtoShare {
  /** _id */
  _id: string

  /** name */
  name: string

  /** code */
  code: string
}

export interface StudyScheduleFieldStatusDto {
  /** name */
  name: string

  /** errorName */
  errorName: string

  /** description */
  description: string

  /** value */
  value: string[]

  /** isValid */
  isValid: boolean
}

export interface StudyScheduleDto {
  /** room */
  room: RoomDtoShare

  /** class */
  class: ClassDtoShare

  /** schoolHour */
  schoolHour: SchoolHourDtoShare

  /** lecturer */
  lecturer: EmployeeDtoShare

  /** tutors */
  tutors: EmployeeDtoShare[]

  /** interns */
  interns: EmployeeDtoShare[]

  /** object id */
  _id: string

  /** classId */
  classId: string

  /** lesson */
  lesson: number

  /** name */
  name: string

  /** date */
  date: string

  /** dayOfWeek */
  dayOfWeek: string

  /** schoolHourId */
  schoolHourId: string

  /** shift */
  shift: string

  /** area */
  area: string

  /** roomId */
  roomId: string

  /** lecturerId */
  lecturerId: string

  /** tutorIds */
  tutorIds: string[]

  /** internIds */
  internIds: string[]

  /** maxMemberLesson */
  maxMemberLesson: number

  /** formTextColor */
  formTextColor: string

  /** status */
  status: string

  /** fieldsStatus */
  fieldsStatus: StudyScheduleFieldStatusDto[]

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyStudyScheduleDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: StudyScheduleDto[]
}

export interface GenerateStudyScheduleDto {
  /** classId */
  classId: string

  /** force */
  force?: boolean
}

export interface PreviewStudyScheduleDto {
  /** lecturerId */
  lecturerId: string

  /** roomId */
  roomId: string

  /** timeFrames */
  timeFrames: string[]

  /** openingDate */
  openingDate: string

  /** subjectId */
  subjectId: string

  /** tutorIds */
  tutorIds: string[]

  /** internIds */
  internIds: string[]

  /** maxMemberRoom */
  maxMemberRoom: number

  /** schoolHours */
  schoolHours: string[]

  /** locationId */
  locationId: string

  /** status */
  status: string
}

export interface ResponseGetOneStudyScheduleDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: StudyScheduleDto
}

export interface CreateStudyScheduleDto {
  /** _id */
  _id?: string

  /** lesson */
  lesson?: number

  /** classId */
  classId: string

  /** roomId */
  roomId: string

  /** locationId */
  locationId: string

  /** schoolHourId */
  schoolHourId: string

  /** lecturerId */
  lecturerId: string

  /** tutorIds */
  tutorIds?: string[]

  /** internIds */
  internIds?: string[]

  /** date */
  date: string

  /** dayOfWeek */
  dayOfWeek: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday"

  /** maxMemberLesson */
  maxMemberLesson?: number

  /** formTextColor */
  formTextColor?: string
}

export interface CheckStatusScheduleDto {
  /** lecturerId */
  lecturerId: string

  /** schoolHourId */
  schoolHourId: string

  /** tutorIds */
  tutorIds?: string[]

  /** internIds */
  internIds?: string[]

  /** date */
  date: string

  /** dayOfWeek */
  dayOfWeek: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday"
}

export interface UpdateStudyScheduleDto {
  /** _id */
  _id?: string

  /** roomId */
  roomId: string

  /** locationId */
  locationId: string

  /** schoolHourId */
  schoolHourId: string

  /** lecturerId */
  lecturerId: string

  /** tutorIds */
  tutorIds?: string[]

  /** internIds */
  internIds?: string[]

  /** date */
  date: string

  /** dayOfWeek */
  dayOfWeek: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday"

  /** maxMemberLesson */
  maxMemberLesson?: number

  /** lesson */
  lesson?: number

  /** classId */
  classId?: string

  /** formTextColor */
  formTextColor?: string
}

export interface UpdateManyStudyScheduleDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManyStudyScheduleDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export interface WorkTimeDto {
  lecturer: EmployeeDtoShare
  schoolHours: SchoolHourDtoShare[]

  /** object id */
  _id: string

  /** lecturerId */
  lecturerId: string

  /** fromDate */
  fromDate: string

  /** toDate */
  toDate: string

  /** dateOfWeek */
  dateOfWeek: string

  /** schoolHourIds */
  schoolHourIds: string

  /** status */
  status: string

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyWorkTimeDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: WorkTimeDto[]
}

export interface ResponseGetOneWorkTimeDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: WorkTimeDto
}

export interface CreateWorkTimeDto {
  /** lecturerId */
  lecturerId: string

  /** from date */
  fromDate: string

  /** to date */
  toDate: string

  /** dateOfWeek */
  dateOfWeek: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday"

  /** schoolHourIds */
  schoolHourIds: string[]

  /** status */
  status?: "INIT" | "ACTIVE" | "CANCELED"
}

export interface UpdateWorkTimeDto {
  /** lecturerId */
  lecturerId: string

  /** from date */
  fromDate: string

  /** to date */
  toDate: string

  /** dateOfWeek */
  dateOfWeek: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday"

  /** schoolHourIds */
  schoolHourIds: string[]

  /** status */
  status?: "INIT" | "ACTIVE" | "CANCELED"
}

export interface UpdateManyWorkTimeDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManyWorkTimeDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export interface TeachScheduleDto {
  /** class */
  class: LocationDtoShare

  /** location */
  location: LocationDtoShare

  /** room */
  room: RoomDtoShare

  /** schoolHour */
  schoolHours: SchoolHourDtoShare

  /** lecturer */
  lecturer: EmployeeDtoShare

  /** tutors */
  tutors: EmployeeDtoShare[]

  /** interns */
  interns: EmployeeDtoShare[]

  /** takeCares */
  takeCares: EmployeeDtoShare[]

  /** object id */
  _id: string

  /** date */
  date: string

  /** classId */
  classId: string

  /** locationId */
  locationId: string

  /** roomId */
  roomId: string

  /** lecturerId */
  lecturerId: string

  /** schoolHourId */
  schoolHourId: string

  /** tutorIds */
  tutorIds: string[]

  /** internIds */
  internIds: string[]

  /** takeCareIds */
  takeCareIds: string[]

  /** numberOfCurrent */
  numberOfCurrent: number

  /** numberRecruited */
  numberRecruited: number

  /** incomingRate */
  incomingRate: number

  /** outgoingRate */
  outgoingRate: number

  /** numberRegister */
  numberRegister: number

  /** status */
  status: string

  /** is archived */
  isArchived: boolean

  /** is deleted */
  isDeleted: boolean

  /** created by */
  createdBy: string

  /** updated by */
  updatedBy: string

  /** deleted by */
  deletedBy: string

  /** deleted at */
  deletedAt: string

  /** created at */
  createdAt: string

  /** updated at */
  updatedAt: string
}

export interface ResponseGetManyTeachScheduleDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: TeachScheduleDto[]
}

export interface ResponseGetOneTeachScheduleDto {
  /** Total documents */
  total: number

  /** Data is object or array. Return array if get many, return object if get one. */
  data: TeachScheduleDto
}

export interface CreateTeachScheduleDto {
  /** classId */
  classId: string

  /** studySchedule */
  studySchedule: number

  /** maxDayOfLesson */
  maxDayOfLesson: number

  /** status */
  status: number

  /** date */
  date: string

  /** locationId */
  locationId: string

  /** roomId */
  roomId: string

  /** lecturerId */
  lecturerId: string

  /** schoolHourId */
  schoolHourId: string

  /** tutorIds */
  tutorIds?: string[]

  /** internIds */
  internIds?: string[]

  /** takeCareIds */
  takeCareIds?: string[]
}

export interface UpdateTeachScheduleDto {
  /** classId */
  classId: string

  /** studySchedule */
  studySchedule: number

  /** maxDayOfLesson */
  maxDayOfLesson: number

  /** status */
  status: number

  /** date */
  date: string

  /** locationId */
  locationId: string

  /** roomId */
  roomId: string

  /** lecturerId */
  lecturerId: string

  /** schoolHourId */
  schoolHourId: string

  /** tutorIds */
  tutorIds?: string[]

  /** internIds */
  internIds?: string[]

  /** takeCareIds */
  takeCareIds?: string[]
}

export interface UpdateManyTeachScheduleDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object

  /** isArchived */
  isArchived: boolean
}

export interface DeleteManyTeachScheduleDto {
  filterFields?: string[]
  sortFields?: string[]

  /** Paginate: offset param. Example: /users?offset=10 */
  offset?: string

  /** Paginate: limit param. Example: /users?limit=10 */
  limit?: string

  /** Search by ids. Example: /users?ids=1,2,4,5. Example in body: {ids: [1,2,3]} */
  ids?: object

  /** Example: /users?sort=name_desc,createdAt_asc . default sort by createdAt desc */
  sort?: object
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = ""
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"]
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&")
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter(key => "undefined" !== typeof query[key])
    return keys
      .map(key => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&")
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ""
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  }

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async response => {
      const r = response as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then(data => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch(e => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title MTB API Service
 * @version 1.0.0
 * @contact
 *
 * The MTB API service description
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name MainControllerGetHomePage
   * @request GET:/
   */
  mainControllerGetHomePage = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/`,
      method: "GET",
      ...params,
    })

  docs = {
    /**
     * No description
     *
     * @name MainControllerGetErrorCodesPage
     * @request GET:/docs/error-codes.html
     */
    mainControllerGetErrorCodesPage: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/docs/error-codes.html`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @name MainControllerGetWebsocketPage
     * @request GET:/docs/websocket.html
     */
    mainControllerGetWebsocketPage: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/docs/websocket.html`,
        method: "GET",
        ...params,
      }),
  }
  actions = {
    /**
     * No description
     *
     * @tags Action
     * @name ActionControllerGetMany
     * @summary Get list
     * @request GET:/actions
     */
    actionControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        name?: string
        key?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyActionDto, ResponseErrorDto | void>({
        path: `/actions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionControllerCreateOne
     * @request POST:/actions
     */
    actionControllerCreateOne: (data: CreateActionDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneActionDto, ResponseErrorDto | void>({
        path: `/actions`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionControllerGetOne
     * @request GET:/actions/{id}
     */
    actionControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneActionDto, ResponseErrorDto | void>({
        path: `/actions/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionControllerUpdateOne
     * @request PUT:/actions/{id}
     */
    actionControllerUpdateOne: (id: string, data: CreateActionDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneActionDto, ResponseErrorDto | void>({
        path: `/actions/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionControllerDeleteOne
     * @request DELETE:/actions/{id}
     */
    actionControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/actions/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  cacheManagers = {
    /**
     * No description
     *
     * @tags Cache Manager
     * @name CacheManagerControllerClearAll
     * @request POST:/cache-managers/clear-all
     */
    cacheManagerControllerClearAll: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/cache-managers/clear-all`,
        method: "POST",
        ...params,
      }),
  }
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerLogin
     * @summary Login by user
     * @request POST:/auth/login
     */
    authControllerLogin: (data: LoginDto, params: RequestParams = {}) =>
      this.request<ResponseLoginDto, ResponseErrorDto | void>({
        path: `/auth/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerForgotPassword
     * @summary Forgot password
     * @request POST:/auth/forgot-password
     */
    authControllerForgotPassword: (data: ForgotPasswordDto, params: RequestParams = {}) =>
      this.request<ResponseForgotPasswordDto, ResponseErrorDto | void>({
        path: `/auth/forgot-password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerResetPassword
     * @summary Forgot password
     * @request POST:/auth/reset-password
     */
    authControllerResetPassword: (data: ResetPasswordDto, params: RequestParams = {}) =>
      this.request<ResponseResetPasswordDto, ResponseErrorDto | void>({
        path: `/auth/reset-password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  }
  permissions = {
    /**
     * No description
     *
     * @tags Permission
     * @name PermissionControllerGetMany
     * @summary Get list
     * @request GET:/permissions
     */
    permissionControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        name?: string
        key?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyPermissionDto, ResponseErrorDto | void>({
        path: `/permissions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name PermissionControllerCreateOne
     * @request POST:/permissions
     */
    permissionControllerCreateOne: (data: CreatePermissionDto, params: RequestParams = {}) =>
      this.request<ResponseGetOnePermissionDto, ResponseErrorDto | void>({
        path: `/permissions`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name PermissionControllerUpdateMany
     * @request PATCH:/permissions
     */
    permissionControllerUpdateMany: (data: UpdateManyPermissionDto, params: RequestParams = {}) =>
      this.request<ResponseUpdateManyPermissionDto, ResponseErrorDto | void>({
        path: `/permissions`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name PermissionControllerDeleteMany
     * @request DELETE:/permissions
     */
    permissionControllerDeleteMany: (data: DeleteManyPermissionDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteManyPermissionDto, ResponseErrorDto | void>({
        path: `/permissions`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name PermissionControllerGetOne
     * @request GET:/permissions/{id}
     */
    permissionControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOnePermissionDto, ResponseErrorDto | void>({
        path: `/permissions/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name PermissionControllerUpdateOne
     * @request PUT:/permissions/{id}
     */
    permissionControllerUpdateOne: (id: string, data: CreatePermissionDto, params: RequestParams = {}) =>
      this.request<ResponseGetOnePermissionDto, ResponseErrorDto | void>({
        path: `/permissions/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name PermissionControllerDeleteOne
     * @request DELETE:/permissions/{id}
     */
    permissionControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/permissions/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  roles = {
    /**
     * No description
     *
     * @tags Role
     * @name RoleControllerGetMany
     * @summary Get list settings
     * @request GET:/roles
     */
    roleControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        name?: string
        isArchived?: "true" | "false"
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyRoleDto, ResponseErrorDto | void>({
        path: `/roles`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name RoleControllerCreateOne
     * @request POST:/roles
     */
    roleControllerCreateOne: (data: CreateRoleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneRoleDto, ResponseErrorDto | void>({
        path: `/roles`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name RoleControllerUpdateMany
     * @request PATCH:/roles
     */
    roleControllerUpdateMany: (data: UpdateManyRoleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneRoleDto, ResponseErrorDto | void>({
        path: `/roles`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name RoleControllerDeleteMany
     * @request DELETE:/roles
     */
    roleControllerDeleteMany: (data: DeleteManyRoleDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/roles`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name RoleControllerGetOne
     * @request GET:/roles/{id}
     */
    roleControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneRoleDto, ResponseErrorDto | void>({
        path: `/roles/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name RoleControllerUpdateOne
     * @request PUT:/roles/{id}
     */
    roleControllerUpdateOne: (id: string, data: CreateRoleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneRoleDto, ResponseErrorDto | void>({
        path: `/roles/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name RoleControllerDeleteOne
     * @request DELETE:/roles/{id}
     */
    roleControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/roles/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  users = {
    /**
     * No description
     *
     * @tags User
     * @name UserControllerGetMany
     * @summary Get list
     * @request GET:/users
     */
    userControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        name?: string
        username?: string
        roleId?: string
        email?: string
        phone?: string
        code?: string
        type?: string
        status?: string
        isArchived?: "true" | "false"
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyUserDto, ResponseErrorDto | void>({
        path: `/users`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserControllerCreateOne
     * @request POST:/users
     */
    userControllerCreateOne: (data: CreateUserDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneUserDto, ResponseErrorDto | void>({
        path: `/users`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserControllerUpdateMany
     * @request PATCH:/users
     */
    userControllerUpdateMany: (data: UpdateManyUserDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneUserDto, ResponseErrorDto | void>({
        path: `/users`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserControllerDeleteMany
     * @request DELETE:/users
     */
    userControllerDeleteMany: (data: DeleteManyUserDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/users`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserControllerGetOne
     * @request GET:/users/{id}
     */
    userControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneUserDto, ResponseErrorDto | void>({
        path: `/users/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserControllerUpdateOne
     * @request PUT:/users/{id}
     */
    userControllerUpdateOne: (id: string, data: UpdateUserDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneUserDto, ResponseErrorDto | void>({
        path: `/users/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserControllerDeleteOne
     * @request DELETE:/users/{id}
     */
    userControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/users/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  profile = {
    /**
     * No description
     *
     * @tags Profile
     * @name ProfileControllerGetProfile
     * @summary Get profile
     * @request GET:/profile
     */
    profileControllerGetProfile: (params: RequestParams = {}) =>
      this.request<ResponseGetProfileDto, ResponseErrorDto | void>({
        path: `/profile`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileControllerUpdateProfile
     * @summary Update profile
     * @request POST:/profile
     */
    profileControllerUpdateProfile: (data: UpdateProfileDto, params: RequestParams = {}) =>
      this.request<void, ResponseErrorDto | void>({
        path: `/profile`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
  migrations = {
    /**
     * No description
     *
     * @tags migrations
     * @name MigrationControllerCreate
     * @request POST:/migrations/create
     */
    migrationControllerCreate: (data: CreateCommandDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/migrations/create`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags migrations
     * @name MigrationControllerRollback
     * @request POST:/migrations/rollback
     */
    migrationControllerRollback: (data: CreateCommandDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/migrations/rollback`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags migrations
     * @name MigrationControllerFakedata
     * @request POST:/migrations/fakedata
     */
    migrationControllerFakedata: (data: CreateCommandDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/migrations/fakedata`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
  locations = {
    /**
     * No description
     *
     * @tags Location
     * @name LocationControllerGetMany
     * @summary Get list
     * @request GET:/locations
     */
    locationControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        name?: string
        code?: string
        provinceId?: string
        districtId?: string
        wardId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyLocationDto, ResponseErrorDto | void>({
        path: `/locations`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationControllerCreateOne
     * @request POST:/locations
     */
    locationControllerCreateOne: (data: CreateLocationDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneLocationDto, ResponseErrorDto | void>({
        path: `/locations`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationControllerUpdateMany
     * @request PATCH:/locations
     */
    locationControllerUpdateMany: (data: UpdateManyLocationDto, params: RequestParams = {}) =>
      this.request<ResponseUpdateManyLocationDto, ResponseErrorDto | void>({
        path: `/locations`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationControllerDeleteMany
     * @request DELETE:/locations
     */
    locationControllerDeleteMany: (data: DeleteManyLocationDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteManyLocationDto, ResponseErrorDto | void>({
        path: `/locations`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationControllerGetOne
     * @request GET:/locations/{id}
     */
    locationControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneLocationDto, ResponseErrorDto | void>({
        path: `/locations/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationControllerUpdateOne
     * @request PUT:/locations/{id}
     */
    locationControllerUpdateOne: (id: string, data: CreateLocationDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneLocationDto, ResponseErrorDto | void>({
        path: `/locations/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationControllerDeleteOne
     * @request DELETE:/locations/{id}
     */
    locationControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/locations/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  schoolHours = {
    /**
     * No description
     *
     * @tags School Hour
     * @name SchoolHourControllerGetMany
     * @summary Get list
     * @request GET:/school-hours
     */
    schoolHourControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        startHour?: number
        endHour?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManySchoolHourDto, ResponseErrorDto | void>({
        path: `/school-hours`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags School Hour
     * @name SchoolHourControllerCreateOne
     * @request POST:/school-hours
     */
    schoolHourControllerCreateOne: (data: CreateSchoolHourDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSchoolHourDto, ResponseErrorDto | void>({
        path: `/school-hours`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags School Hour
     * @name SchoolHourControllerUpdateMany
     * @request PATCH:/school-hours
     */
    schoolHourControllerUpdateMany: (data: UpdateManySchoolHourDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSchoolHourDto, ResponseErrorDto | void>({
        path: `/school-hours`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags School Hour
     * @name SchoolHourControllerDeleteMany
     * @request DELETE:/school-hours
     */
    schoolHourControllerDeleteMany: (data: DeleteManySchoolHourDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/school-hours`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags School Hour
     * @name SchoolHourControllerGetOne
     * @request GET:/school-hours/{id}
     */
    schoolHourControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneSchoolHourDto, ResponseErrorDto | void>({
        path: `/school-hours/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags School Hour
     * @name SchoolHourControllerUpdateOne
     * @request PUT:/school-hours/{id}
     */
    schoolHourControllerUpdateOne: (id: string, data: CreateSchoolHourDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSchoolHourDto, ResponseErrorDto | void>({
        path: `/school-hours/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags School Hour
     * @name SchoolHourControllerDeleteOne
     * @request DELETE:/school-hours/{id}
     */
    schoolHourControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/school-hours/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  rooms = {
    /**
     * No description
     *
     * @tags Room
     * @name RoomControllerGetMany
     * @summary Get list
     * @request GET:/rooms
     */
    roomControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        code?: string
        locationId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyRoomDto, ResponseErrorDto | void>({
        path: `/rooms`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Room
     * @name RoomControllerCreateOne
     * @request POST:/rooms
     */
    roomControllerCreateOne: (data: CreateRoomDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneRoomDto, ResponseErrorDto | void>({
        path: `/rooms`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Room
     * @name RoomControllerUpdateMany
     * @request PATCH:/rooms
     */
    roomControllerUpdateMany: (data: UpdateManyRoomDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneRoomDto, ResponseErrorDto | void>({
        path: `/rooms`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Room
     * @name RoomControllerDeleteMany
     * @request DELETE:/rooms
     */
    roomControllerDeleteMany: (data: DeleteManyRoomDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/rooms`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Room
     * @name RoomControllerGetOne
     * @request GET:/rooms/{id}
     */
    roomControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneRoomDto, ResponseErrorDto | void>({
        path: `/rooms/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Room
     * @name RoomControllerUpdateOne
     * @request PUT:/rooms/{id}
     */
    roomControllerUpdateOne: (id: string, data: CreateRoomDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneRoomDto, ResponseErrorDto | void>({
        path: `/rooms/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Room
     * @name RoomControllerDeleteOne
     * @request DELETE:/rooms/{id}
     */
    roomControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/rooms/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  subjects = {
    /**
     * No description
     *
     * @tags Subject
     * @name SubjectControllerGetMany
     * @summary Get list
     * @request GET:/subjects
     */
    subjectControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        code?: string
        name?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManySubjectDto, ResponseErrorDto | void>({
        path: `/subjects`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject
     * @name SubjectControllerCreateOne
     * @request POST:/subjects
     */
    subjectControllerCreateOne: (data: CreateSubjectDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectDto, ResponseErrorDto | void>({
        path: `/subjects`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject
     * @name SubjectControllerUpdateMany
     * @request PATCH:/subjects
     */
    subjectControllerUpdateMany: (data: UpdateManySubjectDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectDto, ResponseErrorDto | void>({
        path: `/subjects`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject
     * @name SubjectControllerDeleteMany
     * @request DELETE:/subjects
     */
    subjectControllerDeleteMany: (data: DeleteManySubjectDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/subjects`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject
     * @name SubjectControllerGetOne
     * @request GET:/subjects/{id}
     */
    subjectControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectDto, ResponseErrorDto | void>({
        path: `/subjects/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject
     * @name SubjectControllerUpdateOne
     * @request PUT:/subjects/{id}
     */
    subjectControllerUpdateOne: (id: string, data: CreateSubjectDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectDto, ResponseErrorDto | void>({
        path: `/subjects/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject
     * @name SubjectControllerDeleteOne
     * @request DELETE:/subjects/{id}
     */
    subjectControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/subjects/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  subjectTypes = {
    /**
     * No description
     *
     * @tags Subject Type
     * @name SubjectTypeControllerGetMany
     * @summary Get list
     * @request GET:/subject-types
     */
    subjectTypeControllerGetMany: (
      query?: { filterFields?: string[]; sortFields?: string[]; offset?: string; limit?: string; name?: string },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManySubjectTypeDto, ResponseErrorDto | void>({
        path: `/subject-types`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Type
     * @name SubjectTypeControllerCreateOne
     * @request POST:/subject-types
     */
    subjectTypeControllerCreateOne: (data: CreateSubjectTypeDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectTypeDto, ResponseErrorDto | void>({
        path: `/subject-types`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Type
     * @name SubjectTypeControllerUpdateMany
     * @request PATCH:/subject-types
     */
    subjectTypeControllerUpdateMany: (data: UpdateManySubjectTypeDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectTypeDto, ResponseErrorDto | void>({
        path: `/subject-types`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Type
     * @name SubjectTypeControllerDeleteMany
     * @request DELETE:/subject-types
     */
    subjectTypeControllerDeleteMany: (data: DeleteManySubjectTypeDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/subject-types`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Type
     * @name SubjectTypeControllerGetOne
     * @request GET:/subject-types/{id}
     */
    subjectTypeControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectTypeDto, ResponseErrorDto | void>({
        path: `/subject-types/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Type
     * @name SubjectTypeControllerUpdateOne
     * @request PUT:/subject-types/{id}
     */
    subjectTypeControllerUpdateOne: (id: string, data: CreateSubjectTypeDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectTypeDto, ResponseErrorDto | void>({
        path: `/subject-types/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Type
     * @name SubjectTypeControllerDeleteOne
     * @request DELETE:/subject-types/{id}
     */
    subjectTypeControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/subject-types/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  subjectForms = {
    /**
     * No description
     *
     * @tags Subject Form
     * @name SubjectFormControllerGetMany
     * @summary Get list
     * @request GET:/subject-forms
     */
    subjectFormControllerGetMany: (
      query?: { filterFields?: string[]; sortFields?: string[]; offset?: string; limit?: string; name?: string },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManySubjectFormDto, ResponseErrorDto | void>({
        path: `/subject-forms`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Form
     * @name SubjectFormControllerCreateOne
     * @request POST:/subject-forms
     */
    subjectFormControllerCreateOne: (data: CreateSubjectFormDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectFormDto, ResponseErrorDto | void>({
        path: `/subject-forms`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Form
     * @name SubjectFormControllerUpdateMany
     * @request PATCH:/subject-forms
     */
    subjectFormControllerUpdateMany: (data: UpdateManySubjectFormDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectFormDto, ResponseErrorDto | void>({
        path: `/subject-forms`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Form
     * @name SubjectFormControllerDeleteMany
     * @request DELETE:/subject-forms
     */
    subjectFormControllerDeleteMany: (data: DeleteManySubjectFormDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/subject-forms`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Form
     * @name SubjectFormControllerGetOne
     * @request GET:/subject-forms/{id}
     */
    subjectFormControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectFormDto, ResponseErrorDto | void>({
        path: `/subject-forms/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Form
     * @name SubjectFormControllerUpdateOne
     * @request PUT:/subject-forms/{id}
     */
    subjectFormControllerUpdateOne: (id: string, data: CreateSubjectFormDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectFormDto, ResponseErrorDto | void>({
        path: `/subject-forms/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Form
     * @name SubjectFormControllerDeleteOne
     * @request DELETE:/subject-forms/{id}
     */
    subjectFormControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/subject-forms/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  subjectLevels = {
    /**
     * No description
     *
     * @tags Subject Level
     * @name SubjectLevelControllerGetMany
     * @summary Get list
     * @request GET:/subject-levels
     */
    subjectLevelControllerGetMany: (
      query?: { filterFields?: string[]; sortFields?: string[]; offset?: string; limit?: string; name?: string },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManySubjectLevelDto, ResponseErrorDto | void>({
        path: `/subject-levels`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Level
     * @name SubjectLevelControllerCreateOne
     * @request POST:/subject-levels
     */
    subjectLevelControllerCreateOne: (data: CreateSubjectLevelDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectLevelDto, ResponseErrorDto | void>({
        path: `/subject-levels`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Level
     * @name SubjectLevelControllerUpdateMany
     * @request PATCH:/subject-levels
     */
    subjectLevelControllerUpdateMany: (data: UpdateManySubjectLevelDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectLevelDto, ResponseErrorDto | void>({
        path: `/subject-levels`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Level
     * @name SubjectLevelControllerDeleteMany
     * @request DELETE:/subject-levels
     */
    subjectLevelControllerDeleteMany: (data: DeleteManySubjectLevelDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/subject-levels`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Level
     * @name SubjectLevelControllerGetOne
     * @request GET:/subject-levels/{id}
     */
    subjectLevelControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectLevelDto, ResponseErrorDto | void>({
        path: `/subject-levels/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Level
     * @name SubjectLevelControllerUpdateOne
     * @request PUT:/subject-levels/{id}
     */
    subjectLevelControllerUpdateOne: (id: string, data: CreateSubjectLevelDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectLevelDto, ResponseErrorDto | void>({
        path: `/subject-levels/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Level
     * @name SubjectLevelControllerDeleteOne
     * @request DELETE:/subject-levels/{id}
     */
    subjectLevelControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/subject-levels/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  subjectObjects = {
    /**
     * No description
     *
     * @tags Subject Object
     * @name SubjectObjectControllerGetMany
     * @summary Get list
     * @request GET:/subject-objects
     */
    subjectObjectControllerGetMany: (
      query?: { filterFields?: string[]; sortFields?: string[]; offset?: string; limit?: string; name?: string },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManySubjectObjectDto, ResponseErrorDto | void>({
        path: `/subject-objects`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Object
     * @name SubjectObjectControllerCreateOne
     * @request POST:/subject-objects
     */
    subjectObjectControllerCreateOne: (data: CreateSubjectObjectDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectObjectDto, ResponseErrorDto | void>({
        path: `/subject-objects`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Object
     * @name SubjectObjectControllerUpdateMany
     * @request PATCH:/subject-objects
     */
    subjectObjectControllerUpdateMany: (data: UpdateManySubjectObjectDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectObjectDto, ResponseErrorDto | void>({
        path: `/subject-objects`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Object
     * @name SubjectObjectControllerDeleteMany
     * @request DELETE:/subject-objects
     */
    subjectObjectControllerDeleteMany: (data: DeleteManySubjectObjectDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/subject-objects`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Object
     * @name SubjectObjectControllerGetOne
     * @request GET:/subject-objects/{id}
     */
    subjectObjectControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectObjectDto, ResponseErrorDto | void>({
        path: `/subject-objects/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Object
     * @name SubjectObjectControllerUpdateOne
     * @request PUT:/subject-objects/{id}
     */
    subjectObjectControllerUpdateOne: (id: string, data: CreateSubjectObjectDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneSubjectObjectDto, ResponseErrorDto | void>({
        path: `/subject-objects/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subject Object
     * @name SubjectObjectControllerDeleteOne
     * @request DELETE:/subject-objects/{id}
     */
    subjectObjectControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/subject-objects/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  classNumbers = {
    /**
     * No description
     *
     * @tags Class Number
     * @name ClassNumberControllerGetMany
     * @summary Get list
     * @request GET:/class-numbers
     */
    classNumberControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        roomId?: string
        subjectId?: string
        locationId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyClassNumberDto, ResponseErrorDto | void>({
        path: `/class-numbers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Class Number
     * @name ClassNumberControllerCreateOne
     * @request POST:/class-numbers
     */
    classNumberControllerCreateOne: (data: CreateClassNumberDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneClassNumberDto, ResponseErrorDto | void>({
        path: `/class-numbers`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Class Number
     * @name ClassNumberControllerUpdateMany
     * @request PATCH:/class-numbers
     */
    classNumberControllerUpdateMany: (data: UpdateManyClassNumberDto, params: RequestParams = {}) =>
      this.request<ResponseUpdateManyClassNumberDto, ResponseErrorDto | void>({
        path: `/class-numbers`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Class Number
     * @name ClassNumberControllerDeleteMany
     * @request DELETE:/class-numbers
     */
    classNumberControllerDeleteMany: (data: DeleteManyClassNumberDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteManyClassNumberDto, ResponseErrorDto | void>({
        path: `/class-numbers`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Class Number
     * @name ClassNumberControllerGetOne
     * @request GET:/class-numbers/{id}
     */
    classNumberControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneClassNumberDto, ResponseErrorDto | void>({
        path: `/class-numbers/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Class Number
     * @name ClassNumberControllerUpdateOne
     * @request PUT:/class-numbers/{id}
     */
    classNumberControllerUpdateOne: (id: string, data: CreateClassNumberDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneClassNumberDto, ResponseErrorDto | void>({
        path: `/class-numbers/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Class Number
     * @name ClassNumberControllerDeleteOne
     * @request DELETE:/class-numbers/{id}
     */
    classNumberControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/class-numbers/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  departments = {
    /**
     * No description
     *
     * @tags Department
     * @name DepartmentControllerGetMany
     * @summary Get list
     * @request GET:/departments
     */
    departmentControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        name?: string
        parentDepartmentId?: string
        managerEmployeeId?: string
        isDefault?: boolean
        isSpecialize?: boolean
        isOperator?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyDepartmentDto, ResponseErrorDto | void>({
        path: `/departments`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Department
     * @name DepartmentControllerCreateOne
     * @request POST:/departments
     */
    departmentControllerCreateOne: (data: CreateDepartmentDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneDepartmentDto, ResponseErrorDto | void>({
        path: `/departments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Department
     * @name DepartmentControllerUpdateMany
     * @request PATCH:/departments
     */
    departmentControllerUpdateMany: (data: UpdateManyDepartmentDto, params: RequestParams = {}) =>
      this.request<ResponseUpdateManyDepartmentDto, ResponseErrorDto | void>({
        path: `/departments`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Department
     * @name DepartmentControllerDeleteMany
     * @request DELETE:/departments
     */
    departmentControllerDeleteMany: (data: DeleteManyDepartmentDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteManyDepartmentDto, ResponseErrorDto | void>({
        path: `/departments`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Department
     * @name DepartmentControllerGetOne
     * @request GET:/departments/{id}
     */
    departmentControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneDepartmentDto, ResponseErrorDto | void>({
        path: `/departments/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Department
     * @name DepartmentControllerUpdateOne
     * @request PUT:/departments/{id}
     */
    departmentControllerUpdateOne: (id: string, data: CreateDepartmentDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneDepartmentDto, ResponseErrorDto | void>({
        path: `/departments/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Department
     * @name DepartmentControllerDeleteOne
     * @request DELETE:/departments/{id}
     */
    departmentControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/departments/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  provinces = {
    /**
     * No description
     *
     * @tags Master data
     * @name MasterDataControllerGetManyProvinces
     * @summary Get list provinces
     * @request GET:/provinces
     */
    masterDataControllerGetManyProvinces: (
      query?: { filterFields?: string[]; sortFields?: string[]; offset?: string; limit?: string; name?: string },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyProvinceDto, void>({
        path: `/provinces`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  }
  districts = {
    /**
     * No description
     *
     * @tags Master data
     * @name MasterDataControllerGetManyDistricts
     * @summary Get list districts
     * @request GET:/districts
     */
    masterDataControllerGetManyDistricts: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        name?: string
        provinceId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyDistrictDto, void>({
        path: `/districts`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  }
  wards = {
    /**
     * No description
     *
     * @tags Master data
     * @name MasterDataControllerGetManyWards
     * @summary Get list wards
     * @request GET:/wards
     */
    masterDataControllerGetManyWards: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        name?: string
        provinceId?: string
        districtId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyWardDto, void>({
        path: `/wards`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  }
  jobs = {
    /**
     * No description
     *
     * @tags Job
     * @name JobControllerGetMany
     * @summary Get list
     * @request GET:/jobs
     */
    jobControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        departmentId?: string
        name?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyJobDto, ResponseErrorDto | void>({
        path: `/jobs`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobControllerCreateOne
     * @request POST:/jobs
     */
    jobControllerCreateOne: (data: CreateJobDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneJobDto, ResponseErrorDto | void>({
        path: `/jobs`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobControllerUpdateMany
     * @request PATCH:/jobs
     */
    jobControllerUpdateMany: (data: UpdateManyJobDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneJobDto, ResponseErrorDto | void>({
        path: `/jobs`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobControllerDeleteMany
     * @request DELETE:/jobs
     */
    jobControllerDeleteMany: (data: DeleteManyJobDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/jobs`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobControllerGetOne
     * @request GET:/jobs/{id}
     */
    jobControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneJobDto, ResponseErrorDto | void>({
        path: `/jobs/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobControllerUpdateOne
     * @request PUT:/jobs/{id}
     */
    jobControllerUpdateOne: (id: string, data: CreateJobDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneJobDto, ResponseErrorDto | void>({
        path: `/jobs/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobControllerDeleteOne
     * @request DELETE:/jobs/{id}
     */
    jobControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/jobs/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  teacherRoles = {
    /**
     * No description
     *
     * @tags Teacher Role
     * @name TeacherRoleControllerGetMany
     * @summary Get list
     * @request GET:/teacher-roles
     */
    teacherRoleControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        name?: string
        subjectId?: string
        employeeId?: string
        provinceId?: string
        role?: string
        roles?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyTeacherRoleDto, ResponseErrorDto | void>({
        path: `/teacher-roles`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teacher Role
     * @name TeacherRoleControllerCreateOne
     * @request POST:/teacher-roles
     */
    teacherRoleControllerCreateOne: (data: CreateTeacherRoleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneTeacherRoleDto, ResponseErrorDto | void>({
        path: `/teacher-roles`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teacher Role
     * @name TeacherRoleControllerUpdateMany
     * @request PATCH:/teacher-roles
     */
    teacherRoleControllerUpdateMany: (data: UpdateManyTeacherRoleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneTeacherRoleDto, ResponseErrorDto | void>({
        path: `/teacher-roles`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teacher Role
     * @name TeacherRoleControllerDeleteMany
     * @request DELETE:/teacher-roles
     */
    teacherRoleControllerDeleteMany: (data: DeleteManyTeacherRoleDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/teacher-roles`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teacher Role
     * @name TeacherRoleControllerGetOne
     * @request GET:/teacher-roles/{id}
     */
    teacherRoleControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneTeacherRoleDto, ResponseErrorDto | void>({
        path: `/teacher-roles/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teacher Role
     * @name TeacherRoleControllerUpdateOne
     * @request PUT:/teacher-roles/{id}
     */
    teacherRoleControllerUpdateOne: (id: string, data: CreateTeacherRoleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneTeacherRoleDto, ResponseErrorDto | void>({
        path: `/teacher-roles/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teacher Role
     * @name TeacherRoleControllerDeleteOne
     * @request DELETE:/teacher-roles/{id}
     */
    teacherRoleControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/teacher-roles/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  employees = {
    /**
     * No description
     *
     * @tags Employee
     * @name EmployeeControllerGetMany
     * @summary Get list
     * @request GET:/employees
     */
    employeeControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        departmentId?: string
        name?: string
        mobilePhone?: string
        type?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyEmployeeDto, ResponseErrorDto | void>({
        path: `/employees`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name EmployeeControllerCreateOne
     * @request POST:/employees
     */
    employeeControllerCreateOne: (data: CreateEmployeeDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneEmployeeDto, ResponseErrorDto | void>({
        path: `/employees`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name EmployeeControllerUpdateMany
     * @request PATCH:/employees
     */
    employeeControllerUpdateMany: (data: UpdateManyEmployeeDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneEmployeeDto, ResponseErrorDto | void>({
        path: `/employees`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name EmployeeControllerDeleteMany
     * @request DELETE:/employees
     */
    employeeControllerDeleteMany: (data: DeleteManyEmployeeDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/employees`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name EmployeeControllerGetOne
     * @request GET:/employees/{id}
     */
    employeeControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneEmployeeDto, ResponseErrorDto | void>({
        path: `/employees/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name EmployeeControllerUpdateOne
     * @request PUT:/employees/{id}
     */
    employeeControllerUpdateOne: (id: string, data: CreateEmployeeDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneEmployeeDto, ResponseErrorDto | void>({
        path: `/employees/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name EmployeeControllerDeleteOne
     * @request DELETE:/employees/{id}
     */
    employeeControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/employees/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  teachers = {
    /**
     * No description
     *
     * @tags Teacher
     * @name TeacherControllerGetMany
     * @summary Get list
     * @request GET:/teachers
     */
    teacherControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        employeeId?: string
        name?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyTeacherDto, ResponseErrorDto | void>({
        path: `/teachers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teacher
     * @name TeacherControllerCreateOne
     * @request POST:/teachers
     */
    teacherControllerCreateOne: (data: CreateTeacherDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneTeacherDto, ResponseErrorDto | void>({
        path: `/teachers`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teacher
     * @name TeacherControllerUpdateMany
     * @request PATCH:/teachers
     */
    teacherControllerUpdateMany: (data: UpdateManyTeacherDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneTeacherDto, ResponseErrorDto | void>({
        path: `/teachers`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teacher
     * @name TeacherControllerDeleteMany
     * @request DELETE:/teachers
     */
    teacherControllerDeleteMany: (data: DeleteManyTeacherDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/teachers`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teacher
     * @name TeacherControllerGetOne
     * @request GET:/teachers/{id}
     */
    teacherControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneTeacherDto, ResponseErrorDto | void>({
        path: `/teachers/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teacher
     * @name TeacherControllerUpdateOne
     * @request PUT:/teachers/{id}
     */
    teacherControllerUpdateOne: (id: string, data: CreateTeacherDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneTeacherDto, ResponseErrorDto | void>({
        path: `/teachers/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teacher
     * @name TeacherControllerDeleteOne
     * @request DELETE:/teachers/{id}
     */
    teacherControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/teachers/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  courses = {
    /**
     * No description
     *
     * @tags Course
     * @name CourseControllerGetMany
     * @summary Get list
     * @request GET:/courses
     */
    courseControllerGetMany: (
      query?: { filterFields?: string[]; sortFields?: string[]; offset?: string; limit?: string; employeeId?: string },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyCourseDto, ResponseErrorDto | void>({
        path: `/courses`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course
     * @name CourseControllerCreateOne
     * @request POST:/courses
     */
    courseControllerCreateOne: (data: CreateCourseDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneCourseDto, ResponseErrorDto | void>({
        path: `/courses`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course
     * @name CourseControllerUpdateMany
     * @request PATCH:/courses
     */
    courseControllerUpdateMany: (data: UpdateManyCourseDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneCourseDto, ResponseErrorDto | void>({
        path: `/courses`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course
     * @name CourseControllerDeleteMany
     * @request DELETE:/courses
     */
    courseControllerDeleteMany: (data: DeleteManyCourseDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/courses`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course
     * @name CourseControllerGetOne
     * @request GET:/courses/{id}
     */
    courseControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneCourseDto, ResponseErrorDto | void>({
        path: `/courses/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course
     * @name CourseControllerUpdateOne
     * @request PUT:/courses/{id}
     */
    courseControllerUpdateOne: (id: string, data: CreateCourseDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneCourseDto, ResponseErrorDto | void>({
        path: `/courses/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course
     * @name CourseControllerDeleteOne
     * @request DELETE:/courses/{id}
     */
    courseControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/courses/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  absentSchedules = {
    /**
     * No description
     *
     * @tags Absent Schedule
     * @name AbsentScheduleControllerGetMany
     * @summary Get list
     * @request GET:/absent-schedules
     */
    absentScheduleControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        employeeId?: string
        fromDate?: string
        toDate?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyAbsentScheduleDto, ResponseErrorDto | void>({
        path: `/absent-schedules`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Absent Schedule
     * @name AbsentScheduleControllerCreateOne
     * @request POST:/absent-schedules
     */
    absentScheduleControllerCreateOne: (data: CreateAbsentScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneAbsentScheduleDto, ResponseErrorDto | void>({
        path: `/absent-schedules`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Absent Schedule
     * @name AbsentScheduleControllerUpdateMany
     * @request PATCH:/absent-schedules
     */
    absentScheduleControllerUpdateMany: (data: UpdateManyAbsentScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneAbsentScheduleDto, ResponseErrorDto | void>({
        path: `/absent-schedules`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Absent Schedule
     * @name AbsentScheduleControllerDeleteMany
     * @request DELETE:/absent-schedules
     */
    absentScheduleControllerDeleteMany: (data: DeleteManyAbsentScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/absent-schedules`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Absent Schedule
     * @name AbsentScheduleControllerGetOne
     * @request GET:/absent-schedules/{id}
     */
    absentScheduleControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneAbsentScheduleDto, ResponseErrorDto | void>({
        path: `/absent-schedules/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Absent Schedule
     * @name AbsentScheduleControllerUpdateOne
     * @request PUT:/absent-schedules/{id}
     */
    absentScheduleControllerUpdateOne: (id: string, data: UpdateAbsentScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneAbsentScheduleDto, ResponseErrorDto | void>({
        path: `/absent-schedules/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Absent Schedule
     * @name AbsentScheduleControllerDeleteOne
     * @request DELETE:/absent-schedules/{id}
     */
    absentScheduleControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/absent-schedules/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  workSchedules = {
    /**
     * No description
     *
     * @tags Work Schedule
     * @name WorkScheduleControllerGetMany
     * @summary Get list
     * @request GET:/work-schedules
     */
    workScheduleControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        employeeId?: string
        fromDate?: string
        toDate?: string
        departmentId?: string
        status?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyWorkScheduleDto, ResponseErrorDto | void>({
        path: `/work-schedules`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work Schedule
     * @name WorkScheduleControllerCreateOne
     * @request POST:/work-schedules
     */
    workScheduleControllerCreateOne: (data: CreateWorkScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneWorkScheduleDto, ResponseErrorDto | void>({
        path: `/work-schedules`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work Schedule
     * @name WorkScheduleControllerUpdateMany
     * @request PATCH:/work-schedules
     */
    workScheduleControllerUpdateMany: (data: UpdateManyWorkScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneWorkScheduleDto, ResponseErrorDto | void>({
        path: `/work-schedules`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work Schedule
     * @name WorkScheduleControllerDeleteMany
     * @request DELETE:/work-schedules
     */
    workScheduleControllerDeleteMany: (data: DeleteManyWorkScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/work-schedules`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work Schedule
     * @name WorkScheduleControllerGetOne
     * @request GET:/work-schedules/{id}
     */
    workScheduleControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneWorkScheduleDto, ResponseErrorDto | void>({
        path: `/work-schedules/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work Schedule
     * @name WorkScheduleControllerUpdateOne
     * @request PUT:/work-schedules/{id}
     */
    workScheduleControllerUpdateOne: (id: string, data: UpdateWorkScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneWorkScheduleDto, ResponseErrorDto | void>({
        path: `/work-schedules/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work Schedule
     * @name WorkScheduleControllerDeleteOne
     * @request DELETE:/work-schedules/{id}
     */
    workScheduleControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/work-schedules/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  availability = {
    /**
     * No description
     *
     * @tags Availability
     * @name AvailabilityControllerCheck
     * @summary Get list
     * @request POST:/availability
     */
    availabilityControllerCheck: (data: CheckAvailabilityDto, params: RequestParams = {}) =>
      this.request<ResponseCheckAvailabilityDto, ResponseErrorDto | void>({
        path: `/availability`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Availability
     * @name AvailabilityControllerGetEmployees
     * @summary Get list
     * @request GET:/availability/employees
     */
    availabilityControllerGetEmployees: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        type?: "LECTURER" | "TUTOR" | "INTERN" | "INTERN_LECTURER"
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseCheckAvailabilityDto, ResponseErrorDto | void>({
        path: `/availability/employees`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  }
  classes = {
    /**
     * No description
     *
     * @tags Class
     * @name ClassControllerGetMany
     * @summary Get list
     * @request GET:/classes
     */
    classControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        code?: string
        name?: string
        status?: string
        employeeId?: string
        fromDate?: string
        toDate?: string
        locationId?: string
        subjectId?: string
        roomId?: string
        isDeleted?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyClassDto, ResponseErrorDto | void>({
        path: `/classes`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Class
     * @name ClassControllerCreateOne
     * @request POST:/classes
     */
    classControllerCreateOne: (data: CreateClassDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneClassDto, ResponseErrorDto | void>({
        path: `/classes`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Class
     * @name ClassControllerUpdateMany
     * @request PATCH:/classes
     */
    classControllerUpdateMany: (data: UpdateManyClassDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneClassDto, ResponseErrorDto | void>({
        path: `/classes`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Class
     * @name ClassControllerDeleteMany
     * @request DELETE:/classes
     */
    classControllerDeleteMany: (data: DeleteManyClassDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/classes`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Class
     * @name ClassControllerGetOne
     * @request GET:/classes/{id}
     */
    classControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneClassDto, ResponseErrorDto | void>({
        path: `/classes/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Class
     * @name ClassControllerUpdateOne
     * @request PUT:/classes/{id}
     */
    classControllerUpdateOne: (id: string, data: UpdateClassDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneClassDto, ResponseErrorDto | void>({
        path: `/classes/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Class
     * @name ClassControllerDeleteOne
     * @request DELETE:/classes/{id}
     */
    classControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/classes/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Class
     * @name ClassControllerAcceptClass
     * @request PATCH:/classes/{id}/accept
     */
    classControllerAcceptClass: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneClassDto, ResponseErrorDto | void>({
        path: `/classes/${id}/accept`,
        method: "PATCH",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Class
     * @name ClassControllerCancelClass
     * @request PATCH:/classes/{id}/cancel
     */
    classControllerCancelClass: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneClassDto, ResponseErrorDto | void>({
        path: `/classes/${id}/cancel`,
        method: "PATCH",
        format: "json",
        ...params,
      }),
  }
  studySchedules = {
    /**
     * No description
     *
     * @tags Study Schedule
     * @name CheckStudyScheduleControllerCheckInvalid
     * @summary Get list
     * @request GET:/study-schedules/check-invalid
     */
    checkStudyScheduleControllerCheckInvalid: (
      query: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        fromDate: string
        toDate: string
        areas?: string
        locationIds?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyStudyScheduleDto, ResponseErrorDto | void>({
        path: `/study-schedules/check-invalid`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study Schedule
     * @name StudyScheduleControllerGenerate
     * @summary Get list
     * @request POST:/study-schedules/generate
     */
    studyScheduleControllerGenerate: (data: GenerateStudyScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseGetManyStudyScheduleDto, ResponseErrorDto | void>({
        path: `/study-schedules/generate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study Schedule
     * @name StudyScheduleControllerPreview
     * @summary Get list preview
     * @request POST:/study-schedules/preview
     */
    studyScheduleControllerPreview: (data: PreviewStudyScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseGetManyStudyScheduleDto, ResponseErrorDto | void>({
        path: `/study-schedules/preview`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study Schedule
     * @name StudyScheduleControllerGetMany
     * @summary Get list
     * @request GET:/study-schedules
     */
    studyScheduleControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        employeeId?: string
        fromDate?: string
        toDate?: string
        classId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyStudyScheduleDto, ResponseErrorDto | void>({
        path: `/study-schedules`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study Schedule
     * @name StudyScheduleControllerCreateOne
     * @request POST:/study-schedules
     */
    studyScheduleControllerCreateOne: (data: CreateStudyScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneStudyScheduleDto, ResponseErrorDto | void>({
        path: `/study-schedules`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study Schedule
     * @name StudyScheduleControllerUpdateMany
     * @request PATCH:/study-schedules
     */
    studyScheduleControllerUpdateMany: (data: UpdateManyStudyScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneStudyScheduleDto, ResponseErrorDto | void>({
        path: `/study-schedules`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study Schedule
     * @name StudyScheduleControllerGetOne
     * @request GET:/study-schedules/{id}
     */
    studyScheduleControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneStudyScheduleDto, ResponseErrorDto | void>({
        path: `/study-schedules/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study Schedule
     * @name StudyScheduleControllerUpdateOne
     * @request PUT:/study-schedules/{id}
     */
    studyScheduleControllerUpdateOne: (id: string, data: UpdateStudyScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneStudyScheduleDto, ResponseErrorDto | void>({
        path: `/study-schedules/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study Schedule
     * @name StudyScheduleControllerDeleteOne
     * @request DELETE:/study-schedules/{id}
     */
    studyScheduleControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/study-schedules/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study Schedule
     * @name StudyScheduleControllerCheckStatusSchedule
     * @request POST:/study-schedules/check-status
     */
    studyScheduleControllerCheckStatusSchedule: (data: CheckStatusScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseSuccessDto, ResponseErrorDto | void>({
        path: `/study-schedules/check-status`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  }
  uploads = {
    /**
     * No description
     *
     * @tags Upload
     * @name UploadControllerUploadFile
     * @request POST:/uploads
     */
    uploadControllerUploadFile: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/uploads`,
        method: "POST",
        ...params,
      }),
  }
  workTimes = {
    /**
     * No description
     *
     * @tags Work time
     * @name WorkTimeControllerGetMany
     * @summary Get list
     * @request GET:/work-times
     */
    workTimeControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        classId?: string
        locationId?: string
        roomId?: string
        fromDate?: string
        toDate?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyWorkTimeDto, ResponseErrorDto | void>({
        path: `/work-times`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work time
     * @name WorkTimeControllerCreateOne
     * @request POST:/work-times
     */
    workTimeControllerCreateOne: (data: CreateWorkTimeDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneWorkTimeDto, ResponseErrorDto | void>({
        path: `/work-times`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work time
     * @name WorkTimeControllerUpdateMany
     * @request PATCH:/work-times
     */
    workTimeControllerUpdateMany: (data: UpdateManyWorkTimeDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneWorkTimeDto, ResponseErrorDto | void>({
        path: `/work-times`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work time
     * @name WorkTimeControllerDeleteMany
     * @request DELETE:/work-times
     */
    workTimeControllerDeleteMany: (data: DeleteManyWorkTimeDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/work-times`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work time
     * @name WorkTimeControllerGetOne
     * @request GET:/work-times/{id}
     */
    workTimeControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneWorkTimeDto, ResponseErrorDto | void>({
        path: `/work-times/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work time
     * @name WorkTimeControllerUpdateOne
     * @request PUT:/work-times/{id}
     */
    workTimeControllerUpdateOne: (id: string, data: UpdateWorkTimeDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneWorkTimeDto, ResponseErrorDto | void>({
        path: `/work-times/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work time
     * @name WorkTimeControllerDeleteOne
     * @request DELETE:/work-times/{id}
     */
    workTimeControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/work-times/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  seedData = {
    /**
     * No description
     *
     * @tags Seed data
     * @name SeedDataControllerCreateOne
     * @request POST:/seed-data
     */
    seedDataControllerCreateOne: (params: RequestParams = {}) =>
      this.request<void, ResponseErrorDto>({
        path: `/seed-data`,
        method: "POST",
        ...params,
      }),
  }
  teachSchedules = {
    /**
     * No description
     *
     * @tags Teach Schedule
     * @name TeachScheduleControllerGetMany
     * @summary Get list
     * @request GET:/teach-schedules
     */
    teachScheduleControllerGetMany: (
      query?: {
        filterFields?: string[]
        sortFields?: string[]
        offset?: string
        limit?: string
        classId?: string
        status?: string
        employeeId?: string
        date?: string
        locationId?: string
        roomId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseGetManyTeachScheduleDto, ResponseErrorDto | void>({
        path: `/teach-schedules`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teach Schedule
     * @name TeachScheduleControllerCreateOne
     * @request POST:/teach-schedules
     */
    teachScheduleControllerCreateOne: (data: CreateTeachScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneTeachScheduleDto, ResponseErrorDto | void>({
        path: `/teach-schedules`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teach Schedule
     * @name TeachScheduleControllerUpdateMany
     * @request PATCH:/teach-schedules
     */
    teachScheduleControllerUpdateMany: (data: UpdateManyTeachScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneTeachScheduleDto, ResponseErrorDto | void>({
        path: `/teach-schedules`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teach Schedule
     * @name TeachScheduleControllerDeleteMany
     * @request DELETE:/teach-schedules
     */
    teachScheduleControllerDeleteMany: (data: DeleteManyTeachScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/teach-schedules`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teach Schedule
     * @name TeachScheduleControllerGetOne
     * @request GET:/teach-schedules/{id}
     */
    teachScheduleControllerGetOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseGetOneTeachScheduleDto, ResponseErrorDto | void>({
        path: `/teach-schedules/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teach Schedule
     * @name TeachScheduleControllerUpdateOne
     * @request PUT:/teach-schedules/{id}
     */
    teachScheduleControllerUpdateOne: (id: string, data: UpdateTeachScheduleDto, params: RequestParams = {}) =>
      this.request<ResponseGetOneTeachScheduleDto, ResponseErrorDto | void>({
        path: `/teach-schedules/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teach Schedule
     * @name TeachScheduleControllerDeleteOne
     * @request DELETE:/teach-schedules/{id}
     */
    teachScheduleControllerDeleteOne: (id: string, params: RequestParams = {}) =>
      this.request<ResponseDeleteSuccessDto, ResponseErrorDto | void>({
        path: `/teach-schedules/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
}
