import {
  GET_DETAIL_ROOM_SUCCESS,
  GET_LIST_ROOM,
  GET_LIST_ROOM_SUCCESS,
  PAGINATION_DEFAULT,
  SET_LOADING_ROOM,
} from "../../constants"
import { IRoomState } from "../types"

interface ActionProps {
  type: string
  payload: any
}

const initState: IRoomState = {
  list: {
    rooms: [],
    total: 0,
    limit: PAGINATION_DEFAULT.limit,
    offset: PAGINATION_DEFAULT.offset,
  },
  detail: {
    rooms: {},
  },
  isLoadingRequest: false,
  isDeleteRequest: false,
  isUpdateRequest: false,
  isCreateRequest: false,
}

export const room = (state: IRoomState = initState, action: ActionProps) => {
  const { type, payload } = action

  switch (type) {
    case GET_LIST_ROOM: {
      return {
        ...state,
      }
    }
    case GET_LIST_ROOM_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          rooms: payload.data,
          total: payload.total,
        },
      }
    }
    case GET_DETAIL_ROOM_SUCCESS: {
      return {
        ...state,
        detail: {
          ...state.detail,
          rooms: payload.data,
        },
      }
    }
    case SET_LOADING_ROOM: {
      return {
        ...state,
        ...payload,
      }
    }
    default: {
      return state
    }
  }
}
