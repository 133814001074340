import { Input, Tree } from "antd"
import React, { useEffect, useState } from "react"
import "./index.css"

const { Search } = Input

interface SearchSelectTreeProps {
  treeData: any[]
  onCheckedEmit: (value: any) => void
  expandKs: string[]
  checkedKs: string[]
}

export interface InitCheckProps {
  id: string
  actionIds: string[]
}

const dataList: any[] = []
const generateList = (data: any[]) => {
  for (let i = 0; i < data.length; i += 1) {
    const node = data[i]
    const { key, title } = node
    dataList.push({ key, title })
    if (node.children) {
      generateList(node.children)
    }
  }
}

const getParentKey = (key: string, tree: any[]): any => {
  let parentKey
  for (let i = 0; i < tree.length; i += 1) {
    const node = tree[i]
    if (node.children) {
      if (node.children.some((item: any) => item.key === key)) {
        parentKey = node.key
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children)
      }
    }
  }
  return parentKey
}

export const SearchSelectTree: React.FC<SearchSelectTreeProps> = ({ treeData, onCheckedEmit, expandKs, checkedKs }) => {
  const [searchValue, setEearchValue] = useState<string>("")
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true)
  const [expandedKeys, setExpandedKeys] = useState<any[]>([...(expandKs || [])])
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([...(checkedKs || [])])

  useEffect(() => {
    generateList(treeData)
  }, [treeData])

  const onChange = (e: any) => {
    const { value } = e.target
    const exKeys = dataList
      .map(item => {
        if (item.title.indexOf(value) > -1) {
          return getParentKey(item.key, treeData)
        }
        return null
      })
      .filter((item, i, self) => item && self.indexOf(item) === i)
    setExpandedKeys(exKeys)
    setEearchValue(value)
    setAutoExpandParent(true)
  }

  const onExpand = (exKeys: React.Key[]) => {
    setExpandedKeys(exKeys)
    setAutoExpandParent(false)
  }

  const onCheck = (checkedKVs: any) => {
    onCheckedEmit(checkedKVs)
    setCheckedKeys(checkedKVs)
  }

  const loop = (data: any[]): any =>
    data.map((item: any) => {
      const index = item.title.indexOf(searchValue)
      const beforeStr = item.title.substr(0, index)
      const afterStr = item.title.substr(index + searchValue.length)
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span className="site-tree-search-value">{searchValue}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        )
      if (item.children) {
        return { title, key: item.key, children: loop(item.children) }
      }

      return {
        title,
        key: item.key,
      }
    })

  return (
    <div>
      <Search style={{ marginBottom: 8 }} placeholder="Tìm kiếm" onChange={onChange} />
      <Tree
        style={{ maxHeight: 500, overflow: "auto" }}
        checkable
        onCheck={onCheck}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        checkedKeys={checkedKeys}
        treeData={loop(treeData)}
      />
    </div>
  )
}
