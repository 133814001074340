import { DebounceSelect } from "@/components/antd/form/DebounceSelect"
import { Table, TableProps } from "@/components/antd/table/Table"
import { ACTION_METHOD_OBJ } from "@/constants"
import { API } from "@/network/http"
import { CRUD_ACTION } from "@/types"
import { formatOnlyDate } from "@/utils"
import { TeacherDto } from "@/__generated__/api-v1"
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons"
import type { ActionType, ProColumns } from "@ant-design/pro-table"
import { notification, Popconfirm, Space, Tooltip } from "antd"
import { FC, useRef, useState } from "react"
import { DEFINE_MODULE } from "../constants"
import UpsertForm from "./UpsertForm"

const List: FC = () => {
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false)
  const [isCreate, setCreate] = useState<boolean>(false)
  const [isUpdate, setUpdate] = useState<boolean>(false)
  const [values, setValues] = useState<any>()
  const actionRef = useRef<ActionType>()

  const [notifyApi, contextHolder] = notification.useNotification()

  const onClickEditButton = async (id: string) => {
    const detailData = await API.teachers.teacherControllerGetOne(id)
    setValues(detailData.data.data)
    setUpdate(true)
  }

  const onClickDeleteButton = async (id: string) => {
    try {
      await API.teachers.teacherControllerDeleteOne(id)
      notifyApi.success({ message: `${ACTION_METHOD_OBJ["DELETE"]} ${DEFINE_MODULE.name} thành công` })
      actionRef.current?.reload()
    } catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      })
    }
  }

  const onSubmit = async (val: any, type: CRUD_ACTION, _id?: string) => {
    try {
      setLoadingBtn(true)
      const reqData =
        type === "CREATE"
          ? await API.teachers.teacherControllerCreateOne(val)
          : await API.teachers.teacherControllerUpdateOne(_id || "", val)
      if (reqData && reqData.data) {
        if (type === "CREATE") setCreate(false)
        if (type === "UPDATE") setUpdate(false)
        notifyApi.success({
          message: "Thông báo",
          description: `${ACTION_METHOD_OBJ[type]} thành công`,
          placement: "topRight",
        })
        actionRef.current?.reload()
      }
    } catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      })
    } finally {
      setLoadingBtn(false)
    }
  }

  const fetchData = async (queries: Parameters<typeof API.teachers.teacherControllerGetMany>["0"]) => {
    return (await API.teachers.teacherControllerGetMany({ ...queries })).data
  }

  const onDeleteMany = async (ids: string[]) => {
    try {
      await API.teachers.teacherControllerDeleteMany({ ids })
      notifyApi.success({ message: `${ACTION_METHOD_OBJ["DELETE"]} ${DEFINE_MODULE.name} thành công` })
      actionRef.current?.reload()
    } catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      })
    }
  }

  const onArchiveMany = async (ids: string[]) => {
    try {
      await API.teachers.teacherControllerUpdateMany({ ids, isArchived: true })
      notifyApi.success({ message: `${ACTION_METHOD_OBJ["ARCHIVE"]} ${ids.length} ${DEFINE_MODULE.name} thành công` })
      actionRef.current?.reload()
    } catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      })
    }
  }

  const onUnarchiveMany = async (ids: string[]) => {
    try {
      await API.teachers.teacherControllerUpdateMany({ ids, isArchived: false })
      notifyApi.success({ message: `${ACTION_METHOD_OBJ["UNARCHIVE"]} ${ids.length} ${DEFINE_MODULE.name} thành công` })
      actionRef.current?.reload()
    } catch (err: any) {
      notifyApi?.error({
        message: err.error?.message || err.message || err,
      })
    }
  }

  const getListEmployee = async (searchValue?: string) => {
    const response = await API.employees.employeeControllerGetMany({ name: searchValue })
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    )
  }

  const getListLocation = async (searchValue?: string) => {
    const response = await API.locations.locationControllerGetMany({ name: searchValue })
    return (
      response?.data?.data?.map((item: any) => ({
        value: item._id,
        key: item._id,
        name: item.name,
      })) || []
    )
  }

  const columns: ProColumns<TeacherDto & { employee: any }>[] = [
    {
      hideInTable: true,
      dataIndex: "employeeId",
      title: "Nhân viên",
      renderFormItem: () => {
        return <DebounceSelect placeholder="Chọn nhân viên" fetchOptions={getListEmployee} />
      },
    },
    {
      hideInTable: true,
      dataIndex: "locationId",
      title: "Địa điểm làm việc",
      renderFormItem: () => {
        return <DebounceSelect placeholder="Chọn địa điểm làm việc" fetchOptions={getListLocation} />
      },
    },
    {
      dataIndex: ["employee", "name"],
      title: "Nhân viên",
      search: false,
    },
    {
      dataIndex: "---",
      title: "Chức vụ",
      search: false,
    },
    {
      dataIndex: ["employee", "workAddress"],
      title: "Địa điểm làm việc",
      search: false,
    },
    {
      dataIndex: ["employee", "startWorkingDate"],
      title: "Ngày bắt đầu làm việc",
      search: false,
      renderText: (_, entity) => formatOnlyDate(entity.employee.startWorkingDate),
    },
    {
      dataIndex: ["employee", "manager", "name"],
      title: "Người quản lý",
      search: false,
    },
    {
      title: "Thao tác",
      align: "center",
      search: false,
      fixed: "right",
      width: 80,
      render: (_, entity) => {
        return (
          <Space size={10}>
            <Tooltip title={`Cập nhật ${DEFINE_MODULE.name}`}>
              <EditTwoTone onClick={() => onClickEditButton(entity._id)} />
            </Tooltip>
            <Tooltip title={`Xoá ${DEFINE_MODULE.name}`}>
              <Popconfirm
                title={`Bạn có chắc rằng muốn xoá ${DEFINE_MODULE.name} này không?`}
                onConfirm={() => onClickDeleteButton(entity._id)}
                okText="Có"
                cancelText="Không"
              >
                <DeleteTwoTone twoToneColor={"red"} />
              </Popconfirm>
            </Tooltip>
          </Space>
        )
      },
    },
  ]

  const protableProps: TableProps = {
    actionRef,
    DEFINE_MODULE,
    contextHolder,
    fetchData,
    columns,
    deleteMany: onDeleteMany,
    archiveMany: onArchiveMany,
    unArchiveMany: onUnarchiveMany,
    createForm: {
      isCreate,
      setCreate,
      component: (
        <UpsertForm
          key="CREATE"
          onCancel={() => setCreate(false)}
          modalVisible={isCreate}
          onSubmit={onSubmit}
          actionType="CREATE"
          loadingBtn={loadingBtn}
        />
      ),
    },
    updateForm: {
      isUpdate,
      component: (
        <UpsertForm
          key="UPDATE"
          onCancel={() => setUpdate(false)}
          modalVisible={isUpdate}
          onSubmit={onSubmit}
          actionType="UPDATE"
          loadingBtn={loadingBtn}
          values={values}
        />
      ),
    },
  }

  return <Table {...protableProps} />
}

export default List
