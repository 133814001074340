import { GetDateRangeProps, GetDateRangeResponseProp } from "@/typing/date"
import { TimeFrame } from "@/__generated__/graphql-types"
import fnsFormat from "date-fns/format"
import { slice, sortBy } from "lodash"
import moment, { Moment } from "moment"

export const formatDate = (date: string, format = "DD/MM/YY") => {
  return moment(date).format(format)
}

export const dateFormat = (date: string, format?: string) => {
  return fnsFormat(new Date(date), format ?? "dd/MM/yy, hh:mm")
}

export const formatOnlyDate = (date: string, format?: "dd/MM/yyyy") => {
  return fnsFormat(new Date(date), format ?? "dd/MM/yyyy")
}

export const getHourNumberByString = (date: string) => {
  const datetime = new Date(date)
  const hours = datetime.getHours()
  const minutes = datetime.getMinutes()
  return hours * 60 + minutes
}

export const getHourStringByNumber = (time: number) => {
  const hours = Math.floor(time / 60)
  const minutes = time - hours * 60
  const datetime = new Date()
  datetime.setHours(hours, minutes, 0, 0)
  return datetime.toString()
}

export const startNendDayOfWeek = (
  weekInYear: number,
): {
  startWeek: Moment
  endWeek: Moment
} => {
  const getWeekNumber = moment().isoWeek(weekInYear)
  const firstDayOfWeek = moment(getWeekNumber).startOf("isoWeek")
  const lastDayOfWeek = moment(getWeekNumber).endOf("isoWeek")
  return {
    startWeek: firstDayOfWeek,
    endWeek: lastDayOfWeek,
  }
}

export const startNendDayOfWeekWithFormat = (
  weekInYear: number,
  format = "DD-MM-YYYY",
): {
  startWeek: string
  endWeek: string
} => {
  const { startWeek: start, endWeek: end } = startNendDayOfWeek(weekInYear)
  return {
    startWeek: start.format(format),
    endWeek: end.format(format),
  }
}

export const getListDayInWeek = (
  firstDayOfWeek: string,
): {
  time: string
  dayName: string
}[] => {
  const listDayInWeek = []
  const begin = moment(firstDayOfWeek).startOf("week").isoWeekday(1)
  for (let i = 0; i < 7; i++) {
    const time = begin.format("DD-MM-YYYY")
    const dayName = begin.format("dddd").toLowerCase()
    listDayInWeek.push({
      time,
      dayName,
    })
    begin.add("d", 1)
  }
  return listDayInWeek
}

export const getDayInWeek = (weekNumber: number) => {
  const getWeekNumber = moment().isoWeek(weekNumber)
  const weekStart = moment(getWeekNumber).startOf("isoWeek")
  const days = []
  for (let i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, "days"))
  }
  return days
}

export const convertUixTime = (unixTime: string | number) => {
  if (typeof unixTime === "string") {
    return moment(Number(unixTime)).format("DD-MM-YYYY")
  }
  return moment(unixTime).format("DD-MM-YYYY")
}

// p-d-0
// poles: p | n ( + | - )
// dateType: d | w | m | y
// numDate: 0 is today , another is number;
export const handleGetDateRange = (dataDate: GetDateRangeProps): GetDateRangeResponseProp => {
  moment.updateLocale("en", {
    week: {
      dow: 1, // Monday is the first day of the week.
    },
  })
  const { poles, dateType, numDate } = dataDate
  let timeGet
  let timeOf
  switch (dateType) {
    case "d":
      timeOf = "day"
      break
    case "w":
      timeOf = "week"
      break
    case "M":
      timeOf = "month"
      break
    case "y":
      timeOf = "year"
      break
    default:
      break
  }
  switch (poles) {
    case "n":
      timeGet = moment().subtract(numDate, dateType)
      break
    case "p":
    default:
      timeGet = moment().add(numDate, dateType)
      break
  }
  // @ts-ignore
  const fromDate = moment(timeGet).startOf(timeOf || "day")
  // @ts-ignore
  const toDate = moment(timeGet).endOf(timeOf || "day")

  // @ts-ignore
  return {
    // @ts-ignore
    fromDate,
    // @ts-ignore
    toDate,
  }
}

export const calculateGraduationDate = (frames: TimeFrame[], openingDate: Moment, numberOfLessions: number): Moment => {
  const ISO_WEEKDAYS_MAP = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  }

  if (frames.some(e => !e?.schoolHourId || !e?.dayOfWeek)) {
    return moment()
  }

  const validFrames = frames
    .reduce((prev: TimeFrame[], current) => {
      return prev?.find(e => e?.dayOfWeek === current?.dayOfWeek && e?.schoolHourId === current?.schoolHourId)
        ? prev
        : [...prev, current]
    }, [])
    .map(e => {
      // @ts-ignore

      return { ...e, dayOfWeekInNumber: ISO_WEEKDAYS_MAP[e?.dayOfWeek] as number }
    })

  const sortFrames = (frames: typeof validFrames, currentWeekday: number) => {
    const i = frames.findIndex(el => el.dayOfWeekInNumber >= currentWeekday)

    return [...slice(frames, i), ...slice(frames, 0, i)]
  }

  const sortedFrames = sortFrames(sortBy(validFrames, ["dayOfWeekInNumber", "asc"]), moment(openingDate).isoWeekday())

  let currentDate = openingDate
  while (numberOfLessions > 0) {
    if (numberOfLessions <= sortedFrames.length) {
      for (const i in sortedFrames) {
        const diff = sortedFrames[i].dayOfWeekInNumber - currentDate.isoWeekday()

        if (diff >= 0) {
          currentDate = currentDate.add(diff, "days")
        } else {
          currentDate = currentDate.add(7 - currentDate.isoWeekday() + sortedFrames[i].dayOfWeekInNumber, "days")
        }

        numberOfLessions--
      }
    } else {
      numberOfLessions = numberOfLessions - sortedFrames.length
      currentDate = moment(currentDate).add(1, "weeks")
    }
  }

  return currentDate
}
